// src/features/products/basicdisplay/productBasicsSlice.js
import productsData from '../../../data/products_.json';
import stacksData from '../../../data/stacks_.json';
import { createSlice } from '@reduxjs/toolkit';

// Prepare the initial state
const initialStack = stacksData.find(stack => stack.stack_id === 1);
initialStack.products_inStack = initialStack.products_inStack || [];

const initialAvailableProducts = productsData.filter(product => 
  !initialStack.products_inStack.includes(product.product_id)
);

export const productBasicsSlice = createSlice({
  name: 'productBasics',
  initialState: {
    availableProducts: initialAvailableProducts,
    stack: initialStack,
    productStack: [],
  },
  reducers: {
    // Action to set the available products
    setAvailableProducts: (state, action) => {
      state.availableProducts = action.payload;
    },
    
    setProductStack: (state, action) => {
      // Assuming action.payload is an array of products
      state.productStack = action.payload;
    },

    // Action to add a product to the stack
    addProductToStack: (state, action) => {
      const { productId } = action.payload;
      const product = state.availableProducts.find(p => p.product_id === productId);
      if (product) {
        state.stack.products_inStack.push(productId);
        // Remove from available products
        state.availableProducts = state.availableProducts.filter(p => p.product_id !== productId);
      }
    },
    // Action to remove a product from the stack
    removeProductFromStack: (state, action) => {
      const { productId } = action.payload;
      const productIndex = state.stack.products_inStack.indexOf(productId);
      if (productIndex !== -1) {
        state.stack.products_inStack.splice(productIndex, 1);
        // Add back to available products
        // state.availableProducts.push(productsData.find(p => p.product_id === productId));
        state.availableProducts = [...state.availableProducts, productsData.find(p => p.product_id === productId)];
      }
    },
    
  }
  // Other Reducers as needed
});

export const { setAvailableProducts, addProductToStack, removeProductFromStack, updateStack } = productBasicsSlice.actions;

export default productBasicsSlice.reducer;