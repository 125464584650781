import React from "react";
import { Box } from "@mui/material";
import HeaderStreamed from "../../../components/headerstreamed/HeaderStreamed";
import  "./userAccountTeams.scss";
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import ImageFiller from 'react-image-filler';
import './userAccountTeams.scss';

const UserAccountTeams = ({userData, userId, entityType}) => {

  return(
    <Box className='user-account-page-container'>
        <HeaderStreamed title="My Teams" subtitle="create, manage and delete teams from here"/>
        <Box className="account-container-main" >
            <Box className="account-gridPlusSide" >
                <Box className= "account-main-grid">
                    <BoxComponent
                        title="Create New Team"
                        subtitle="set general feed preferences | some are premium features (e.g. turn off ads)"
                        width={12}
                    >
                    </BoxComponent>
                    
                    <BoxComponent
                        title="Manage Teams"
                        subtitle="search, add or remove interests from your feed"
                        width={12}
                    >
                    </BoxComponent>
                    
                    <BoxComponent 
                    title="Manage Users" 
                    subtitle="same implementaiton as with 'manage interests' component"
                    width={12}
                    // sideText="10000"
                    // icon={<DownloadOutlinedIcon/>}
                    // showButton={true}
                    // link="https://www.google.com"  
                    />
                </Box>
                <Box className="account-right-panel" >
                    <SideBarComponent 
                        title="Side Panel Widget 1"
                        // subtitle="This is irreversible"
                        sideText="10"
                        showButton={true}
                        link="https://www.google.com"
                    >
                        
                    </SideBarComponent>
                    <SideBarComponent 
                        title="Side Panel Widget 2"
                        // subtitle="This is sidebar box 2"
                        // sideText="10"
                        showButton={false}
                        // link="https://www.google.com"
                    >
                        <div><ImageFiller width={260} height={100}/></div>
                    </SideBarComponent>
                    <SideBarComponent 
                        title="Side Panel Widget 3"
                        // subtitle="This is sidebar box 3"
                        // sideText="10"
                        showButton={false}
                        // link="https://www.google.com"
                    >
                        <div><ImageFiller width={260} height={100}/></div>
                    </SideBarComponent>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}


export default UserAccountTeams;