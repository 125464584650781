import React from "react";
import { Box } from "@mui/material";
import HeaderStreamed from "../../../components/headerstreamed/HeaderStreamed";
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import ImageFiller from 'react-image-filler';
import FeedInterest from "../../../components/feed/feedsettings/feedinterests/feedInterests";
import  "./userAccountFeed.scss";

const UserAccountFeed = ({userData, userId, entityType}) => {

  return(
    <Box className='user-account-page-container'>
        <HeaderStreamed title="My Feed" subtitle="customise your feed | needs components for each area of feed"/>
        <Box className="account-container-main" >
            <Box className="account-gridPlusSide" >
                <Box className= "account-main-grid">
                    <BoxComponent
                        title="General Feed Setting"
                        subtitle="set general feed preferences | some are premium features (e.g. turn off ads)"
                        width={12}
                    >
                    </BoxComponent>
                    
                    <BoxComponent
                        title="Manage Interests"
                        subtitle="search, add or remove interests from your feed"
                        width={12}
                    >
                        <FeedInterest/>
                    </BoxComponent>
                    
                    <BoxComponent 
                    title="Manage Categories" 
                    subtitle="same implementaiton as with 'manage interests' component"
                    width={12}
                    />
                    
                    <BoxComponent title="Inclusion of Product Updates" subtitle="general toggle-based preference setting" width={12} />
                    <BoxComponent title="Inclusion of Stack Updates" subtitle="general toggle-based preference setting" width={12} />
                    <BoxComponent title="Inclusion of Updates from Other Users" subtitle="toggle for e.g. 'include user posts', 'replies to my comments', etc." width={12} />
                    
                </Box>
                <Box className="account-right-panel" >
                    <SideBarComponent 
                        title="Side Panel Widget"
                    >
                        <div><ImageFiller width={260} height={100}/></div>
                    </SideBarComponent>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}


export default UserAccountFeed;