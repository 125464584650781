import React, { useState } from 'react';
import { Box, Tab, Tabs, Grid, Typography } from '@mui/material';
import StackTileSimple from './../../global/StackTileSimple';
import ImageFiller from 'react-image-filler';
import './myStacksMain.scss';

const categories = [
    'My Stacks',
    'Shared with Me',
    'Following',
    'Recent',
    'Recommended',
    'All',
];

const mockData = {
    'My Stacks': ['Stack 1', 'Stack 2', 'Stack 3'],
    'Shared with Me': ['Stack A', 'Stack B'],
    Following: [
        'Stack 1',
        'Stack 2',
        'Stack 3',
        'Stack 4',
        'Stack 5',
        'Stack 6',
    ],
};

const MyStacksMain = () => {
    const [selectedCategory, setSelectedCategory] = useState(0);
    const currentCategory = categories[selectedCategory];
    const stacksToShow = mockData[currentCategory] || [];

    return (
        <div className="mainContainer">
            <Box
                sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {/* Tabs */}
                <Tabs
                    value={selectedCategory}
                    onChange={(event, newValue) =>
                        setSelectedCategory(newValue)
                    }
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'transparent',
                        },
                        '& .MuiTab-root.Mui-selected': {
                            fontWeight: 'bold',
                            color: 'yellow !important',
                            borderBottom: '2px solid #fff',
                        },
                    }}
                >
                    {categories.map((category, index) => (
                        <Tab
                            key={index}
                            label={category}
                            sx={{
                                borderBottom: '0px solid gray',
                                borderRadius: '0px',
                            }}
                        />
                    ))}
                </Tabs>

                {/* Main Content Area */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    border="1px solid white"
                    flexDirection="row"
                    width="100%"
                    mt={3}
                >
                    {/* Left - Grid of Stacks */}
                    <Box
                        flex="0.7"
                        pr={2}
                        border="1px solid green"
                        margin="10px"
                    >
                        <Grid container spacing={1}>
                            {stacksToShow.map((stack, index) => (
                                <Grid item xs={12} md={3} key={index}>
                                    <StackTileSimple productName={stack} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    {/* Right - Widgets */}
                    <Box
                        flex="0.2"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="200px"
                            bgcolor="#f5f5f5"
                            borderRadius="8px"
                        >
                            <Typography color="black" variant="h6">
                                Widget 1
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="200px"
                            bgcolor="#f5f5f5"
                            borderRadius="8px"
                        >
                            <Typography color="black" variant="h6">
                                Widget 2
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="200px"
                            bgcolor="#f5f5f5"
                            borderRadius="8px"
                        >
                            <Typography color="black" variant="h6">
                                Widget 3
                            </Typography>
                        </Box>
                        <div>
                            <ImageFiller width={280} height={200} />
                        </div>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default MyStacksMain;
