import React from 'react';
import { FormControlLabel, useTheme, Checkbox } from '@mui/material';
import './checkboxFilterComponent.scss';

const CheckboxFilter = ({ label, checked, onChange }) => {
  const theme = useTheme();
  
  return (
    <FormControlLabel
      control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            sx={{
              '&.MuiCheckbox-root': {
                color: theme.palette.neutral.light,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
              '&.Mui-checked': {
                color: theme.palette.secondary.main,
                '& .MuiSvgIcon-root': {
                  fill: theme.palette.secondary.main,
                },
              },
            }}
          />
      }
      label={label}
    />
  );
}

export default CheckboxFilter;