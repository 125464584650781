import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TablePagination, Select, MenuItem, Box, Typography } from '@mui/material';
import StackCard from '../../../components/stack/stackCard/stackCard';
import LikeButton from '../../../components/socials/likes/likeButton';
import FollowButton from '../../../components/socials/follow/followButton';
import SaveButton from '../../../components/socials/saves/saveButton';
import ShareButton from '../../../components/socials/shares/shareButton';
import NopeButton from '../../../components/socials/nope/nopeButton';
import './userProfileStacksDataGrid.scss'

const UserProfileStacksDataGrid = ({ userId, visitorUserId, entityTypeId, entityType }) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentFilter, setCurrentFilter] = useState('liked'); 
    // const entityType ='Stack'
    const filterOptions = ['liked', 'saved', 'following', 'trialling', 'using', 'evaluating', 'shared', 'commented', 'posted'];

    const mapFilterToBackend = (filter) => {
        const filterMap = {
            'liked': 'like',
            'saved': 'save',
            'following': 'follow',
            'trialling': 'trial',
            'using': 'using',
            'evaluating': 'evaluate',
            'shared': 'share',
            'commented': 'comment',
            'posted': 'post',
            
        };
        return filterMap[filter] || filter;
    };

    const handleFilterChange = (event) => {
        setCurrentFilter(event.target.value);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(event.target.value);
    };

    
    useEffect(() => {
        // console.log('itemsPerPage:', itemsPerPage);
        let isMounted = true; 
    
        const fetchStackRelations = async () => {
            try {
                const interactionsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/datagrid/user-interactions/${userId}/${entityType}?page=${page}&limit=${itemsPerPage}&filter=${mapFilterToBackend(currentFilter)}`);
                if (!interactionsResponse.ok) throw new Error('Failed to fetch user interactions');
                const interactionsData = await interactionsResponse.json();

                if (!interactionsData.rows || !interactionsData.entityDetails) {
                    console.error('rows or entityDetails is undefined');
                    return;
                }
                
                const combinedData = interactionsData.rows.map((interaction, index) => ({
                    id: interactionsData.entityDetails[index].entityid,
                    entityId: interactionsData.entityDetails[index].entityid,
                    entityName: interactionsData.entityDetails[index].entityname,
                    entityImage: interactionsData.entityDetails[index].entityimage,
                    entityDesc: interactionsData.entityDetails[index].entitydesc,
                    interactionType: interaction.action_type,
                    interactionState: interaction.action_state,
                    interactionTimestamp: interaction.timestamp,
                }));
    
                if (isMounted) {
                    setData(combinedData);
                    setRowCount(interactionsData.total); 
                }
            } catch (error) {
                console.error('Error fetching product relations:', error);
            }
        };
    
        fetchStackRelations();
    
        return () => {
            isMounted = false; 
        };
    }, [userId, page, itemsPerPage, currentFilter]);

    const columns = [
        {
            field: 'stackLogo',
            headerName: 'Stack',
            width: 80,
            renderCell: (params) => (
                <div className="stack-card-container">
                    <StackCard
                        style={{
                            border: '1px solid white',
                            borderRadius: '50%',
                        }}
                        stackId={params.row.entityId}
                        userId={visitorUserId}
                        showQuickView={true}
                        flexDirection={'row'}
                    />
                </div>
            ),
        },
        {
            field: 'stackName',
            headerName: 'Stack Name',
            width: 150,
            renderCell: (params) => (
                <div>{params.row.entityName}</div>
            ),
        },
        {
            field: 'stackDescription',
            headerName: 'Description',
            width: 280,
            renderCell: (params) => (
                <div>{params.row.entityDesc || null}</div>
            ),
        },
        {
            field: 'socialInteractions',
            headerName: 'Social Interactions',
            renderCell: (params) => (
                <div className='interaction-buttons-datagrid'>
                    <LikeButton 
                        showCount={false}
                        showTooltip = {false}
                        flexDirection = 'row'
                        userId={visitorUserId}
                        entityType={entityType}
                        entityId={params.row.id}
                    />
                    <FollowButton 
                        showCount={false}
                        showTooltip = {false}
                        flexDirection = 'row'
                        userId={visitorUserId}
                        entityType={entityType}
                        entityId={params.row.id}
                    />
                    <SaveButton 
                        showCount={false}
                        showTooltip = {false}
                        flexDirection = 'row'
                        userId={visitorUserId}
                        entityType={entityType}
                        entityId={params.row.id}
                    />
                    <ShareButton 
                        showCount={false}
                        showTooltip = {false}
                        flexDirection = 'row'
                        userId={visitorUserId}
                        entityType={entityType}
                        entityId={params.row.id}
                    />
                    <NopeButton 
                        showCount={false}
                        showTooltip = {false}
                        flexDirection = 'row'
                        userId={visitorUserId}
                        entityType={entityType}
                        entityId={params.row.id}
                    />
                </div>
            ),
            width: 380, 
        },
    ];

    

    
    return (
        <div style={{ height: 600, width: '100%' }}>
            <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                <Typography variant="header4" display="flex" marginRight='10px' alignItems='center'>Products this user </Typography>
                <Select
                    className="custom-filter-select"
                    value={currentFilter}
                    onChange={handleFilterChange}
                    size="small"
                >
                    {filterOptions.map((option) => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                </Select>
            </Box>
            <DataGrid
                className="stack-social-interations-dataGrid"
                rows={data}
                columns={columns}
                pageSize={itemsPerPage}
                rowCount={rowCount}
                paginationMode="server"
                onPageChange={(newPage) => setPage(newPage + 1)} 
                page={page - 1} 
                autoHeight
                componentsProps={{
                    pagination: {
                        component: (props) => (
                            <TablePagination
                                className="custom-pagination"
                                {...props}
                                count={rowCount}
                                page={page - 1} 
                                onPageChange={(_, newPage) => setPage(newPage + 1)}
                                rowsPerPage={itemsPerPage}
                                onRowsPerPageChange={handleItemsPerPageChange}
                                ActionsComponent={() => (
                                    <Box className="custom-pagination-actions" display="flex" justifyContent='space-between' alignItems="center" gap={2} flexWrap="nowrap">
                                        <Typography className="custom-pagination-text" sx={{ flexGrow: 1, marginRight: 'auto' }}> {rowCount} Items</Typography>
                                        <Select
                                            className="custom-pagination-select"
                                            value={itemsPerPage}
                                            onChange={handleItemsPerPageChange}
                                            size="small"
                                        >
                                            {[10, 20, 50].map((option) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                )}
                                rowsPerPageOptions={[]} 
                            />
                        ),
                    },
                }}
            />
        </div>
    );
};

export default UserProfileStacksDataGrid;
