import React, { useState } from 'react';
import apiFetch from '../../../helpers/apifetch';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
    Grid,
    Alert,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Box,
    useTheme,
    InputAdornment,
    IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

const validationSchema = Yup.object().shape({
    productName: Yup.string(),
    rootDomain: Yup.string().url('Must be a valid URL').required('Required'),
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
    },
    '& .MuiDialogTitle-root': {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        fontSize: '18px'
    },
}));

const SuggestProduct = ({ open, onClose }) => {
    const theme = useTheme();
    const token = useSelector(state => state.auth.token);
    const [successMessage, setSuccessMessage] = useState('');
    const [conflictAlert, setConflictAlert] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [existingProductId, setExistingProductId] = useState(null);
    const [query, setQuery] = useState('');
    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.user?.user_id);
    const [results, setResults] = useState([]);
    const [selectedMatch, setSelectedMatch] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [selectedMatchURL, setSelectedMatchURL] = useState(''); // For visual feedback

    const handleSearch = async e => {
        e.preventDefault();
        const apiKey=process.env.REACT_APP_GOOGLE_API_KEY;
        const cx=process.env.REACT_APP_GOOGLE_CX;
        const url = `https://www.googleapis.com/customsearch/v1?key=${apiKey}&cx=${cx}&q=${encodeURIComponent(
            query
        )}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            setResults(data.items.slice(0, 5) || []); // Limit to top 5 results
        } catch (error) {
            console.error('Search error:', error);
        }
    };

    const handleSubmit = async (values, formikHelpers) => {
        const { setSubmitting, resetForm } = formikHelpers;
        apiFetch(
            `${process.env.REACT_APP_API_URL}/api/v1/product/suggest`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Replace with actual token retrieval method
                },
                body: JSON.stringify({ 
                    request_product_domain: selectedMatch, 
                    request_product_name: values.productName, 
                    product_thumb_logo: values.product_thumb_logo,
                    userId: userId 
                }),
            },
            dispatch
        )
        .then(async response => {
            const data = await response.json();
            if (response.status === 409) {
                setConflictAlert(data.message);
                setExistingProductId(data.existingProduct?.product_id || data.existingRequest?.product_id);
                resetForm(); // Reset all form fields since we have a conflict
                setTimeout(() => {
                    setConflictAlert('');
                }, 3000);
            } else {
                setSuccessMessage('Product suggested successfully!');
                resetForm(); // Reset all form fields after successful submission
                setSelectedMatch('');
                setSelectedMatchURL('');
                setConflictAlert('');
                setExistingProductId(null);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setSuccessMessage('');
        })
        .finally(() => setSubmitting(false));
    };

    return (
        <StyledDialog 
            open={open}
            onClose={() => {
                onClose();
                setQuery('');
                setResults([]);
                setSuccessMessage('');
            }}
            sx={{
                borderRadius: '5px',
                background: 'theme.palette.primary.main',
            }}
        >
            <DialogTitle>Suggest a product</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => {
                    onClose();
                    setQuery('');
                    setResults([]);
                }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {successMessage && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {successMessage}
                    </Alert>
                )}
                {conflictAlert && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {conflictAlert}
                    </Alert>
                )}
                <Box display="flex" gap={2} mb={2}>
                    <TextField
                        fullwidth='true'
                        sx={{ flexGrow: 1 }}
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                        placeholder="Search the web"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button 
                        onClick={() => setQuery('')}
                        sx={{
                            backgroundColor: theme => theme.palette.neutral.dark,
                            color: theme => theme.palette.neutral.light,
                            '&:hover': {
                                backgroundColor: theme => theme.palette.primary.main,
                                color: theme => theme.palette.neutral.light,
                            },
                        }}
                    >
                        Clear
                    </Button>
                </Box>
                <Formik
                    initialValues={{ productName: '', rootDomain: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched, isSubmitting, setFieldValue }) => (
                        <Form>
                            <Grid container spacing={2} margin='30px 0px 30px 0px' justifyContent='space-between'>
                                    {results.map((item, index) => (
                                        <Grid container key={index} alignItems="center" mb='10px' gap='10px' spacing={2}>
                                            <Grid 
                                                item style={{ 
                                                    margin: '10px 0px 10px 20px', 
                                                    width: "40px", 
                                                    height: "40px", 
                                                    display: 'flex', 
                                                    alignItems: 'center',
                                                    justifyContent: 'center', 
                                                    backgroundColor: theme.palette.neutral.main
                                                }}
                                            >
                                                {item.pagemap?.cse_image?.length > 0 && (
                                                    <img 
                                                        src={item.pagemap.cse_image[0].src} 
                                                        alt="" 
                                                        style={{ width: "40px", height: "40px", objectFit: "cover" }} 
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs>
                                                <Typography
                                                    variant="subtitle1"
                                                    component="h2"
                                                    fontWeight="bold"
                                                    style={{
                                                        maxWidth: '300px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                                <Typography 
                                                    variant="body2"
                                                    component="p"
                                                    gutterBottom
                                                    style={{
                                                        maxWidth: '300px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {item.link}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                            <Button
                                                sx={{ 
                                                    mr:'20px',
                                                    borderColor: theme => theme.palette.neutral.light,
                                                    color: theme => theme.palette.neutral.light,
                                                    '&:hover': {
                                                        borderColor: theme => theme.palette.neutral.light,
                                                        color: theme => theme.palette.neutral.light,
                                                    },
                                                }}
                                                variant="outlined"
                                                onClick={() => {
                                                    setFieldValue('productName', item.title);
                                                    
                                                    // Clean the URL before setting the rootDomain field
                                                    const url = new URL(item.formattedUrl);
                                                    const cleanedUrl = url.origin;
                                                    
                                                    // Update selectedMatch and rootDomain field with the cleaned URL
                                                    setSelectedMatch(cleanedUrl);
                                                    setFieldValue('rootDomain', cleanedUrl);
                                                    
                                                    setFieldValue('product_thumb_logo', item.pagemap?.cse_image?.[0]?.src || ''); // Set thumbnail URL
                                                }}
                                            >
                                                Select
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            <Box 
                                sx={{
                                    gap:'10px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Field
                                    name="productName"
                                    as={TextField}
                                    size="small"
                                    label="Product Name"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <Field
                                    name="rootDomain"
                                    size="small"
                                    as={TextField}
                                    label="Root Domain"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    variant="register"
                                    size="small"
                                >
                                    Submit
                                </Button>

                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </StyledDialog>
    );
};

export default SuggestProduct;
