import React, { useState, useEffect } from 'react';
import {Box, Typography } from '@mui/material';
import './rotatingText.scss'; // Import the SCSS file

const RotatingText = ({ variant, words, intervalDuration }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, intervalDuration); // Change word in miliseconds

        return () => clearInterval(interval); // Clean up on unmount
    }, [words, intervalDuration]);

    return (
        <Box className="rotatingTextContainer">
            {words.map((word, i) => (
                <Typography 
                    variant={variant}
                    component="div"
                    className={`rotatingText ${i === index ? 'visible' : ''}`}
                    key={i}
                >
                    {word}
                </Typography>
            ))}
        </Box>
    );
};

export default RotatingText;