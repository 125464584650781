import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TablePagination, Select, MenuItem, Box, Typography } from '@mui/material';
import PeopleCard from '../../../components/users/peoplecard/peopleCard';
import LikeButton from '../../../components/socials/likes/likeButton';
import FollowButton from '../../../components/socials/follow/followButton';
import SaveButton from '../../../components/socials/saves/saveButton';
import ShareButton from '../../../components/socials/shares/shareButton';
import './userProfilePeopleDataGrid.scss'

const UserProfilePeopleDataGrid = ({ userId, visitorUserId, entityTypeId, entityType }) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentFilter, setCurrentFilter] = useState('liked'); // Default to 'liked'
    const filterOptions = ['liked', 'saved', 'following', 'shared'];

    const mapFilterToBackend = (filter) => {
        const filterMap = {
            'liked': 'like',
            'saved': 'save',
            'following': 'follow',
            'shared': 'share',
            
        };
        return filterMap[filter] || filter;
    };

    const handleFilterChange = (event) => {
        setCurrentFilter(event.target.value);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(event.target.value);
    };

    
    useEffect(() => {
        // console.log('itemsPerPage:', itemsPerPage);
    
        let isMounted = true;
    
        const fetchProductRelations = async () => {
            try {
                const interactionsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/datagrid/user-interactions/${userId}/${entityType}?page=${page}&limit=${itemsPerPage}&filter=${mapFilterToBackend(currentFilter)}`);
                if (!interactionsResponse.ok) throw new Error('Failed to fetch user interactions');
                const interactionsData = await interactionsResponse.json();
                // console.log('Server Response', interactionsData);
        
                if (!interactionsData.rows || !interactionsData.entityDetails) {
                    console.error('rows or entityDetails is undefined');
                    return;
                }
                
                const combinedData = interactionsData.rows.map((interaction, index) => {
                    
                    const isUserDeleted = interactionsData.entityDetails[index].is_deleted;
                    return {
                        id: interactionsData.entityDetails[index].entityid,
                        entityId: interactionsData.entityDetails[index].entityid,
                        entityName: isUserDeleted ? 'Deleted User' : interactionsData.entityDetails[index].entityname,
                        entityImage: isUserDeleted ? 'default_image_url' : interactionsData.entityDetails[index].entityimage,
                        entityDesc: isUserDeleted ? 'This user has deleted their account.' : interactionsData.entityDetails[index].entitydesc,
                        interactionType: interaction.action_type,
                        interactionState: interaction.action_state,
                        interactionTimestamp: interaction.timestamp,
                    };
                });
        
                
                if (isMounted) {
                    setData(combinedData);
                    setRowCount(interactionsData.total); 
                }
            } catch (error) {
                console.error('Error fetching product relations:', error);
            }
        };
    
        fetchProductRelations();
    
        
        return () => {
            isMounted = false;
        };
    }, [userId, page, itemsPerPage, currentFilter, entityType]);

    const columns = [
        
        {
            field: 'userImage',
            headerName: 'User',
            width: 80,
            renderCell: (params) => (
                <div style={{ padding: '20px' }}>
                    <PeopleCard
                        style={{
                            border: '1px solid white',
                            borderRadius: '50%',
                        }}
                        entityId={params.row.entityId}
                        userId={params.row.entityId}
                        visitorUserId={visitorUserId}
                        showFirstName = {false}
                        showUserCompany = {false}
                        showBusinessRole = {false}
                        showStacks = {false}
                        showActions= {false}
                        showQuickView = {true}
                        imgStyle ={{width: '34px', height: '34px'}}
                        flexDirection = 'row'
                    />
                </div>
            ),
        },
        {
            field: 'userName',
            headerName: 'User Name',
            width: 150,
            renderCell: (params) => (
                <div>{params.row.entityName}</div>
            ),
        },
        {
            field: 'userBio',
            headerName: 'Bio',
            width: 320,
            renderCell: (params) => (
                <div style={{ 
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis', 
                    maxWidth: '300px' 
                }}>
                    {params.row.entityDesc}
                </div>
            ),
        },
        {
            field: 'socialInteractions',
            width: 250, 
            headerName: 'Social Interactions',
            renderCell: (params) => (
                <div className='interaction-buttons-datagrid'>
                    <LikeButton 
                        showCount={false}
                        showTooltip = {false}
                        flexDirection = 'row'
                        userId={visitorUserId}
                        entityType={'user'}
                        entityId={params.row.entityId}
                    />
                    <FollowButton 
                        showCount={false}
                        showTooltip = {false}
                        flexDirection = 'row'
                        userId={visitorUserId}
                        entityType={'user'}
                        entityId={params.row.entityId}
                    />
                    <SaveButton 
                        showCount={false}
                        showTooltip = {false}
                        flexDirection = 'row'
                        userId={visitorUserId}
                        entityType={'user'}
                        entityId={params.row.entityId}
                    />
                    <ShareButton 
                        showCount={false}
                        showTooltip = {false}
                        flexDirection = 'row'
                        userId={visitorUserId}
                        entityType={'user'}
                        entityId={params.row.entityId}
                    />
                </div>
            ),
        },
    ];

    

    
    return (
        <div style={{ height: 600, width: '100%' }}>
            <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                <Typography variant="header4" display="flex" marginRight='10px' alignItems='center'>Users this user </Typography>
                <Select
                    className="custom-filter-select"
                    value={currentFilter}
                    onChange={handleFilterChange}
                    size="small"
                >
                    {filterOptions.map((option) => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                </Select>
            </Box>
            <DataGrid
                className="user-social-interations-dataGrid"
                variant="entities"
                rows={data}
                columns={columns}
                pageSize={itemsPerPage}
                rowCount={rowCount}
                paginationMode="server"
                onPageChange={(newPage) => setPage(newPage + 1)} 
                page={page - 1} 
                autoHeight
                componentsProps={{
                    pagination: {
                        component: (props) => (
                            <TablePagination
                                className="custom-pagination"
                                {...props}
                                count={rowCount}
                                page={page - 1} 
                                onPageChange={(_, newPage) => setPage(newPage + 1)}
                                rowsPerPage={itemsPerPage}
                                onRowsPerPageChange={handleItemsPerPageChange}
                                ActionsComponent={() => (
                                    <Box className="custom-pagination-actions" display="flex" justifyContent='space-between' alignItems="center" gap={2} flexWrap="nowrap">
                                        <Typography className="custom-pagination-text" sx={{ flexGrow: 1, marginRight: 'auto' }}> {rowCount} Items</Typography>
                                        <Select
                                            className="custom-pagination-select"
                                            value={itemsPerPage}
                                            onChange={handleItemsPerPageChange}
                                            size="small"
                                        >
                                            {[10, 20, 50].map((option) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                )}
                                rowsPerPageOptions={[]} 
                            />
                        ),
                    },
                }}
            />
        </div>
    );
};

export default UserProfilePeopleDataGrid;
