import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import './selectFilterComponent.scss';

const SelectFilter = ({ label, value, onChange, options }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        label={label}
        value={value}
        onChange={handleChange}
        sx={{ color: 'white' }} 
      >
        <MenuItem value=""><em>All</em></MenuItem>
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectFilter;