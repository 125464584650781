// chatterSlice.js
// complete review required
import { createSlice } from '@reduxjs/toolkit';

export const chatterSlice = createSlice({
  name: 'chatter',
  initialState: {
    drawer: {
      open: false,
      entityId: null,
    },
    comments: {
        'product_1': [
            {
              commentId: 'c1',
              userId: 'u1',
              content: 'Great product!',
              // more properties as needed
            },
            // more comments
          ],
      },
  },
  reducers: {
    toggleChatterDrawer: (state, action) => {
      state.drawer.open = action.payload.open;
      state.drawer.entityId = action.payload.entityId || null;
    },
    // addNewComment: (state, action) => {
    //     const { entityId, comment } = action.payload;
    //     // Assuming each entity has an array of comments
    //     if (!state.comments[entityId]) {
    //       state.comments[entityId] = [];
    //     }
    //     state.comments[entityId].push({
    //       comment,
    //       // add more properties like commentId, userId, etc.
    //     });
    //   },
  },
});

export const { toggleChatterDrawer, addNewComment } = chatterSlice.actions;
export default chatterSlice.reducer;


