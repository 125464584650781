import React, { useState } from 'react';
import { Box } from '@mui/material';
import UserPersonalsForm from '../../../../../components/users/useraccount/userdetailsform/userPersonalFormPartial/userPersonalsForm';  
import HeaderStreamed from '../../../../../components/headerstreamed/HeaderStreamed';

const StackDetailPersonalStepper = ({token, userId, newStackUserData, onNext}) => {
    const [isValid, setIsValid] = useState(false);
    return (
        <Box sx={{margin: '20px 10px'}}>
            <Box sx={{margin: '0px'}}>
                <HeaderStreamed title='Personal Details' subtitle=' confirm or enter missing details'/>
            </Box>
            <Box sx={{padding: '10px', border: '1px solid white', borderRadius: '5px'}}>
                <UserPersonalsForm
                    token={token}
                    userId={userId}
                    newStackUserData={newStackUserData}
                    isValid={isValid} 
                    setIsValid={setIsValid}
                />
            </Box>
        </Box>
    );
};


export default StackDetailPersonalStepper;
