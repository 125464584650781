// src/components/users/useraccount/userdetailsvalidation/UserDetailsFormPartial.jsx

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import apiFetch from '../../../../../helpers/apifetch';
import { Formik, Form, Field } from 'formik';
import { Button, useTheme } from '@mui/material';
import * as Yup from 'yup';
import StaticDropdownOptions from '../../../../../data/StaticFormOptions/staticDropdownOptions.json';
import './userDetailsFormPartial.scss';

const fieldLabels = {
    business_relation: 'I work here as',
    company_name: 'Company Name',
    company_url: 'Company URL', 
    company_size_fte: 'Company Size (FTE)',
    company_size_rev: 'Company Size (Revenue)',
    company_country_id: 'Company Country',
    company_industry_id: 'Company Industry',
    company_sector_id: 'Company Sector',
    is_primary: 'Company Status',
};

const UserDetailsFormPartial = ({
    userId,
    onFormSubmission,
    requiredFields,
    companyId,
}) => {
    const dispatch = useDispatch();
    const [industries, setIndustries] = useState([]); 
    const [countries, setCountries] = useState([]); 
    const [sectors, setSectors] = useState([]); 
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [formData, setFormData] = useState({});
    const token = useSelector(state => state.auth.token);
    const theme = useTheme();
    
    // Fetch company data
    useEffect(() => {
        const fetchCompanyData = async () => {
            
            try {
                const response = await apiFetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/company/company_details/${companyId}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch company data');
                }
                const companyData = await response.json();
                setFormData(prevData => ({ ...prevData, ...companyData }));
            } catch (error) {
                console.error('Error fetching company data:', error);
            }
        };

        if (companyId) {
            fetchCompanyData();
        }
    }, [companyId, dispatch, token]);

    // Fetch junction data
    useEffect(() => {
        const fetchJunctionData = async () => {
            try {
                const response = await apiFetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/company/junction_data/${userId}/${companyId}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
    
                if (!response.ok) {
                    throw new Error('Failed to fetch junction data');
                }
                const junctionData = await response.json();
                setFormData(prevData => ({
                    ...prevData,
                    user_business_relation: junctionData.user_business_relation,
                    is_primary: junctionData.is_primary,
                }));
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
    
        if (userId && companyId) {
            fetchJunctionData();
        }
    }, [userId, companyId, dispatch, token]);

    const [initialValues, setInitialValues] = useState({
        user_business_relation: '',
        company_name: '',
        company_url: '',
        company_size_fte: '',
        company_size_rev: '',
        company_country_id: '',
        company_industry_id: '',
        company_sector_id: '',
        is_primary: '',
    });

    useEffect(() => {
        if (formData) {
            
            setSelectedIndustry(formData.company_industry_id); 
            setInitialValues({
                user_business_relation: formData.user_business_relation || '',
                company_name: formData.company_name || '',
                company_url: formData.company_url || '',
                company_size_fte: formData.company_size_fte || '',
                company_size_rev: formData.company_size_rev || '',
                company_country_id: formData.company_country_id || '',
                company_industry_id: formData.company_industry_id || '',
                company_sector_id: formData.company_sector_id || '',
                is_primary: formData.is_primary || '',
            });
        }
    }, [formData]);

    // Fetch countries
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/form/company_country`
                );
                if (!response.ok) throw new Error('Failed to fetch functions');
                const data = await response.json();
                setCountries(data);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);

    // Fetch company industries and industry sectors
    useEffect(() => {
        const fetchIndustries = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/form/company_industry`
                );
                if (!response.ok) throw new Error('Failed to fetch industries');
                const data = await response.json();
                setIndustries(data);
            } catch (error) {
                console.error('Error fetching industries:', error);
            }
        };
        fetchIndustries();
    }, []);

    // Fetch sectors based on the selected industry
    useEffect(() => {
        const fetchSectors = async () => {
            if (!selectedIndustry) return;

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/form/company_sector?industryId=${selectedIndustry}`
                );
                if (!response.ok) throw new Error('Failed to fetch sectors');
                const data = await response.json();
                setSectors(data);
            } catch (error) {
                console.error('Error fetching sectors:', error);
            }
        };
        fetchSectors();
    }, [selectedIndustry]);

    // to make YUP field url: .url('Invalid URL.')
    const validationSchema = Yup.object().shape({
        user_business_relation: Yup.string().required('Required'),
        company_name: Yup.string().required('Required'),
        company_url: Yup.string()
            .url('Must be a valid URL')
            .required('Required'),
        company_size_fte: Yup.string().required('Required'),
        company_size_rev: Yup.string().required('Required'),
        company_country_id: Yup.number().required('Required'),
        company_industry_id: Yup.number().required('Required'),
        company_sector_id: Yup.number().required('Required'),
        is_primary: Yup.boolean(),
    });

    
    const handleSubmit = async (values, { setSubmitting, errors }) => {
        const companyPayload = {
            company_name: values.company_name,
            company_url: values.company_url,
            company_size_fte: values.company_size_fte,
            company_size_rev: values.company_size_rev,
            company_country_id: values.company_country_id,
            company_industry_id: values.company_industry_id,
            company_sector_id: values.company_sector_id,
            user_business_relation: values.user_business_relation,
            is_primary: Boolean(values.is_primary),
        };
        
    
        try {
            const response = await apiFetch(
                `${process.env.REACT_APP_API_URL}/api/v1/company/${userId}/${companyId}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(companyPayload),
                },
                dispatch
            );
        
            if (!response.ok) {
                throw new Error('Failed to update details');
            }

        
        } catch (error) {
            console.error('Update error:', error);
            if (typeof onFormSubmission === 'function') {
                onFormSubmission(false);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, values, touched }) => (
                    <Form className="company-details-form-partial">
                        <div className="company-validation-form-row-one">
                            {/* User Company Name */}
                            <div className="partial-form-row">
                                <label htmlFor="company_name">
                                    {fieldLabels.company_name}
                                </label>
                                <Field
                                    id="company_name"
                                    name="company_name"
                                    className={
                                        touched.company_name
                                            ? 'error-field'
                                            : ''
                                    }
                                />
                            </div>
                            {/* Company Industry Dropdown */}
                            <div className="partial-form-row">
                                <label htmlFor="company_industry">
                                    Company Industry
                                </label>
                                <Field
                                    as="select"
                                    id="company_industry"
                                    name="company_industry_id"
                                    value={values.company_industry_id}
                                    className={
                                        touched.company_industry_id
                                            ? 'error-field'
                                            : ''
                                    }
                                    onChange={e => {
                                        setFieldValue(
                                            'company_industry_id',
                                            e.target.value
                                        );
                                        setSelectedIndustry(e.target.value);
                                    }}
                                >
                                    <option value=""></option>
                                    {industries.map(ind => (
                                        <option
                                            key={ind.industry_id}
                                            value={ind.industry_id}
                                        >
                                            {ind.industry_name}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            {/* Company Sector Dropdown */}
                            <div className="partial-form-row">
                                <label htmlFor="company_sector">
                                    Company Sector
                                </label>
                                <Field
                                    as="select"
                                    id="company_sector"
                                    name="company_sector_id"
                                    value={values.company_sector_id}
                                    className={
                                        touched.company_sector_id
                                            ? 'error-field'
                                            : ''
                                    }
                                    onChange={e =>
                                        setFieldValue(
                                            'company_sector_id',
                                            e.target.value
                                        )
                                    }
                                >
                                    <option value=""></option>
                                    {sectors.map(sec => (
                                        <option
                                            key={sec.sector_id}
                                            value={sec.sector_id}
                                        >
                                            {sec.sector_name}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                        <div className="company-validation-form-row-two">
                            {/* User Company Country */}
                            <div className="partial-form-row">
                                <label htmlFor="company_country">
                                    HQ Location
                                </label>
                                <Field
                                    as="select"
                                    id="company_country"
                                    name="company_country_id"
                                    value={values.company_country_id}
                                    className={
                                        touched.company_country_id
                                            ? 'error-field'
                                            : ''
                                    }
                                    onChange={e => {
                                        setFieldValue(
                                            'company_country_id',
                                            e.target.value
                                        );
                                    }}
                                >
                                    <option value=""></option>
                                    {countries.map(cont => (
                                        <option
                                            key={cont.country_id}
                                            value={cont.country_id}
                                        >
                                            {cont.country_name}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            {/* User Company Size Fte */}
                            <div className="partial-form-row">
                                <label htmlFor="company_size_fte">
                                    {fieldLabels.company_size_fte}
                                </label>
                                <Field
                                    as="select"
                                    id="company_size_fte"
                                    name="company_size_fte"
                                    value={values.company_size_fte}
                                    className={
                                        touched.company_size_fte
                                            ? 'error-field'
                                            : ''
                                    }
                                >
                                    <option value=""></option>
                                    {StaticDropdownOptions.companySizeFTE.map(
                                        option => (
                                            <option
                                                key={option.id}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )
                                    )}
                                </Field>
                            </div>
                            {/* User Company Size Rev */}
                            <div className="partial-form-row">
                                <label htmlFor="company_size_rev">
                                    {fieldLabels.company_size_rev}
                                </label>
                                <Field
                                    as="select"
                                    id="company_size_rev"
                                    name="company_size_rev"
                                    value={values.company_size_rev}
                                    className={
                                        touched.company_size_rev
                                            ? 'error-field'
                                            : ''
                                    }
                                >
                                    <option value=""></option>
                                    {StaticDropdownOptions.companySizeRevenue.map(
                                        option => (
                                            <option
                                                key={option.id}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )
                                    )}
                                </Field>
                            </div>
                        </div>
                        <div className="company-validation-form-row-three">
                            {/* User Company URL */}
                            <div className="partial-form-row">
                                <label htmlFor="company_url">
                                    {fieldLabels.company_url}
                                </label>
                                <Field
                                    id="company_url"
                                    name="company_url"
                                    placeholder="https://www.example.com"
                                    className={
                                        touched.company_url ? 'error-field' : ''
                                    }
                                />
                            </div>
                            {/* User Company Relation */}
                            <div className="partial-form-row">
                                <label htmlFor="user_business_relation">
                                    {fieldLabels.business_relation}
                                </label>
                                <Field
                                    id="user_business_relation"
                                    as="select"
                                    name="user_business_relation"
                                    value={values.user_business_relation}
                                    className={
                                        touched.user_business_relation
                                            ? 'error-field'
                                            : ''
                                    }
                                >
                                    <option value=""></option>
                                    {StaticDropdownOptions.businessRelations.map(
                                        option => (
                                            <option
                                                key={option.id}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )
                                    )}
                                </Field>
                            </div>
                            
                            {/* Primary Company */}
                            <div className="partial-form-row ">
                                <label htmlFor="company_is_rimary">
                                    Company Status
                                </label>
                                <div className="mark-company-primary">
                                    <Field
                                        className="large-checkbox"
                                        type="checkbox"
                                        id="company_is_rimary"
                                        name="is_primary"
                                    />
                                    <div
                                        htmlFor="company_is_rimary"
                                        className="checkbox-label"
                                    >
                                        I work here now
                                    </div>
                                </div>
                                {values.is_primary && (
                                    <div className="helper-text">
                                        (checked = primary company)
                                    </div>
                                )}
                            </div>
                        </div>
                        <Button
                            type="submit"
                            variant='outlined'
                            size= 'small'
                            className='submit-btn'
                            disabled={isSubmitting}
                            style={{
                                backgroundColor: theme.palette.secondary.main,
                                boxShadow: theme.palette.boxshadow.default,
                            }}
                            
                        >
                            Update
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UserDetailsFormPartial;
