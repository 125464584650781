import React, { useState } from 'react';
import { Button, Modal, Typography } from '@mui/material';
import './userAccountDelete.scss';

const UserAccountDelete = ({ userId }) => {
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const handleDelete = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        });

        if (response.ok) {
            handleClose();
            localStorage.clear();
            window.location.href = '/';
        } else {
            alert('Failed to delete account');
        }
    };

    return (
        <div className="delete-account-component">
            <h2>Delete Your Account</h2>
            <p>This action cannot be undone and will permanently delete your account.</p>
            <Button variant="contained" color="error" onClick={handleOpen}>
                Delete Account
            </Button>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="delete-account-modal-content">
                    <h2>Confirm Account Deletion</h2>
                    <p>Are you sure you want to delete your account?</p>
                    <p>This action is irreversible.</p>
                    <Button variant="contained" color="error" onClick={handleDelete}>
                        Confirm Delete
                    </Button>
                    <Typography className='leave-button' onClick={handleClose}>
                        Back to Safety
                    </Typography>
                </div>
            </Modal>
        </div>
    );
};

export default UserAccountDelete;
