//src>components>profilepage>profielpageheader>profilepageuserdetails>profilePageUserDetails.jsx

import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import './profilePageUserDetails.scss';

function ProfilePageUserDetails({
    userId,
    entityType,
    visitorUserId,
    showCompanyName = false,
}) {
    const [userProfileData, setUserProfileData] = useState(null);
    const theme = useTheme();

    useEffect(() => {
        const fetchUserProfileData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/userDetails/user-profile/${userId}`
                );
                if (!response.ok) {
                    throw new Error(
                        `Failed to fetch user profile with ID ${userId}`
                    );
                }
                const data = await response.json();
                setUserProfileData(data);
            } catch (error) {
                console.error('Failed to fetch user profile data:', error);
            }
        };

        fetchUserProfileData();
    }, [userId]);

    return (
        <Box className="user-profile-header-details">
            <div className="profile-user-info">
                <div className="header-details-personal">
                    <div>{userProfileData?.user?.user_first_name}</div>
                    <div>{userProfileData?.user?.user_last_name}</div>
                </div>
                <div className="header-details-role">
                    <div>{userProfileData?.user?.businessRoleName}</div>
                </div>
            </div>
            <div className="header-details-company">
                {showCompanyName && (
                    <div className="header-details-company-name">
                        {userProfileData?.company?.company_name}
                    </div>
                )}
                <div className="header-details-company-info">
                    <div>{userProfileData?.company?.companyIndustryName}</div>
                </div>
            </div>
            <Box 
                className="user-primary-stacks"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                    gap: '10px',
                    height: '100%',
                    border: `1px solid ${theme.palette.neutral.main}`,
                    borderRadius: '5px',
                    backgroundColor: theme.palette.primary.dark, 
                    boxShadow: `1px 1px 2px ${theme.palette.neutral.dark}`,
                    transition: 'all 0.5s ease',
                    '&:hover': {
                    boxShadow: `1px 1px 2px ${theme.palette.neutral.light}`,
                    cursor: 'pointer', 
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        fontSize: '12px',
                        alignItems: 'center',
                    }}
                >
                    Official Stacks:
                </Box>
                <Box className="user-official-stack">
                    <div className="user-official-stacks">
                        <a
                            href={`/stack/profile/${userProfileData?.stacks?.userPersonalStack?.stack_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <LayersIcon
                                sx={{
                                    bgcolor: 'pink',
                                    borderRadius: '50%',
                                    color: 'grey',
                                }}
                                fontSize="medium"
                            />
                        </a>
                    </div>
                    <Typography type="body2" sx={{ fontSize: '10px' }}>
                        Personal
                    </Typography>
                </Box>
                <Box className="user-official-stack">
                    <div className="user-official-stacks">
                        <a
                            href={`/stack/profile/${userProfileData?.stacks?.userBusinessStack?.stack_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <LayersIcon
                                sx={{ bgcolor: 'grey', borderRadius: '50%' }}
                                color="secondary"
                                fontSize="medium"
                            />
                        </a>
                    </div>
                    <Typography type="body2" sx={{ fontSize: '10px' }}>
                        Business
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default ProfilePageUserDetails;
