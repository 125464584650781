import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip, Box, Typography, useTheme } from '@mui/material';
import { fetchFeatureAvailability } from '../../../features/users/featureavailability/featureAvailabilitySlice';
import ExploreIcon from '@mui/icons-material/Explore';
import { usePlanId } from '../../../hooks/usePlanId';
import { fetchEvaluateCount, fetchEvaluateState, toggleEvaluate, } from '../../../features/socials/evaluate/evaluateSlice';
import './evaluateButton.scss';

const EvaluateButton = ({
    entityType,
    entityId,
    userId,
    showCount = true,
    flexDirection = 'row',
    showTooltip = true,
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const planId = usePlanId();
    const platformFeatureId = 22;
    const [isLoading, setIsLoading] = useState(true);
    const [isFeatureAvailable, setIsFeatureAvailable] = useState(false);

    const evaluateCountId = `${entityType}_${entityId}`;
    const evaluateId = `${entityType}_${entityId}_${userId}`;
    const evaluateCount = useSelector(
        state => state.evaluates?.counts?.[evaluateCountId] || 0
    );
    const isEvaluated = useSelector(
        state => state.evaluates?.byId?.[evaluateId]?.evaluated || false
    );
    
    useEffect(() => {
        dispatch(fetchFeatureAvailability({ planId, platformFeatureId }))
            .unwrap()
            .then((availability) => {
                setIsFeatureAvailable(availability.isFeatureAvailable);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Failed to fetch feature availability:', error);
                setIsLoading(false);
            });
    }, [dispatch, planId, platformFeatureId]);

    useEffect(() => {
        if (entityType && entityId && userId) {
            dispatch(fetchEvaluateCount({ entityType, entityId })).unwrap();
            dispatch(fetchEvaluateState({ entityType, entityId, userId })).unwrap();
        }
    }, [dispatch, entityType, entityId, userId]);

    const handleEvaluate = () => {
        if (!userId) {
            console.log('Please log in or register to evaluate.');
            return;
        }
        if (userId) {
            dispatch(toggleEvaluate({ entityType, entityId, userId })).then(
                () => {
                    const updatedEvaluateCount = isEvaluated
                        ? evaluateCount - 1
                        : evaluateCount + 1;
                    dispatch({
                        type: 'evaluates/updateEvaluateCount',
                        payload: { evaluateCountId, count: updatedEvaluateCount },
                    });
                }
            );
        } else {
            console.log('Please log in or register to like this.');
        }
    };

    // If the feature is not available or the data is still loading, do not render the component
    if (!isFeatureAvailable || isLoading) {
        return null;
    }

    const boxStyle = {
        backgroundColor: theme.palette.neutral.light,
        color: theme.palette.getContrastText(theme.palette.neutral.light),
        boxshadow: `0px 4px 4px ${theme.palette.boxshadow.default}`,
        borderRadius: '5px',
        margin: '0px',
        padding: '0px',
    };

    const button = (
        <IconButton
            sx={{
                background: 'transparent',
                border: 'none',
                color: isEvaluated
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                '&.Mui-disabled': {
                    color: theme.palette.primary.main,
                },
                '&:disabled': {
                    cursor: 'default',
                },
            }}
            onClick={handleEvaluate}
            aria-label="evaluate"
            disabled={!userId}
        >
            <ExploreIcon />
        </IconButton>
    );

    return (
        <Box
            style={boxStyle}
            sx={{
                flexDirection: flexDirection,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                margin: '0px',
                padding: '0px',
            }}
        >
            {showTooltip ? (
                <Tooltip
                    title={`${evaluateCount || 0} Evaluates`}
                    placement="top"
                    arrow
                >
                    <div>{button}</div>
                </Tooltip>
            ) : (
                button
            )}
            {showCount && <Typography 
                sx={{
                display: 'flex',
                width: 'auto',
                justifyContent: 'center',
                marginRight: '8px',
                marginLeft: flexDirection === 'row' ? '8px' : '0px',
                marginTop: flexDirection === 'column' ? '4px' : '0px',
                fontSize: '16px',
                fontweight: '700',
                textShadow: '0px 0px 1px black',
                }}
            >
                {evaluateCount || 0}
            </Typography>}
        </Box>
    );
};

export default EvaluateButton;
