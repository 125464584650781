// src>features>charts>stack>stackpage>stackcount>stackProductCountSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchStackProductCount = createAsyncThunk(
    'stackProductCount/fetchStackProductCount',
    async ({ stackId, companyId }, { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/charts/stack-products-comparison/${stackId}`);
            if (!response.ok) throw new Error('Failed to fetch product comparison data');
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    data: { product_count: 0, max_product_count: 0, avg_product_count: 0 },
    loading: false,
    error: null,
};

const stackProductCountSlice = createSlice({
    name: 'stackProductCount',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStackProductCount.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchStackProductCount.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchStackProductCount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { reset } = stackProductCountSlice.actions;

// Selectors
export const selectStackProductCountData = state => state.stackProductCount.data;
export const selectStackProductCountLoading = state => state.stackProductCount.loading;
export const selectStackProductCountError = state => state.stackProductCount.error;

export default stackProductCountSlice.reducer;
