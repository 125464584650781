import React from "react";
import { Box } from "@mui/material";
import HeaderStreamed from "../../../components/headerstreamed/HeaderStreamed";
import PeopleCard from "../../../components/users/peoplecard/peopleCard";
import UserAccountSideDetails from "../../../components/users/useraccount/useraccountsidedetails/UserAccountSideDetails";
import UserAccountDetailsForm from '../../../components/users/useraccount/userdetailsform/userAccountDetailsForm/userAccountDetailsForm';

import  "./userDetails.scss";

const UserDetails = ({ userData, userId, entityType }) => {
    
    return (
        <Box className='user-account-details-page-container'>
            <HeaderStreamed title="Personal Details" subtitle="Edit profile details and link your accounts"/>
            <Box className="user-details-page">
                <Box className="content-container">
                    <Box className="user-details-left-column">
                        <PeopleCard
                            userId={userId}
                            userData={userData}
                            entityType={entityType}
                            showFirstName={true}
                            showUserName={true}
                            showBusinessRole={true}
                            showUserCompany={true}
                            showQuickView={true}
                            flexDirection="row"
                        />
                        <UserAccountSideDetails userId={userId} userData={userData} entityType={entityType}/>
                    </Box>

                    <Box className="user-details-right-column">
                        <UserAccountDetailsForm userId={userId} userData={userData} entityType={entityType}/>       
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


export default UserDetails;