import React from 'react';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import {Box, TextField, List, ListItem } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TwitterIcon from '@mui/icons-material/Twitter';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import CakeIcon from '@mui/icons-material/Cake';
import RocketIcon from '@mui/icons-material/Rocket';
import ConstructionIcon from '@mui/icons-material/Construction';


const AboutUsPage = () => {
    
    const StyledListItem = styled(ListItem)(({ theme }) => ({
        color: theme.palette.neutral.main,
        '&::before': {
          content: '"•"',
          color: theme.palette.neutral.main,
          paddingRight: '5px',
        },
      }));
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '1200px',
                margin: '0 auto',
            }}
        >
            <Box 
                sx={{
                    mb:'50px'
                }}
            >
                <Typography variant="h4" component="div" gutterBottom>
                    The Vision
                </Typography>
                <TextField
                    multiline rows={4}
                    variant="outlined"
                    fullWidth
                    value="This is what we want to create for you"
                />
            </Box>
            <Box 
                sx={{
                    mb:'50px'
                }}
            >
                <Typography variant="h4" component="div" gutterBottom>
                    We believe...
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Things are changing. Transforming business reality at a pace faster than traditional consultants and business analysts can put their recommendations together.
                </Typography>
                <List>
                    <StyledListItem> AI and ML have blown up task allocation and completion amongst people and algorithms, pressing companies to rethink their organizational structure across all levels</StyledListItem>
                    <StyledListItem> As businesses must adapt to a new norm in automation possibilities, leaders must rethink their process design, software stack, and integration strategy</StyledListItem>
                    <StyledListItem> Identifying, choosing, implementing, and running the right software in the context of specific needs of every company will be more important than ever </StyledListItem>
                </List>
                <Typography variant="body1" gutterBottom>
                    And that's why we're building StackRunner.
                </Typography>
            </Box>
            <Grid container spacing={2}
                sx={{
                    mb:'50px',
                    alignItems:"stretch",
                }}
            >
                <Grid 
                    item xs={12} sm={6} md={4}
                    sx={{
                        minHeight: '150px',
                        // alignItems:"stretch",
                    }}
                >
                    <Card sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Blog
                            </Typography>
                            <Typography variant="body2">
                                {/* Your blog description goes here */}
                            </Typography>
                        </CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CardActions>
                                <Button variant='register' size="small">Check blog</Button>
                            </CardActions>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Community
                            </Typography>
                            <Typography variant="body2">
                                {/* Your community description goes here */}
                            </Typography>
                        </CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CardActions>
                                <Button variant='register' size="small">join us</Button>
                            </CardActions>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Be social
                            </Typography>
                            <Typography variant="body2">
                                we're a lot on twitter actually
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <TwitterIcon />
                                <Typography variant="body2" sx={{ ml: 1 }}>
                                    @comingsoon
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Box>
                <Typography variant="h4" component="div" gutterBottom>
                    Timeline of Major Events
                </Typography>
                <Timeline position="alternate">
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                        >
                            early 2023
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot>
                                <CakeIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h5" component="span">
                                Aha!
                            </Typography>
                            <Typography variant="h6" >the idea for stackrunner is born</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            variant="body2"
                            color="text.secondary"
                        >
                            Oct 12, 2023
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="secondary">
                                <LaptopMacIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h4" component="span">
                                Hello World!
                            </Typography>
                            <Typography variant='h6'>First line of code prints in the console</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            variant="body2"
                            color="text.secondary"
                        >
                            100 days later
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="primary" variant="outlined">
                                <HotelIcon />
                            </TimelineDot>
                            <TimelineConnector
                                sx={{ bgcolor: 'secondary.main' }}
                            />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h5" component="span">
                                Climb Mountains
                            </Typography>
                            <Typography variant="h6">Going through the steepest learning curves </Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            variant="body2"
                            color="text.secondary"
                        >
                            Feb 12, 2024
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector
                                sx={{ bgcolor: 'secondary.main' }}
                            />
                            <TimelineDot color="secondary">
                                <RocketIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h5" component="span">
                                Launch Alpha 0.1
                            </Typography>
                            <Typography variant="h6">
                                release to closed group of Alpha testers 
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            variant="body2"
                            color="text.secondary"
                        >
                            Join the waitlist!
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector
                                sx={{ bgcolor: 'secondary.main' }}
                            />
                            <TimelineDot color="secondary">
                                <ConstructionIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h5" component="span">
                                More hustling...
                            </Typography>
                            <Typography variant="h6">
                                before public release for stackrunners like you
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </Box>
        </Box>
    );
};

export default AboutUsPage;
