//sr-build/client/src/components/product/productlisting/categorylisting/productListingTile.jsx
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProductCard from '../../productCard/productCard';
import { formatUrlPart } from '../../../../utils/formaturlpart';
import { Grid, Box, Typography, Button, CircularProgress } from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import './productListingTile.scss';

const ProductListingTile = ({ productId }) => {
    const userId = useSelector(state =>
        state.auth.user ? state.auth.user.user_id : null
    );
    const [productData, setProductData] = useState(null);
    const [pathData, setPathData] = useState(null);
    const [productUrl, setProductUrl] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const productResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/product/${productId}`
                );
                if (!productResponse.ok) {
                    throw new Error(
                        `HTTP error! status: ${productResponse.status}`
                    );
                }
                const productData = await productResponse.json();
                // console.log('Fetched product data:', productData);
                setProductData(productData);
            } catch (error) {
                console.error('Error fetching product:', error);
            } finally {
                setLoading(false);
            }
        };

        if (productId) {
            fetchProduct();
        }
    }, [productId]);

    useEffect(() => {
        const fetchPathDetails = async () => {
            // console.log('Fetching path details...');
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/product/details/${productData.product_id}`
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const pathData = await response.json();
                // console.log('Fetched path details:', pathData);
                setPathData(pathData);

                const url = `/products/${formatUrlPart(
                    pathData.domain_name || 'undefined'
                )}/${formatUrlPart(
                    pathData.primary_category_name || 'undefined'
                )}/${formatUrlPart(
                    pathData.primary_sub_category_name || 'undefined'
                )}/${pathData.product_id || ''}/${formatUrlPart(
                    pathData.product_name || 'undefined'
                )}`;
                // console.log('Constructed product URL:', url);
                setProductUrl(url);
            } catch (error) {
                console.error(
                    'A problem occurred while fetching the product details:',
                    error
                );
            }
        };

        if (productData && productData.product_id) {
            fetchPathDetails();
        }
    }, [productData]);

    if (loading) {
        return <CircularProgress />;
    }

    if (!productData) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid white',
                    borderRadius: '5px',
                    padding: '12px',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        gap: '20px',
                        flexDirection: 'row',
                        alignContent: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <ProductCard
                        productId={productId}
                        productData={productData}
                        showName={true}
                        showVendor={true}
                        showCategory={false}
                        showSubcategory={true}
                        showSocialButtonsOne={true}
                        showSocialButtonsTwo={false}
                        showQuickView={true}
                        flexDirection="row"
                    />
                    <Box
                        style={{ display: 'flex', alignItems: 'center' }}
                        className="product-action"
                    >
                        <Button
                            variant="register"
                            // color="primary"
                            href={productUrl}
                        >
                            Go to Page
                        </Button>
                    </Box>
                </Box>
                <Grid item xs={12} container alignItems="center">
                    <Grid item xs style={{ flexGrow: 1 }}>
                        <Typography
                            variant="subtitle1"
                            className="product-rating"
                        >
                            <StarRateIcon /> {productData.product_rating_main} /
                            5
                        </Typography>
                    </Grid>
                    <Grid item xs style={{ flexGrow: 5 }}>
                        <Typography
                            variant="body2"
                            className="product-description"
                        >
                            {productData.audience_description}
                        </Typography>

                        {/* <Typography variant="h6" className="product-name">
                            {productData.product_name}
                        </Typography> */}
                        {/* <Typography variant="body1" className="product-category">
                            {pathData && pathData.domain_name}
                        </Typography>
                        <Typography variant="body1" className="product-category">
                            {pathData && pathData.primary_category_name}
                        </Typography> */}
                        {/* <Typography variant="body1" className="product-category">
                            {pathData && pathData.primary_sub_category_name}
                        </Typography> */}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};

export default ProductListingTile;
