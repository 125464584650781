import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApexCharts from 'react-apexcharts';
import { CircularProgress } from '@mui/material';
import { fetchStackProductCount } from '../../../features/charts/stack/stackpage/stackcount/stackProductCountSlice';
import ChartDataContext from '../../../contexts/chartDataContext';
import './chartStackProductCount.scss';

const StackProductChart = ({ stackData, stackId, userId, companyId, height = '200', width = '350', fontSize = '42'}) => {
    const { setChartData } = React.useContext(ChartDataContext);
    const dispatch = useDispatch();
    const { data, loading, error } = useSelector(state => state.stackProductCount);

    useEffect(() => {
        dispatch(fetchStackProductCount({ stackId, companyId }));
    }, [dispatch, stackId, companyId]);

    useEffect(() => {
        if (data) {
            setChartData(`Total: ${data.product_count}, Max: ${data.max_product_count}, Avg: ${data.avg_product_count}`);
        }
    }, [data, setChartData]);

    const totalProductCount = data?.product_count || 0;
    const maxProductCount = data?.max_product_count || 0; 
    const avgProductCount = data?.avg_product_count || 0;

    const percentageOfMax = (totalProductCount / maxProductCount) * 100;

    const chartOptions = {
        chart: {
            type: 'radialBar',
            offsetY: -10,
            height: `${height}px`,
            width: `${width}px`,
        },
        annotations: {
            position: 'front',
            custom: [{
                x: '50%',
                y: '50%',
                borderColor: '#fff',
                borderWidth: 2,
                text: `Total: ${totalProductCount}, Max: ${maxProductCount}, Avg: ${avgProductCount}`,
                style: {
                    color: '#fff',
                    background: '#000',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    textAnchor: 'middle',
                }
            }]
        },
        plotOptions: {
            radialBar: {
                startAngle: -100,
                endAngle: 100,
                hollow: {
                    margin: 0,
                    size: '60%', 
                    background: 'transparent',
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: 5,
                        fontSize: `${fontSize}px`,
                        color: '#ffffff',
                        formatter: function (val) {
                            return `${totalProductCount} `; 
                        }
                    }
                },
                track: {
                    background: '#ccc', 
                    strokeWidth: '97%',
                }
            }
        },
        stroke: {
            lineCap: "butt",
        },
        labels: ['Total Count'],
        colors: ['#20E647'],
    };

    if (loading || !data) return <CircularProgress />;
    if (error) return <div>Error: {error}</div>;

    const series = [percentageOfMax]; 

    return <ApexCharts options={chartOptions} series={series} type="radialBar" />;
};

export default StackProductChart;
