import React from "react";
import { Box } from "@mui/material";
import HeaderStreamed from "../../../components/headerstreamed/HeaderStreamed";
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/sidepanelcomponent/SideBarComponent';
import  "./userAccountBilling.scss";

const UserAccountBilling = ({userData, userId, entityType}) => {

  return(
    <Box className='user-account-page-container'>
        <HeaderStreamed title="Plan, Billing and Payments" subtitle="page to access and manage claimed entities on Stackrunner"/>
        <Box className="account-container-main" >
            <Box className="account-gridPlusSide" >
                <Box className= "account-main-grid">
                    <BoxComponent
                        title="Plans and Feature Access"
                        subtitle="see what plan you have an what features you can access (and which ones you can't)"
                        width={12}
                    >
                    </BoxComponent>
                    
                    <BoxComponent
                        title="Payment and Billing Info"
                        subtitle="add card, see billing amount and cycle, etc."
                        width={12}
                    >
                        
                    </BoxComponent>
                    
                    <BoxComponent 
                        title="Transaction History" 
                        subtitle="scroll through transaction history"
                        width={12}
                    />
                </Box>
                <Box className="account-right-panel" >
                    <SideBarComponent 
                        title="Good space for HelCenter"
                    >
                        
                    </SideBarComponent>
                    <SideBarComponent 
                        title="Good space for Upsell"
                    >
                        
                    </SideBarComponent>
                    <SideBarComponent 
                        title="Good space for New Feature Releases"
                    >
                        
                    </SideBarComponent>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}


export default UserAccountBilling;