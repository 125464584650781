import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { fetchFeatureAvailability } from '../../../features/users/featureavailability/featureAvailabilitySlice';
import { usePlanId } from '../../../hooks/usePlanId';
import {fetchLikeCount,toggleLike } from '../../../features/socials/likes/likeSlice';


const LikeButton = ({
    entityType,
    entityId,
    userId,
    showCount = true,
    flexDirection = 'row',
    showTooltip = true,
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const planId = usePlanId();
    const platformFeatureId = 17;
    const [isLoading, setIsLoading] = useState(true);
    const [isFeatureAvailable, setIsFeatureAvailable] = useState(false);
    const likeCountId = `${entityType}_${entityId}`;
    const likeId = `${entityType}_${entityId}_${userId}`;
    const likeCount = useSelector(
        state => state.likes?.counts?.[likeCountId] || 0
    );
    const isLiked = useSelector(
        state => state.likes?.byId?.[likeId]?.liked || false
    );

    useEffect(() => {
        dispatch(fetchFeatureAvailability({ planId, platformFeatureId }))
            .unwrap()
            .then(availability => {
                // console.log(
                //     'Feature availability:',
                //     availability.isFeatureAvailable
                // ); // Log the received data
                setIsFeatureAvailable(availability.isFeatureAvailable);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Failed to fetch feature availability:', error);
                setIsLoading(false);
            });
    }, [dispatch, planId, platformFeatureId]);

    useEffect(() => {
        if (entityType && entityId) {
            dispatch(fetchLikeCount({ entityType, entityId }));
        }
    }, [dispatch, entityType, entityId]);

    const handleLike = () => {
        if (!userId) {
            console.log('Please log in or register to like.');
            return;
        }
        if (userId) {
            dispatch(toggleLike({ entityType, entityId, userId })).then(() => {
                const updatedLikeCount = isLiked
                    ? likeCount - 1
                    : likeCount + 1;
                dispatch({
                    type: 'likes/updateLikeCount',
                    payload: { likeCountId, count: updatedLikeCount },
                });
            });
        } else {
            console.log('Please log in or register to like this.');
        }
    };

    // If the feature is not available or the data is still loading, do not render the component
    if (!isFeatureAvailable || isLoading) {
        return null;
    }

    const boxStyle = {
        backgroundColor: theme.palette.neutral.light,
        color: theme.palette.getContrastText(theme.palette.neutral.light),
        boxshadow: `0px 4px 4px ${theme.palette.boxshadow.default}`,
        borderRadius: '5px',
        margin: '0px',
        padding: '0px',
    };

    const button = (
        <IconButton
            sx={{
                background: 'transparent',
                border: 'none',
                color: isLiked
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                '&.Mui-disabled': {
                    color: theme.palette.primary.main,
                },
                '&:disabled': {
                    cursor: 'default',
                },
            }}
            onClick={handleLike}
            aria-label="like"
            disabled={!userId}
        >
            <ThumbUpIcon />
        </IconButton>
    );

    return (
        <Box
            style={boxStyle}
            sx={{
                flexDirection: flexDirection,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                margin: '0px',
                padding: '0px',
            }}
        >
            {showTooltip ? (
                <Tooltip
                    title={`${likeCount || 0} Likes`}
                    placement="top"
                    arrow
                >
                    <div>{button}</div>
                </Tooltip>
            ) : (
                button
            )}
            {showCount && (
                <Typography
                    sx={{
                        display: 'flex',
                        width: 'auto',
                        justifyContent: 'center',
                        marginRight: '8px',
                        marginLeft: flexDirection === 'row' ? '8px' : '0px',
                        marginTop: flexDirection === 'column' ? '4px' : '0px',
                        fontSize: '16px',
                        fontweight: '700',
                        textShadow: '0px 0px 1px black',
                    }}
                >
                    {likeCount || 0}
                </Typography>
            )}
        </Box>
    );
};

export default LikeButton;
