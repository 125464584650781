//src>features>users>userpage>userPageSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Async thunk to fetch a single user by ID
export const fetchUserForPage = createAsyncThunk(
  'userPage/fetchUser',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/userDetails/user-profile/${userId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch user with ID ${userId}`);
      }
      const userData = await response.json();
      return userData; // Assuming the API returns the user data directly
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to fetch additional data for the logged-in user
export const fetchVisitorUserData = createAsyncThunk(
  'userPage/fetchVisitorUserData',
  async (visitorUserId, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/userDetails/user-profile/${visitorUserId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch additional data for user with ID ${visitorUserId}`);
      }
      const visitorUserData = await response.json();
      return visitorUserData; // Assuming the API returns the additional data directly
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Initial state for the stack page slice
const initialState = {
  user: null,
  visitorUserData: null,
  loading: false,
  error: null
};

const userPageSlice = createSlice({
  name: 'userPage',
  initialState,
  reducers: {
    // Add any reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserForPage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserForPage.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchUserForPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchVisitorUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVisitorUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.visitorUserData = action.payload;
      })
      .addCase(fetchVisitorUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userPageSlice.reducer;
