import { createSlice } from '@reduxjs/toolkit';

export const drawerSlice = createSlice({
    name: 'createnewdrawer',
    initialState: {
        isOpen: false,
    },
    reducers: {
        toggleCreatenewdrawer: (state) => {
            state.isOpen = !state.isOpen;
        },
    },
});

export const { toggleCreatenewdrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
