import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async ({ page, limit, domain, category }) => {
        let queryParams = new URLSearchParams({ page, limit });
        if (domain) queryParams.append('domain', domain);
        if (category) queryParams.append('category', category);
        const url = `${process.env.REACT_APP_API_URL}/api/v1/product/browse?${queryParams}`;
        // console.log('Fetching products with URL:', url); // Debugging log

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch products');
        }
        return await response.json();
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        items: [],
        totalItems: 0,
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.items = action.payload.products;
                state.totalItems = action.payload.totalItems;
                state.loading = false;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default productsSlice.reducer;

