//src>userprofile>userPublicProfile>productsUser>profileProductsUser.jsx
import React from 'react';
import { Box } from '@mui/material';
import HeaderStreamed from '../../../components/headerstreamed/HeaderStreamed';
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/sidepanelcomponent/SideBarComponent';
import UserProfilePeopleDataGrid from '../../../global/datagrids/userprofilepeople/userProfilePeopleDataGrid';  
import ImageFiller from 'react-image-filler';
import  "./profilePeopleUser.scss";


const ProfilePeopleUser = ({ userId, entityTypeId, visitorUserId }) => {
    const entityType = 'user'
    
    if (!userId || !entityTypeId ) {
        return null;
    }

    return (
        <Box>

            <Box className='user-account-page-container'>
                <HeaderStreamed title="User Profile: People" subtitle="Data Grid displaying product based on filter options"/>
                <Box className="account-container-main" >
                    <Box className="account-gridPlusSide" >
                        <Box className= "account-main-grid">
                            <BoxComponent 
                                title="People" 
                                subtitle="updated"
                                width={12}
                            >
                                <UserProfilePeopleDataGrid
                                    userId={userId}
                                    entityTypeId={entityTypeId}
                                    entityType={entityType}
                                    visitorUserId={visitorUserId}
                                />
                            </BoxComponent>
                        </Box>
                        <Box className="account-right-panel" >
                            <SideBarComponent 
                                title="Teams ? (in account) "
                                subtitle="toggable subtitle"
                                sideText="10"
                                showButton={true}
                                link="https://www.google.com"
                            >
                                <div><ImageFiller width={260} height={100}/></div>
                            </SideBarComponent>
                            <SideBarComponent title="Groups ? (in account)"/>
                            <SideBarComponent title="SideBar Component 3"/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ProfilePeopleUser;
