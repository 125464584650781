import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '../../../helpers/apifetch';
import Cookies from 'js-cookie';

// Async thunk to fetch the like count
export const fetchLikeCount = createAsyncThunk(
    'likes/fetchCount',
    async ({ entityType, entityId }) => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/interactions/like/count/${entityType}/${entityId}`
        );
        if (!response.ok) {
            throw new Error('Failed to fetch like count');
        }
        const data = await response.json();
        return { entityId, entityType, count: Number(data.total_likes) };
    }
);

// Async thunk to toggle like
export const toggleLike = createAsyncThunk(
    'likes/toggle',
    async ({ entityType, entityId, userId }, { getState, dispatch }) => {
        const token = Cookies.get('token'); // original: const token = getState().auth.token;
        // console.log('likesSlice: Toggle Like Token:', token);
        // console.log('Entity ID: ', entityId);
        // console.log('Entity Type: ', entityType);
        // console.log('User ID: ', userId);

        try {
            const response = await apiFetch(
                `${process.env.REACT_APP_API_URL}/api/v1/interactions/like/toggle`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ entityId, entityType, userId }),
                },
                dispatch
            );

            if (!response.ok) throw new Error('Failed to toggle like');

            const data = await response.json();

            dispatch({ type: 'auth/logToken', payload: token });

            return data;
        } catch (error) {
            throw new Error('Failed to toggle like');
        }
    }
);

export const likesSlice = createSlice({
    name: 'likes',
    initialState: {
        counts: {},
        byId: {},
        allIds: [],
        loading: false,
        error: null,
    },
    reducers: {
        updateLikeCount: (state, action) => {
            const { likeCountId, count } = action.payload;
            state.counts[likeCountId] = count;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchLikeCount.pending, state => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLikeCount.fulfilled, (state, action) => {
                const { entityType, entityId, count } = action.payload;
                const likeCountId = `${entityType}_${entityId}`;

                if (!state.counts) state.counts = {};

                if (entityType && entityId) {
                    state.counts[likeCountId] = count;
                } else {
                    console.error(`Invalid likeCountId: ${likeCountId}`);
                }
                state.loading = false;
            })
            .addCase(fetchLikeCount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(toggleLike.fulfilled, (state, action) => {
                const { entityType, entityId, userId } = action.meta.arg;
                const likeId = `${entityType}_${entityId}_${userId}`;
                const likeCountId = `${entityType}_${entityId}`;
                const updatedLikeCount = action.payload.total_likes;

                if (state.byId[likeId]) {
                    state.byId[likeId].liked = !state.byId[likeId].liked;
                } else {
                    state.byId[likeId] = { entityId, userId, liked: true };
                    if (!state.allIds) state.allIds = [];
                    state.allIds.push(likeId);

                }

                state.counts[likeCountId] = updatedLikeCount;
            })
            .addCase(toggleLike.rejected, (state, action) => {
                state.error = action.error.message;
            });
    },
});

export const { updateLikeCount } = likesSlice.actions;
export default likesSlice.reducer;
