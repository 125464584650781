//src>components>product>productpage>productrating>productaveragerating>productPageRatingMain.jsx
// to be replaced by entity-agnostic component reviewbarmain.jsx

import React from 'react';
import { Typography, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';


const ProductPageRatingMain = ({ showReviews = true, showComments = true, showChatter = true, showWriteReview = true }) => {
    

    
    return (
        
        <Box display="flex" gap= '20px' justifyContent='center' alignItems="center">
            <Box sx={{ display:"flex", flexDirection:"row", alignItems:"center"}}>
                <StarIcon color='white' />
                <Typography variant="subtitle1">4.7 / 5</Typography>
            </Box>
            <Box sx={{ display:"flex", flexDirection:"row", alignItems:"center", gap: "10px"}}>
                
                <Box sx={{ display:"flex", flexDirection:"row", alignItems:"center", gap:'20px'}}>
                    {showReviews && <Typography variant='h6' className="ratingText"> Reviews</Typography>}
                    {showWriteReview && <Typography variant='h6' className="ratingText"> Write Review</Typography>}
                    {showComments && <Typography variant='h6' className="ratingText"> Comments</Typography>}
                    {showChatter && <Typography variant='h6' className="ratingText"> Chatter</Typography>}
                </Box>
            </Box>  
        </Box>
    );
};

export default ProductPageRatingMain;
