//src>components>users>peoplecard>peopleCard.jsx
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Tooltip, IconButton } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import FollowButton from '../../../components/socials/follow/followButton';
import LikeButton from '../../../components/socials/likes/likeButton';
import SaveButton from '../../../components/socials/saves/saveButton';
import ShareButton from '../../../components/socials/shares/shareButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import QuickViewUser from '../../../components/users/userprofile/quickView/quickViewUser';

// import { fetchUserForPage } from '../../../features/users/userpage/userPageSlice';
import './peopleCard.scss'

const PeopleCard = ({ 
        userId,
        showFirstName = false,
        showUserCompany = false,
        showBusinessRole = false,
        showStacks = false,
        showActions= false,
        showQuickView = false,
        imgStyle = {},
        flexDirection = 'row'
    }) => {
    const [userData, setUserData] = useState(null);
    const [quickViewOpen, setQuickViewOpen] = useState(false);
    const buttonClass = userId ? 'testing-new logged-in' : 'testing-new';
    const visitorUserId = useSelector(state => state.auth.user?.user_id);
    
    
    // Fetch joint data about user with userId from userDetails route >> to replace with data from userPageSlice
    useEffect(() => {
        if (userId) {

            const fetchData = async () => {
                try {
                    const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/userDetails/user-profile/${userId}`);
                    if (!userResponse.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const userData = await userResponse.json();
                    setUserData(userData);
                    
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };
    
            fetchData();
        }
        else {
            // console.log("No userId provided");
        }
    }, [userId]);

    const entityType = userData?.user?.entity_type;
    
    


    if (!userData) {
        return <div>Oops! Sorry, we couldn't find this user.</div>;
    }

    const handleOpenQuickView = () => setQuickViewOpen(true);
    const handleCloseQuickView = () => setQuickViewOpen(false);

    const handleNavigateToProfile = () => {
        const url = `/user/profile/${userId}`;
        window.open(url, '_blank');
    };
    
    return (
        <Box className="people-card" sx={{ flexDirection: flexDirection }}>
            <Box  className="user-profile-image" style={{ position: 'relative' }}>
                <img 
                    className='people-card-user-profile-image'
                    src={userData.user.user_profile_image || "https://robohash.org/evenietnisitemporibus.png?size=50x50&set=set4" }
                    alt={userData.user.user_first_name}
                    onClick={handleNavigateToProfile}
                    style={{ ...imgStyle, objectFit: 'cover' }}
                />
                {showQuickView && (
                    <IconButton 
                        className='people-card-show-quickview'
                        style={{ position: 'absolute', top: -14, left: -14 , borderRadius: '50%'}}
                        onClick={handleOpenQuickView}
                    >
                        <VisibilityIcon style={{fontSize:'16'}}/>
                    </IconButton>
                )}
            </Box>
            <Box className='user-people-card-details'>
                <Box className='people-card-user-info'>
                    {showFirstName && <Box className='people-card-details-personal'>
                        <div>{userData?.user?.user_first_name}</div>
                        <div>{userData?.user?.user_last_name}</div>
                    </Box>}
                    {showBusinessRole && <div className='people-card-details-role'>
                        {/* <div>{userData?.user?.businessFunctionName}</div> */}
                        <div>{userData?.user?.businessRoleName}</div>
                    </div>}
                </Box>
                {showUserCompany && <Box className='people-card-details-company'>
                    <div className='people-card-compamy-details'> {userData?.company?.companySectorName}</div>
                    {/* <div className='people-card-compamy-details'>{userData?.company?.company_name}</div> */}
                    <div className='people-card-compamy-details'>
                        {/* <div> {userData?.company?.companyIndustryName}</div> */}
                    </div>
                </Box>}
            </Box>
            {showStacks && <Box className='people-card-stacks'>
                <Box sx={{display: 'flex', fontSize: '12px', alignItems: 'center' }}>Official Stacks:</Box>
                <Box className='user-primary-stacks'>
                    <Box className='user-official-stack' >
                        <div className='user-official-stacks'>
                            <a href={`/stacks/${userData?.stacks?.userPersonalStack?.stack_id}`} target="_blank" rel="noopener noreferrer">
                                <Tooltip title="Personal" placement="top"> 
                                    <LayersIcon sx={{ bgcolor:'pink', borderRadius: '50%', color: 'grey' }} fontSize="medium"/>
                                </Tooltip>
                            </a>
                        </div>
                    </Box>
                    <Box className='user-official-stack'>
                        <div className='user-official-stacks'>
                            <a href={`/stacks/${userData?.stacks?.userBusinessStack?.stack_id}`} target="_blank" rel="noopener noreferrer">
                                <Tooltip title="Business" placement="top">
                                    <LayersIcon sx={{ bgcolor:'grey', borderRadius: '50%' }} color= 'secondary' fontSize="medium"/>
                                </Tooltip>
                            </a>
                        </div>
                    </Box>
                </Box>
            </Box>}
            {showActions && (
                <Box className="peoplecard-actions">
                    <Box className={buttonClass}>{entityType && userId &&  (<LikeButton showCount={false} entityType={entityType} entityId={userId} userId={visitorUserId}/>)} </Box>
                    <Box className={buttonClass}>{entityType && userId &&  (<FollowButton showCount={false} entityType={entityType} entityId={userId} userId={visitorUserId} />)}</Box>
                    <Box className={buttonClass}>{entityType && userId &&  (<SaveButton showCount={false} entityType={entityType} entityId={userId} userId={visitorUserId} />)}</Box>
                    <Box className={buttonClass}>{entityType && userId &&  (<ShareButton showCount={false} entityType={entityType} entityId={userId} userId={visitorUserId} />)}</Box>
                </Box>
            )}
            {quickViewOpen && <QuickViewUser userId={userId} entityType={entityType} userData={userData} visitorUserId={visitorUserId} open={quickViewOpen} onClose={handleCloseQuickView} />}
        </Box>
    );
};

export default PeopleCard;
