// src/features/products/quickview/productQuickViewSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const productQViewSlice = createSlice({
  name: 'productQView',
  initialState: [],
  reducers: {
    addProduct: (state, action) => {
      state.push(action.payload);
    },
    removeProduct: (state, action) => {
      return state.filter(product => product.id !== action.payload.id);
    },
    // ... more reducers can be added as needed
  }
});

export const { addProduct, removeProduct } = productQViewSlice.actions;
export default productQViewSlice.reducer;