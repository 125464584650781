import React from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import './toggleFilterComponent.scss';

const ToggleFilter = ({ label, value, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onChange={(event) => onChange(event.target.checked)}
          name={label}
        />
      }
      label={label}
    />
  );
}

export default ToggleFilter;