import React, { useState } from 'react';
import { Box, Button, TextField, Checkbox, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './waitlistuser.scss';

const WaitlistUser = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .matches(
                /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                'Invalid email address'
            )
            .required('Email is required'),
        joinedAngel: Yup.boolean(),
        linkedInProfile: Yup.string()
            .url('Invalid LinkedIn URL')
            .matches(
                /^(https?:\/\/)?(www\.)?linkedin\.com\/.+/,
                'Invalid LinkedIn URL'
            )
            .notRequired()
    });

    const handleWaitlist = async (values) => {
        setErrorMessage('');

        const userType = ['waitlist'];
        if (values.joinedAlpha) userType.push('alpha');
        if (values.joinedAngel) userType.push('angel');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/waitlist`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    user_email: values.email, 
                    user_type: userType, 
                    user_handle_linkedin: values.linkedInProfile,
                    password: 'defaultPassword'
                })
            });

            const data = await response.json();
            if (response.ok) {
                // After successful submission, redirect to the WaitlistRedirect component
                navigate('/waitlist-confirm', { state: { user_type: userType } });
            } else {
                setErrorMessage(data.message || 'An error occurred during registration.');
            }
        } catch (error) {
            setErrorMessage('Failed to send registration request.');
        }
    };

    return (
        <Formik
            initialValues={{ email: '', joinedAlpha: false, joinedAngel: false, linkedInProfile: '' }}
            validationSchema={validationSchema}
            onSubmit={handleWaitlist}
        >
            {({ values, isValid, dirty }) => (
                <Form>
                    <Box className="join-waitlist-main-container">
                        <Typography className='waitlist-title'> Join the waitlist!</Typography> 
                        {errorMessage && (
                            <div className="error-message">
                                {errorMessage}
                                {errorMessage.includes("Email already exists") && (
                                    <span> Email is already on the waitlist.</span>
                                )}
                            </div>
                        )}
                        <Box className="join-waitlist-user">
                            <Field 
                                name="email"
                                as={TextField}
                                className="input-field"
                                size='small'
                                label="Email"
                                variant="outlined"
                            />
                            <ErrorMessage name="email" component="div" />
                            <Box className='waitlist-add-interest'>
                                <Box>
                                    <Field name="joinedAlpha" type="checkbox" as={Checkbox} />
                                    <label>I'm interested in being an Alpha tester</label>
                                </Box>
                                <Box>
                                    <Field name="joinedAngel" type="checkbox" as={Checkbox} />
                                    <label>Add me as potential Angel investor</label>
                                    {values.joinedAngel && (
                                        <>
                                            <Field 
                                                name="linkedInProfile" 
                                                as={TextField} 
                                                className="input-field" 
                                                size='small' 
                                                label="Paste your LinkedIn profile url" 
                                                variant="outlined" 
                                                style={{ marginTop: '20px' }}
                                            />
                                            <ErrorMessage name="linkedInProfile" component="div" />
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Button 
                            type="submit"
                            variant='register'
                            disabled={!(isValid && values.email)}
                            className="waitlist-submit-button"
                        >
                            Sign me up!
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default WaitlistUser;


