import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Divider } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import apiFetch from '../../../../helpers/apifetch';
import './UserAccountSideDetails.scss';

// Update social handles with userData as prop

const UserAccountSideDetails = () => {
    const token = useSelector(state => state.auth.token);
    const { userId } = useParams();
    const [userDetails, setUserDetails] = useState({
        status: '',
        account: '',
        points: 0,
        plan: '',
        memberSince: '',
    });
    const [linkedAccounts, setLinkedAccounts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiFetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUserDetails({
                    status: data.user_status_platform,
                    account: data.user_status_account,
                    points: data.user_points,
                    plan: data.user_plan,
                    memberSince: 'Jan 2024', 
                });
                setLinkedAccounts(data.linkedAccounts || []); 
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
        fetchData();
    }, [userId, token]);

    return (
        <>
            <div className="user-sideprofile-container">
                <Box className="user-sideprofile-widget">
                    <div>Status: {userDetails.status}</div>
                    <div>Point Balance: {userDetails.points}</div>
                </Box>
                <Divider />
                <Box className="user-sideprofile-widget">
                    <div>Account: {userDetails.account}</div>
                    <div>Plan: {userDetails.plan}</div>
                    <div>Member since: {userDetails.memberSince}</div>
                </Box>
                <Divider />
                <Box className="user-sideprofile-widget">
                    <div>Linked accounts</div>
                    <Box className='side-profile-linked-accounts'>
                        {linkedAccounts.map((account, index) => (
                            <div key={index}>{account.name}</div>
                        ))}
                    </Box>
                </Box>
                <Divider />
                <Box className="user-sideprofile-widget">
                <Box className="user-sideprofile-widget">
                    <div>Linkable accounts</div>
                    <Box className='side-profile-linkable-accounts'>
                        <Box className='single-linkable-account'>
                            <div>Connect with</div>
                            <LinkedInIcon />
                        </Box>
                        <Box className='single-linkable-account'>
                            <div>Connect with</div>
                            <FacebookIcon />
                        </Box>
                        <Box className='single-linkable-account'>
                            <div>Connect with</div>
                            <GitHubIcon />
                        </Box>
                    </Box>
                </Box>
                </Box>
            </div>
        </>
    );
};

export default UserAccountSideDetails;
