import React from 'react';
import HeaderStreamed from '../../components/headerstreamed/HeaderStreamed';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import './myProductFolders.scss';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';


const MyProductFolders = () => {
  return (
    
    <div className='myproductfolders-main-container'>
      <HeaderStreamed title="Product Folders" subtitle="folder needs to sit under 'Profile' (dynamic pages) Users drag products into folders by lifecycle"/>  
      <div className='all-folders'>
        <div className='stack-folder-box'>
          <Box className='fullview-icon'> <OpenInNewIcon/></Box>
          <h5>My Stacks</h5>
          <div className="folder-composition">
            <h6>34</h6>
            <h6>Products</h6>
          </div>
          <div className='display-container'>
            <div className="sample-products">
              <img className="sample-product-selected-folder-first" src= "https://robohash.org/ipsaetperspiciatis.png?size=40x40&set=set2" alt="product sample" />
              <img className="sample-product-selected-folder-next" src= "https://robohash.org/eossintipsam.png?size=40x40&set=set2" alt="product sample" />
              <img className="sample-product-selected-folder-then" src= "https://robohash.org/asperioressequimaiores.png?size=40x40&set=set2" alt="product sample" />
              
            </div>
            
            <Link to="/your-path" onClick={(event) => {
                  event.preventDefault();
                  window.open(event.currentTarget.href, '_blank');
                }}>
                  <div className="see-all-container">
                    <AddIcon sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
                  </div>
             </Link>
          </div>
        </div>
        <div className="other-folders-container">
          <div className='stack-folder-box'>
            <Box className='fullview-icon'> <OpenInNewIcon/></Box>
            <h5>Following</h5>
            <div className="folder-composition">
              <h6>64</h6>
              <h6>Products</h6>
            </div>
            <div className='display-container'>
              <div className="sample-products">
                <img className="sample-product-selected-folder-first" src= "https://robohash.org/ipsaetperspiciatis.png?size=30x30&set=set2" alt="product sample" />
                <img className="sample-product-selected-folder-next" src= "https://robohash.org/asperioressequimaiores.png?size=30x30&set=set2" alt="product sample" />
                <img className="sample-product-selected-folder-then" src= "https://robohash.org/eossintipsam.png?size=30x30&set=set2" alt="product sample" />
              </div>
              
              <Link to="/your-path" onClick={(event) => {
                    event.preventDefault();
                    window.open(event.currentTarget.href, '_blank');
                  }}>
                    <div className="see-all-container">
                      <AddIcon sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
                    </div>
              </Link>
            </div>
          </div>
          <div className='stack-folder-box'>
            <Box className='fullview-icon'> <OpenInNewIcon/></Box>
            <div className="product-folder-head">
              <SaveIcon className='folder-icon'/>
              <div>
                <Typography>Saved</Typography>
                {/* <div className="folder-composition">
                  <h6>3</h6>
                  <h6>Products</h6>
                </div> */}
              </div>
            </div>
            <div className='display-container'>
              <div className="sample-products">
                <img className="sample-product-selected-folder-first" src= "https://robohash.org/ipsaetperspiciatis.png?size=30x30&set=set2" alt="product sample" />
                <img className="sample-product-selected-folder-next" src= "https://robohash.org/asperioressequimaiores.png?size=30x30&set=set2" alt="product sample" />
                <img className="sample-product-selected-folder-then" src= "https://robohash.org/eossintipsam.png?size=30x30&set=set2" alt="product sample" />
                <Box className="sample-product-selected-folder-last">
                  <div className="products-in-stack">+33</div>
                </Box>
              </div>
              
              <Link to="/your-path" onClick={(event) => {
                    event.preventDefault();
                    window.open(event.currentTarget.href, '_blank');
                  }}>
                    <div className="see-all-container">
                      <AddIcon sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
                    </div>
              </Link>
            </div>
          </div>
          <div className='stack-folder-box'>
            <Box className='fullview-icon'> <OpenInNewIcon/></Box>
            <h5>Trialling</h5>
            <div className="folder-composition">
              <h6>64</h6>
              <h6>Products</h6>
            </div>
            <div className='display-container'>
              <div className="sample-products">
                <img className="sample-product-selected-folder-first" src= "https://robohash.org/ipsaetperspiciatis.png?size=30x30&set=set2" alt="product sample" />
                <img className="sample-product-selected-folder-next" src= "https://robohash.org/asperioressequimaiores.png?size=30x30&set=set2" alt="product sample" />
                <img className="sample-product-selected-folder-then" src= "https://robohash.org/eossintipsam.png?size=30x30&set=set2" alt="product sample" />
              </div>
              
              <Link to="/your-path" onClick={(event) => {
                    event.preventDefault();
                    window.open(event.currentTarget.href, '_blank');
                  }}>
                    <div className="see-all-container">
                      <AddIcon sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
                    </div>
              </Link>
            </div>
          </div>
          <div className='stack-folder-box'>
            <Box className='fullview-icon'> <OpenInNewIcon/></Box>
            <h5>Evaluate</h5>
            <div className="folder-composition">
              <h6>2</h6>
              <h6>Products</h6>
            </div>
            <div className='display-container'>
              <div className="sample-products">
                <img className="sample-product-selected-folder-first" src= "https://robohash.org/ipsaetperspiciatis.png?size=30x30&set=set2" alt="product sample" />
                <img className="sample-product-selected-folder-next" src= "https://robohash.org/asperioressequimaiores.png?size=30x30&set=set2" alt="product sample" />
                {/* <img className="sample-product-selected-folder-then" src= "https://robohash.org/eossintipsam.png?size=30x30&set=set2" alt="product sample" /> */}
              </div>
              
              <Link to="/your-path" onClick={(event) => {
                    event.preventDefault();
                    window.open(event.currentTarget.href, '_blank');
                  }}>
                    <div className="see-all-container">
                      <AddIcon sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
                    </div>
              </Link>
            </div>
          </div>
          <div className='stack-folder-box'>
            <Box className='fullview-icon'> <OpenInNewIcon/></Box>
            <h5>Ignore</h5>
            <div className="folder-composition">
              <h6>5</h6>
              <h6>Products</h6>
            </div>
            <div className='display-container'>
              <div className="sample-products">
                <img className="sample-product-selected-folder-first" src= "https://robohash.org/ipsaetperspiciatis.png?size=30x30&set=set2" alt="product sample" />
                <img className="sample-product-selected-folder-next" src= "https://robohash.org/asperioressequimaiores.png?size=30x30&set=set2" alt="product sample" />
                <img className="sample-product-selected-folder-then" src= "https://robohash.org/eossintipsam.png?size=30x30&set=set2" alt="product sample" />
              </div>
              
              <Link to="/your-path" onClick={(event) => {
                    event.preventDefault();
                    window.open(event.currentTarget.href, '_blank');
                  }}>
                    <div className="see-all-container">
                      <AddIcon sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
                    </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>filters and search for available products</div>
      <div>grid (left) display of available products</div>
      <div>grid (right) of products in folder</div>
      {/* <img src= {RefImage} alt="productfoldersref" /> */}
      
      
    </div>
  );
}

export default MyProductFolders;