// src/components/entitytesting/entityTesting.jsx

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import PeopleCard from '../../components/users/peoplecard/peopleCard';
import './entityTesting.scss';

const EntityTesting = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/all`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    return (
        <Box className='entity-testing-container'>
            {users.map((user, index) => (
                <Box key={index} className='people-card-container'>
                    
                    <PeopleCard 
                        userId={user.user_id}
                        showFirstName={true}
                        showUserCompany={true}
                        showBusinessRole={true}
                        flexDirection = 'column'
                    />
                </Box>
            ))}
        </Box>
    );
};

export default EntityTesting;
