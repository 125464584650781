import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setStackDetails } from '../../../../../features/stacks/temporaryId/temporaryIdSlice';
import CreateNewCompany from '../../../../../userprofile/userCompany/createnewcompany/createNewCompany';
import UserDetailsFormPartial from '../../../../../components/users/useraccount//userdetailsform/userDetailsFormPartial/userDetailsFormPartial';
import HeaderStreamed from '../../../../../components/headerstreamed/HeaderStreamed';

const StackDetailCompanyStepper = ({ userId, userDataContext, companyList, accessToken, onNext }) => {
    const [selectedCompany, setSelectedCompany] = useState(companyList[0]?.company_id || null);
    const dispatch = useDispatch();
    

    const handleCompanyChange = (event) => {
        setSelectedCompany(event.target.value);
        dispatch(setStackDetails({ details: { companyId: event.target.value } }));
    };

    useEffect(() => {
        if (selectedCompany) {
            dispatch(setStackDetails({ companyId: selectedCompany }));
        }
    }, [selectedCompany, dispatch]);

    

    return (
        <Box sx={{ml: '20px'}}>
            <Box >
                <Box sx={{mb: '20px'}}>
                    <HeaderStreamed title='What company is the stack for'/>
                </Box>
                {companyList && companyList.length > 0 ? (
                    <Select
                        value={selectedCompany}
                        onChange={handleCompanyChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        size='small'
                        sx={{margin:'0px 0px 40px 10px'}}
                    >
                        {companyList.map((company) => (
                            <MenuItem key={company.company_id} value={company.company_id}>
                                {company.company_name}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <CreateNewCompany userId={userId} />
                )}

                {selectedCompany && <UserDetailsFormPartial userId={userId} accessToken={accessToken} companyId={selectedCompany} />}
            </Box>
        </Box>
    );
};

export default StackDetailCompanyStepper;