// now redundant, but keeping for reference

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    stackPurpose: null,  // 'Personal' or 'Business'
    // Other stack-related state variables can be added here
};

export const stackPurposeSlice = createSlice({
    name: 'stackCreation',
    initialState,
    reducers: {
        setStackPurpose: (state, action) => {
            state.stackPurpose = action.payload;
        },
        setNewCompanyDetails: (state, action) => {
            state.newCompanyDetails = action.payload;
        },
        updateUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
    },
});

export const { setStackPurpose } = stackPurposeSlice.actions;
export default stackPurposeSlice.reducer;
