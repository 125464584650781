import React from 'react';
import { Box, Grid } from '@mui/material';
import HeaderStreamed from '../../components/headerstreamed/HeaderStreamed';
import ImageFiller from 'react-image-filler';
import './editStack.scss';

const EditStack = () => {
    return (
        <Box className="mainFrame" width="1200px">
            <HeaderStreamed
                title="EditStack"
                subtitle="testing the product quickview"
            />
            <HeaderStreamed title="Testing Product Pagination" />
            <Grid
                container
                spacing={1}
                sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignContent: 'center',
                    padding: '10px',
                    gap: '10px',
                    border: '0px solid green',
                    background: 'transparent',
                }}
            >
                <div>
                    <ImageFiller width={280} height={200} />
                </div>
            </Grid>
        </Box>
    );
};

export default EditStack;
