import React from 'react';
import { Box, Typography, Grid } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import './accountaboutuser.scss'


const AccountAboutUser = ({ userId, userData, visitorUserId, entityType, showCompany = false}) => {
    if (!userData) {
        return null;
    }

    return (
        <Box className='user-about-account-widget-main'>
            <Grid item xs={12} md={6} sx={{minWidth:'150px'}}>
                <Box className='info-section'>
                    <AccountCircleIcon className='icon' />
                    <Typography variant="body1">{userData.user.user_status_account}</Typography>
                </Box>
                <Box className='info-section'>
                    <WorkIcon className='icon' />
                    <Typography variant="body1">{userData.user.user_points}</Typography>
                </Box>
                <Box className='info-section'>
                    <BusinessIcon className='icon' />
                    <Typography variant="body1">{userData.user.user_status_platform}</Typography>
                </Box>
                <Box className='info-bio'>
                    <DescriptionIcon className='icon' />
                    <Typography variant="body1">{userData.user.user_created_at}</Typography>
                </Box>
                <Box className='info-bio'>
                    <DescriptionIcon className='icon' />
                    <Typography variant="body1">{userData.user.user_updated_at}</Typography>
                </Box>
            </Grid>
        </Box>
    )
}

export default AccountAboutUser;
