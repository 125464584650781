import React from "react";
import { Box } from "@mui/material";
import StackCard from "../../../../components/stack/stackCard/stackCard";
import HeaderStreamed from "../../../../components/headerstreamed/HeaderStreamed";
import BoxComponent from '../../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../../global/dashboards/boxcomponent/BoxComponent';
import ImageFiller from 'react-image-filler';
import ChartStackProductFit from '../../../../charts/stack/stackfit/chartStackProductMatch';
import SharedProductsStackFit from '../../../../charts/stack/stackfit/sharedproducts/sharedProductsStackFit';
import StackProductChart from '../../../../charts/stack/productcount/chartStackProductCount';
import StackProductCountCompare from '../../../../charts/stack/productcountcompare/chartStackProductCountCompare';
// import StackProductByDomain from '../../../../charts/stack/productcount/productperdomain/chartProductByDomain';
import  "./overviewViewStack.scss";

const OverviewViewStack = ({stackId, stackData, userId, companyId, visitorUserId}) => {
  const entityType = 'stack';

  return(
    <Box className="streamed-tab-component-outer" >
      <HeaderStreamed title="Stack Overview" subtitle="Summary page for dynamic stack page"/>
      <Box className="streamed-tab-component-container-main">
        
        <Box className="streamed-tab-component-grid-and-side" >
          <Box className= "streamed-tab-component-grid-main">
            <BoxComponent 
              title="Total Products in Stack"
              subtitle="Subtitle above ChartDataContext"
              width={4}
              showButton={false}
            >
              <StackProductChart stackData={stackData} stackId={stackId} companyId={companyId}/>
            </BoxComponent>
            <BoxComponent 
              title="Products vs Max and Avg"
              subtitle="Test 2"
              width={4}
              showButton={false}
            >
              <StackProductCountCompare stackData={stackData} stackId={stackId} companyId={companyId}/>
            </BoxComponent>
            <BoxComponent 
              title="Stack Match"
              width={4}
              showButton={false}
            >
              <ChartStackProductFit
                stackData={stackData}
                stackId={stackId}
                companyId={companyId}
                userId={userId}
                visitorUserId={visitorUserId}
                showTitle = {true}
              />

            </BoxComponent>
            <BoxComponent 
              title="Products by Domain"
              subtitle="In development"
              width={6}
              showButton={false}
            >
              {/* <StackProductByDomain stackData={stackData} stackId={stackId} companyId={companyId}/> */}
            </BoxComponent>
            <BoxComponent 
              title="Stack Shared Products" 
              width={6}
              showButton={true}
              link={`/stacks/${stackId}/composition`}
            >
              <SharedProductsStackFit
                  stackData={stackData}
                  stackId={stackId}
                  companyId={companyId}
                  userId={userId}
                  visitorUserId={visitorUserId}
                  showShared = {true}
                  showTitle = {true}
                  numSharedProductsToShow = '3'
                />
            </BoxComponent>
            <BoxComponent title="Stack QuickView" width={12}>
              <StackCard 
                      stackData={stackData}
                      stackId={stackId}
                      userId={userId}
                      entityType={entityType}
                      showName = {true}
                      showType = {true}
                      showPurpose = {true}
                      showOfficial = {true}
                      flexDirection = 'row'
                      showQuickView = {true}
                      showSocialButtons = {true}
                      showBookmarkButton = {false}
                      showCompany ={false}
                  />
            </BoxComponent>
          </Box>
          <Box className="streamed-tab-component-right-panel" >
            <SideBarComponent title="Similar Stacks">
              <div><ImageFiller width={260} height={100}/></div>
            </SideBarComponent>
            <SideBarComponent title="Stacks with higher Fit">
              <div><ImageFiller width={260} height={100}/></div>
            </SideBarComponent>
          <SideBarComponent 
              title="Product X"
              subtitle="with high fit to your main stack"
              sideText="97%"
              showButton={true}
              link="https://www.google.com"
                
            >
              <div><ImageFiller width={260} height={100}/></div>
            </SideBarComponent>
          </Box>
        </Box>
        </Box>
    </Box>
  )
}

export default OverviewViewStack;