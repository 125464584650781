import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HeaderStreamed from '../../../../../components/headerstreamed/HeaderStreamed';
import { setStackDetails } from '../../../../../features/stacks/temporaryId/temporaryIdSlice';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';

const StackPurposeStepper = ({ onPurposeSelected, onValidityChange }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [isValid, setIsValid] = useState(false);
    const [selectedPurpose, setSelectedPurpose] = useState(null);

    
    const handlePurposeSelection = purpose => {
        setSelectedPurpose(purpose);
        dispatch(setStackDetails({ purpose })); 
        setIsValid(true);
        if (onPurposeSelected) {
            onPurposeSelected(purpose);
        }
    };


    useEffect(() => {
        if (onValidityChange) {
            onValidityChange(isValid);
        }
    }, [isValid, onValidityChange]);

    const purposes = [
        {
            title: 'Personal Stack',
            description:
                'create a stack for your personal apps and software you use',
            value: 'Personal',
        },
        {
            title: 'Business Stack',
            description:
                'create a stack for your business apps and software you use',
            value: 'Business',
        },
    ];

    return (
        <Box className="stack-purpose-choice">
            <Box
                sx={{margin: '20px'}}
            >
                <HeaderStreamed
                    title="What will you use this stack for?"
                    subtitle="Get better insights based on how how use your stack"
                />
            </Box>
            <Box className="purpose-option-tiles" sx={{border: '1px solid white', borderRadius: '5px'}}>
                {purposes.map(purpose => (
                    <Card
                        key={purpose.value}
                        onClick={() => handlePurposeSelection(purpose.value)}
                        sx={{
                            margin: '10px',
                            backgroundColor:
                                selectedPurpose === purpose.value
                                    ? theme.palette.background.hover
                                    : theme.palette.background.paper,
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: theme.palette.background.hover,
                            },
                            '& .MuiCardContent-root': {
                                color:
                                    selectedPurpose === purpose.value
                                        ? theme.palette.common.white
                                        : theme.palette.neutral.light,
                            },
                        }}
                    >
                        <CardContent>
                            <Typography variant="h4">
                                {purpose.title}
                            </Typography>
                            <Typography variant="h6">
                                {purpose.description}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </Box>
    );
};

export default StackPurposeStepper;
