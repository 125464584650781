import React from 'react';
import { Modal, Box, Typography, Button, Link, Avatar, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LikeButton from '../../socials/likes/likeButton';
import FollowButton from '../../socials/follow/followButton';
import SaveButton from '../../socials/saves/saveButton';
import NopeButton from '../../socials/nope/nopeButton';
import ShareButton from '../../socials/shares/shareButton';
import CloneButton from '../../socials/clone/cloneButton';
import ChartStackProductFit from '../../../charts/stack/stackfit/chartStackProductMatch';
import StackProductChart from '../../../charts/stack/productcount/chartStackProductCount';
import StackPageAwards from '../stackpage/stackpageawards/stackPageAwards'; 
import StarRateIcon from '@mui/icons-material/StarRate';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './stackQuickView.scss'

const StackQuickView = ({ open, onClose, stackData, stackId, entityType, userId, showCompany = false }) => {
  const navigate = useNavigate();

  if (!stackId) return null;
  
  const handleCloseClick = () => {
    
      onClose(); 
  };
    const handleViewStackDetail = () => {
        navigate(`/stack/profile/${stackData.stackPackage.stack_id}`);
        onClose();
    };  

  return (
    <Modal disableEnforceFocus open={open} onClose={onClose}>
        <Box className='stack-quickview-modal'>
            <Box className='quickview-image-and-info' >    
                <Box>
                    <Box className='stack-quickview-stack-image' component="img" src={stackData.stackPackage.stack_profile_image} alt={stackData.stackPackage.stack_name} />
                    <Box className='stack-quickview-rating'>
                        <Box><StarRateIcon/></Box>
                        <div>4.5</div>
                    </Box>
                </Box>
                <Box className="stack-quickview-page-info-main">
                    <Box className="stack-quickview-page-info" >
                        <Box className='stack-quickview-detail'>
                            <Box display='flex' gap='20px'>
                                <Typography variant="h4">{stackData.stackPackage.stack_name}</Typography>
                                {stackData.stackPackage.stack_is_official && (
                                    <Box className="stack-page-official" display='flex' gap='5px' alignItems='center'>
                                        <CheckCircleIcon fontSize='small' color="secondary" />
                                        <Typography variant="body2">official</Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box className="stack-quickview-owner-detail">
                                <span style={{ color: 'white' }}>by:</span>
                                <Avatar 
                                    className="stack-owner-avatar"
                                    src={stackData.userPackage.user_profile_image}
                                    alt={`${stackData.userPackage.user_first_name} ${stackData.userPackage.user_last_name}`}
                                    sx={{ width: 20, height: 20 }} 
                                />
                                <Link className='owner-name-link' to={`/user/profile/${stackData.userPackage.user_id}`}>
                                    {stackData.userPackage.user_first_name}
                                </Link>
                            </Box>
                            <Box className='stack-quickview-type-and-purpose'>
                                <Typography variant="body2">Type: {stackData.stackPackage.stack_type}</Typography>
                                <Typography variant="body2">Purpose: {stackData.stackPackage.stack_purpose}</Typography>
                            </Box>
                            {showCompany && (
                                <Typography variant="h6">{stackData.companyPackage.company_name}</Typography>
                            )}
                            <Box className='stack-quickview-industry-info'>
                                <Typography variant="body2">{stackData.companyPackage.industry_name}</Typography>
                                <Typography>|</Typography>
                                <Typography variant="body2">{stackData.companyPackage.sector_name}</Typography>
                                <Typography>|</Typography>
                                <Typography variant="body2">{stackData.companyPackage.country_name}</Typography>
                            </Box>
                        </Box>
                        <Box className="stack-quickview-recognition">
                            <StackPageAwards stackData={stackData} stackId={stackId} userId={userId}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Typography className='stack-quickview-page-description' variant="body1">Description: {stackData.stackPackage.stack_description}</Typography>
            <Box className='stack-quickview-social-buttons'>
                <LikeButton entityId={stackId} entityType={entityType} userId={userId}/>
                <FollowButton entityId={stackId} entityType={entityType} userId={userId}/>
                <SaveButton entityId={stackId} entityType={entityType} userId={userId}/>
                <NopeButton entityId={stackId} entityType={entityType} userId={userId}/>
                <CloneButton entityId={stackId} entityType={entityType} userId={userId}/>
                <ShareButton entityId={stackId} entityType={entityType} userId={userId}/>
            </Box>
            <Box className='stack-quickview-composition'>
                <Box sx={{minWidth:'180px', minHeight: '180px'}}>
                    {userId ? (
                        <ChartStackProductFit 
                            userId={userId}
                            stackData={stackData}
                            showShared = {false}
                            showTitle = {true}
                            numSharedProductsToShow = '5'
                        />
                    ) : (
                        <Box 
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,0.05)',
                                borderRadius: '4px',
                                border: '1px solid white',
                                padding: '0.1rem',
                            }}
                        >
                            Login or Register <br></br>to see stack match
                        </Box>
                    )}
                </Box>
                <Box>
                    <Box sx={{display:'flex', alignItems:'center'}}>Count: {stackData.productPackage.length} Products</Box>
                    <Box> 
                        <StackProductChart 
                            height= '30' 
                            width= '140'
                            fontSize = '24'
                            stackData={stackData} 
                            stackId={stackId} 
                            companyId={stackData.companyPackage.company_id}
                        />
                    </Box>
                </Box>
                <Box>
                    <Box style={{display:'flex', alignItems:'center'}}> Stack Composition</Box>
                    <Box className='stack-quickview-product-display'>
                        <Box className='stack-quickview-selected-products'>
                            {stackData.productPackage.slice(0, 5).map((product, index) => (
                                <Box key={index} className='product'>
                                    <Tooltip title={product.product_name} placement="top">
                                        <img src={product.product_thumb_logo} alt={product.product_name} className='product-thumb-logo' />
                                    </Tooltip>
                                </Box>
                            ))}
                            <Box className='more-circle' onClick={handleViewStackDetail}><span>...</span></Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className='stack-quickview-main-actions'>
                <Box display='flex' alignItems='center' gap='20px' borderRadius='5px'>
                    <Button className="view-stack-detail-button" onClick={handleViewStackDetail} sx={{ color: 'white' }}>View Stack Detail</Button>
                </Box>
            </Box>
            <Button className="buttonClose" onClick={handleCloseClick}>x</Button>
        </Box>
    </Modal>
  );
};

export default StackQuickView;
