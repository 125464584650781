import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '../../../helpers/apifetch';
import Cookies from 'js-cookie';

// Async thunk to fetch the evaluate count
export const fetchEvaluateCount = createAsyncThunk(
  'evaluates/fetchCount',
  async ({ entityType, entityId }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/evaluate/count/${entityType}/${entityId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch evaluate count');
    }
    const data = await response.json();
    // console.log('fetchEvaluateCount data:', data);
    return { entityId, entityType, count: Number(data.total_evaluates) };
  }
);


// Async thunk to fetch the user's evaluate state
export const fetchEvaluateState = createAsyncThunk(
  'evaluates/fetchStatus',
  async ({ entityType, entityId, userId }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/evaluate/status/${userId}/${entityType}/${entityId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch evaluate status');
    }
    const data = await response.json();
    return { entityId, entityType, userId, is_evaluating: data.is_evaluating };
  }
);

// Async thunk to toggle evaluate
export const toggleEvaluate = createAsyncThunk(
  'evaluates/toggle',
  async ({ entityType, entityId, userId }, { getState, dispatch }) => {
    const token = Cookies.get('token'); // original: const token = getState().auth.token;
    
    try {
      const response = await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/evaluate/toggle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ entityId, entityType, userId })
      }, dispatch);

      if (!response.ok) throw new Error('Failed to toggle evaluate');

      const data = await response.json();

      dispatch({ type: 'auth/logToken', payload: token });
      // Dispatch fetchEvaluateCount action to update the evaluate count in the state
      dispatch(fetchEvaluateCount({ entityType, entityId }));

      return data; 
    } catch (error) {
      throw new Error('Failed to toggle evaluate');
    }
    
  }
);


export const evaluateSlice = createSlice({
  name: 'evaluates',
  initialState: {
    counts: {}, 
    byId: {},
    allIds: [],
    loading: false,
    error: null
  },
  reducers: {
    updateEvaluateCount: (state, action) => {
      const { evaluateCountId, count } = action.payload;
      state.counts[evaluateCountId] = count;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvaluateState.fulfilled, (state, action) => {
        const { entityType, entityId, userId, is_evaluating } = action.payload;
        const evaluateId = `${entityType}_${entityId}_${userId}`;
      
        if (!state.byId[evaluateId]) {
          state.byId[evaluateId] = { entityId, userId, evaluated: is_evaluating };
          if (!state.allIds) {
            state.allIds = [];
          }
          state.allIds.push(evaluateId);
        } else {
          state.byId[evaluateId].evaluated = is_evaluating;
        }
        // console.log(`User ${userId} evaluate STATE for ${entityType} ${entityId}:`, state.byId[evaluateId].evaluated);
        state.loading = false;
      })
      .addCase(fetchEvaluateCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEvaluateCount.fulfilled, (state, action) => {
        const { entityType, entityId, count } = action.payload;
        const evaluateCountId = `${entityType}_${entityId}`;
    
        if (!state.counts) state.counts = {}; 
    
        if (entityType && entityId) {
          state.counts[evaluateCountId] = count;
        } else {
          console.error(`Invalid evaluateCountId: ${evaluateCountId}`);
        }
        state.loading = false;
      })
      .addCase(fetchEvaluateCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(toggleEvaluate.fulfilled, (state, action) => {
        const { entityType, entityId, userId } = action.meta.arg;
        const evaluateId = `${entityType}_${entityId}_${userId}`;
        const evaluateCountId = `${entityType}_${entityId}`;
        const updatedEvaluateCount = action.payload.total_evaluates;

        if (state.byId[evaluateId]) {
          state.byId[evaluateId].evaluated = !state.byId[evaluateId].evaluated;
        } else {
          state.byId[evaluateId] = { entityId, userId, evaluated: true };
          if (!state.allIds) {
            state.allIds = [];
          }
          state.allIds.push(evaluateId);
        }
        // console.log(`User ${userId} evaluated STATUS for ${entityType} ${entityId}:`, state.byId[evaluateId].evaluated);
        state.counts[evaluateCountId] = updatedEvaluateCount;
      })
      .addCase(toggleEvaluate.rejected, (state, action) => {
        state.error = action.error.message;
      });
  }
});

export const { updateEvaluateCount } = evaluateSlice.actions;
export default evaluateSlice.reducer;
