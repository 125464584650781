// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'productLogo',
    'productQView',
    'likes',
    'follows',
    'saves',
    'share',
    'addTo',
    'chatter',
    'compare',
    'trials',
    'nopes',
    'evaluate',
    'using',
    'clone',
    'drawer',
    'products',
    'productPage',
    'stackPage',
    'tempstackid',
    'stackPurpose',
    'stackProductCount',
    'productsByDomain',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Logger middleware
const loggerMiddleware = storeAPI => next => action => {
  // console.log('Dispatching action:', action);
  // console.log('State before dispatch:', storeAPI.getState());

  const result = next(action);

  // console.log('State after dispatch:', storeAPI.getState());

  return result;
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PURGE'], // Ignore the PURGE action
    },
  }).concat(loggerMiddleware),
});

export { store };

export const persistor = persistStore(store);




