import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '../../../helpers/apifetch';
import Cookies from 'js-cookie';

// Async thunk to fetch the nope count
export const fetchNopeCount = createAsyncThunk(
  'nopes/fetchCount',
  async ({ entityType, entityId }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/nope/count/${entityType}/${entityId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch nope count');
    }
    const data = await response.json();
    return { entityId, entityType, count: Number(data.total_nopes) };
  }
);

// Async thunk to fetch the user's nope state
export const fetchNopeState = createAsyncThunk(
  'nopes/fetchStatus',
  async ({ entityType, entityId, userId }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/nope/status/${userId}/${entityType}/${entityId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch nope status');
    }
    const data = await response.json();
    return { entityId, entityType, userId, is_noped: data.is_noped };
  }
);

// Async thunk to toggle nope
export const toggleNope = createAsyncThunk(
  'nopes/toggle',
  async ({ entityType, entityId, userId }, { getState, dispatch }) => {
    const token = Cookies.get('token'); // original: const token = getState().auth.token;
    
    try {
      const response = await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/nope/toggle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ entityId, entityType, userId })
      }, dispatch);

      if (!response.ok) throw new Error('Failed to toggle nope');

      const data = await response.json();

      dispatch({ type: 'auth/logToken', payload: token });
      // Dispatch fetchNopeCount action to update the nope count in the state
      dispatch(fetchNopeCount({ entityType, entityId }));

      return data; 
    } catch (error) {
      throw new Error('Failed to toggle nope');
    }
    
  }
);


export const nopeSlice = createSlice({
  name: 'nopes',
  initialState: {
    counts: {}, 
    byId: {},
    allIds: [],
    loading: false,
    error: null
  },
  reducers: {
    updateNopeCount: (state, action) => {
      const { nopeCountId, count } = action.payload;
      state.counts[nopeCountId] = count;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNopeState.fulfilled, (state, action) => {
        const { entityType, entityId, userId, is_noped } = action.payload;
        const nopeId = `${entityType}_${entityId}_${userId}`;
      
        if (!state.byId[nopeId]) {
          state.byId[nopeId] = { entityId, userId, noped: is_noped };
          if (!state.allIds) {
            state.allIds = [];
          }
          state.allIds.push(nopeId);
        } else {
          state.byId[nopeId].noped = is_noped;
        }
        // console.log(`User ${userId} nope STATE for ${entityType} ${entityId}:`, state.byId[nopeId].noped);
        state.loading = false;
      })
      .addCase(fetchNopeCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNopeCount.fulfilled, (state, action) => {
        const { entityType, entityId, count } = action.payload;
        const nopeCountId = `${entityType}_${entityId}`;
    
        if (!state.counts) state.counts = {}; 
    
        if (entityType && entityId) {
          state.counts[nopeCountId] = count;
        } else {
          console.error(`Invalid nopeCountId: ${nopeCountId}`);
        }
        state.loading = false;
      })
      .addCase(fetchNopeCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(toggleNope.fulfilled, (state, action) => {
        const { entityType, entityId, userId } = action.meta.arg;
        const nopeId = `${entityType}_${entityId}_${userId}`;
        const nopeCountId = `${entityType}_${entityId}`;
        const updatedNopeCount = action.payload.total_nopes;

        if (state.byId[nopeId]) {
          state.byId[nopeId].noped = !state.byId[nopeId].noped;
        } else {
          state.byId[nopeId] = { entityId, userId, noped: true };
          if (!state.allIds) {
            state.allIds = [];
          }
          state.allIds.push(nopeId);
        }
        // console.log(`User ${userId} noped STATUS for ${entityType} ${entityId}:`, state.byId[nopeId].noped);
        state.counts[nopeCountId] = updatedNopeCount;
      })
      .addCase(toggleNope.rejected, (state, action) => {
        state.error = action.error.message;
      });
  }
});

export const { updateNopeCount } = nopeSlice.actions;
export default nopeSlice.reducer;
