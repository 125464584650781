//src>userprofile>userPublicProfile>productsUser>profileProductsUser.jsx
import React from 'react';
import { Box } from '@mui/material';
import HeaderStreamed from '../../../components/headerstreamed/HeaderStreamed';
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/sidepanelcomponent/SideBarComponent';
import UserProfileProductsDataGrid from '../../../global/datagrids/userprofileproducts/userProfileProductsDataGrid';
import ImageFiller from 'react-image-filler';
import  "./profileProductsUser.scss";


const ProfileProductsUser = ({ userId, entityTypeId, visitorUserId }) => {
    const entityType = 'product'
    
    return (
        <Box>

            <Box className='user-account-page-container'>
                <HeaderStreamed title="User Profile: Products" subtitle="Data Grid displaying users based on filter options"/>
                <Box className="account-container-main" >
                    <Box className="account-gridPlusSide" >
                        <Box className= "account-main-grid">
                            <BoxComponent 
                                width={12}
                            >
                                <UserProfileProductsDataGrid
                                    userId={userId}
                                    entityType={entityType}
                                    entityTypeId={entityTypeId}
                                    visitorUserId={visitorUserId}
                                />
                            </BoxComponent>
                        </Box>
                        <Box className="account-right-panel" >
                            <SideBarComponent 
                                title="SideBar Component 1 "
                                subtitle="toggable subtitle"
                                sideText="10"
                                showButton={true}
                                link="https://www.google.com"
                            >
                                <div><ImageFiller width={260} height={100}/></div>
                            </SideBarComponent>
                            <SideBarComponent title="SideBar Component 2"/>
                            <SideBarComponent title="SideBar Component 3"/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ProfileProductsUser;
