import React from 'react';
import { Box, Typography, Grid } from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';
import FactoryIcon from '@mui/icons-material/Factory';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import './accountaboutcompany.scss'


const AccountAboutCompany = ({ userId, userData, visitorUserId, entityType, showCompany = false}) => {
    if (!userData) {
        return null;
    }

    return (
        <Box className='company-about-account-widget-main'>
            <Grid item xs={12} md={6} sx={{minWidth:'150px'}}>
                <Box className='info-section'>
                    <LocationCityIcon className='icon' />
                    <Typography variant="body1">
                        {showCompany ? (userData.company ? userData.company.company_name : '') : 'Anonymous'}
                    </Typography>
                </Box>
                <Box className='info-section'>
                    <PlaceIcon className='icon' />
                    <Typography variant="body1">{userData.company ? userData.company.companyCountryName : ''}</Typography>
                </Box>
                <Box className='info-section'>
                    <FactoryIcon className='icon' />
                    <Typography variant="body1">{userData.company ? userData.company.companyIndustryName : ''}</Typography>
                </Box>
                <Box className='info-section'>
                    <LocalConvenienceStoreIcon className='icon' />
                    <Typography variant="body1">{userData.company ? userData.company.companySectorName : ''}</Typography>
                </Box>
                <Box className='info-section'>
                    <Box className='info-section'>
                        <PeopleIcon className='icon' />
                        <Typography variant="body1">{userData.company ? userData.company.company_size_fte : ''}</Typography>
                    </Box>
                    <Box className='info-section'>
                        <AttachMoneyIcon className='icon' />
                        <Typography variant="body1">{userData.company ? userData.company.company_size_rev : ''}</Typography>
                    </Box>
                </Box>
            </Grid>
        </Box>
    )
}

export default AccountAboutCompany;
