import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Link } from '@mui/material';
import { fetchFeatureAvailability } from '../../../../features/users/featureavailability/featureAvailabilitySlice';
import { usePlanId } from '../../../../hooks/usePlanId';
import './productPageAwards.scss';

const ProductPageAwards = ({ productData, showAllAwardsLink = true }) => {
    const dispatch = useDispatch();
    const planId = usePlanId();
    const platformFeatureId = 42;
    const [awards, setAwards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isFeatureAvailable, setIsFeatureAvailable] = useState(false);
    const { entity_type, product_id } = productData;

    useEffect(() => {
        dispatch(fetchFeatureAvailability({ planId, platformFeatureId }))
            .unwrap()
            .then((availability) => {
                setIsFeatureAvailable(availability.isFeatureAvailable);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Failed to fetch feature availability:', error);
                setIsLoading(false);
            });
    }, [dispatch, planId, platformFeatureId]);

    useEffect(() => {
        if (entity_type && product_id && isFeatureAvailable) {
            const fetchAwards = async () => {
                setIsLoading(true);
                setError(null);
                try {
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/v1/award/${entity_type}/${product_id}`
                    );
                    if (!response.ok) {
                        throw new Error(
                            `Failed to fetch awards for ${entity_type} with ID ${product_id}`
                        );
                    }
                    const data = await response.json();
                    setAwards(data);
                } catch (error) {
                    setError(error.message);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchAwards();
        }
    }, [entity_type, product_id, isFeatureAvailable]);

    if (isLoading || error || awards.length === 0 || !isFeatureAvailable) return null;

    return (
        <Box className="product-awards-container">
            <Box className="product-awards-display">
                {awards.slice(0, 2).map((award, index) => (
                    <Box key={index} className="product-award-item">
                        <img
                            src={award.award_badge}
                            alt={award.award_name}
                            className="product-award-icon"
                        />
                        <Typography className="product-award-name">
                            {award.award_name}
                        </Typography>
                    </Box>
                ))}
            </Box>
            {showAllAwardsLink && awards.length > 2 && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Link
                        href={`/product/${productData.product_id}/overview`}
                        sx={{
                            textDecoration: 'underlined',
                            justifyContent: 'center',
                            fontSize: '10px',
                            color: 'inherit',
                        }}
                    >
                        all awards and badges
                    </Link>
                </Box>
            )}
        </Box>
    );
};

export default ProductPageAwards;