import React from "react";
import { Box } from "@mui/material";
import HeaderStreamed from "../../../components/headerstreamed/HeaderStreamed";
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import  "./userAccountEntities.scss";

const UserAccountEntities = ({userData, userId, entityType}) => {

  return(
    <Box className='user-account-page-container'>
        <HeaderStreamed title="My Entities" subtitle="page to access and manage claimed entities on Stackrunner"/>
        <Box className="account-container-main" >
            <Box className="account-gridPlusSide" >
                <Box className= "account-main-grid">
                    <BoxComponent
                        title="Select Entity"
                        subtitle="set general feed preferences | some are premium features (e.g. turn off ads)"
                        width={12}
                    >
                    </BoxComponent>
                    <BoxComponent
                        title="Dynamic Content of selected entity above"
                        subtitle="search, add or remove interests from your feed"
                        width={12}
                    >
                        
                    </BoxComponent>
                    <BoxComponent 
                    title="Manage Categories" 
                    subtitle="same implementaiton as with 'manage interests' component"
                    width={12}
                    />
                </Box>
                <Box className="account-right-panel" >
                    <SideBarComponent 
                        title="May not need side panel for Entities"
                    >   
                    </SideBarComponent>
                    <SideBarComponent 
                        title="Side Panel Widget 2"
                    >
                    </SideBarComponent>
                    <SideBarComponent 
                        title="Side Panel Widget 3"
                    >   
                    </SideBarComponent>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}


export default UserAccountEntities;