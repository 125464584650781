import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip, Box, Typography, useTheme } from '@mui/material';
import { fetchFeatureAvailability } from '../../../features/users/featureavailability/featureAvailabilitySlice';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { usePlanId } from '../../../hooks/usePlanId';
import { fetchFollowCount, fetchFollowState, toggleFollow, } from '../../../features/socials/follow/followSlice';
import './followButton.scss';

const FollowButton = ({
    entityType,
    entityId,
    userId,
    showCount = true,
    flexDirection = 'row',
    showTooltip = true,
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const planId = usePlanId();
    const platformFeatureId = 18;
    const [isLoading, setIsLoading] = useState(true);
    const [isFeatureAvailable, setIsFeatureAvailable] = useState(false);

    const followCountId = `${entityType}_${entityId}`;
    const followId = `${entityType}_${entityId}_${userId}`;
    const followCount = useSelector(
        state => state.follows?.counts?.[followCountId] || 0
    );
    const isFollowed = useSelector(
        state => state.follows?.byId?.[followId]?.followed || false
    );
    
    useEffect(() => {
        dispatch(fetchFeatureAvailability({ planId, platformFeatureId }))
            .unwrap()
            .then((availability) => {
                setIsFeatureAvailable(availability.isFeatureAvailable);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Failed to fetch feature availability:', error);
                setIsLoading(false);
            });
    }, [dispatch, planId, platformFeatureId]);

    useEffect(() => {
        if (entityType && entityId && userId) {
            dispatch(fetchFollowCount({ entityType, entityId })).unwrap();
            dispatch(fetchFollowState({ entityType, entityId, userId })).unwrap();
        }
    }, [dispatch, entityType, entityId, userId]);

    const handleFollow = () => {
        if (!userId) {
            console.log('Please log in or register to follow.');
            return;
        }
        if (userId) {
            dispatch(toggleFollow({ entityType, entityId, userId })).then(
                () => {
                    const updatedFollowCount = isFollowed
                        ? followCount - 1
                        : followCount + 1;
                    dispatch({
                        type: 'follows/updateFollowCount',
                        payload: { followCountId, count: updatedFollowCount },
                    });
                }
            );
        } else {
            console.log('Please log in or register to like this.');
        }
    };

    // If the feature is not available or the data is still loading, do not render the component
    if (!isFeatureAvailable || isLoading) {
        return null;
    }

    const boxStyle = {
        backgroundColor: theme.palette.neutral.light,
        color: theme.palette.getContrastText(theme.palette.neutral.light),
        boxshadow: `0px 4px 4px ${theme.palette.boxshadow.default}`,
        borderRadius: '5px',
        margin: '0px',
        padding: '0px',
    };

    const button = (
        <IconButton
            sx={{
                background: 'transparent',
                border: 'none',
                color: isFollowed
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                '&.Mui-disabled': {
                    color: theme.palette.primary.main,
                },
                '&:disabled': {
                    cursor: 'default',
                },
            }}
            onClick={handleFollow}
            aria-label="follow"
            disabled={!userId}
        >
            <PersonAddIcon />
        </IconButton>
    );

    return (
        <Box
            style={boxStyle}
            sx={{
                flexDirection: flexDirection,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                margin: '0px',
                padding: '0px',
            }}
        >
            {showTooltip ? (
                <Tooltip
                    title={`${followCount || 0} Follows`}
                    placement="top"
                    arrow
                >
                    <div>{button}</div>
                </Tooltip>
            ) : (
                button
            )}
            {showCount && <Typography 
                sx={{
                display: 'flex',
                width: 'auto',
                justifyContent: 'center',
                marginRight: '8px',
                marginLeft: flexDirection === 'row' ? '8px' : '0px',
                marginTop: flexDirection === 'column' ? '4px' : '0px',
                fontSize: '16px',
                fontweight: '700',
                textShadow: '0px 0px 1px black',
                }}
            >
                {followCount || 0}
            </Typography>}
        </Box>
    );
};

export default FollowButton;
