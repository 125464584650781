//src>charts>stack>stackfit>chartStackProductMatch.jsx

import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Box, Typography, Link, useTheme } from '@mui/material';
import './chartStackProductMatch.scss';

const ChartStackProductFit = ({userId, stackId, stackData, showTitle = false}) => {
    const theme = useTheme();
    const [numCommonProducts, setNumCommonProducts] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    

    useEffect(() => {
        
        // Return early if stackData or stackData.visitorUserIdStackPackage is undefined
        if (!stackData || !stackData.visitorUserIdStackPackage) {
            return;
        }

        if (stackData.productPackage && stackData.visitorUserIdStackPackage.products) {
            const visitorProductIds = stackData.visitorUserIdStackPackage.products.map(product => product.product_id);
            const commonProducts = stackData.productPackage.filter(product => visitorProductIds.includes(product.product_id));
            setNumCommonProducts(commonProducts.length);
            
            if (stackData && stackData.visitorUserIdStackPackage && stackData.visitorUserIdStackPackage.products) {
                setTotalProducts(stackData.visitorUserIdStackPackage.products.length);
            }
        }
    }, [stackData]);

    const chartOptions = {
        labels: ['Common Products', 'Other Products'],
        dataLabels: {
            enabled: false,
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex]
            },
        },
        colors: ['#F44336', '#E0E0E0'],
        stroke: {
            width: 0 
        },
        grid: {
            padding: {
                bottom: 0 
            }
        },
        chart: {
            height: '50%',
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                donut: {
                    labels: {
                        show: true,
                        name: {
                            show: false
                        },
                        value: {
                            show: true,
                            fontSize: '24px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            color: '#FFFFFF',
                            offsetY: -10,
                            formatter: function (val, opts) {
                                if (opts.seriesIndex === 0) {
                                    return numCommonProducts
                                }
                                return ''
                            }
                        },
                        total: {
                            show: true,
                            label: 'Common Products',
                            formatter: function (w) {
                                const percentageCommon = (w.globals.series[0] / (w.globals.series[0] + w.globals.series[1])) * 100;
                                return percentageCommon.toFixed(0) + "%"
                            },
                            color: '#FFFFFF',
                            style: {
                                colors: ['#FFFFFF']
                            }
                        }
                    }
                }
            }
        },
        legend: {
            show: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 160
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    const chartSeries = [numCommonProducts, totalProducts - numCommonProducts];

    return (
        <Box className='chart-stack-match-main'>
            <Box className="chart-stack-match-chart">
                {userId ? (
                    <>
                        {showTitle && (
                            <Box style={{ maxWidth: '150px' }}>
                                <Typography variant='body1' className="chart-stack-match-title" style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                    {stackData?.visitorUserIdStackPackage?.stack?.stack_name} ({totalProducts} products)
                                </Typography>
                            </Box>
                        )}
                        <Chart options={chartOptions} series={chartSeries} type="donut" />
                    </>
                ) : (
                    <Box>
                        <Typography
                            variant='body1'
                            // sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        >
                            <Link href="/login" style={{ fontWeight: 'bold', cursor: 'pointer', color: theme.palette.neutral.light, textDecoration: 'none' }}>Login</Link> or 
                            <Link href="/register" style={{ fontWeight: 'bold', cursor: 'pointer', color: theme.palette.neutral.light, textDecoration: 'none' }}> Register</Link> to see how your stack matches up
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default ChartStackProductFit;