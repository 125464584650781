import React from 'react';
import { Box, Grid, Card, CardContent, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { ReactComponent as SRLogo } from "../../assets/placeholders/SR-logo.svg";
import RotatingText from '../../components/visualdisplay/rotatingtext/rotatingText';
import BoxComponent from "../../global/dashboards/boxcomponent/BoxComponent";
import NumberIcon from '../../global/dashboards/numbericon/numberIcon';
import PeopleCard from '../../components/users/peoplecard/peopleCard';
import StackCard from '../../components/stack/stackCard/stackCard';
import ProductCard from '../../components/product/productCard/productCard';
import { ArrowForward, AddShoppingCart, Store } from "@mui/icons-material";
import './home.scss'

const Home = () => {
    const theme = useTheme();
    const cards = [
        {
            // imageUrl: "https://source.unsplash.com/random/1",
            title: "Browse Products",
            subtitle: "This is a subtitle for browse software products",
            icon: <ArrowForward />, 
            link: "/category/all", 
            slotImage: "https://source.unsplash.com/random/4", 
        },
        {
            imageUrl: "https://source.unsplash.com/random/2",
            title: "Browse Stacks",
            subtitle: "This is a subtitle for browse stacks from other users",
            icon: <AddShoppingCart />, 
            link: "/browse/stacks", 
            slotImage: "https://source.unsplash.com/random/5", 
        },
        {
            imageUrl: "https://source.unsplash.com/random/3",
            title: "Peronsalise your feed",
            subtitle: "This is a subtitle to customise your feed",
            icon: <Store />, 
            // link: "/browse/products", 
            slotImage: "https://source.unsplash.com/random/6", 
        },
    ];

    return (
        <Box>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '500px',
                    backgroundImage: `url('https://source.unsplash.com/random/1920x1080')`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        // cool radial gradient overlay: background: `radial-gradient(circle at center, transparent, ${theme.palette.background.default} 30%)`,
                        background: `linear-gradient(0deg, ${theme.palette.background.default}, transparent 50%, ${theme.palette.background.default})`,
                    },
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                        width: '100%',
                        borderRadius: '5px',
                    }}
                >
                    <Box 
                        sx={{
                            display:'inline-flex',
                            alignItems:'center',
                            justifyContent:'center',
                            mb:'20px',
                        }}
                        >
                        <Box 
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: '0px 5px 0px 10px',
                                width: '50px',
                                height: '50px',
                                backgroundColor: theme.palette.common.white,
                                borderRadius: '50%',
                                padding: '6px 6px 0px 4px',

                            }}
                        >
                            <SRLogo/>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            backgroundColor: (theme) => theme.palette.boxshadow.primary,
                            width: '100%',
                            padding: '5px 0px 5px 0px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box 
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                // width: '100%',
                                borderRadius: '5px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            >
                            <Typography
                                component="div"
                                sx={{
                                    color: (theme) => theme.palette.secondary.main,
                                    borderRadius: '5px',
                                    backgroundColor: (theme) => theme.palette.boxshadow.home,
                                    padding: '0px 10px 0px 10px',
                                    width: '150px', 
                                    textAlign: 'right',
                                }}
                            >
                                <RotatingText 
                                    variant="h1"
                                    words={["discover", "compare", "run", "optimise"]}
                                    intervalDuration={2000} 
                                />

                            </Typography>
                            <Typography 
                                variant="h1"
                                component="div"
                                sx={{
                                    color: (theme) => theme.palette.primary.contrastText,
                                }}
                                >
                                the right stack
                            </Typography>
                        </Box>
                        <Typography 
                            variant="h4"
                            component="div"
                            gutterBottom
                            sx={{
                                color: (theme) => theme.palette.primary.contrastText,
                            }}
                        >
                            the new way of making better software decisions is fully contextual
                        </Typography>
                    </Box>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: '20px' }}>
                        <Button variant="new" endIcon={<ArrowForward />}>
                            Create Your Stack
                        </Button>
                    </Box> */}
                </Box>
            </Box>
            <Box className='home-page-container'>
                {/* Main cards */}
                <Box className='home-header-cards'>
                    <Grid container spacing={3} justifyContent="center"> 
                        {cards.map((card, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Link to={card.link} style={{ textDecoration: 'none' }}>
                                    <Card className='home-main-card' style={{ backgroundImage: `url(${card.imageUrl})`, backgroundSize: 'cover', height: '300px' }}>
                                        <CardContent>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Box display="flex" alignItems="flex-start">
                                                    <Box className="card-icon" marginRight={2}>
                                                        {card.icon}
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="h5" component="span">{card.title}</Typography>
                                                        <Typography variant="subtitle1" color="textSecondary">
                                                            {card.subtitle}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                {card.slotImage && (
                                                    <Box>
                                                        <img src={card.slotImage} alt="" style={{ height: '100px', width: '100px' }} /> 
                                                    </Box>
                                                )}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box className="home-container-main" >
                    <Box className="home-popular-entities" >
                        <Box className= "home-main-grid">                  
                            <BoxComponent
                                title="Become a stackrunner"
                                // subtitle='Create your stack once. Update it with easy'
                                width={4}
                                icon={<NumberIcon number={1} />}
                            >
                                <Box
                                    sx={{
                                        // border: '1px solid',
                                        borderRadius: '5px',
                                        padding: '8px',
                                        margin: '0px 0px 10px 30px',
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <Box 
                                        sx={{
                                            display: "flex", 
                                            flexDirection: 'column', 
                                            alignItems: "center", 
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography variant="h6" align="center" mb='12px' >Join people like you from companies like yours.</Typography>
                                        <Link to="/waitlist" style={{ textDecoration: 'none' }}>
                                            <Button variant='register'>
                                                Join the waitlist!
                                            </Button>
                                        </Link>
                                        <Typography variant="h6" align="center" mt='12px' >Create your stack once. Update it with easy.</Typography>
                                    </Box>

                                </Box>
                            </BoxComponent>
                            <BoxComponent
                                title="Get contextual insights on your stack"
                                // subtitle=' Check this sample stack'
                                width={4}
                                icon={<NumberIcon number={2} />}
                                showButton={true}
                                link={`/stack/profile/44`}
                            >
                                <Box 
                                    sx={{
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent: "flex-start",
                                        gap:'10px',
                                        flexDirection:'column',
                                        width: '100%'      
                                    }}
                                >
                                    <Typography 
                                        sx={{
                                            color: theme.palette.secondary.main,
                                            variant: "body3",
                                            align:"center",
                                            mt:'12px' 
                                        }}
                                        >
                                            Check out this sample stack.
                                    </Typography>

                                    <Box
                                        sx={{
                                            // border: '1px solid',
                                            backgroundColor: theme.palette.primary.main,
                                            boxShadow: ` 2px 2px 5px 1px ${theme.palette.boxshadow.default}`,
                                            borderRadius: '5px',
                                            padding: '8px',
                                            margin: '0px 10px 10px 0px',
                                        }}
                                    >
                                        <StackCard
                                            stackId={44}
                                            userId={28}
                                            showName={true}
                                            showQuickView={true}
                                            showPurpose = {false}
                                            showOfficial = {false}
                                            showOwner = {true}
                                            flexDirection = 'column'
                                        />
                                    </Box>

                                </Box>
                            </BoxComponent>
                            <BoxComponent 
                            title="Be inspired by others" 
                            // subtitle="View Sandy's profile"
                            width={4}
                            icon={<NumberIcon number={3} />}
                            showButton={true}
                            link={`/user/profile/28`}
                            >
                                <Box 
                                    sx={{
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent: "flex-start",
                                        gap:'10px',
                                        flexDirection:'column',
                                        width: '100%'      
                                    }}
                                >
                                    <Typography 
                                        sx={{
                                            color: theme.palette.secondary.main,
                                            variant: "body3",
                                            align:"center",
                                            mt:'12px' 
                                        }}
                                        >
                                            View Sandy Dummy's profile.
                                    </Typography>

                                    <Box
                                        sx={{
                                            // border: '1px solid',
                                            backgroundColor: theme.palette.primary.main,
                                            boxShadow: ` 2px 2px 5px 1px ${theme.palette.boxshadow.default}`,
                                            borderRadius: '5px',
                                            padding: '8px',
                                            margin: '0px 10px 10px 0px',
                                        }}
                                    >
                                        <PeopleCard
                                            userId={28}
                                            showFirstName = {true}
                                            showUserCompany = {false}
                                            showBusinessRole = {true}
                                            showStacks = {true}
                                            showActions= {false}
                                            showQuickView = {true}
                                            flexDirection = 'column'
                                        />
                                    </Box>
                                </Box>
                            </BoxComponent>
                            <BoxComponent 
                                title="Let the right apps and software come to you" 
                                subtitle="Explore software products and emerging tech in context of your your business and role"
                                // sideText={}
                                width={8}
                                icon={<NumberIcon number={4} />}
                                showButton={true}
                                link={'/products/sales-(t)/sales-enablement-(t)/content-sharing-(t)/28/productname18(t)'}
                            >
                                <Box 
                                    sx={{
                                        border: '1px solid',
                                        borderRadius: '5px',
                                        padding: '14px',
                                        margin: '0px 10px 10px 50px',
                                    }}
                                >
                                    <ProductCard
                                        productId={103}
                                        productNameStyle={{ fontSize: '0.8rem', fontWeight: 'normal' }}
                                        showName={true}
                                        showVendor={false}
                                        showCategory={false}
                                        showSubcategory={false}
                                        showSocialButtonsOne={false}
                                        showSocialButtonsTwo={false}
                                        showBookmarkButton={false}
                                        showQuickView={true}
                                        flexDirection={'column'}
                                    />
                                </Box>
                                <Box>
                                    <ul>
                                        <li>Contextual suggestions and recommendations based on your profile</li>
                                        <li>Quickview access via the Eye icon or explore the full product profile</li>
                                        <li>Get relevant product insights from vendors and the community</li>
                                        <li>Understand product fit with your company, stack or role</li>
                                    </ul>
                                </Box>
                            </BoxComponent>
                            <BoxComponent
                                title="Stay on top a fast-moving world" 
                                subtitle="Customise your feed ..."
                                // sideText={}
                                width={4}
                                icon={<NumberIcon number={5} />}
                                showButton={true}
                                link={`/feed`}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        border: '1px solid',
                                        borderRadius: '5px',
                                        padding: '8px',
                                        margin: '0px 10px 10px 30px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flex: 2,
                                        }}
                                    >
                                        <Typography variant='body2'>
                                            get the latest updates on the products and stacks that matter to you
                                        </Typography>
                                    </Box>
                                    <Box component="img" 
                                        src="https://source.unsplash.com/random/7"
                                        alt="Random Unsplash Image" 
                                        sx={{ 
                                            marginLeft: '10px',
                                            width: '60px', 
                                            height: '80px',
                                            flex: 1,
                                        }} 
                                    />
                                </Box>
                            </BoxComponent>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
        
    );
};

export default Home;