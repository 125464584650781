//src>features>stacks>stackpage>stackPageSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Async thunk to fetch a single stack by ID
export const fetchStackForPage = createAsyncThunk(
  'stackPage/fetchStack',
  async ({ stackId, visitorUserId }, { rejectWithValue }) => {
    try {
      // Include visitorUserId in the URL if it's not undefined or null
      const url = `${process.env.REACT_APP_API_URL}/api/v1/stacks/${stackId}` + (visitorUserId ? `/${visitorUserId}` : '');
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch stack with ID ${stackId}`);
      }
      const stackData = await response.json();
      return stackData; // Assuming the API returns the stack data directly
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Initial state for the stack page slice
const initialState = {
  stack: null,
  loading: false,
  error: null
};

// Slice definition
const stackPageSlice = createSlice({
  name: 'stackPage',
  initialState,
  reducers: {
    // Add any reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStackForPage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStackForPage.fulfilled, (state, action) => {
        state.loading = false;
        state.stack = action.payload;
      })
      .addCase(fetchStackForPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default stackPageSlice.reducer;
