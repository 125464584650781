import React from 'react';
import { Box } from '@mui/material';
import PeopleCard from '../../../peoplecard/peopleCard';
import { useTheme } from '@emotion/react';
import ProfilePageUserDetails from '../profilepageuserdetails/profilePageUserDetails';
import ProfilePageUserRecognition from '../profilepageuserrecognition/profilePageUserRecognition';
import ProfilePageUserStatus from '../profilepageuserstatus/profilePageUserStatus';
import ProfilePageHeaderActions from '../profilepageheaderactions/profilePageHeaderActions';
import './userProfileHeader.scss';

const UserProfileHeader = ({ userId, userData, visitorUserId }) => {
    const theme = useTheme();
    const { entity_type } = userData?.user || {};
    if (!userData) {
        return <div>Loading...</div>;
    }

    return (
        <Box
            className="user-profile-header-main-container"
            sx={{
                backgroundColor: theme.palette.background.default,
            }}
        >
            <Box className="user-profile-header-main">
                <Box className="user-profile-header-column-one"
                    sx={{
                        display: 'flex',
                        mr: '20px',
                        boxSizing: 'border-box',
                        borderRadius: '50%',
                    }}
                >
                    <Box
                        className="row-one-column-one"
                        sx={{
                            height: '100px',
                            width: '100px',
                            mr: '20px',
                            boxSizing: 'border-box',
                            borderRadius: '50%',
                        }}
                    >
                        <PeopleCard
                            userId={userId}
                            userData={userData}
                            entityType={entity_type}
                            showActions={false}
                            showQuickView={false}
                            showFirstName={false}
                            showUserCompany={false}
                            showBusinessRole={false}
                            imgStyle={{ width: '100px', height: '100px' }}
                            flexDirection="column"
                        />
                    </Box>
                    <Box className="row-one-column-two">
                        <ProfilePageUserDetails
                            userId={userId}
                            entityType={entity_type}
                            visitorUserId={visitorUserId}
                        />
                    </Box>
                </Box>
                <Box className="user-profile-header-column-two">
                    <Box className="column-two-element-one">
                        <ProfilePageUserRecognition
                            userId={userId}
                            userData={userData}
                            entityType={entity_type}
                            visitorUserId={visitorUserId}
                        />
                    </Box>
                    <Box className="column-two-element-two">
                        <ProfilePageUserStatus
                            userId={userId}
                            userData={userData}
                            entityType={entity_type}
                            visitorUserId={visitorUserId}
                        />
                    </Box>
                    <Box className="column-two-element-three">
                        <ProfilePageHeaderActions
                            userId={userId}
                            userData={userData}
                            entityType={entity_type}
                            visitorUserId={visitorUserId}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default UserProfileHeader;
