import React from "react";
import { Box, Typography, useTheme, IconButton} from "@mui/material";
import PropTypes from 'prop-types';
import InfoIcon from "@mui/icons-material/Info"

const SideBarComponent = ({ icon, title, subtitle, sideText, width, showButton, link, platform, children, shadow = 'default'  }) => {
  const theme = useTheme();
  const handleButtonClick = () => {
    if (link) {
      if (platform === 'internal') {
        window.location.href = link;
      } else {
        window.open(link, '_blank');
      }
    }
  };


  return (
      <Box 
          sx={{ 
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gridColumn: `span ${width}`, 
              padding: "0.5rem",
              backgroundColor: theme.palette.background.default,
              boxShadow: `0.1rem 0.1rem 0.1rem 0.01rem ${theme.palette.boxshadow[shadow]}`,
              border: `0.5px solid ${theme.palette.neutral.main}`,
              borderRadius: "0.2rem",
          }}
      >
        
        <Box display="flex" flexDirection="row" marginBottom="5px" position= "relative">
          {showButton && (
            <IconButton 
              onClick={handleButtonClick}
              sx={{
                size:"small",
                position: "absolute",
                top:"-8px",
                right:"-8px",
                padding: "2px",
                width: "24px",
                height: "24px",
                borderRadius: '50%',
                border: `0.5px solid ${theme.palette.neutral.main}`,
                '&:hover': {
                  backgroundColor: theme.palette.background.hover,
                  cursor: 'pointer',
                },
              }}
            >
              <InfoIcon fontSize="inherit"/>
            </IconButton>
          )}
          {icon && (
            <Box display="flex" alignItems="center" margin="5px" justifyContent="center" width="50px">
            {icon}
            </Box>
          )}
          <Box display= "flex" flexDirection="column" width="100%">
            <Typography variant="h5" mb="-0.1rem">{title}</Typography>
            <Typography variant="body2" margin="5px 0">{subtitle}</Typography>
          </Box>
          <Typography display= "flex" alignItems="center" variant="h5" fontWeight="700" padding="0 5px" marginRight= "20px" color="grey">
            {sideText}
          </Typography>
          
        </Box>
        <Box className='box-component-child' sx={{display:'flex', flewGrow: 1, marginTop: '0.1rem', marginBottom: '0.1rem',  minHeight: '20px', borderRadius: '5px'}}>
          {children}
        </Box>
      </Box>
  );
};

SideBarComponent.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.number,
  sideText: PropTypes.string,
  showButton: PropTypes.bool,
  link: PropTypes.string,
  platform: PropTypes.string,
};

export default SideBarComponent;