import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '../../../helpers/apifetch';
import Cookies from 'js-cookie';

// Async thunk to fetch the trial count
export const fetchTrialCount = createAsyncThunk(
  'trials/fetchCount',
  async ({ entityType, entityId }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/trial/count/${entityType}/${entityId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch trial count');
    }
    const data = await response.json();
    return { entityId, entityType, count: Number(data.total_trials) };
  }
);

// Async thunk to fetch the user's trial state
export const fetchTrialState = createAsyncThunk(
  'trials/fetchStatus',
  async ({ entityType, entityId, userId }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/trial/status/${userId}/${entityType}/${entityId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch trial status');
    }
    const data = await response.json();
    return { entityId, entityType, userId, is_trialed: data.is_trialed };
  }
);

// Async thunk to toggle trial
export const toggleTrial = createAsyncThunk(
  'trials/toggle',
  async ({ entityType, entityId, userId }, { getState, dispatch }) => {
    const token = Cookies.get('token'); // original: const token = getState().auth.token;
    
    try {
      const response = await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/trial/toggle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ entityId, entityType, userId })
      }, dispatch);

      if (!response.ok) throw new Error('Failed to toggle trial');

      const data = await response.json();

      dispatch({ type: 'auth/logToken', payload: token });
      // Dispatch fetchTrialCount action to update the trial count in the state
      dispatch(fetchTrialCount({ entityType, entityId }));

      return data; 
    } catch (error) {
      throw new Error('Failed to toggle trial');
    }
    
  }
);


export const trialSlice = createSlice({
  name: 'trials',
  initialState: {
    counts: {}, 
    byId: {},
    allIds: [],
    loading: false,
    error: null
  },
  reducers: {
    updateTrialCount: (state, action) => {
      const { trialCountId, count } = action.payload;
      state.counts[trialCountId] = count;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrialState.fulfilled, (state, action) => {
        const { entityType, entityId, userId, is_trialed } = action.payload;
        const trialId = `${entityType}_${entityId}_${userId}`;
      
        if (!state.byId[trialId]) {
          state.byId[trialId] = { entityId, userId, trialed: is_trialed };
          if (!state.allIds) {
            state.allIds = [];
          }
          state.allIds.push(trialId);
        } else {
          state.byId[trialId].trialed = is_trialed;
        }
        // console.log(`User ${userId} trial STATE for ${entityType} ${entityId}:`, state.byId[trialId].trialed);
        state.loading = false;
      })
      .addCase(fetchTrialCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTrialCount.fulfilled, (state, action) => {
        const { entityType, entityId, count } = action.payload;
        const trialCountId = `${entityType}_${entityId}`;
    
        if (!state.counts) state.counts = {}; 
    
        if (entityType && entityId) {
          state.counts[trialCountId] = count;
        } else {
          console.error(`Invalid trialCountId: ${trialCountId}`);
        }
        state.loading = false;
      })
      .addCase(fetchTrialCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(toggleTrial.fulfilled, (state, action) => {
        const { entityType, entityId, userId } = action.meta.arg;
        const trialId = `${entityType}_${entityId}_${userId}`;
        const trialCountId = `${entityType}_${entityId}`;
        const updatedTrialCount = action.payload.total_trials;

        if (state.byId[trialId]) {
          state.byId[trialId].trialed = !state.byId[trialId].trialed;
        } else {
          state.byId[trialId] = { entityId, userId, trialed: true };
          if (!state.allIds) {
            state.allIds = [];
          }
          state.allIds.push(trialId);
        }
        // console.log(`User ${userId} trialed STATUS for ${entityType} ${entityId}:`, state.byId[trialId].trialed);
        state.counts[trialCountId] = updatedTrialCount;
      })
      .addCase(toggleTrial.rejected, (state, action) => {
        state.error = action.error.message;
      });
  }
});

export const { updateTrialCount } = trialSlice.actions;
export default trialSlice.reducer;
