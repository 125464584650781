import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Async thunk to fetch a single product by ID
export const fetchProductForPage = createAsyncThunk(
  'productPage/fetchProduct',
  async (productId, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/product/details/${productId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch product with ID ${productId}`);
      }
      const productData = await response.json();
      return productData; // Assuming the API returns the product data directly
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Initial state for the product page slice
const initialState = {
  productData: null,
  loading: false,
  error: null
};

// Slice definition
const productPageSlice = createSlice({
  name: 'productPage',
  initialState,
  reducers: {
    // Add any reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductForPage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductForPage.fulfilled, (state, action) => {
        state.loading = false;
        state.productData = action.payload;
      })
      .addCase(fetchProductForPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default productPageSlice.reducer;
