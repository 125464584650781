import React from 'react';
import { Modal, Box, Typography, Button, Divider, Tooltip } from '@mui/material';
import ShareButton from '../../../../components/socials/shares/shareButton';
import LikeButton from '../../../socials/likes/likeButton';
import FollowButton from '../../../socials/follow/followButton';
import SaveButton from '../../../socials/saves/saveButton';
import NopeButton from '../../../socials/nope/nopeButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PlaceIcon from '@mui/icons-material/Place';
import FactoryIcon from '@mui/icons-material/Factory';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import './quickViewUser.scss';

const QuickViewUser = ({ userData, userId, entityType, visitorUserId, open, onClose }) => {
    
    if (!userData || !userId) return null;
    const buttonClass = visitorUserId ? 'testing-new logged-in' : 'testing-new';
    const SHOW_COMPANY_NAME = true;
    

    const handleCloseClick = () => {
        onClose();
    };

    return (
        <Modal disableEnforceFocus open={open} onClose={onClose}>
            <Box className='user-quickview-modal'>
                <Box className='quickview-info-display'>
                    <Box className='quickview-user-info'>
                        <Box className='quickview-image-status'>
                            <Box className='user-quickview-profile-image'>
                                <img src={userData?.user?.user_profile_image} alt={userData?.user?.first_name} />
                            </Box>
                            <Box className='quickview-user-status'>
                                <Box className='info-section'>
                                    <EmojiEventsIcon className='icon' />
                                    <Typography variant="body1">{`${userData.user.user_points} Points`}</Typography>
                                </Box>
                                <Box className='info-section'>
                                    <VerifiedUserIcon className='icon' />
                                    <Typography variant="body1">{userData.user.user_status_platform} </Typography>
                                </Box>
                            </Box>
                            </Box>
                        <Box className='quickview-user-deatils'>
                            <Box className='info-section'>
                                <AccountCircleIcon className='icon' />
                                <Typography variant="body1">{userData?.user?.user_first_name} {userData?.user?.user_last_name}</Typography>
                            </Box>
                            <Box className='quickview-user-role'>
                                <Box className='info-section'>
                                    <WorkIcon className='icon' />
                                    <Typography variant="body1">{userData?.user?.businessRoleName}</Typography>
                                </Box>
                                <Box className='info-section'>
                                    <BusinessIcon className='icon' />
                                    <Typography variant="body1">{userData?.user?.businessFunctionName}</Typography>
                                </Box>
                                <Box className='info-section'>
                                    <PlaceIcon className='icon' />
                                    <Typography variant="body1">{userData?.company?.companyCountryName}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='quickview-company-deatils'>
                            <Box className='quickview-company-info'>
                                {SHOW_COMPANY_NAME &&<Box className='info-section'>
                                    <LocationCityIcon className='icon' />
                                    <Typography variant="body1">{userData?.company?.company_name}</Typography>
                                </Box>}
                                    <Box className='info-section'>
                                        <FactoryIcon className='icon' />
                                        <Typography variant="body1">{userData?.company?.companyIndustryName}</Typography>
                                    </Box>
                                    <Box className='info-section'>
                                        <LocalConvenienceStoreIcon className='icon' />
                                        <Typography variant="body1">{userData?.company?.companySectorName}</Typography>
                                    </Box>
                                <Box className='info-section'>
                                    <PeopleIcon className='icon' />
                                    <Typography variant="body1">{userData?.company?.company_size_fte}</Typography>
                                </Box>
                                <Box className='info-section'>
                                    <AttachMoneyIcon className='icon' />
                                    <Typography variant="body1">{userData?.company?.company_size_rev}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Divider/>
                    <Box className='bio-and-stacks'>
                        <Box className='info-section'>
                            <DescriptionIcon className='icon' />
                            <Typography variant="body1">{userData?.user?.user_bio}</Typography>
                        </Box>
                        <Box className='people-card-stacks'>
                            <Box className='user-primary-stacks'>
                            </Box>
                        </Box>
                        <Box className='user-stacks'>
                            <Box className='user-official-stack' >
                                <Box>Personal (official)</Box>
                                <div className='user-official-stacks'>
                                    <a href={`/stack/profile/${userData?.stacks?.userPersonalStack?.stack_id}`} target="_blank" rel="noopener noreferrer">
                                        <Tooltip title="Personal" placement="top"> 
                                            <div className='quickview-official-stack-image-container'>
                                                {userData?.stacks?.userPersonalStack?.stack_background_image && (
                                                    <img className='quickview-official-stack-image' src={userData?.stacks?.userPersonalStack?.stack_profile_image} alt={userData?.stacks?.userPersonalStack?.stack_name} />
                                                )}
                                                <p className='quickview-official-stack-name'>{userData?.stacks?.userPersonalStack?.stack_name}</p>
                                            </div>
                                        </Tooltip>
                                    </a>
                                </div>
                            </Box>
                            <Box>
                                <Box>Company (official)</Box> 
                                <Box className='user-official-stack'>
                                    <div className='user-official-stacks'>
                                        <a href={`/stack/profile/${userData?.stacks?.userBusinessStack?.stack_id}`} target="_blank" rel="noopener noreferrer">
                                            <Tooltip title="Business" placement="top">
                                                <div className='quickview-official-stack-image-container'>
                                                    {userData?.stacks?.companyBusinessStack?.stack_background_image && (
                                                        <img className='quickview-official-stack-image' src={userData?.stacks?.companyBusinessStack?.stack_profile_image} alt={userData?.stacks?.companyBusinessStack?.stack_name} />
                                                    )} 
                                                    <p className='quickview-official-stack-name'>{userData?.stacks?.companyBusinessStack?.stack_name}</p>
                                                </div>
                                            </Tooltip>
                                        </a>
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="quickview-actions">
                        <Box className={buttonClass}>{entityType && userId && visitorUserId &&  (<LikeButton showCount={true} entityType={entityType} entityId={userId} userId={visitorUserId}/>)} </Box>
                        <Box className={buttonClass}>{entityType && userId && visitorUserId && (<FollowButton showCount={true} entityType={entityType} entityId={userId} userId={visitorUserId} />)}</Box>
                        <Box className={buttonClass}>{entityType && userId && visitorUserId && (<SaveButton showCount={true} entityType={entityType} entityId={userId} userId={visitorUserId} />)}</Box>
                        <Box className={buttonClass}>{entityType && userId && visitorUserId && (<ShareButton showCount={true} entityType={entityType} entityId={userId} userId={visitorUserId} />)}</Box>
                        <Box className={buttonClass}>{entityType && userId && visitorUserId && (<NopeButton showCount={true} entityType={entityType} entityId={userId} userId={visitorUserId} />)}</Box>
                        <Button variant="text" sx={{color: 'white'}} onClick={() => window.open(`/user/profile/${userId}`, '_blank')}>Go to Profile</Button>
                    </Box>
                    <Button className="buttonClose" onClick={handleCloseClick}>x</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default QuickViewUser;
