import React from 'react';
import { Box, Typography, Chip, Grid } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import PlaceIcon from '@mui/icons-material/Place';
import FactoryIcon from '@mui/icons-material/Factory';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import './userAboutPublic.scss';

const UserAboutPublic = ({ userId, userData, visitorUserId, entityType, showCompany = false}) => {
    if (!userData) {
        return null;
    }

    const personalLinks = [
        { icon: <FacebookIcon />, label: "Facebook", href: userData.user.user_handle_facebook },
        { icon: <GitHubIcon />, label: "GitHub", href: userData.user.user_handle_github },
        { icon: <LinkedInIcon />, label: "LinkedIn", href: userData.user.user_handle_linkedin },
        { icon: <TwitterIcon />, label: "Twitter", href: userData.user.user_handle_twitter },
        { icon: <LanguageIcon />, label: "Website", href: userData.user.user_handle_website },
    ];

    return (
        <Box className='user-about-public-profile'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" mb='5px'>User info</Typography>
                    <Box className='info-section'>
                        <AccountCircleIcon className='icon' />
                        <Typography variant="body1">{userData.user.user_first_name}</Typography>
                    </Box>
                    <Box className='info-section'>
                        <WorkIcon className='icon' />
                        <Typography variant="body1">{userData.user.businessFunctionName}</Typography>
                    </Box>
                    <Box className='info-section'>
                        <BusinessIcon className='icon' />
                        <Typography variant="body1">{userData.user.businessRoleName}</Typography>
                    </Box>
                    <Box className='info-bio'>
                        <DescriptionIcon className='icon' />
                        <Typography variant="body1">{userData.user.user_bio}</Typography>
                    </Box>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" mb='5px'>User Links</Typography>
                        <Box sx={{ display: 'flex', gap: 0.3, flexWrap: 'nowrap' }}>
                            {personalLinks.map((link, index) => (
                                link.href && <Chip key={index} icon={link.icon} label={link.label} component="a" href={link.href} target="_blank" rel="noopener noreferrer" variant="outlined" clickable />
                            ))}
                        </Box>
                    </Grid>
                </Grid>
                
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" mb='5px'>User Company</Typography>
                    <Box className='info-section'>
                        <LocationCityIcon className='icon' />
                        <Typography variant="body1">
                            {showCompany ? (userData.company ? userData.company.company_name : '') : 'Anonymous'}
                        </Typography>
                    </Box>
                    <Box className='info-section'>
                        <PlaceIcon className='icon' />
                        <Typography variant="body1">{userData.company ? userData.company.companyCountryName : ''}</Typography>
                    </Box>
                    <Box className='info-section'>
                        <FactoryIcon className='icon' />
                        <Typography variant="body1">{userData.company ? userData.company.companyIndustryName : ''}</Typography>
                    </Box>
                    <Box className='info-section'>
                        <LocalConvenienceStoreIcon className='icon' />
                        <Typography variant="body1">{userData.company ? userData.company.companySectorName : ''}</Typography>
                    </Box>
                    <Box className='info-section'>
                        <Box className='info-section'>
                            <PeopleIcon className='icon' />
                            <Typography variant="body1">{userData.company ? userData.company.company_size_fte : ''}</Typography>
                        </Box>
                        <Box className='info-section'>
                            <AttachMoneyIcon className='icon' />
                            <Typography variant="body1">{userData.company ? userData.company.company_size_rev : ''}</Typography>
                        </Box>
                    </Box>
                </Grid>
                {showCompany && userData.company.company_url && (
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6">Company Link</Typography>
                        <Box sx={{ display: 'flex', gap: 0.3, flexWrap: 'wrap' }}>
                            <Chip icon={<LanguageIcon />} label="Website" component="a" href={userData.company.company_url} variant="outlined" clickable />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default UserAboutPublic;
