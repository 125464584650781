import React, { useState, useEffect } from 'react';
import apiFetch from '../../../../helpers/apifetch';
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeaderStreamed from '../../../headerstreamed/HeaderStreamed';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CreateNewCompany from '../../../../userprofile/userCompany/createnewcompany/createNewCompany';
import { setStackDetails } from '../../../../features/stacks/temporaryId/temporaryIdSlice';
import StaticDropdownOptions from '../../../../data/StaticFormOptions/staticDropdownOptions.json';
import './stackDetailsForm.scss';

const StackDetailsFormSchema = Yup.object().shape({
    stack_name: Yup.string().required('Stack name is required'),
    stack_description: Yup.string().max(
        255,
        'Description cannot exceed 255 characters'
    ),
    stack_type: Yup.string().required('Stack type is required'),
    stack_purpose: Yup.string().required('Stack purpose is required'),
    stack_is_official: Yup.boolean(),
    stack_create_mode_id: Yup.number().required('Creation mode is required'),
    selectedCompany: Yup.string().required('Required'),
});


const FormikEffect = ({ onChange }) => {
    const { values } = useFormikContext();
    useEffect(() => {
        onChange(values.selectedCompany);
    }, [values.selectedCompany, onChange]);
    return null;
};

const StackDetailsForm = ({
    onDetailsSubmit,
    userId,
    selectedPurpose,
    onCompanyChange,
    newStackUserData,
    isValidationComplete
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token);
    const tempStackDetails = useSelector(state => state.tempstackid.tempStack.details);
    const [creationModeSelected, setCreationModeSelected] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        apiFetch(
            `${process.env.REACT_APP_API_URL}/api/v1/company/user_companies?userId=${userId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then(response => response.json())
            .then(data => {
                setCompanyList(data);
                if (data.length > 0) {
                    setSelectedCompany(data[0].company_id);
                }
            })
            .catch(error => console.error('Error fetching companies:', error));
    }, [userId, token, dispatch]);

    const initialValues = {
        stack_name: tempStackDetails.stack_name || '',
        stack_description: tempStackDetails.stack_description || '',
        stack_type: tempStackDetails.stack_type || '',
        stack_purpose: tempStackDetails.stack_purpose || '',
        stack_is_official: tempStackDetails.stack_is_official || false,
        stack_create_mode_id: tempStackDetails.stack_create_mode_id || null,
        stack_owner_company_id: tempStackDetails.stack_owner_company_id || '',
        stack_owner_user_admin_id: tempStackDetails.stack_owner_user_admin_id || '',
        stack_author_user_id: tempStackDetails.stack_author_user_id || '',
        selectedCompany: '',
    };

    const handleSubmit = (values, actions) => {
        actions.setSubmitting(false);
        const parsedValues = {
            ...values,
            stack_create_mode_id: parseInt(values.stack_create_mode_id, 10),
            stack_owner_company_id: selectedCompany,
            stack_author_user_id: userId,
            stack_owner_user_admin_id: [userId],
        };
        // console.log('StackDetailsForm details submitted:', parsedValues);

        dispatch(setStackDetails(parsedValues));
        onDetailsSubmit(parsedValues);

        switch (parsedValues.stack_create_mode_id) {
            case 1:
                navigate('/create/newstack/select');
                break;
            case 2:
                navigate('/create/newstack/template');
                break;
            case 3:
                navigate('/create/newstack/clone');
                break;
            default:
                console.error('No valid creation mode selected');
        }
    };

    const handleFormikChange = newSelectedCompany => {
        if (newSelectedCompany !== selectedCompany) {
            setSelectedCompany(newSelectedCompany);
            onCompanyChange(newSelectedCompany);
        }
    };

    return (
        <div className="stack-details-form-main-container">
            <HeaderStreamed
                flexDirection="column"
                title="Your Stack Details"
                subtitle="Choose a company and creation mode"
            />
            <Formik
                initialValues={initialValues}
                validationSchema={StackDetailsFormSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ errors, touched, values, setFieldValue }) => (
                    <div>
                        <Form className="stack-details-form">
                            <FormikEffect onChange={handleFormikChange} />
                            <div className="stack-form-row-one">
                                <div className="form-row">
                                    <label htmlFor="stack_purpose">
                                        Purpose
                                    </label>
                                    <input
                                        type="text"
                                        id="stack_purpose"
                                        name="stack_purpose"
                                        value={selectedPurpose}
                                        readOnly
                                    />
                                </div>
                                {/* Company Selection Dropdown */}
                                <div className="stack-form-company-selection">
                                    {companyList.length > 0 ? (
                                        <>
                                            <div className="create-new-company">
                                                <div className="form-row">
                                                    <label htmlFor="selectedCompany">
                                                        Select Company
                                                    </label>
                                                    <Field
                                                        as="select"
                                                        id="select_company"
                                                        name="selectedCompany"
                                                    >
                                                        <option value="">
                                                            Select a company...
                                                        </option>
                                                        {companyList.map(
                                                            company => (
                                                                <option
                                                                    key={
                                                                        company.company_id
                                                                    }
                                                                    value={
                                                                        company.company_id
                                                                    }
                                                                >
                                                                    {
                                                                        company.company_name
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </Field>
                                                </div>
                                                {/* 'Create New Company' Button */}
                                                <div className="stack-form-create-new-company-button">
                                                    <IconButton
                                                        style={{
                                                            margin: '10px 0',
                                                        }}
                                                        onClick={toggleModal}
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="stack-form-no-company">
                                            <p className="stack-form-no-company-text">
                                                No companies found. Create a
                                                company.
                                            </p>
                                            <IconButton
                                                style={{ margin: '10px 0' }}
                                                onClick={toggleModal}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="stack-form-row-two">
                                {/* Stack Name */}
                                <div className="form-row">
                                    <label htmlFor="stack_name">
                                        Stack Name
                                    </label>
                                    <Field
                                        as="input"
                                        id="stack_name"
                                        name="stack_name"
                                    />
                                    {errors.stack_name &&
                                        touched.stack_name && (
                                            <div className="error">
                                                {errors.stack_name}
                                            </div>
                                        )}
                                </div>
                                {/* Stack Type */}
                                <div className="form-row">
                                    <label htmlFor="stack_type">Type</label>
                                    <Field
                                        as="select"
                                        id="stack_type"
                                        name="stack_type"
                                        placeholder="Select Type"
                                    >
                                        <option value=""></option>
                                        {StaticDropdownOptions.stackType.map(
                                            option => (
                                                <option
                                                    key={option.id}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )
                                        )}
                                    </Field>
                                    {errors.stack_type &&
                                        touched.stack_type && (
                                            <div className="error">
                                                {errors.stack_type}
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className="stack-form-row-three">
                                {/* Stack Description */}
                                <div className="form-row">
                                    <label htmlFor="stack_description">
                                        Short Description
                                    </label>
                                    <Field
                                        as="textarea"
                                        id="stack_description"
                                        name="stack_description"
                                    />
                                    {errors.stack_description &&
                                        touched.stack_description && (
                                            <div className="error">
                                                {errors.stack_description}
                                            </div>
                                        )}
                                </div>
                                <div className="form-row">
                                    <label htmlFor="stack_create_mode_id">
                                        Creation Mode
                                    </label>
                                    <Field
                                        as="select"
                                        id="stack_create_mode_id"
                                        name="stack_create_mode_id"
                                        onChange={e => {
                                            const modeId = parseInt(
                                                e.target.value,
                                                10
                                            );
                                            setFieldValue(
                                                'stack_create_mode_id',
                                                modeId
                                            );
                                            setCreationModeSelected(
                                                !isNaN(modeId) && modeId > 0
                                            );
                                        }}
                                    >
                                        <option value=""></option>
                                        {StaticDropdownOptions.stackCreationMode.map(
                                            option => (
                                                <option
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.label}
                                                </option>
                                            )
                                        )}
                                    </Field>
                                    {errors.stack_create_mode_id &&
                                        touched.stack_create_mode_id && (
                                            <div className="error">
                                                {errors.stack_create_mode_id}
                                            </div>
                                        )}
                                    {/* Stack Official */}
                                    <div className="form-row stack-status">
                                        <label htmlFor="stack_is_official">
                                            Stack Status
                                        </label>
                                        <div className="mark-stack-official">
                                            <Field
                                                className="large-checkbox"
                                                type="checkbox"
                                                id="stack_is_official"
                                                name="stack_is_official"
                                            />
                                            <div
                                                htmlFor="stack_is_official"
                                                className="checkbox-label"
                                            >
                                                Make official stack
                                            </div>
                                        </div>
                                        {values.stack_is_official && (
                                            <div className="helper-text">
                                                Existing official stacks will be
                                                set to 'unofficial'.
                                            </div>
                                        )}
                                        {errors.stack_is_official &&
                                            touched.stack_is_official && (
                                                <div className="error">
                                                    {errors.stack_is_official}
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="submit-btn"
                                    disabled={!creationModeSelected}
                                >
                                    Next
                                </button>{' '}
                                {/* put "|| !isValidationComplete"  back once validaiton working*/}
                            </div>
                        </Form>
                        <CreateNewCompany
                            isOpen={isModalOpen}
                            onClose={toggleModal}
                        />
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default StackDetailsForm;
