// src/components/users/useraccount/userdetailsvalidation/userDetailsValidation.jsx

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiFetch from '../../../../helpers/apifetch';
import UserDetailsFormPartial from '../userdetailsform/userDetailsFormPartial/userDetailsFormPartial';
import UserPersonalsForm from '../userdetailsform/userPersonalFormPartial/userPersonalsForm';
import HeaderStreamed from '../../../headerstreamed/HeaderStreamed';
import { Box } from '@mui/material';
import './userDetailsValidation.scss';

const UserDataValidation = ({
    userId,
    onValidationComplete,
    companyId,
    stackPurpose,
    newStackUserData
    
}) => {
    const [isCompanyDataComplete, setIsCompanyDataComplete] = useState(false);
    const [isPersonalDataComplete, setIsPersonalDataComplete] = useState(false);
    const token = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    

    const personalFields = useMemo(() => [
        'user_first_name',
        'user_business_function_id',
        'user_business_role_id',
    ], []);
    
    const businessFields = useMemo(() => [
        'company_name',
        'company_url',
        'company_size_fte',
        'company_size_rev',
        'company_country_id',
        'company_industry_id',
        'company_sector_id',
    ], []);
    
    const statusFields = useMemo(() => ['is_primary', 'user_business_relation'], []);
    
    const requiredFields =
        stackPurpose === 'Personal'
            ? personalFields
            : [...personalFields, ...businessFields, ...statusFields];

    const validateUser = useCallback(userData => {
        const isDataComplete = personalFields.every(
            field =>
                userData[field] !== null &&
                userData[field] !== undefined &&
                userData[field] !== ''
        );
        setIsPersonalDataComplete(isDataComplete);
    }, [personalFields]);

    const validateCompanyData = useCallback(companyData => {
        const isDataComplete = businessFields.every(
            field =>
                companyData[field] !== null &&
                companyData[field] !== undefined &&
                companyData[field] !== ''
        );
        setIsCompanyDataComplete(isDataComplete);
    }, [businessFields]);

    const validateJunctionData = useCallback(junctionData => {
        const isDataComplete = statusFields.every(
            field =>
                junctionData[field] !== undefined && junctionData[field] !== ''
        );
        setIsCompanyDataComplete(isDataComplete);
    }, [statusFields]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await apiFetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const userData = await response.json();
                validateUser(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchUserData();
    }, [userId, dispatch, token, validateUser]);


    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                const companyData = await apiFetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/company/company_details/${companyId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                validateCompanyData(companyData);
            } catch (error) {
                console.error('Error fetching company data:', error);
            }
        };

        if (companyId) {
            fetchCompanyData();
        }
    }, [companyId, token, dispatch, validateCompanyData]);

    
    useEffect(() => {
        const fetchJunctionData = async () => {
            try {
                const junctionData = await apiFetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/company/junction_data/${userId}/${companyId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                validateJunctionData(junctionData);
            } catch (error) {
                console.error('Error fetching junction data:', error);
            }
        };

        if (userId && companyId) {
            fetchJunctionData();
        }
    }, [userId, companyId, token, dispatch, validateJunctionData]);


    return (
        <div>
            <HeaderStreamed
                flexDirection="column"
                title="Complete your details"
                subtitle="So we can give you relevant comparisons, insights and recommendations"
            />
            <div className="user-details-validation">
                {!isPersonalDataComplete ? (
                    <Box>
                        <UserPersonalsForm
                            userId={userId}
                            requiredFields={requiredFields}
                            stackPurpose={stackPurpose}
                            companyId={companyId}
                        />
                    </Box>
                ) : (
                    <div className="details-complete-confirm">
                        Personal Data complete.
                    </div>
                )}
            </div>
            <div className="user-details-validation">
                {!isCompanyDataComplete ? (
                    <Box>
                        <UserDetailsFormPartial
                            userId={userId}
                            requiredFields={requiredFields}
                            stackPurpose={stackPurpose}
                            companyId={companyId}
                        />
                    </Box>
                ) : (
                    <div className="details-complete-confirm">
                        Company Data complete.
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserDataValidation;
