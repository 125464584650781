// src/userprofile/userCompany/createnewcompany/createNewCompany.jsx

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import apiFetch from '../../../helpers/apifetch';
import { Box, Snackbar, Alert, styled, Grid, TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText, Button, Checkbox, FormGroup, FormControlLabel } from '@mui/material';

// import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import HeaderStreamed from '../../../components/headerstreamed/HeaderStreamed';
import StaticDropdownOptions from '../../../data/StaticFormOptions/staticDropdownOptions.json';
import './createNewCompany.scss';

const SubmitButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.background.paper,
    '&:disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
    },
    '&:hover': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.background.paper,
    },
    '&:disabled:hover': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
    },
    transition: 'none',
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.secondary.main,
    '&.Mui-checked': {
      color: theme.palette.secondary.main,
    },
    '&.MuiCheckbox-root': {
      color: theme.palette.neutral.light,
    },
}));
// add onclose back into props when iconbutton is active again
const NewCompanyForm = ({ userId, onCompanyCreated, open  }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [countries, setCountries] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const token = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    const [snackOpen, setSnackOpen] = useState(false);
    const [alert, setAlert] = useState({severity: 'success', message: ''});

    // Fetch countries
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/form/company_country`
                );
                if (!response.ok) throw new Error('Failed to fetch countries');
                const data = await response.json();
                setCountries(data);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);

    // Fetch industries and industry sectors
    useEffect(() => {
        const fetchIndustries = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/form/company_industry`
                );
                if (!response.ok) throw new Error('Failed to fetch industries');
                const data = await response.json();
                setIndustries(data);
            } catch (error) {
                console.error('Error fetching industries:', error);
            }
        };
        fetchIndustries();
    }, []);

    useEffect(() => {
        const fetchSectors = async () => {
            if (!selectedIndustry) return;
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/form/company_sector?industryId=${selectedIndustry}`
                );
                if (!response.ok) throw new Error('Failed to fetch sectors');
                const data = await response.json();
                setSectors(data);
            } catch (error) {
                console.error('Error fetching sectors:', error);
            }
        };
        fetchSectors();
    }, [selectedIndustry]);

    // Validation Schema for New Company Form
    const validationSchema = Yup.object().shape({
        company_name: Yup.string().required('Company name is required'),
        company_url: Yup.string()
            .url('Invalid URL')
            .required('Company URL is required'),
        company_size_fte: Yup.string().required('Company size (FTE) is required'),
        company_size_rev: Yup.string().required('Company size (Revenue) is required'),
        company_country_id: Yup.number().required('Company country is required'),
        company_industry_id: Yup.number().required('Company industry is required'),
        company_sector_id: Yup.number().required('Company sector is required'),
        user_business_relation: Yup.string().required('Company relationship is required'),
        is_primary: Yup.boolean(),
    });

    // Handle form submission
    const handleSubmit = async values => {
        setIsSubmitting(true);
        try {
            const companyData = await apiFetch(
                `${process.env.REACT_APP_API_URL}/api/v1/company/createnew`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        ...values,
                        user_id: userId,
                        is_primary: values.is_primary,
                    }),
                },
                dispatch
            );
    
            const companyId = companyData.company_id;
    
            onCompanyCreated(companyId); // Notify parent component about the successful creation
            setAlert({ snackOpen: true, message: 'Company created successfully', severity: 'success' });
            setSnackOpen(true); // Open the Snackbar
        } catch (error) {
            console.error('Error creating new company:', error);
            setAlert({ snackOpen: true, message: 'Error creating new company', severity: 'error' });
            setSnackOpen(true); // Open the Snackbar
        } finally {
            setIsSubmitting(false);
        }
    };

    return open ? (
        <div>
            <Box sx={{gap: '20px'}}>
                <Box sx={{margin: '0px'}}>
                    <HeaderStreamed title='Create Company Record' subtitle= 'enter details for your new company record'/>
                </Box>
                <Box className="new-company-form-container">
                    {/* <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton> */}
                    <Formik
                        initialValues={{
                            company_name: '',
                            company_url: '',
                            company_size_fte: '',
                            company_size_rev: '',
                            company_country_id: '',
                            company_industry_id: '',
                            company_sector_id: '',
                            user_business_relation: '',
                            is_primary: false,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            console.log('Submitting form', values);
                            handleSubmit(values).finally(() => {
                                setSubmitting(false); 
                            });
                        }}
                        enableReinitialize
                    >
                        {({ setFieldValue, touched, errors, values }) => (
                            <Form className="new-company-form">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            name="company_name"
                                            as={TextField}
                                            label="Company Name"
                                            size='medium'
                                            fullWidth
                                            error={touched.company_name && !!errors.company_name}
                                            helperText={<ErrorMessage name="company_name" />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            name="company_url"
                                            as={TextField}
                                            label="Company URL"
                                            size='medium'
                                            fullWidth
                                            error={touched.company_url && !!errors.company_url}
                                            helperText={<ErrorMessage name="company_url" />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth error={touched.user_business_relation && !!errors.user_business_relation}>
                                            <InputLabel id="user-company-relation-label">Role</InputLabel>
                                            <Field
                                                component={Select}
                                                name="user_business_relation"
                                                labelId="user-company-relation-label"
                                                id="user_business_relation"
                                                label="Role"
                                                onChange={e => setFieldValue('user_business_relation', e.target.value)}
                                                error={touched.user_business_relation && Boolean(errors.user_business_relation)}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                <MenuItem value="Boss">Boss</MenuItem>
                                                <MenuItem value="Employee">Employee</MenuItem>
                                                <MenuItem value="Contractor">Contractor</MenuItem>
                                            </Field>
                                            <FormHelperText>{touched.user_business_relation && errors.user_business_relation}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth error={touched.company_industry_id && !!errors.company_industry_id}>
                                            <InputLabel id="company-industry-label">Industry</InputLabel>
                                            <Select
                                                labelId="company-industry-label"
                                                id="company_industry_id"
                                                name="company_industry_id"
                                                size= 'default'
                                                label="Industry"
                                                value={values.company_industry_id || ''}
                                                onChange={(e) => {
                                                    setFieldValue('company_industry_id', e.target.value);
                                                    setSelectedIndustry(e.target.value);
                                                }}
                                                error={touched.company_industry_id && Boolean(errors.company_industry_id)}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {industries.map((industry) => (
                                                    <MenuItem key={industry.industry_id} value={industry.industry_id}>
                                                        {industry.industry_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{touched.company_industry_id && errors.company_industry_id}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth error={touched.company_sector_id && !!errors.company_sector_id}>
                                            <InputLabel id="company-sector-label">Sector</InputLabel>
                                            <Select
                                                labelId="company-sector-label"
                                                id="company_sector_id"
                                                name="company_sector_id"
                                                label="Sector"
                                                value={values.company_sector_id || ''}
                                                onChange={(e) => setFieldValue('company_sector_id', e.target.value)}
                                                error={touched.company_sector_id && Boolean(errors.company_sector_id)}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {sectors.map((sector) => (
                                                    <MenuItem key={sector.sector_id} value={sector.sector_id}>
                                                        {sector.sector_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{touched.company_sector_id && errors.company_sector_id}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth error={touched.company_country_id && !!errors.company_country_id}>
                                            <InputLabel>Country</InputLabel>
                                            <Field
                                                name="company_country_id"
                                                as={Select}
                                                label="Country"
                                                onChange={e => setFieldValue('company_country_id', e.target.value)}
                                            >
                                                {countries.map(country => (
                                                    <MenuItem key={country.country_id} value={country.country_id}>
                                                        {country.country_name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                            <FormHelperText><ErrorMessage name="company_country_id" /></FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth error={touched.company_size_fte && !!errors.company_size_fte}>
                                            <InputLabel id="company-size-fte-label">Company Size (FTE)</InputLabel>
                                            <Field
                                                component={Select}
                                                name="company_size_fte"
                                                labelId="company-size-fte-label"
                                                id="company_size_fte"
                                                label="Company Size (FTE)"
                                                onChange={e => setFieldValue('company_size_fte', e.target.value)}
                                                error={touched.company_size_fte && Boolean(errors.company_size_fte)}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                {StaticDropdownOptions.companySizeFTE.map(option => (
                                                    <MenuItem key={option.id} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                            <FormHelperText>{touched.company_size_fte && errors.company_size_fte}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth error={touched.company_size_rev && !!errors.company_size_rev}>
                                            <InputLabel id="company-size-rev-label">Company Size (Revenue)</InputLabel>
                                            <Field
                                                component={Select}
                                                name="company_size_rev"
                                                labelId="company-size-rev-label"
                                                id="company_size_rev"
                                                label="Company Size (Revenue)"
                                                onChange={e => setFieldValue('company_size_rev', e.target.value)}
                                                error={touched.company_size_rev && Boolean(errors.company_size_rev)}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                {StaticDropdownOptions.companySizeRevenue.map(option => (
                                                    <MenuItem key={option.id} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                            <FormHelperText>{touched.company_size_rev && errors.company_size_rev}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        as={StyledCheckbox}
                                                        name="is_primary"
                                                    />
                                                }
                                                label="Is this your primary company?"
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid 
                                        item xs={12}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: "flex-end",
                                            mb: '50px'
                                        }}
                                    >
                                        <SubmitButton type="submit"  variant="contained" color="primary" disabled={isSubmitting}>
                                            Create Company
                                        </SubmitButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
            <Snackbar 
                open={snackOpen}
                autoHideDuration={6000}
                onClose={() => setSnackOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert elevation={6} variant="filled" severity={alert.severity} onClose={() => setSnackOpen(false)}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </div>
    ) : null;
};

export default NewCompanyForm;
