// src>global>filters>autocomplete>autocompleteFilterComponent.jsx
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const AutocompleteFilterComponent = ({ label, value, onChange, options }) => {
    return (
        <Autocomplete
            options={options}
            size='small'
            getOptionLabel={(option) => option ? option : ''}
            value={value}
            onInputChange={(event, newInputValue) => {
                onChange(newInputValue);
            }}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    );
};

export default AutocompleteFilterComponent;