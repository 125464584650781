import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDrag } from 'react-dnd';
import { Box, Tooltip, Typography } from '@mui/material';
import ProductQuickView from '../productQuickView/productQuickView';
import { addProductToStack } from '../../../features/products/basicsdisplay/productBasicsSlice';
import './basicDisplay.module.scss';

const BasicDisplay = ({ product, showTooltip = true }) => {
    const dispatch = useDispatch();
    const [{ isDragging }, dragRef] = useDrag({
        type: 'PRODUCT',
        item: { id: product.product_id },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult && dropResult.stackId) {
                dispatch(
                    addProductToStack({
                        userId: 'X',
                        productId: item.id,
                        stackId: dropResult.stackId,
                    })
                );
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const [quickViewOpen, setQuickViewOpen] = useState(false);
    const handleOpenQuickView = () => {
        setQuickViewOpen(true);
    };
    const handleCloseQuickView = () => {
        setQuickViewOpen(false);
    };
    useEffect(() => {}, [quickViewOpen]);

    return (
        <>
            <Box
                onClick={handleOpenQuickView}
                className="productSimple"
                ref={dragRef}
                sx={{
                    opacity: isDragging ? 0.5 : 0.8,
                    cursor: 'pointer',
                    textAlign: 'center',
                    borderRadius: '5px',
                    padding: '5px',
                }}
            >
                {showTooltip ? (
                    <Tooltip title={product.product_name}>
                        <Box
                            component="img"
                            src={product.product_image}
                            alt={product.product_name}
                            sx={{
                                maxWidth: '50px',
                                maxHeight: '50px',
                                border: '0px solid green',
                            }}
                        />
                    </Tooltip>
                ) : (
                    <Box
                        component="img"
                        src={product.product_image}
                        alt={product.product_name}
                        sx={{
                            maxWidth: '50px',
                            maxHeight: '50px',
                            overflow: 'hidden',
                            border: '0px solid blue',
                        }}
                    />
                )}
                <Typography>{product.product_name}</Typography>
            </Box>
            <ProductQuickView
                product={product}
                open={quickViewOpen}
                onClose={handleCloseQuickView}
            />
        </>
    );
};

export default BasicDisplay;
