import React from "react";
import { Box, Button } from "@mui/material";
import HeaderStreamed from "../../../components/headerstreamed/HeaderStreamed";
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/sidepanelcomponent/SideBarComponent';
import FeedInterest from "../../../components/feed/feedsettings/feedinterests/feedInterests";
import AccountAboutUser from '../userdetails/accountaboutuser/accountAboutUser';
import AccountAboutCompany from '../userdetails/accountaboutcompany/accountAboutCompany';
import PeopleCard from '../../../components/users/peoplecard/peopleCard';
import  "./userAccountOverview.scss";

const UserAccountOverview = ({ userData, userId, entityType }) => {
    
    if (!userData) {
        return null
    }

  return(
    <Box className='streamed-tab-component-outer'>
        <HeaderStreamed title="Account Overview" subtitle="access all areas of your account from here"/>
        <Box className="streamed-tab-component-container-main" >
            <Box className="streamed-tab-component-grid-and-side" >
                <Box className= "streamed-tab-component-grid-main">
                    <BoxComponent
                        title="Manage your account information"
                        subtitle="add or edit personal, social and your company info"
                        width={12}
                    >
                    </BoxComponent>
                    <BoxComponent width={12} />
                    <BoxComponent 
                        title="Customise your feed" 
                        subtitle="same implementaiton as with 'manage interests' component"
                        width={12}
                    >
                        <FeedInterest userId={userId} />
                    </BoxComponent>
                    
                    <BoxComponent title="Manage Teams" subtitle="general toggle-based preference setting" width={12} />
                    <BoxComponent title="Manage claimed entities" subtitle="general toggle-based preference setting" width={12} />
                    <BoxComponent title="Billing" subtitle="toggle for e.g. 'include user posts', 'replies to my comments', etc." width={12} />
                    
                </Box>
                <Box className="streamed-tab-component-right-panel" >
                    <Button variant='register' >GO TO SETTINGS</Button>
                    <SideBarComponent 
                        title="Manage your Details"
                        subtitle="Edit personal and company info"
                        showButton={true}
                        
                        
                    >
                        <PeopleCard 
                            userId={userId}
                            useData={userData}
                            entityType={entityType}
                            showFirstName={true}
                            showUserName={true}
                            showBusinessRole={true}
                            showUserCompany={true}
                            showQuickView={true}
                        />
                        
                    </SideBarComponent>
                    <SideBarComponent 
                        title="Account Details"
                    >
                        <AccountAboutUser
                            userId={userId}
                            userData={userData}
                            entityType={entityType}
                        />
                    </SideBarComponent>
                    <SideBarComponent 
                        title="Company Details"
                    >
                        <AccountAboutCompany
                            userId={userId}
                            userData={userData}
                            entityType={entityType}
                            showCompany={true}
                        />
                    </SideBarComponent>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}


export default UserAccountOverview;