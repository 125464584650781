import React from 'react';
import { Box } from "@mui/material";
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import EntityTesting from '../../../components/entitytesting/entityTesting';
import './profileContentUser.scss';

const ProfileContentUser = (userId) => {

  return(
    <Box className='user-account-page-container'>
        <Box className="account-container-main" >
            <Box className="account-gridPlusSide" >
                <Box className= "account-main-grid">
                    <BoxComponent
                        title="Display all fetched entities"
                        // subtitle=""
                        width={12}
                    >
                        <EntityTesting user={userId}/>
                    </BoxComponent>
                    
                    
                </Box>
                <Box className="account-right-panel" >
                    <SideBarComponent 
                        title="Placeholder"
                        // subtitle="This is irreversible"
                        sideText="10"
                        showButton={true}
                        link="https://www.google.com"
                    >
                        
                    </SideBarComponent>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}


export default ProfileContentUser;