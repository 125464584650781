import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import './registernewuser.scss';

const RegisterNewUser = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const passwordMinLength = 6;

    const handleRegister = async () => {
        // Reset error message
        setErrorMessage('');

        if (!email || !password || !firstName || !agreedToTerms) {
            setErrorMessage('All fields are required.');
            return;
        }

        if (!emailRegex.test(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }
    
        if (password.length < passwordMinLength) {
            setErrorMessage(`Password must be at least ${passwordMinLength} characters.`);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_email: email, password, user_first_name: firstName, user_last_name: lastName, agreed_to_terms: agreedToTerms })
            });

            const data = await response.json();
            if (response.ok) {
                navigate('/login');
            } else {
                
                setErrorMessage(data.message || 'An error occurred during registration.');
            }
        } catch (error) {
            setErrorMessage('Failed to send registration request.');
        }
    };

    return (
        <Box className="register-new-user">
            <h2>Register New User</h2>
            {errorMessage && (
                <div className="error-message">
                    {errorMessage}
                    {errorMessage.includes("Email already exists") && (
                        <span> <Link to="/login">Login</Link> or register another email.</span>
                    )}
                </div>
            )}
            <TextField className="input-field" label="First Name" variant="outlined" value={firstName} onChange={e => setFirstName(e.target.value)} />
            <TextField className="input-field" label="Last Name" variant="outlined" value={lastName} onChange={e => setLastName(e.target.value)} />
            <TextField className="input-field" label="Email" variant="outlined" value={email} onChange={e => setEmail(e.target.value)} />
            <TextField className="input-field" label="Password" type="password" variant="outlined" value={password} onChange={e => setPassword(e.target.value)} />
            <FormControlLabel
                control={<Checkbox checked={agreedToTerms} onChange={e => setAgreedToTerms(e.target.checked)} />}
                label="I agree to the Terms & Conditions"
            />
            <Button className="register-button" onClick={handleRegister}>Register</Button>
        </Box>
    );
};

export default RegisterNewUser;

