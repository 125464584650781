// Logout.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../features/userauth/userAuthSlice';
import { persistor } from '../../store/store';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const performLogout = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/logout`, {
                    method: 'POST',
                    credentials: 'include' 
                });

                if (response.ok) {
                    dispatch(logout()); 
                    try {
                        await persistor.purge(); // purge the persisted state
                    } catch (error) {
                        console.error('Failed to purge persisted state:', error);
                    }
                    localStorage.removeItem('postLoginRedirect'); 
                    navigate('/'); 
                    // console.log('Component - Logout action performed successfully');
                } else {
                    console.error('Logout failed:', response.statusText);
                }
            } catch (error) {
                console.error('Logout error:', error);
            }
        };
        

        performLogout();
    }, [dispatch, navigate]);

    return null; 
};

export default Logout;

