import React from "react";
import { Box } from "@mui/material";
import HeaderStreamed from "../../../components/headerstreamed/HeaderStreamed";
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import ImageFiller from 'react-image-filler';
import  "./userAccountPreferences.scss";

const UserAccountPreferences = ({userData, userId, entityType}) => {

  return(
    <Box className='user-account-page-container'>
        <HeaderStreamed title="Preferences" subtitle="email, notifications and other preferences"/>
        <Box className="account-container-main" >
            <Box className="account-gridPlusSide" >
                <Box className= "account-main-grid">
                    <BoxComponent
                        title="General Account Preferences"
                        subtitle="set general feed preferences | some are premium features (e.g. turn off ads)"
                        width={12}
                    >
                    </BoxComponent>
                    
                    <BoxComponent
                        title="Email Preferences"
                        subtitle="search, add or remove interests from your feed"
                        width={12}
                    >
                        
                    </BoxComponent>
                    
                    <BoxComponent 
                        title="Notification Preferences" 
                        subtitle="same implementaiton as with 'manage interests' component"
                        width={12}
                    />
                    
                    <BoxComponent title="Other Preferences" subtitle="general toggle-based preference setting" width={12} />
                </Box>
                <Box className="account-right-panel" >
                    <SideBarComponent 
                        title="Side Panel Widget 1"
                        sideText="10"
                        showButton={true}
                        link="https://www.google.com"
                    >
                        
                    </SideBarComponent>
                    <SideBarComponent 
                        title="Side Panel Widget 2"
                        showButton={false}
                    >
                        <div><ImageFiller width={260} height={100}/></div>
                    </SideBarComponent>
                    <SideBarComponent 
                        title="Side Panel Widget 3"
                        showButton={false}
                    >
                        <div><ImageFiller width={260} height={100}/></div>
                    </SideBarComponent>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}


export default UserAccountPreferences;