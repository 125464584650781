import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, FormControl, InputLabel, Select, Box, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { setStackInfo } from '../../../../../features/stacks/temporaryId/temporaryIdSlice';
import HeaderStreamed from '../../../../../components/headerstreamed/HeaderStreamed';

const creationModes = {
    "Create from Scratch": 1,
    // "Use a Template": 2,
    // "Clone Existing": 3
};

const StackInfoStepper = ({ newStackUserData, companyList, onNext }) => {
    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.user.user_id);
    const selectedCompanyId = useSelector(state => state?.tempstackid?.tempStack?.details?.companyId);
    
    const [formValues, setFormValues] = useState({
        stack_name: '',
        stack_description: '',
        stack_type: '',
        stack_is_official: false,
        stack_create_mode_id: creationModes["Create from Scratch"],
        stack_author_user_id: '',
        stack_owner_company_id: '',
        stack_owner_user_admin_id: '',
    });

    useEffect(() => {
        if (userId !== undefined && selectedCompanyId !== undefined) {
            setFormValues(prevValues => ({
                ...prevValues,
                stack_author_user_id: userId || '',
                stack_owner_company_id: selectedCompanyId || '',
                stack_owner_user_admin_id: [userId || ''],
            }));
        }
    }, [userId, selectedCompanyId]);

    const handleChange = (event) => {
        let value = event.target.value;

        if (event.target.name === 'stack_create_mode_id' && !isNaN(value)) {
            value = parseInt(value, 10);
        }
    
        setFormValues({
            ...formValues,
            [event.target.name]: value,
        });
    };

    const handleCheckboxChange = (event) => {
        setFormValues({
            ...formValues,
            stack_is_official: event.target.checked,
        });
    };

    const isValid = formValues.stack_name && formValues.stack_type && formValues.stack_create_mode_id;

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isValid) {        
          dispatch(setStackInfo(formValues));
          onNext();
        }
      };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml:'20px', gap: 2 }}>
                <HeaderStreamed title='Stack Info' subtitle='' />
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: '20px', gap: 2 }}>
                    <Box sx={{ minWidth: 200 }}>
                        <TextField
                            label="Name"
                            name="stack_name"
                            value={formValues.stack_name}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ minWidth: 200 }}>
                        <FormControl required fullWidth>
                            <InputLabel id="stack_type-label">Stack Type</InputLabel>
                            <Select
                                labelId="stack_type-label"
                                id="stack_type"
                                name="stack_type"
                                size='small'
                                style={{display: 'flex', alignContent: 'center', fontSize: '1.2rem'}}
                                value={formValues.stack_type}
                                onChange={handleChange}
                            >
                                <MenuItem value="Business-wide">Business-wide</MenuItem>
                                <MenuItem value="Selected Functions">Selected Functions</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 200 }}>
                        <FormControl required fullWidth>
                            <InputLabel id="stack_creation_mode_id-label">Stack Creation Mode</InputLabel>
                            <Select
                                labelId="stack_creation_mode_id-label"
                                id="stack_creation_mode_id"
                                name="stack_creation_mode_id"
                                size='small'
                                style={{display: 'flex', alignContent: 'center', fontSize: '1.2rem'}}
                                value={formValues.stack_creation_mode_id}
                                onChange={handleChange}
                            >
                                {Object.entries(creationModes).map(([mode, id]) => (
                                    <MenuItem key={id} value={id}>{mode}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    <Box sx={{ minWidth: 200 }}>
                        <TextField
                            label="Description"
                            name="stack_description"
                            style={{display: 'flex', alignContent: 'center', minWidth: '500px', gap: 3}}
                            value={formValues.stack_description}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ minWidth: 200 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="stack_is_official"
                                    checked={formValues.stack_is_official}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Is Official"
                        />
                    </Box>
                </Box>
            </Box>
        </form>
    );
};

export default StackInfoStepper;