// src/features/stacks/temporaryStackSlice/temporaryStackSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stackDetails: {},
  personalInfo: {},
  companyInfo: {},
  stackInfo: {},
  stackProducts: [],
};

export const newStackSlice = createSlice({
  name: 'newStack',
  initialState,
  reducers: {
    setStackDetails: (state, action) => {
      state.stackDetails = action.payload;
    },
    setPersonalInfo: (state, action) => {
      state.personalInfo = action.payload;
    },
    setCompanyInfo: (state, action) => {
      state.companyInfo = action.payload;
    },
    setStackInfo: (state, action) => {
      state.stackInfo = action.payload;
    },
    addProductToStack: (state, action) => {
      const existingProduct = state.stackProducts.find(product => product.product_id === action.payload.product_id);
      if (!existingProduct) {
          state.stackProducts.push(action.payload);
      }
    },
    removeProductFromStack: (state, action) => {
      state.stackProducts = state.stackProducts.filter(product => product.product_id !== action.payload);
    },
    setStackProducts: (state, action) => {
      state.stackProducts = action.payload;
    },
    resetNewStack: () => {
      return initialState;
    },
  },
});

export const { setStackDetails, setPersonalInfo, setCompanyInfo, setStackInfo, setStackProducts, addProductToStack, removeProductFromStack, resetNewStack } = newStackSlice.actions;

export default newStackSlice.reducer;