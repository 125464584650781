import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Checkbox, IconButton, Collapse, Tooltip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';

const plans = [
    {
        name: 'Basic',
        features: ['Feature 1', 'Feature 2'],
    },
    {
        name: 'Pro',
        features: ['Feature 1', 'Feature 2', 'Feature 3'],
    },
    {
        name: 'Enterprise',
        features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
    },
];

const featureGroups = [
    {
        name: 'Group 1',
        features: [
            { name: 'Feature 1', description: 'This is feature 1' },
            { name: 'Feature 2', description: 'This is feature 2' },
        ],
    },
    {
        name: 'Group 2',
        features: [
            { name: 'Feature 3', description: 'This is feature 3' },
            { name: 'Feature 4', description: 'This is feature 4' },
        ],
    },
];

const FeatureComparisonTable = () => {
    const [open, setOpen] = useState(featureGroups.reduce((prev, curr) => ({ ...prev, [curr.name]: true }), {}));

    const handleToggle = (groupName) => {
        setOpen((prev) => ({ ...prev, [groupName]: !prev[groupName] }));
    };

    return (
        <Box sx={{ width: '1200px', margin: '0 auto', padding: 2 }}>
          <Typography variant="h4" gutterBottom>
            Compare Details
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Features</TableCell>
                  {plans.map((plan, index) => (
                    <TableCell key={index} align="center">{plan.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {featureGroups.map((group, groupIndex) => (
                  <React.Fragment key={groupIndex}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <IconButton size="small" onClick={() => handleToggle(group.name)}>
                          {open[group.name] ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        {group.name}
                      </TableCell>
                      {plans.map((plan, index) => (
                        <TableCell key={index} />
                      ))}
                    </TableRow>
                    {group.features.map((feature, featureIndex) => (
                      <TableRow key={feature.name}>
                        <TableCell component="th" scope="row" sx={{ pl: 4}}>
                          <Collapse in={open[group.name]} timeout="auto" unmountOnExit>
                            <>
                              {feature.name}
                              <Tooltip title={feature.description} placement="right">
                                <IconButton size="small" sx={{ ml: 1 }}>
                                  <InfoIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            </>
                          </Collapse>
                        </TableCell>
                        {plans.map((plan, index) => (
                          <TableCell align="center" key={`${feature.name}-${plan.name}`}>
                            <Checkbox
                              checked={plan.features.includes(feature.name)}
                              icon={<CheckCircleOutlineIcon />}
                              checkedIcon={<CheckCircleOutlineIcon />}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
};

export default FeatureComparisonTable;