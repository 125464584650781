import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import LikeButton from '../../../components/socials/likes/likeButton';
import FollowButton from '../../socials/follow/followButton';
import SaveButton from '../../../components/socials/saves/saveButton';
import NopeButton from '../../socials/nope/nopeButton';
import ShareButton from '../../socials/shares/shareButton';
import CloneButton from '../../socials/clone/cloneButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StackQuickView from '../stackQuickView/stackQuickView';
import './stackCard.scss';

const StackCard = ({
        userId,
        stackId,
        showName = false,
        showType = false,
        showPurpose = false,
        showOfficial = false,
        showOwner = false,
        flexDirection = 'row',
        showQuickView = false,
        showSocialButtons = false,
        showBookmarkButton = false,
        showCompany = false,
    }) => {
    const [stackData, setStackData] = useState(null);
    const [quickViewOpen, setQuickViewOpen] = useState(false);
    const entityType = 'stack'
    const visitorUserId = userId || null

    // Fetch joint data about user with userId from userDetails route >> to replace with data from userPageSlice
    useEffect(() => {
        if (stackId) {

            const fetchData = async () => {
                try {
                    const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/stacks//${stackId}/${visitorUserId}`);
                    if (!userResponse.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const stackData = await userResponse.json();
                    setStackData(stackData);
                    
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };
    
            fetchData();
        }
        else {
            console.log("Failed to get stack details. No stack ID provided");
        }
    }, [stackId, visitorUserId]);


    if (!stackData) {
        return <div>Oops! We couldn't find that stack.</div>;
    }
    
    const handleOpenQuickView = () => setQuickViewOpen(true);
    const handleCloseQuickView = () => setQuickViewOpen(false);

    return (
        <Box className={`stack-card-main ${flexDirection === 'column' ? 'column-direction' : ''}`}>
            <Box className="stack-card-info" style={{ position: 'relative' }}>
                <img src={stackData.stackPackage.stack_profile_image || "https://robohash.org/evenietnisitemporibus.png?size=50x50&set=set2"} alt={stackData.stackPackage.stack_name} />
                {showQuickView && (
                    <IconButton 
                        className='stack-card-show-quickview'
                        style={{ position: 'absolute', top: -14, left: -14 }}
                        onClick={handleOpenQuickView}
                    >
                        <VisibilityIcon style={{fontSize:'16'}}/>
                    </IconButton>
                )}
            </Box>
            <Box className="stack-card-info">
                {showName && <Typography variant="h6" className="stack-name">{stackData.stackPackage.stack_name || 'N/A'}</Typography>}
                <Box className='stack-card-info-groups'>
                    <Box className="group1">
                        {showType && <Typography variant="body3" className="stack-type"> Type: {stackData.stackPackage.stack_type || 'N/A'}</Typography>}
                        {showPurpose && <Typography variant="body3" className="stack-purpose">Purpose: {stackData.stackPackage.stack_purpose || 'N/A'}</Typography>}
                        {showOfficial && <Typography variant="body3" className="stack-status"> Status: {stackData.stackPackage.stack_is_official ? 'Official' : 'Unofficial'}</Typography>}
                    </Box>
                    {showOwner && (
                        <Box className="group2">
                            <Typography variant="body3" className="stack-owner">Author: {stackData.userPackage.user_first_name || 'N/A'}</Typography>
                            <Typography variant="body3" className="stack-owner">Industry: {stackData.companyPackage.industry_name || 'N/A'}</Typography>
                            <Typography variant="body3" className="stack-owner">Sector: {stackData.companyPackage.sector_name || 'N/A'}</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box className="stack-actions">
                {showSocialButtons && (
                        <Box className="stack-card-actions">
                            <LikeButton entityId={stackId} userId={userId} entityType={entityType}/>
                            <FollowButton entityId={stackId} userId={userId} entityType={entityType}/>
                            <SaveButton entityId={stackId} userId={userId} entityType={entityType}/>
                            <ShareButton entityId={stackId} userId={userId} entityType={entityType}/>
                            <NopeButton entityId={stackId} userId={userId} entityType={entityType}/>
                            <CloneButton entityId={stackId} userId={userId} entityType={entityType}/>
                        </Box>
                    )}
            </Box>
            {quickViewOpen && <StackQuickView showCompany={showCompany} stackId={stackId} userId={userId} entityType={entityType} stackData={stackData} open={quickViewOpen} onClose={handleCloseQuickView} />}
        </Box>
    );
};

export default StackCard;
