import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const authState = useSelector((state) => state.auth); // 'auth' is the name of auth slice
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const isAuth = !!authState.user;
    setIsAuthenticated(isAuth);
    setUserId(isAuth ? authState.user.user_id : null);
  }, [authState.user]);

  return (
    <UserContext.Provider value={{ isAuthenticated, userId }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export default UserContext;
