import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Link } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { usePlanId } from '../../../../hooks/usePlanId';
import { fetchFeatureAvailability } from '../../../../features/users/featureavailability/featureAvailabilitySlice';
import './stackPageAwards.scss'

const StackPageAwards = ({ stackData, stackId, entityType }) => {
    const dispatch = useDispatch();
    const planId = usePlanId();
    const platformFeatureId = 43; // replace with the correct feature id
    const [isLoading, setIsLoading] = useState(true);
    const [isFeatureAvailable, setIsFeatureAvailable] = useState(false);

    const awards = stackData.awardPackage;

    useEffect(() => {
        dispatch(fetchFeatureAvailability({ planId, platformFeatureId }))
            .unwrap()
            .then((availability) => {
                setIsFeatureAvailable(availability.isFeatureAvailable);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Failed to fetch feature availability:', error);
                setIsLoading(false);
            });
    }, [dispatch, planId, platformFeatureId]);

    // If the feature is not available or the data is still loading, do not render the component
    if (!isFeatureAvailable || isLoading) {
        return null;
    }

    if (!awards || awards.length === 0) return null;

    return (
        <Box className="awards-container">
            <Box className="awards-display">
                {awards.slice(0, 2).map((award, index) => (
                    <Box key={index} className="award-item">
                        <img src={award.award_badge} alt={award.award_name} className="award-icon" />
                        <Typography className="award-name">{award.award_name}</Typography>
                    </Box>
                ))}
                
                <Box className="see-all-icon">
                    {awards.length > 2 && (
                        <Link href={`/stack/${stackData.stackPackage.stack_id}`}>
                            <ArrowUpwardIcon style={{ color: '#fff', transform: 'rotate(45deg)', fontSize: 'medium' }} />
                        </Link>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default StackPageAwards;