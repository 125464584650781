import React, { useEffect, useState } from 'react';
import { Box, Avatar } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import './productPageOwner.scss';

const ProductPageOwner = ({product_claimed, product_owner_company, product_owner_user, productId, showVendorAdmin = false }) => {
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    const userId = product_owner_user;
    
    useEffect(() => {
        const fetchUserData = async () => {
            if (!userId || !product_claimed) {
                console.log('Rendering without user ID or product is not claimed');
                return;
            }
    
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`);
                if (!response.ok) {
                    throw new Error(`Could not fetch user data`);
                }
                const data = await response.json();
                setUserData(data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError(error.toString());
            }
        };
    
        fetchUserData();
    }, [userId, product_claimed]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Box display="flex" flexDirection="column" className="product-owner-info-details">
            <Box display="flex" className="product-owner-detail">
                <Box className="product-claimed">
                    <VerifiedIcon color={product_claimed ? 'action' : 'disabled'} />
                    {product_claimed ? (
                        <span style={{ color: 'white' }}>claimed</span>
                    ) : (
                        <a href="./" style={{ color: 'white' }}>claim this product</a>
                    )}
                </Box>
                {showVendorAdmin && product_claimed && userData &&
                    <Box className="product-owner-admin">
                        <span style={{ color: 'white' }}>Admin:</span>
                        <Avatar className="product-owner-avatar" src={userData.user_profile_image} alt={`${userData.user_first_name} ${userData.user_last_name}`} sx={{ width: 20, height: 20 }} />
                        {userData.user_first_name}
                    </Box>
                }
            </Box>
            <Box className="product-detail">{product_owner_company}</Box>
        </Box>
    );
};

export default ProductPageOwner;