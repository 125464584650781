// src>charts>stack>productcountcompare>chartStackProductCountCompare.jsx

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApexCharts from 'react-apexcharts';
import { CircularProgress } from '@mui/material';
import { fetchStackProductCount } from '../../../features/charts/stack/stackpage/stackcount/stackProductCountSlice';
import './chartStackProductCountCompare';

const StackProductCountCompare = ({ stackId, companyId }) => {
    const dispatch = useDispatch();
    const { data, loading, error } = useSelector(state => state.stackProductCount);

    useEffect(() => {
        dispatch(fetchStackProductCount({ stackId, companyId }));
    }, [dispatch, stackId, companyId]);

    const totalProductCount = data?.product_count || 0;
    const maxProductCount = data?.max_product_count || 100; 
    const avgProductCount = data?.avg_product_count || 0;

    
    const series = [totalProductCount, avgProductCount, maxProductCount];

    const chartOptions = {
        chart: {
            type: 'radialBar',
            offsetY: -10,
        },
        plotOptions: {
            radialBar: {
                startAngle: -100,
                endAngle: 100,
                hollow: {
                    margin: 0,
                    size: '55%',
                    background: 'transparent',
                },
                track: {
                    background: '#ddd',
                    strokeWidth: '60%',
                    margin: 2, 
                },
                dataLabels: {
                    name: {
                        show: true,
                    },
                    value: {
                        offsetY: 15,
                        show: true,
                        fontSize: '42px',
                        formatter: function (val) {
                            return `${Math.round(val)} `;
                        },
                    },
                },
                max: maxProductCount  
            }
        },
        labels: ['Total', 'Average', 'Max'],
        colors: ['#20E647', '#FFC300', '#FF5733'],
        stroke: {
            lineCap: "butt",
        },
    };

    if (loading) return <CircularProgress />;
    if (error) return <div>Error: {error}</div>;

    return <ApexCharts options={chartOptions} series={series} type="radialBar" />;
};

export default StackProductCountCompare;
