// src/store/rootReducer.js

// Redundant Reducers
import productLogoReducer from '../features/products/productLogo/productLogoSlice';
import productBasicsReducer from '../features/products/basicsdisplay/productBasicsSlice';

// Active Reducers
import likesReducer from '../features/socials/likes/likeSlice';
import followsReducer from '../features/socials/follow/followSlice';
import savesReducer from '../features/socials/saves/saveSlice';
import shareReducer from '../features/socials/shares/shareSlice';
import addToReducer from '../features/socials/bookmark/bookmarkSlice';
import chatterReducer from '../features/socials/chatter/chatterSlice';
import compareReducer from '../features/socials/compare/compareSlice';
import trialsReducer from '../features/socials/trials/trialsSlice';
import nopeReducer from '../features/socials/nopes/nopeSlice';
import evaluatesReducer from '../features/socials/evaluate/evaluateSlice';
import usingReducer from '../features/socials/using/usingSlice';
import cloneReducer from '../features/socials/clone/cloneSlice';

import productQViewReducer from '../features/products/quickView/productQuickViewSlice';
import featureAvailabilityReducer from '../features/users/featureavailability/featureAvailabilitySlice';
import userAuthReducer from '../features/userauth/userAuthSlice';
import drawerReducer from '../features/platformbuttons/createnewdrawer/createNewDrawerSlice';
import productsReducer from '../features/products/productsslice/productsSlice';
import productPageReducer from '../features/products/productpage/productPageSlice';
import stackPageReducer from '../features/stacks/stackpage/stackPageSlice';
import userPageReducer from '../features/users/userpage/userPageSlice';
import temporaryIdReducer from '../features/stacks/temporaryId/temporaryIdSlice';
import newStackReducer from '../features/stacks/temporaryStackSlice/temporaryStackSlice';
import stackPurposeReducer from '../features/stacks/stackpurpose/stackPurposeSlice';
import stackProductCountReducer from '../features/charts/stack/stackpage/stackcount/stackProductCountSlice';
import productsByDomainReducer from '../features/charts/stack/stackpage/stackcount/stackProductByDomain';
import { combineReducers } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
    productBasics: productBasicsReducer,
    productLogo: productLogoReducer,
    productQView: productQViewReducer,
    featureAvailability: featureAvailabilityReducer,
    likes: likesReducer,
    follows: followsReducer,
    saves: savesReducer,
    share: shareReducer,
    addTo: addToReducer,
    chatter: chatterReducer,
    compare: compareReducer,
    trials: trialsReducer,
    nopes: nopeReducer,
    evaluates: evaluatesReducer,
    using: usingReducer,
    clone: cloneReducer,
    auth: userAuthReducer,
    drawer: drawerReducer,
    products: productsReducer,
    productPage: productPageReducer,
    stackPage: stackPageReducer,
    userPage: userPageReducer,
    tempstackid: temporaryIdReducer,
    newStack: newStackReducer,
    stackPurpose: stackPurposeReducer,
    stackProductCount: stackProductCountReducer,
    productsByDomain: productsByDomainReducer,
});

export default rootReducer;
