import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import apiFetch from '../../../../helpers/apifetch';
import { useNavigate } from 'react-router-dom';
import { fetchProducts } from '../../../../features/products/productsslice/productsSlice';
import ProductCategoryListing from '../../../../components/product/productlisting/categorylisting/productCategoryListing';
import ProductCategoryPagination from './categorypagepagination/productCategoryPagination';
import HeaderStreamed from '../../../../components/headerstreamed/HeaderStreamed';
import {Grid, Select, Box, MenuItem, FormControl} from '@mui/material';


import './categoryPageMain.scss';

const CategoryPageMain = ({token}) => {
    const dispatch = useDispatch();
    const {
        items: products,
        totalItems,
        loading,
        error,
    } = useSelector(state => state.products);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [fetchTrigger, setFetchTrigger] = useState(0);
    const navigate = useNavigate();
    const [domains, setDomains] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState('All');
    const [selectedCategory, setSelectedCategory] = useState('All');
    // const userId = useSelector(state => state.auth.user?.user_id);

    const fetchDomains = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/product/domains`);
        if (response.ok) {
            const data = await response.json();
            setDomains(['All', ...data.map(d => d.domain_name)]);
        }
    };

    const fetchCategories = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/product/categories`);
        if (response.ok) {
            const data = await response.json();
            setCategories(['All', ...data.map(c => c.category_name)]);
        }
    };

    useEffect(() => {
        fetchDomains();
        fetchCategories();
    }, []);

    useEffect(() => {
        dispatch(
            fetchProducts({
                page: currentPage,
                limit: itemsPerPage,
                domain: selectedDomain,
                category: selectedCategory,
            })
        );
    }, [dispatch, currentPage, itemsPerPage, selectedDomain, selectedCategory, fetchTrigger]);

    const handleDomainChange = e => setSelectedDomain(e.target.value);
    const handleCategoryChange = e => setSelectedCategory(e.target.value);

    const handlePageChange = newPage => {
        setCurrentPage(newPage);
        setFetchTrigger(prev => prev + 1); 
    };

    const handleItemsPerPageChange = e => {
        setItemsPerPage(Number(e.target.value));
        setFetchTrigger(prev => prev + 1); 
    };
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="product-category-page-main">
            <HeaderStreamed
                title="Category Page [testing]"
                subtitle="browse through products"
            />
            <Box className="product-filter-category-main">
                <div>
                    <div>Domain</div>
                    <select
                        value={selectedDomain}
                        label="Domain"
                        id="filter-domains"
                        onChange={handleDomainChange}
                    >
                        {domains.map(domain => (
                            <option key={domain} value={domain}>
                                {domain}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <div>Category</div>
                    <select
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    >
                        {categories.map(category => (
                            <option key={category} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </div>
            </Box>
            <Box className="new-stack-create-titles">
                <div style={{display: 'flex',flex: '2',justifyContent: 'center'}}>Example Category Listing</div>
            </Box>
            <Box className="main-category-grid">
                <Grid
                    className="filtered-products-grid"
                    container
                    spacing={1}
                    item
                    xs={12}
                    sm={12}
                >
                    <ProductCategoryListing products={products}/>
                </Grid>
            </Box>
            <Box className="product-category-pagingation">
                <ProductCategoryPagination
                    className="pagination-options"
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
                <div className="items-per-page" sx={{ display: 'flex' }}>
                    <FormControl>
                        <Select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            style={{ height: '36px' }}
                        >
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </Box>
        </div>
    );
};

export default CategoryPageMain;
