// src>App.js

import React, { Suspense, lazy }  from 'react'; 
import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider, CircularProgress } from '@mui/material';
import GlobalStyles from './styles/globalStyles.js';
import { Routes, Route } from 'react-router-dom';
import { UserProvider } from './contexts/userContext';
import ProtectedRoute from './components/protectedroutes/protectedRoute';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './styles/globalStyles.js';
import './styles/global.scss';
import TopMenu from './global/topmenu/topMenu.jsx';
import SideMenuNew from './global/sidemenu/sidemenunew.jsx';
import NewStackMain from './runnercore/NewStack/newStackMain.jsx';
import NewStackStepper from './runnercore/createstack/createnewstack/newstackstepper/newStackStepper';
import NewStackInfo from './runnercore/createstack/createnewstack/newstackinfo/newStackInfo';
import NewStackSelect from './runnercore/createstack/createnewstack/newstackselect/newStackSelect';
import BrowseFilteredStacks from './scenes/listings/stacklistingpage/browseFilteredStacks.jsx';
import CategoryPageMain from './scenes/listings/productlistingpage/categorypage/categoryPageMain.jsx';
import ThemedComponents from './tests/themedcomponents.jsx';
import Home from './scenes/home/home';
import Login from './global/login/login.jsx';
import Register from './global/register/registernewuser.jsx';
import FeedPageMain from './feed/feedpage/feedPageMain.jsx';
import PlansPage from './global/morepages/planspage/plansPage.jsx';
import AboutUsPage from './global/morepages/aboutpage/aboutPage.jsx';
import CommunityPageMain from './global/morepages/communitypage/communityPage.jsx';
import NewFeaturesPage from './global/morepages/feedbackpage/newFeaturesPage.jsx';
import Waitlist from './global/waitlist/waitlistuser';
import WaitlistRedirect from './global/waitlist/waitlistredirect/waitlistRedirect';
import UserAccountMain from './userprofile/userAccount/useraccountmain/userAccountMain';
import UserPublicProfileMain from './userprofile/userPublicProfile/userpublicprofilemain/userPublicProfileMain';
import ChartDataContext from './contexts/chartDataContext';
// import StackPagesMain from './runnercore/viewstack/stackpages/stackpagemain/stackPagesMain';
// import ProductPageMain from './products/productPages/productsPageMain/productPageMain';

const StackPagesMain = lazy(() =>
    import('./runnercore/viewstack/stackpages/stackpagemain/stackPagesMain')
);
const ProductPageMain = lazy(() =>
    import('./products/productPages/productsPageMain/productPageMain')
);

function App() {
    const [theme, colorMode] = useMode();
    
    const [chartData, setChartData] = React.useState(null);

    return (
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <GlobalStyles theme={theme} />
                    <CssBaseline />
                    <DndProvider backend={HTML5Backend}>
                        <UserProvider>
                            <div className="app">
                                <TopMenu />
                                <SideMenuNew />
                                <main className="content">
                                    <ChartDataContext.Provider
                                        value={{ chartData, setChartData }}
                                    >
                                        <Routes>
                                            <Route path="/" element={<Home />} />
                                            <Route
                                                path="/waitlist"
                                                element={<Waitlist />}
                                            />
                                            <Route
                                                path="/waitlist-confirm"
                                                element={<WaitlistRedirect />}
                                            />
                                            <Route
                                                path="/elements"
                                                element={<ThemedComponents />}
                                            />
                                            <Route
                                                path="/login"
                                                element={<Login />}
                                            />
                                            <Route
                                                path="/register"
                                                element={<Register />}
                                            />
                                            <Route
                                                path="/feed"
                                                element={<FeedPageMain />}
                                            />
                                            <Route
                                                path="/plans"
                                                element={<PlansPage />}
                                            />
                                            <Route
                                                path="/about"
                                                element={<AboutUsPage />}
                                            />
                                            <Route
                                                path="/be-a-stackrunner"
                                                element={<CommunityPageMain />}
                                            />
                                            <Route
                                                path="/new-features"
                                                element={<NewFeaturesPage />}
                                            />
                                            <Route
                                                path="/user/:userId"
                                                element={
                                                    <ProtectedRoute>
                                                        <UserAccountMain />
                                                    </ProtectedRoute>
                                                }
                                            />
                                            <Route
                                                path="/browse/stacks"
                                                element={<BrowseFilteredStacks />}
                                            />
                                            <Route
                                                path="/create/newstack/stepper"
                                                element={<NewStackStepper />}
                                            />
                                            <Route
                                                path="/create/newstack/mode"
                                                element={<NewStackInfo />}
                                            />
                                            <Route
                                                path="/create/newstack/select"
                                                element={<NewStackSelect />}
                                            />
                                            <Route
                                                path="/user/profile/:userId"
                                                element={<UserPublicProfileMain />}
                                            />
                                            <Route 
                                                path="/stack/create/new"
                                                element={<NewStackMain />}
                                            />
                                            <Route 
                                                path="/category/all"
                                                element={<CategoryPageMain />}
                                            />
                                            {/* <Route 
                                                path="/products/:domain/:category/:subCategory/:productId/:productName"
                                                element={<ProductPageMain />} 
                                            /> */}
                                            <Route
                                                path="/products/:domain/:category/:subCategory/:productId/:productName"
                                                element={
                                                    <Suspense
                                                        fallback={
                                                            <CircularProgress />
                                                        }
                                                    >
                                                        <ProductPageMain />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="/stack/profile/:stackId"
                                                element={
                                                    <Suspense
                                                    fallback={
                                                        <CircularProgress />
                                                        }
                                                        >
                                                        <StackPagesMain />
                                                        </Suspense>
                                                    }
                                                />
                                        </Routes>
                                    </ChartDataContext.Provider>
                                </main>
                            </div>
                        </UserProvider>
                    </DndProvider>
                </ThemeProvider>
            </ColorModeContext.Provider>
    );
}

export default App;
