// features/compare/compareSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const compareSlice = createSlice({
  name: 'compare',
  initialState: {
    compareDrawerOpen: false,
    comparisonSet: [],
  },
  reducers: {
    toggleCompareDrawer: (state, action) => {
      state.compareDrawerOpen = action.payload;
    },
    addToComparisonSet: (state, action) => {
      // Assuming there's a limit of 4 items by default
      if (state.comparisonSet.length < 4 || action.payload.forceAdd) {
        state.comparisonSet.push(action.payload.entityId);
      }
    },
    removeFromComparisonSet: (state, action) => {
      state.comparisonSet = state.comparisonSet.filter(id => id !== action.payload);
    },
    clearComparisonSet: (state) => {
      state.comparisonSet = [];
    },
  },
});

export const {
  toggleCompareDrawer,
  addToComparisonSet,
  removeFromComparisonSet,
  clearComparisonSet,
} = compareSlice.actions;

export default compareSlice.reducer;
