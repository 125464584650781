import React, { useContext, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom'; 
import { Box, Button, Badge, IconButton, Typography, AppBar, Toolbar, MenuItem, Menu, useTheme } from '@mui/material';
import { ColorModeContext } from "../../theme";
import { ReactComponent as SRBrandName3 } from "../../assets/placeholders/SR-brandName3.svg";
import { ReactComponent as SRLogo } from "../../assets/placeholders/SR-logo.svg";
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CreateNewButton from "../../components/platformbuttons/createnew/createNewButton";
import AccountWidget from '../../components/platformbuttons/accountwidget/accountWidget';
import './topMenu.scss';


const TopMenu = ({ onSideMenuClick }) => {
    const theme = useTheme();
    const { toggleColorMode } = useContext(ColorModeContext);
    const navigate = useNavigate(); 
    const user = useSelector(state => state.auth.user); 
    const isLoggedIn = Boolean(user);
    const [anchorElStacks, setAnchorElStacks] = useState(null);
    const [anchorElProducts, setAnchorElProducts] = useState(null);
    const [anchorElMore, setAnchorElMore] = useState(null);
    

    const handleStacksOpen = useCallback((event) => {
        setAnchorElStacks(event.currentTarget);
        setAnchorElProducts(null);
        setAnchorElMore(null);
    }, []);

    const handleProductsOpen = useCallback((event) => {
        setAnchorElProducts(event.currentTarget);
        setAnchorElStacks(null);
        setAnchorElMore(null);
    }, []);

    const handleMoreOpen = useCallback((event) => {
        setAnchorElMore(event.currentTarget);
        setAnchorElStacks(null);
        setAnchorElProducts(null);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorElStacks(null);
        setAnchorElProducts(null);
        setAnchorElMore(null);
    }, []);

    const handleRedirect = () => {
        navigate('./products/marketing/seo/undefined/103/wordlift', '_blank');
    };
    //to be changed back to /register
    const handleRegister = () => {
        navigate('/waitlist'); 
    }
    const handleLogin = () => {
        navigate('/login'); 
    }

    return (
        <Box 
            className='topMenu-main-container' 
            sx={{ 
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.default, 
            }}
        >
            <Box 
                className='topMenu-appbar-container' 
                sx={{ 
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.default, 
                }}
            >
                <AppBar
                    position="static"
                    sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.default, boxShadow: 'none'}}> 
                    <Toolbar 
                        className='main-menu-toolbar'
                        sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.default, boxShadow:'none'}} 
                    >
                        <Box className='topMenu-branding'>
                            <Box className="logoIcon" >
                                <Link to="./">
                                    <SRLogo />
                                </Link>
                            </Box>
                            <div className="brandMark"> 
                                <SRBrandName3 /> 
                                <Badge className="topMenu-branding-version">Alpha v0.1</Badge>
                            </div>
                        </Box>
                        <nav className='main-menu-new' display='flex'>
                            <Typography className='menu-feed' 
                                        variant="h6" 
                                        component={Link} 
                                        to="/feed"
                                        sx={{ flexGrow: 1 , display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
                                    >
                                Feed
                            </Typography>
                            <Box className='menu-stacks' 
                                component="div" 
                                sx={{ flexGrow: 1 , display: 'flex', alignItems: 'center'}} 
                                onMouseEnter={handleStacksOpen} 
                                onMouseLeave={handleClose}
                            >
                                <Typography variant='h6' className='open-submenu-on-hover'>Stacks</Typography>
                                {anchorElStacks ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorElStacks}
                                    keepMounted
                                    open={Boolean(anchorElStacks)}
                                    onClose={handleClose}
                                    MenuListProps={{ onMouseLeave: handleClose }}
                                >
                                    <MenuItem onClick={handleClose}>
                                        <Link className='sub-mainMenu' to="/stack/profile/42" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Sample Stack #42
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        <Link to="/browse/stacks" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Browse Stacks
                                        </Link>
                                    </MenuItem>
                                    {/* <MenuItem onClick={handleClose}>
                                        <Link className='sub-mainMenu' to="/userstack/44" target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            userstack #44
                                        </Link>
                                    </MenuItem> */}
                                </Menu>
                            </Box>
                            <Box className='menu-products'
                                component="div" 
                                sx={{ flexGrow: 1 , display: 'flex', alignItems: 'center'}} 
                                onMouseEnter={handleProductsOpen} 
                                onMouseLeave={handleClose}
                            >
                                <Typography variant='h6'>Products</Typography>
                                {anchorElProducts ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorElProducts}
                                    keepMounted
                                    open={Boolean(anchorElProducts)}
                                    onClose={handleClose}
                                    MenuListProps={{ onMouseLeave: handleClose }}
                                >
                                    <MenuItem onClick={handleRedirect}>Sample Product Page</MenuItem>
                                    {/* <MenuItem onClick={handleClose}>Browse Products</MenuItem>
                                    <MenuItem onClick={handleClose}>Newly Added</MenuItem> */}
                                    <MenuItem onClick={handleClose}>
                                        <Link className='sub-mainMenu' to="/category/all" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Category Page
                                        </Link>
                                    </MenuItem>
                                </Menu>
                            </Box>
                            <Box className='menu-more' 
                                variant="h6" 
                                component="div" 
                                sx={{ flexGrow: 1 , display: 'flex', alignItems: 'center'}} 
                                onMouseEnter={handleMoreOpen} 
                                onMouseLeave={handleClose}
                            >
                                <Typography variant='h6'>More</Typography>
                                {anchorElMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorElMore}
                                    keepMounted
                                    open={Boolean(anchorElMore)}
                                    onClose={handleClose}
                                    MenuListProps={{ onMouseLeave: handleClose }}
                                >
                                    <MenuItem onClick={handleClose}>
                                        <Link to="/plans" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Plans
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            About
                                        </Link>
                                    </MenuItem>
                                    {/* <MenuItem onClick={handleClose}>
                                        <Link to="/be-a-stackrunner" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Why join
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        <Link to="/new-features" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Releases
                                        </Link>
                                    </MenuItem> */}
                                </Menu>
                            </Box>
                        </nav>
                        <Box className='topMenu-user-actions'>
                            {!isLoggedIn && (
                                <IconButton 
                                    sx={{ 
                                        color: theme.palette.mode === 'dark' ? 'inherit' : 'black', 
                                    }} 
                                    onClick={toggleColorMode}
                                >
                                    {theme.palette.mode === 'dark' ? <DarkModeOutlinedIcon/> : <LightModeOutlinedIcon />}
                                </IconButton>
                            )}
                            <Box className="loginSignupContainer">
                                {isLoggedIn ? (
                                    <Box className="action-items">            
                                        <Box className="nav-menu-new-button">
                                            <CreateNewButton />
                                        </Box>
                                        <Box className="alerts-container">
                                            <IconButton color="inherit" aria-label='account notifications'>
                                                <Badge badgeContent={5} color="error">
                                                    <NotificationsOutlinedIcon />
                                                </Badge>
                                            </IconButton>
                                        </Box>
                                        <AccountWidget user={user}/>
                                    </Box>
                                ) : (
                                    <>
                                        <Box className='topMenu-access-buttons'>
                                            <Button variant='login' onClick={handleLogin}>Login</Button>
                                            <Button variant='register' onClick={handleRegister} >Register</Button> 
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
        </Box>
    );

}

export default TopMenu;