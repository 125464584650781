import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip, Box, Typography, useTheme } from '@mui/material';
import { fetchFeatureAvailability } from '../../../features/users/featureavailability/featureAvailabilitySlice';
import ShareIcon from '@mui/icons-material/Share';
import { usePlanId } from '../../../hooks/usePlanId';
import { fetchShareCount, fetchShareState, toggleShare, } from '../../../features/socials/shares/shareSlice';
import './shareButton.scss';

const ShareButton = ({
    entityType,
    entityId,
    userId,
    showCount = true,
    flexDirection = 'row',
    showTooltip = true,
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const planId = usePlanId();
    const platformFeatureId = 20;
    const [isLoading, setIsLoading] = useState(true);
    const [isFeatureAvailable, setIsFeatureAvailable] = useState(false);

    const shareCountId = `${entityType}_${entityId}`;
    const shareId = `${entityType}_${entityId}_${userId}`;
    const shareCount = useSelector(
        state => state.share?.counts?.[shareCountId] || 0
    );
    const isShared = useSelector(
        state => state.share?.byId?.[shareId]?.shared || false
    );
    
    useEffect(() => {
        dispatch(fetchFeatureAvailability({ planId, platformFeatureId }))
            .unwrap()
            .then((availability) => {
                setIsFeatureAvailable(availability.isFeatureAvailable);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Failed to fetch feature availability:', error);
                setIsLoading(false);
            });
    }, [dispatch, planId, platformFeatureId]);

    useEffect(() => {
        if (entityType && entityId && userId) {
            dispatch(fetchShareCount({ entityType, entityId })).unwrap();
            dispatch(fetchShareState({ entityType, entityId, userId })).unwrap();
        }
    }, [dispatch, entityType, entityId, userId]);

    const handleShare = () => {
        if (!userId) {
            console.log('Please log in or register to share.');
            return;
        }
        if (userId) {
            dispatch(toggleShare({ entityType, entityId, userId })).then(
                () => {
                    const updatedShareCount = isShared
                        ? shareCount - 1
                        : shareCount + 1;
                    dispatch({
                        type: 'shares/updateShareCount',
                        payload: { shareCountId, count: updatedShareCount },
                    });
                }
            );
        } else {
            console.log('Please log in or register to like this.');
        }
    };

    // If the feature is not available or the data is still loading, do not render the component
    if (!isFeatureAvailable || isLoading) {
        return null;
    }

    const boxStyle = {
        backgroundColor: theme.palette.neutral.light,
        color: theme.palette.getContrastText(theme.palette.neutral.light),
        boxshadow: `0px 4px 4px ${theme.palette.boxshadow.default}`,
        borderRadius: '5px',
        margin: '0px',
        padding: '0px',
    };

    const button = (
        <IconButton
            sx={{
                background: 'transparent',
                border: 'none',
                color: isShared
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                '&.Mui-disabled': {
                    color: theme.palette.primary.main,
                },
                '&:disabled': {
                    cursor: 'default',
                },
            }}
            onClick={handleShare}
            aria-label="share"
            disabled={!userId}
        >
            <ShareIcon />
        </IconButton>
    );

    return (
        <Box
            style={boxStyle}
            sx={{
                flexDirection: flexDirection,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                margin: '0px',
                padding: '0px',
            }}
        >
            {showTooltip ? (
                <Tooltip
                    title={`${shareCount || 0} Shares`}
                    placement="top"
                    arrow
                >
                    <div>{button}</div>
                </Tooltip>
            ) : (
                button
            )}
            {showCount && <Typography 
                sx={{
                display: 'flex',
                width: 'auto',
                justifyContent: 'center',
                marginRight: '8px',
                marginLeft: flexDirection === 'row' ? '8px' : '0px',
                marginTop: flexDirection === 'column' ? '4px' : '0px',
                fontSize: '16px',
                fontweight: '700',
                textShadow: '0px 0px 1px black',
                }}
            >
                {shareCount || 0}
            </Typography>}
        </Box>
    );
};

export default ShareButton;
