import React from 'react';
import GaugeChart from 'react-gauge-chart';
import { Box } from '@mui/material';


const ProductSimpleFeatureGauge = ({
    productData,
    feature,
    showFeatureTitle = false,
    width = '120px',
    height,
    hideText = true,
    animDelay = 2000,
    needleColor = '#474747',
    textColor = '#9b9b9b',
    nrOfLevels = 2,
    arcPadding = 0.02,
    arcWidth = 0.2,
    cornerRadius = 1,
    formatTextValue = value => value,
}) => {
    const percent = productData[feature] ? 1 : 0;
    return (
        <Box 
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            {showFeatureTitle && <p>{feature}</p>}
            <Box style={{ width, height }}>
                <GaugeChart
                    id={`gauge-chart-${feature}`}
                    nrOfLevels={nrOfLevels}
                    percent={percent}
                    textColor={textColor}
                    needleColor={needleColor}
                    colors={['#FF0000', '#00FF00']}
                    hideText={hideText}
                    animDelay={animDelay}
                    arcPadding={arcPadding}
                    arcWidth={arcWidth}
                    cornerRadius={cornerRadius}
                    formatTextValue={formatTextValue}
                />
            </Box>
        </Box>
    );
};

export default ProductSimpleFeatureGauge;
