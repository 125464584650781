// features/featureAvailabilitySlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


// Async thunk to fetch feature availability for a user
export const fetchFeatureAvailability = createAsyncThunk(
    'features/fetchFeatureAvailability',
    async ({ planId, platformFeatureId }, thunkAPI) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/features/availability/${platformFeatureId}?planId=${planId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                return thunkAPI.rejectWithValue('Failed to fetch feature availability');
            }
  
            const data = await response.json();
  
            return data; 
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const featureAvailabilitySlice = createSlice({
    name: 'features',
    initialState: {
        availability: {},
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFeatureAvailability.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFeatureAvailability.fulfilled, (state, action) => {
                state.availability = action.payload;
                state.loading = false;
            })
            .addCase(fetchFeatureAvailability.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default featureAvailabilitySlice.reducer;
