import React from 'react';
import { Box, Typography, useTheme, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import './FeedComponent.scss';

const FeedComponent = ({
    icon,
    title,
    subtitle,
    sideText,
    width,
    showButton,
    link,
    platform,
    children,
    shadow = 'default',
}) => {
    const theme = useTheme();

    const handleButtonClick = () => {
        if (link) {
            if (platform === 'internal') {
                window.location.href = link;
            } else {
                window.open(link, '_blank');
            }
        }
    };

    return (
        <Box
            className="box-component-main-container"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gridColumn: `span ${width}`,
                padding: '0.5rem',
                backgroundColor: theme.palette.background.default,
                boxShadow: `0.1rem 0.1rem 0.1rem 0.01rem ${theme.palette.boxshadow[shadow]}`,
                border: `0.5px solid ${theme.palette.neutral.main}`,
                borderRadius: '0.2rem',
            }}
        >
            <Box className="box-component-outer-box">
                {showButton && (
                    <IconButton
                        className="info-button"
                        onClick={handleButtonClick}
                        sx={{
                            borderRadius: '5px',
                            border: `0.5px solid ${theme.palette.neutral.main}`,
                            '&:hover': {
                                backgroundColor: theme.palette.background.hover,
                                cursor: 'pointer',
                            },
                        }}
                    >
                        <ArrowUpwardIcon
                            style={{
                                color: 'inherit',
                                transform: 'rotate(45deg)',
                                fontSize: 'inherit',
                            }}
                        />
                    </IconButton>
                )}
                {icon && <Box className="icon-box">{icon}</Box>}
                <Box className="content-box">
                    <Typography variant="h5" mb="-0.1rem">
                        {title}
                    </Typography>
                    <Typography variant="body2" margin="5px 0">
                        {subtitle}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    padding="0 5px"
                    marginRight="20px"
                    color="grey"
                >
                    {typeof sideText === 'string' ? (
                        <Typography variant="h5" fontWeight="700">
                            {sideText}
                        </Typography>
                    ) : (
                        sideText
                    )}
                </Box>
            </Box>
            <Box className="box-component-child">{children}</Box>
        </Box>
    );
};

FeedComponent.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    value: PropTypes.string,
    width: PropTypes.number,
    sideText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    showButton: PropTypes.bool,
    link: PropTypes.string,
    platform: PropTypes.string,
};

export default FeedComponent;
