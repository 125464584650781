// src>components/product/productpage/productpagesocialactions/productPageSocialActions.jsx

import React from 'react';
import { Box, Tooltip } from '@mui/material';
import LikeButton from '../../../socials/likes/likeButton';
import FollowButton from '../../../socials/follow/followButton';
import SaveButton from '../../../socials/saves/saveButton';
import ShareButton from '../../../socials/shares/shareButton';
import TrialButton from '../../../socials/trialling/trialButton';
import NopeButton from '../../../socials/nope/nopeButton';
import EvaluateButton from '../../../socials/evaluate/evaluateButton';
import UsingButton from '../../../socials/using/usingButton';
import './productQuickViewSocialActions.scss';


const ProductQuickViewSocialActions = ({entityType ='product', productId, userId}) => {
  const entityId = productId; 
  const buttonClass = userId ? 'testing-new logged-in' : 'testing-new no-pointer-events';
  
  if (!entityType || !entityId) {
    return <div>Can't load actions bar...</div>;
  }

  const actionsBar = (
    <Box className="actions-bar">
      <Box className="user-actions">
        <Box className={buttonClass}><LikeButton entityType={entityType} entityId={entityId} userId={userId}/></Box>
        <Box className={buttonClass}><FollowButton entityType={entityType} entityId={entityId} userId={userId} /></Box>
        <Box className={buttonClass}><SaveButton entityType={entityType} entityId={entityId} userId={userId} showCount={false}/></Box>
        <Box className={buttonClass}><TrialButton entityType={entityType} entityId={entityId} userId={userId} showCount={false}/></Box>
        <Box className={buttonClass}><NopeButton entityType={entityType} entityId={entityId} userId={userId} showCount={false} /></Box>
        <Box className={buttonClass}><EvaluateButton entityType={entityType} entityId={entityId} userId={userId} showCount={false}/></Box>
        <Box className={buttonClass}><ShareButton entityType={entityType} entityId={entityId} userId={userId} showCount={false}/></Box>
        <Box className={buttonClass}><UsingButton entityType={entityType} entityId={entityId} userId={userId} showCount={false} showText={false}/></Box>
      </Box>
    </Box>
  );

  return (
    <Box className='productQuickViewSocialActions-main'>
      {userId ? actionsBar : <Tooltip title="Login to take to use actions" placement="top">{actionsBar}</Tooltip>}
    </Box>
  );
};

export default ProductQuickViewSocialActions;