import React from 'react';
import { Box, Chip, Typography } from "@mui/material";
import TagIcon from '@mui/icons-material/Tag';
import productsData from '../../../../../data/products_.json';
import BoxComponent from '../../../../../global/dashboards/boxcomponent/BoxComponent';
import PopularProductsWidget from '../../../../../global/dashboards/popularproducts/popularProductsWidget';
// import BasicsDisplay from '../../../../../components/product/basicDisplay/basicsDisplay';
import './userPublicInterests.scss';

// Dummy data for Topics and Categories
const topicsData = [
    { label: "Generative AI", href: "#generative-ai" },
    { label: "CRM", href: "#crm" },
];

const categoriesData = [
    { label: "HR Software", href: "#hr-software" },
];

const UserInterestsPublic = () => {
    const displayedProducts = productsData.slice(22, 25);
    
    return (
        <div className='user-interests-public-profile'>
            <InterestSection title="Topics" chipsData={topicsData} />
            <InterestSection title="Categories" chipsData={categoriesData} />
            <ProductsSection products={displayedProducts} />
        </div>
    );
};

const InterestSection = ({ title, chipsData }) => (
    <Box className="interest-section">
        <Typography variant="h6">{title}</Typography>
        <Box className='chips-display'>
            {chipsData.map((chip, index) => (
                <Chip
                    key={index}
                    icon={<TagIcon />}
                    label={chip.label}
                    component="a"
                    href={chip.href}
                    variant="outlined"
                    clickable
                />
            ))}
        </Box>
    </Box>
);

const ProductsSection = () => (
    <Box className="interest-section">
        <Typography variant="h6">Products</Typography>
        <BoxComponent
            title="Popular Product again"
            // subtitle="get from Account > Feed > Topics/Categories/Interests"
            width={12}
        >
            <PopularProductsWidget/>
        </BoxComponent>
        {/* <Typography variant="body1" className="see-all-link">see all</Typography> */}
    </Box>
);

export default UserInterestsPublic;
