import React from "react";
import { Box } from "@mui/material";
import HeaderStreamed from "../../../../components/headerstreamed/HeaderStreamed";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import BoxComponent from '../../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../../global/dashboards/boxcomponent/BoxComponent';
import ImageFiller from 'react-image-filler';
import  "./personalaiViewStack.scss";

const PersonalaiViewStack = () => {

  return(
    <Box className="streamed-tab-component-outer" >
      <HeaderStreamed title="Personal AI Assist" subtitle="AI Interaction on stack"/>
      <Box className="streamed-tab-component-container-main">
        
        <Box className="streamed-tab-component-grid-and-side" >
          <Box className= "streamed-tab-component-grid-main">
            <BoxComponent 
              title="Box 1"
              subtitle="This is box 1"
              sideText="10000"
              icon={<DownloadOutlinedIcon/>}
              width={12}
              showButton={true}
              link="https://www.google.com"  
            >
              {/* <LineChart /> */}
            </BoxComponent>
            <BoxComponent title="Box 2" subtitle="This is box 2" sideText="10" width={12} />
            <BoxComponent title="Box 3" width={12}  />
            <BoxComponent title="Box 4" width={4} />
            <BoxComponent title="Box 5" width={4} />
            <BoxComponent title="Box 6" width={4} />
            <BoxComponent title="Box 7" subtitle="This is box 7" sideText="70" width={3} />
            <BoxComponent title="Box 8" subtitle="This is box 8" sideText="80" width={3} />
            <BoxComponent title="Box 9" subtitle="This is box 9" sideText="90" width={6} />
            <BoxComponent title="Box 10" width={2} />
            <BoxComponent title="Box 11" width={2} />
            <BoxComponent title="Box 12" width={8} />
          </Box>
          <Box className="streamed-tab-component-right-panel" >
          <SideBarComponent 
              title="Box A"
              subtitle="This is box A"
              sideText="10"
              showButton={true}
              link="https://www.google.com"
                
            >
              <div><ImageFiller width={280} height={200}/></div>
            </SideBarComponent>
            <SideBarComponent title="Box B" subtitle="This is box B" sideText="160" />
            <SideBarComponent title="Box C" subtitle="This is box C" sideText="160" />
          </Box>
        </Box>
        </Box>
    </Box>
  )
}

export default PersonalaiViewStack;