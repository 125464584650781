import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Confetti from 'react-dom-confetti';
import './waitlistRedirect.scss';

const WaitlistRedirect = () => {
    const [waitlistCount, setWaitlistCount] = useState(0);
    const [celebrate, setCelebrate] = useState(false); 
    const navigate = useNavigate();
    const location = useLocation();
    const baseCount = 156; 

    const config = { 
        angle: 90,
        spread: 360,
        startVelocity: 20,
        elementCount: 70,
        dragFriction: 0.12,
        duration: 3000,
        stagger: 3,
        width: "10px",
        height: "10px",
        perspective: "500px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
    };


    useEffect(() => {
        const fetchWaitlistCount = async () => {
            if (!location.state) {
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/waitcount`);
                const data = await response.json();

                if (response.ok) {
                    setWaitlistCount(data.count + baseCount);
                    setCelebrate(true); 
                } else {
                    console.error('Failed to fetch waitlist count:', data.message);
                }
            } catch (error) {
                console.error('Failed to send request:', error);
            }
        };

        fetchWaitlistCount();
    }, [location.state]);

    if (!location || !location.state) {
        return (
            <Box className='waitlist-redirect-join'>
                <Button className='waitlist-redirect-join-button' variant="contained" color="primary" onClick={() => navigate('/waitlist')}>
                    Join the waitlist
                </Button>
            </Box>
        );
    }

    const user_type = location.state.user_type;
    const isAlphaTester = user_type.includes('alpha');
    const isAngel = user_type.includes('angel');

    return (
        <Box className='waitlist-redirect-success'>
            <Confetti active={celebrate} config={config} />
            <Box >
                <Box className='waitlist-redirect-counter-title'>Thanks for your early interest!</Box>
                <Box className='waitlist-redirect-counter'>
                    <Box>You're on the list:</Box>
                    <Box className='waitlist-redirect-counter-number'># {waitlistCount}</Box>
                </Box>
            </Box>
            <Box display='flex' flexDirection='column' alignItems='center'>
                <p>There's still some work to do and product details to load into stackrunner.</p>
                <p>We'll email with login details when you're ready for launch. Watch your inbox!</p>
            </Box>
            {isAlphaTester && (
                <Box className='waitlist-redirect-alpha'>
                    <h4>Future Alpha Tester</h4>
                    <p>We'll be in touch as soon as we're ready for early testers like you.</p>
                </Box>
            )}
            {isAngel && (
                <Box className='waitlist-redirect-angel'>
                    <h4>Potential Angels</h4>
                    <p>We're busy building out the stackrunner content pipeline.</p>
                    <p>With stackrunner being released to early testers, we want to engage with potential Angels.</p>
                    <p>We'll reach out as soon as we're ready.</p>
                </Box>
            )}
        </Box>
    );
};

export default WaitlistRedirect;