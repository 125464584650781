import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import './styles/global.scss';
//import { light } from "@mui/material/styles/createPalette";

function hexToRgba(hex, alpha = 1) {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
}

// color design tokens
export const tokens = (mode) => ({
    ...(mode === "dark"
      ? {
          grey: {
            100: "#e0e0e0",
            200: "#c2c2c2",
            300: "#a3a3a3",
            400: "#858585",
            500: "#666666",
            600: "#525252",
            700: "#3d3d3d",
            800: "#292929",
            900: "#141414",
          },
          primary: {
            100: "#d0d1d5",
            200: "#a1a4ab",
            300: "#727681",
            400: "#1F2A40",
            500: "#141b2d",
            600: "#101624",
            700: "#0c101b",
            800: "#080b12",
            900: "#040509",
          },
          greenAccent: {
            100: "#dbf5ee",
            200: "#b7ebde",
            300: "#94e2cd",
            400: "#70d8bd",
            500: "#4cceac",
            600: "#3da58a",
            700: "#2e7c67",
            800: "#1e5245",
            900: "#0f2922",
          },
          redAccent: {
            100: "#f8dcdb",
            200: "#f1b9b7",
            300: "#e99592",
            400: "#e2726e",
            500: "#db4f4a",
            600: "#af3f3b",
            700: "#832f2c",
            800: "#58201e",
            900: "#2c100f",
          },
          blueAccent: {
            100: "#e1e2fe",
            200: "#c3c6fd",
            300: "#a4a9fc",
            400: "#868dfb",
            500: "#6870fa",
            600: "#535ac8",
            700: "#3e4396",
            800: "#2a2d64",
            900: "#151632",
          },
        }
      : {
          grey: {
            100: "#141414",
            200: "#292929",
            300: "#3d3d3d",
            400: "#525252",
            500: "#666666",
            600: "#858585",
            700: "#a3a3a3",
            800: "#c2c2c2",
            900: "#e0e0e0",
          },
          primary: {
            100: "#040509",
            200: "#080b12",
            300: "#0c101b",
            400: "#f2f0f0", // manually changed
            500: "#141b2d",
            600: "#1F2A40",
            700: "#727681",
            800: "#a1a4ab",
            900: "#d0d1d5",
          },
          greenAccent: {
            100: "#0f2922",
            200: "#1e5245",
            300: "#2e7c67",
            400: "#3da58a",
            500: "#4cceac",
            600: "#70d8bd",
            700: "#94e2cd",
            800: "#b7ebde",
            900: "#dbf5ee",
          },
          redAccent: {
            100: "#2c100f",
            200: "#58201e",
            300: "#832f2c",
            400: "#af3f3b",
            500: "#fe0844", // manually changed to SR Red
            600: "#e2726e",
            700: "#e99592",
            800: "#f1b9b7",
            900: "#f8dcdb",
          },
          blueAccent: {
            100: "#151632",
            200: "#2a2d64",
            300: "#3e4396",
            400: "#535ac8",
            500: "#6870fa",
            600: "#868dfb",
            700: "#a4a9fc",
            800: "#c3c6fd",
            900: "#e1e2fe",
          },
        }),
});

//SR Red: #FE0844
// SR Black: #0A0619
// Color Palette Option 1:
//Background Color: #F3EFE7 (Light Grayish Brown) - This will provide a warm and inviting backdrop that contrasts nicely with all the colors in the palette.
// Background Color: #E9F2F9 (Very Pale Blue) - This cooler background color will pair well with the Sea Green and Orange in this palette.
// Off-White: #FEF9F3 (Very Pale Orange) - Complements the gold reward color and the vibrant pink primary color.
// Off-White: #FAFAF8 (Very Pale Green) - This subtly green-tinted off-white complements the sea green tertiary color.

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode);
    return {
      palette: {
        common: {
          black: "#000000",
          white: "#ffffff",
          red: "#FE0844",
        },
        mode: mode,
        ...(mode === "dark"
          ? {
              // palette values for dark mode
              primary: {
                dark: colors.primary[900],
                main: colors.primary[700],
                light: colors.grey[300],
              },
              secondary: {
                main: colors.greenAccent[500],
                dark: colors.greenAccent[800],
                light: colors.greenAccent[200],
              },
              neutral: {
                dark: colors.grey[700],
                main: colors.grey[500],
                light: colors.grey[100],
              },
              background: {
                default: colors.primary[700],
                hover: colors.greenAccent[500],
                action: colors.blueAccent[700]
              },
              border: {
                default: colors.grey[100],
                primary: colors.blueAccent[500],
                secondary: colors.greenAccent[500],
              },
              boxshadow: {
                default: hexToRgba(colors.grey[300], 0.3),
                primary: hexToRgba(colors.grey[800], 0.5),
                button: hexToRgba(colors.redAccent[500], 0.5),
                home: hexToRgba(colors.greenAccent[800], 0.7),
              },
              warning: {
                dark: colors.redAccent[300],
                main: colors.redAccent[500],
                light: colors.redAccent[800],
              },
              text: {
                primary: colors.grey[100],
                secondary: colors.greenAccent[500],
                tertiary: colors.grey[300],
              },
            }
          : {
              // palette values for light mode
              primary: {
                dark: colors.grey[300],
                main: colors.primary[900],
                light: colors.primary[900],
              },
              secondary: {
                main: colors.greenAccent[400],
                dark: colors.greenAccent[200],
                light: colors.greenAccent[800],
              },
              neutral: {
                dark: colors.grey[700],
                main: colors.grey[500],
                light: colors.grey[100],
              },
              background: {
                default: colors.primary[900],
                hover: colors.greenAccent[500],
              },
              border: {
                default: colors.blueAccent[800], 
                primary: colors.grey[100],
                secondary: colors.greenAccent[500],
              },
              boxshadow: {
                default: hexToRgba(colors.grey[100], 0.3),
                primary: hexToRgba(colors.grey[300], 0.5),
                button: hexToRgba(colors.redAccent[500], 0.5),
                home: hexToRgba(colors.greenAccent[800], 0.7),
              },
              warning: {
                dark: colors.redAccent[300],
                main: colors.redAccent[500],
                light: colors.redAccent[800],
              },
              text: {
                primary: colors.grey[100],
                secondary: colors.greenAccent[500],
                tertiary: colors.grey[700],
              },
            }),
      },
      typography: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
        h1: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: '2rem',
          fontWeight: 500,
          lineHeight: 1.2,
        },
        h2: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: '1.5rem',
          fontWeight: 500,
          lineHeight: 1.3,
        },
        h3: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: '1.25rem',
          fontWeight: 500,
          lineHeight: 1.4,
        },
        h4: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: '1.125rem',
          fontWeight: 500,
          lineHeight: 1.4,
        },
        h5: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: 1.5,
        },
        h6: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: '0.875rem',
          fontWeight: 500,
          lineHeight: 1.5,
        },
        body1: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: '1rem',
          fontWeight: 400,
          lineHeight: 1.5,
        },
        body2: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: 1.4,
        },
        body3: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: '0.75rem',
          fontWeight: 300,
          lineHeight: 1.3,
        },
      },
      
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      spacing: 8,
      shape: {
        borderRadius: 4,
      },
      zIndex: {
        appBar: 1200,
        // ...
      },
      components: {
        MuiButton: {
          variants: [
            {
              props: { variant: 'login' },
              style: ({ theme }) => ({
                backgroundColor: 'transparent',
                color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }),
            },
            {
              props: { variant: 'register' },
              style: ({ theme }) => ({
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.dark,
                color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.white,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: `0.1rem 0.1rem 0.1rem 0.01rem ${theme.palette.boxshadow.default}`,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.main,
                  boxShadow: `0.3rem 0.3rem 0.1rem 0.01rem ${theme.palette.boxshadow.default}`,
                  cursor: 'pointer',
                },
              }),
            },
            {
              props: { variant: 'new' },
              style: ({ theme }) => ({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: `0px solid ${theme.palette.neutral.light}`,
                borderRadius: '5px',
                fontSize: '0.8rem',
                fontWeight: 500,
                textAlign: 'center',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                padding: '5px 10px',
                backgroundColor: theme.palette.common.red,
                color: theme.palette.common.white,
                '&:hover': {
                  // height: '38px',
                  boxShadow: `0.5px 0.5px 0.3px ${theme.palette.boxshadow.button}`,
                  fontSize: '0.8rem',
                  backgroundColor: theme.palette.background.default,
                  border: `1px solid ${theme.palette.common.white}`,
                },
              }),
            },
            {
              props: { variant: 'mainaction' },
              style: ({ theme }) => ({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: `1px solid ${theme.palette.neutral.light}`,
                borderRadius: '5px',
                height: '38px',
                fontSize: '0.8rem',
                fontWeight: 500,
                textAlign: 'center',
                cursor: 'pointer',
                boxShadow: `0.01rem 0.01rem 0.05rem 0.01rem ${theme.palette.neutral.main}`,
                transition: 'all 0.2s ease-in-out',
                padding: '4px 4px',
                backgroundColor: theme.palette.neutral.main,
                color: theme.palette.primary.dark,
                '&:hover': {
                  fontSize: '0.8rem',
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.neutral.dark}`,
                  backgroundColor: theme.palette.neutral.light,
                  boxShadow: `0.03rem 0.03rem 0.05rem 0.01rem ${theme.palette.neutral.main}`,
                },
              }),
            },
          ],
        },
        
        MuiTextField: {
          defaultProps: {
            size: 'small', 
          },
        },
      },
    };
  };

  

  // context for color mode
  export const ColorModeContext = createContext({
    colorMode: 'dark',
    toggleColorMode: () => {},
  });
  
  export const useMode = () => {
    const [mode, setMode] = useState("dark");
  
    const colorMode = useMemo(
      () => ({
        colorMode: mode,
        toggleColorMode: () =>
          setMode((prev) => (prev === "light" ? "dark" : "light")),
      }),
      [mode]
    );
  
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    return [theme, colorMode];
  };

