// src>contexts>chartDataContext.js
import React from 'react';


const ChartDataContext = React.createContext({
  chartData: null,
  setChartData: () => {}
});

export default ChartDataContext;
