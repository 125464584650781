import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Link,
    Switch,
    Select,
    MenuItem,
    FormGroup,
    FormControlLabel,
    LinearProgress,
    Chip,
} from '@mui/material';
import { formatUrlPart } from '../../../utils/formaturlpart';
import ProductPageAwards from '../productpage/productpageawards/productPageAwards';
import ProductPageOwner from '../productpage/productowner/productPageOwner';
import ProductPageRatingMain from '../productpage/productrating/productaveragerating/productPageRatingMain';
import ProductQuickViewSocialActions from '../productQuickView/productQuickViewSocialActions/productQuickViewSocialActions';
import ProductSimpleFeatureGauge from '../../../charts/product/productfeaturegauges/productfeaturegauge';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import './productQuickView.scss';

const ProductQuickView = ({
    productId,
    product,
    userId,
    userData,
    open,
    onClose,
    onAddToStack,
    pathDetails,
}) => {
    // console.log('Qieckview Product:', product);
    console.log('Qieckview PathDetails:', pathDetails);
    const userStacks = ['Stack 1', 'Stack 2', 'Stack 3'];
    const userGroups = ['Group 1', 'Group 2', 'Group 3'];
    const [isExpanded, setIsExpanded] = useState(false);
    const { entityType } = product;
    const [productUrl, setProductUrl] = useState('');

    useEffect(() => {
        const fetchPathDetails = async () => {
            console.log('fetchPathDetails is being called');
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/product/details/${product.product_id}`
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                console.log('domain_name:', data.domain_name);
                console.log('primary_category_name:', data.primary_category_name);
                console.log('primary_sub_category_name:', data.primary_sub_category_name);
                console.log('product_id:', data.product_id);
                console.log('product_name:', data.product_name);

                const url = `/products/${formatUrlPart(
                    data.domain_name || 'undefined'
                )}/${formatUrlPart(
                    data.primary_category_name || 'undefined'
                )}/${formatUrlPart(
                    data.primary_sub_category_name || 'undefined'
                )}/${data.product_id || ''}/${formatUrlPart(
                    data.product_name || 'undefined'
                )}`;
                setProductUrl(url);
            } catch (error) {
                console.error(
                    'A problem occurred while fetching the product details:',
                    error
                );
            }
        };

        if (pathDetails) {
            const url = `/products/${formatUrlPart(
                pathDetails.domain_name || 'undefined'
            )}/${formatUrlPart(
                pathDetails.primary_category_name || 'undefined'
            )}/${formatUrlPart(
                pathDetails.primary_sub_category_name || 'undefined'
            )}/${pathDetails.product_id || ''}/${formatUrlPart(
                pathDetails.product_name || 'undefined'
            )}`;
            setProductUrl(url);
        } else if (product && product.entity_id) {
            fetchPathDetails();
        }
    }, [pathDetails, product]);

    if (!product) return null;

    const handleCloseClick = () => {
        onClose();
    };

    const handleExpandAddTo = () => {
        setIsExpanded(true);
    };

    return (
        <Modal disableEnforceFocus open={open} onClose={onClose}>
            <Box className="product-quickview-modal">
                <Box className="quickview-info-display">
                    <Box className="quickview-product-header">
                        <Box className="quickview-product-detail">
                            <Box
                                className="quickview-product-image"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Box
                                    component="img"
                                    src={
                                        product.product_thumb_logo ||
                                        'https://robohash.org/placeatminusexcepturi.png?size=50x50&set=set1'
                                    }
                                    alt={product.product_name}
                                    sx={{
                                        width: '60px',
                                        height: '60px',
                                        border: '0.5px solid lightgrey',
                                        borderRadius: '50%',
                                        objectFit: 'contain',
                                        backgroundColor: 'lightgrey',
                                    }}
                                />
                            </Box>
                            <Box className="quickview-product-name-owner">
                                <Typography variant="h5" component="h2">
                                    {product.product_name}
                                </Typography>
                                <ProductPageOwner
                                    productId={productId}
                                    productData={product}
                                    showVendorAdmin={false}
                                />
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    gap="4px"
                                >
                                    <Typography variant="body2">
                                        Category:{' '}
                                        {product.primary_category_name}
                                    </Typography>
                                    <Typography variant="body2">
                                        Sub-Category:{' '}
                                        {product.primary_sub_category_name}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" ml="20px;" gap="20px">
                                <ProductPageAwards
                                    productId={productId}
                                    productData={product}
                                    showAllAwardsLink={false}
                                />
                            </Box>
                        </Box>
                        <Box className="quickview-product-rating">
                            <ProductPageRatingMain
                                productId={productId}
                                productData={product}
                                showReviews={false}
                                showComments={false}
                                showChatter={false}
                                showWriteReview={false}
                            />
                        </Box>
                        <Box className="quickview-product-description">
                            <Typography variant="body3">
                                {' '}
                                Oneliner: {product.product_descr_short}
                            </Typography>
                        </Box>
                    </Box>

                    <Box className="product-quickview-socials">
                        <ProductQuickViewSocialActions
                            productId={productId}
                            userId={userId}
                            entityType={entityType}
                        />
                    </Box>
                    <Box className="product-quickview-stats">
                        <Box className="product-quickview-feature-display">
                            <Box
                                className="quickview-feature"
                                display="flex"
                                alignItems="center"
                                gap="20px"
                                height="30px"
                            >
                                <Typography variant="h6">Free Trial</Typography>
                                <ProductSimpleFeatureGauge
                                    productId={productId}
                                    productData={product}
                                    feature="product_free_trial"
                                    showFeatureTitle={false}
                                    width="50px"
                                    height="30px"
                                    hideText={true}
                                    animDelay={0}
                                    needleColor="#ffffff"
                                    textColor="#9b9b9b"
                                    nrOfLevels={2}
                                    arcPadding={0.0}
                                    arcWidth={0.2}
                                    cornerRadius={0}
                                />
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                gap="20px"
                                height="30px"
                            >
                                <Typography variant="h6">Free Tier</Typography>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={product.product_free_tier}
                                                color="secondary"
                                                style={{ cursor: 'default' }}
                                            />
                                        }
                                        label={
                                            <Typography variant="h6">
                                                {product.product_free_tier ? 'Available' : 'Not Available'}
                                            </Typography>
                                        }
                                        labelPlacement="start"
                                        style={{ cursor: 'default' }}
                                    />
                                </FormGroup>
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                gap="20px"
                                height="30px"
                            >
                                <Typography variant="h6">
                                    Cardless Trial
                                </Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={
                                        product.product_cardless_trial ? 100 : 0
                                    }
                                    color="secondary"
                                    style={{ width: '100px' }}
                                />
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                gap="20px"
                                height="30px"
                            >
                                <Typography variant="h6">
                                    API Available
                                </Typography>
                                {product.product_api_available ? (
                                    <RadioButtonCheckedIcon color="secondary" />
                                ) : (
                                    <RadioButtonUncheckedIcon color="alert" />
                                )}
                            </Box>
                            <Box display="flex" gap="30px">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="20px"
                                    height="30px"
                                >
                                    <Typography variant="h6">
                                        Pricing
                                    </Typography>
                                    <Chip
                                        label={
                                            product.product_pricing_available
                                                ? 'Available'
                                                : 'Not Available'
                                        }
                                        color={
                                            product.product_free_trial
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                        icon={
                                            product.product_free_trial ? (
                                                <CheckCircleIcon />
                                            ) : (
                                                <CancelIcon />
                                            )
                                        }
                                    />
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                height="30px"
                                gap="10px"
                            >
                                <Typography variant="h6">
                                    Training Options
                                </Typography>
                                {Array.isArray(product.product_training) &&
                                    product.product_training.map(
                                        (trainingOption, index) => (
                                            <Chip
                                                key={index}
                                                label={trainingOption}
                                            />
                                        )
                                    )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                height="30px"
                                gap="10px"
                            >
                                <Typography variant="h6">Support</Typography>
                                {Array.isArray(product.product_support) &&
                                    product.product_support.map(
                                        (supportOption, index) => (
                                            <Chip
                                                key={index}
                                                label={supportOption}
                                            />
                                        )
                                    )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                height="30px"
                                gap="10px"
                            >
                                <Typography variant="h6">Deployment</Typography>
                                {Array.isArray(product.product_deployment) &&
                                    product.product_deployment.map(
                                        (deploymentOption, index) => (
                                            <Chip
                                                key={index}
                                                label={deploymentOption}
                                            />
                                        )
                                    )}
                            </Box>
                        </Box>
                        {/* <Box className="product-quickview-feature-chart">
                            Chart: Use by Industry + Size
                            <ImageFiller width={160} height={100} />
                        </Box> */}
                    </Box>
                    <Box className="product-quickview-actions">
                        <Button
                            variant='register' 
                        >
                            <Link
                                href={productUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View Full Detail
                            </Link>
                        </Button>
                        <Button
                            onClick={(event) => {
                                event.stopPropagation();
                                handleExpandAddTo();
                            }}
                            sx={{ color: 'white', border: '1px solid white' }}
                        >
                            Add to
                        </Button>
                    </Box>
                    {isExpanded && (
                        <Box
                            className="product-quickview-expand-section"
                            display="flex"
                            flexDirection="column"
                            border="1px solid white"
                        >
                            <Typography variant="h5" component="h2">
                                Add this product to a stack or group{' '}
                            </Typography>
                            <Box
                                display="flex"
                                gap="10px"
                                justifyContent="space-between"
                            >
                                <Box>Your official stacks</Box>
                                <Box>Select stack(s)</Box>
                                <Box>Custom group</Box>
                            </Box>
                            <Box display="flex" flexDirection="row">
                                <Box display="flex">
                                    <Button
                                        variant="contained"
                                        sx={{ border: '1px solid white' }}
                                    >
                                        Personal
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ border: '1px solid white' }}
                                    >
                                        Business
                                    </Button>
                                </Box>
                                <Select>
                                    {userStacks.map((stack, index) => (
                                        <MenuItem key={index} value={stack}>
                                            {stack}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select>
                                    {userGroups.map((group, index) => (
                                        <MenuItem key={index} value={group}>
                                            {group}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                    )}
                    <Button className="buttonClose" onClick={(handleCloseClick)}>
                        x
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ProductQuickView;
