// src/components/users/useraccount/userdetailsform>userPersonalsFormPartial/UserPersonalsForm.jsx

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, useTheme } from '@mui/material';
import apiFetch from '../../../../../helpers/apifetch';
import './userPersonalsForm.scss';

const fieldLabels = {
    user_first_name: 'First Name',
    user_business_function_id: 'What business function do you work in?',
    user_business_role_id: 'What role describes you best?',
};

const UserPersonalsForm = ({ newStackUserData, userId, setIsValid }) => {
    const [functions, setFunctions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedFunction, setSelectedFunction] = useState('');
    const token = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    const theme = useTheme();
    

    const [initialValues, setInitialValues] = useState({
        user_first_name: '',
        user_business_function_id: '',
        user_business_role_id: '',
    });
    
    useEffect(() => {
        const allFieldsFilled = Object.values(initialValues).every(value => value !== '');
        setIsValid(allFieldsFilled);
    }, [initialValues, setIsValid]);

    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                
                setInitialValues({
                    user_first_name: data.user_first_name || '',
                    user_business_function_id: data.user_business_function_id
                        ? data.user_business_function_id.toString()
                        : '',
                    user_business_role_id: data.user_business_role_id
                        ? data.user_business_role_id.toString()
                        : '',
                });
                setSelectedFunction(
                    data.user_business_function_id
                        ? data.user_business_function_id.toString()
                        : ''
                );
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchData();
    }, [userId]);

    useEffect(() => {
        const fetchFunctions = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/form/business_function`
                );
                if (!response.ok) throw new Error('Failed to fetch functions');
                const data = await response.json();
                setFunctions(data);
            } catch (error) {
                console.error('Error fetching functions:', error);
            }
        };
        fetchFunctions();
    }, []);

    useEffect(() => {
        const fetchRoles = async () => {
            if (!selectedFunction) return;

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/form/business_role?functionId=${selectedFunction}`
                );
                if (!response.ok) throw new Error('Failed to fetch roles');
                const data = await response.json();
                setRoles(data);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        fetchRoles();
    }, [selectedFunction]);

    const validationSchema = Yup.object().shape({
        user_first_name: Yup.string().required('Required'),
        user_business_function_id: Yup.number().required('Required'),
        user_business_role_id: Yup.number().required('Required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        const payload = {
            ...values,
            user_business_function_id: parseInt(values.user_business_function_id),
            user_business_role_id: parseInt(values.user_business_role_id),
            user_first_name: values.user_first_name,
        };

        try {
            const response = await apiFetch(
                `${process.env.REACT_APP_API_URL}/api/v1/userDetails/${userId}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                },
                dispatch
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(
                    errorData.message || 'Failed to update user details'
                );
            }
            
        } catch (error) {
            console.error('Update error:', error);

        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, values, touched }) => (
                    <Form className="user-personals-form">
                        <div className="personals-form-right-column">
                            <div className="persoanls-form-details">
                                {/* User First Name */}
                                <div className="partial-form-row">
                                    <label htmlFor="user_first_name">
                                        {fieldLabels.user_first_name}
                                    </label>
                                    <Field
                                        id="user_first_name"
                                        as="input"
                                        name="user_first_name"
                                        value={values.user_first_name}
                                        className={
                                            touched.user_first_name
                                                ? 'error-field'
                                                : ''
                                        }
                                    />
                                </div>
                                {/* Business Function Dropdown */}
                                <div className="partial-form-row">
                                    <label htmlFor="business_function">
                                        Business Function
                                    </label>
                                    <Field
                                        as="select"
                                        id="business_function"
                                        name="user_business_function_id"
                                        value={values.user_business_function_id}
                                        className={
                                            touched.user_business_function_id
                                                ? 'error-field'
                                                : ''
                                        }
                                        onChange={e => {
                                            setFieldValue(
                                                'user_business_function_id',
                                                e.target.value
                                            );
                                            setSelectedFunction(e.target.value);
                                        }}
                                    >
                                        <option value=""></option>
                                        {functions.map(func => (
                                            <option
                                                key={func.business_function_id}
                                                value={
                                                    func.business_function_id
                                                }
                                            >
                                                {func.business_function_name}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                                {/* Business Role Dropdown */}
                                <div className="partial-form-row">
                                    <label htmlFor="user_business_role_id">
                                        Business Role
                                    </label>
                                    <Field
                                        as="select"
                                        id="user_business_role"
                                        name="user_business_role"
                                        value={values.user_business_role_id}
                                        onChange={e =>
                                            setFieldValue(
                                                'user_business_role_id',
                                                e.target.value
                                            )
                                        }
                                        className={
                                            touched.user_business_role_id
                                                ? 'error-field'
                                                : ''
                                        }
                                    >
                                        <option value=""></option>
                                        {roles.map(role => (
                                            <option
                                                key={role.business_role_id}
                                                value={role.business_role_id}
                                            >
                                                {role.business_role_name}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                                <Button
                                    type="submit"
                                    variant='outlined'
                                    size= 'small'
                                    style={{backgroundColor: theme.palette.secondary.main, boxShadow: theme.palette.boxshadow.default,}}
                                    disabled={isSubmitting}
                                    className="submit-btn"
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UserPersonalsForm;
