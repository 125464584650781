import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../contexts/userContext';
import NonAuthUserPopup from '../../popupsglobal/nonauthuserspopup/nonAuthUserPopup';
import SuggestProduct from '../suggestproduct/suggestProduct'

const CreateNewButton = () => {
    const { isAuthenticated } = useUser();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showSuggestProduct, setShowSuggestProduct] = useState(false);
    const navigate = useNavigate();

    const handleClick = (event) => {
        if (isAuthenticated) {
            setAnchorEl(event.currentTarget);
        } else {
            setShowPopup(true);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (path) => {
        navigate(path);
        handleClose();
    };

    const handleSuggestProductClick = () => {
        setShowSuggestProduct(true);
        handleClose();
    };

    

    return (
        <>
            <Button
                variant="new"
                startIcon={<AddIcon />}
                aria-label="create new"
                aria-controls="create-new-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                NEW
            </Button>
            <Menu
                id="create-new-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {/* <MenuItem onClick={() => handleMenuClick('/create/newstack/stepper')}>Prev Create Stack</MenuItem> */}
                <MenuItem onClick={() => handleMenuClick('/stack/create/new')}>Create New Stack</MenuItem>
                <MenuItem onClick={handleSuggestProductClick}>Suggest a Product</MenuItem>
                {/* <MenuItem onClick={() => handleMenuClick('/')}>Write Review</MenuItem> */}
            </Menu>
            <NonAuthUserPopup open={showPopup} onClose={() => setShowPopup(false)} />
            <SuggestProduct open={showSuggestProduct} onClose={() => setShowSuggestProduct(false)} />
        </>
    );
};

export default CreateNewButton;
