import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';


const initialState = {
    user: null,
    token: Cookies.get('token') || null,
};

const userAuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { user, token } = action.payload;
            state.user = user;
            state.token = token;
            Cookies.set('token', token, {
                expires: 1 ,
                secure: true,
                sameSite: 'strict',
            });
        },
        resetAccessToken: (state, action) => {
            state.token = action.payload;
            Cookies.set('token', action.payload, {
                expires: 1,
                secure: true,
                sameSite: 'strict',
            }); 
        },
        logout: state => {
            state.user = null;
            state.token = null;
            Cookies.remove('token'); 
        },
    },
});

export const { setCredentials, resetAccessToken, logout } =
    userAuthSlice.actions;

export default userAuthSlice.reducer;
