// src>tests>themedcomponents.jsx
import React, { useState } from 'react';
import { Box, Chip, Button, IconButton, TextField, Typography } from '@mui/material';
import { tokens } from "../theme";
import { useTheme } from "@emotion/react";
import { SketchPicker } from 'react-color';
import NewStackInfo from '../runnercore/createstack/createnewstack/newstackinfo/newStackInfo';
import NewStackSelect from '../runnercore/createstack/createnewstack/newstackselect/newStackSelect';
import EditStack from '../runnercore/editstack/editStack';
import MyStacksMain from '../runnercore/mystacks/myStacksMain';
import ViewStackMainOld from '../runnercore/viewstack/stackpages/viewstackmainold/viewStackMainOld';
import MyProductsFolders from "../products/myproductfolders/myProductFolders";
import NewStackDisplay from "../runnercore/createstack/newstackdisplay";
import ArrowForward from '@mui/icons-material/ArrowForward';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";

import BoxComponent from '../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../global/dashboards/sidepanelcomponent/SideBarComponent';
import ImageFiller from 'react-image-filler';

import './themedcomponents.scss';

const ThemedComponents = () => {
    const theme = useTheme();
    const colors = tokens (theme.palette.mode);
    // console.log('Theme in mode ',theme);
    const [color, setColor] = useState('#fff');
    const [selectedColor, setSelectedColor] = useState('#fff');
    const [isPickerVisible, setIsPickerVisible] = useState(false);

    const handleColorChange = (newColor) => {
        setColor(newColor.hex);
    };

    const openColorPicker = () => {
        setIsPickerVisible(true);
    };

    const selectColor = () => {
        setSelectedColor(color);
        setIsPickerVisible(false);
    };
    


    return (
        <Box className='main-container' sx={{display: 'flex', width: '1600px', margin: ' 0px auto'}}>
            <Box sx={{display: 'flex', gap: '10px', flexDirection:'column', alignItems: 'flex-start', width: '100%'}}>
                <Typography variant="h1">Style Guide</Typography>
                <Box className='Style-Guide-Boxes' sx={{display: 'flex', gap: '10px', flexDirection:'column', alignItems: 'flex-start', width: '100%'}}>
                    <Typography variant="h2">Boxes</Typography>
                    <Box sx={{display: 'flex', gap: '10px', flexDirection:'column', alignItems: 'flex-start', padding: '20px', border:'1px solid white', width: '100%'}}>
                        <Box className='test-element'>
                            <Typography variant="h4" className='style-guide-element-title'>Box Primary </Typography>
                            <Box 
                                sx={{
                                    minHeight: '50px',
                                    minWidth: '200px',
                                    borderRadius: '5px',
                                    border: `1px solid ${theme.palette.border.default}`,
                                    boxShadow: `0.1rem 0.1rem 0.1rem 0.05rem ${theme.palette.boxshadow.default}`,
                                    backgroundColor: theme.palette.primary.main,
                                }}
                            >
                                <Typography variant="body3" sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} >Mode: </Typography>
                            </Box>
                            <Box sx={{display: 'flex', gap: '10px'}}>
                                <Typography variant="body3">Background: primary.main </Typography>
                                <Typography variant="body3">Border: border.default </Typography>
                                <Typography variant="body3">Box Shadow: boxshadow.default </Typography>
                            </Box>
                        </Box>
                        <Box className='test-element'>
                            <Typography variant="h4" className='style-guide-element-title'>Box Secondary </Typography>
                            <Box 
                                sx={{
                                    minHeight: '50px',
                                    minWidth: '200px',
                                    borderRadius: '5px',
                                    border: `1px solid ${theme.palette.border.default}`,
                                    boxShadow: `0.1rem 0.1rem 0.1rem 0.05rem ${theme.palette.boxshadow.default}`,
                                    backgroundColor: theme.palette.primary.main,
                                }}
                            >
                                <Typography variant="body3" sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} >Mode: </Typography>
                            </Box>
                            <Box sx={{display: 'flex', gap: '10px'}}>
                                <Typography variant="body3">Background: primary.main </Typography>
                                <Typography variant="body3">Border: border.default </Typography>
                                <Typography variant="body3">Box Shadow: boxshadow.default </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className='Style-Guide-Chips' sx={{display: 'flex', gap: '10px', flexDirection:'column', alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant="h2">Chips</Typography>
                    <Box sx={{display: 'flex', gap: '10px', flexDirection:'column', alignItems: 'flex-start', padding: '20px', border:'1px solid white', width: '100%'}}>
                        <Box className='test-element'>
                            <Typography variant="h4" sx={{minWidth: '200px'}}>Chip 1 </Typography>
                            <Box>
                                <Chip 
                                    label="Chip 1" 
                                    icon={<SearchIcon />} // Add an icon to the Chip
                                    sx={{
                                        border: `1px solid ${theme.palette.border.default}`,
                                        boxShadow: `0.1rem 0.1rem 0.1rem 0.05rem ${theme.palette.boxshadow.default}`,
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.text.primary,
                                        '&:hover': {
                                        backgroundColor: theme.palette.primary.dark, 
                                        },
                                    }}
                                />
                            </Box>
                            <Box sx={{display: 'flex', gap: '10px'}}>
                                <Typography variant="body3">Background: primary.main </Typography>
                                <Typography variant="body3">Border: border.default </Typography>
                                <Typography variant="body3">Box Shadow: boxshadow.default </Typography>
                            </Box>
                        </Box>
                        <Box className='test-element'>
                            <Typography variant="h4" sx={{minWidth: '200px'}}>Chip 2 </Typography>
                            <Box>
                                <Chip 
                                    label="Chip 2" 
                                    sx={{
                                        border: `1px solid ${theme.palette.border.default}`,
                                        boxShadow: `0.1rem 0.1rem 0.1rem 0.05rem ${theme.palette.boxshadow.default}`,
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.text.primary,
                                        '&:hover': {
                                        backgroundColor: theme.palette.neutral.main, 
                                        cursor: 'pointer',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box className='test-element'>
                            <Typography variant="h4" sx={{minWidth: '200px'}}>Chip 3 </Typography>
                            <Box>
                                <Chip 
                                    label="Chip 3" 
                                    sx={{
                                        border: `1px solid ${theme.palette.border.default}`,
                                        boxShadow: `0.1rem 0.1rem 0.1rem 0.05rem ${theme.palette.boxshadow.default}`,
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.text.primary,
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box className='test-element'>
                            <Typography variant="h4" sx={{minWidth: '200px'}}>Chip 4 </Typography>
                            <Box>
                                <Chip 
                                    label="Chip 4" 
                                    sx={{
                                        border: `1px solid ${theme.palette.border.default}`,
                                        boxShadow: `0.1rem 0.1rem 0.1rem 0.05rem ${theme.palette.boxshadow.default}`,
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.text.primary,
                                        '&:hover': {
                                            backgroundColor: theme.palette.neutral.main, 
                                            cursor: 'pointer',
                                        },
                                    }}
                                />
                        </Box>
                    </Box>
                </Box>
                </Box>
                <Box className='Style-Guide-Buttons' sx={{display: 'flex', gap: '10px', flexDirection:'column', alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant="h2">Buttons</Typography>
                    <Box sx={{display: 'flex', gap: '10px', flexDirection:'column', alignItems: 'flex-start', padding: '20px', border:'1px solid white', width: '100%'}}>
                        <Box className='test-element'>
                            <Typography variant="h4" className='style-guide-element-title'>Button: Primary </Typography>
                            <Box>
                                <Button 
                                    variant="contained"
                                    sx={{
                                    border: `1px solid ${theme.palette.border.default}`,
                                    boxShadow: `0.1rem 0.1rem 0.1rem 0.05rem ${theme.palette.boxshadow.default}`,
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.text.primary, 
                                    }}
                                >
                                    <Typography variant="body3">Button 1 </Typography>
                                </Button>
                                
                            </Box>
                            <Box sx={{display: 'flex', gap: '10px'}}>
                                <Typography variant="body3">Style: standard | Variant: 'contained' </Typography>
                                <Typography variant="body3">Bg (hover): primary.main (primary.main) </Typography>
                                <Typography variant="body3">Border (hover): border.default (border.default) </Typography>
                                <Typography variant="body3">BoxShadow (hover): boxshadow.default (boxshadow.default) </Typography>
                                <Typography variant="body3">Typo (hover): body 3 (body3) </Typography>
                            </Box>
                        </Box>
                        <Box className='test-element'>
                            <Typography variant="h4" className='style-guide-element-title'>Button: Secondary </Typography>
                            <Box>
                                <Button 
                                    variant="contained"
                                    sx={{
                                    border: `1px solid ${theme.palette.border.default}`,
                                    boxShadow: `0.1rem 0.1rem 0.1rem 0.05rem ${theme.palette.boxshadow.default}`,
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.text.primary, 
                                    }}
                                >
                                    <Typography variant="body3">Button 2 </Typography>
                                </Button>
                                
                            </Box>
                            <Box sx={{display: 'flex', gap: '10px'}}>
                                <Typography variant="body3">Style: standard | Variant: 'contained' </Typography>
                                <Typography variant="body3">Bg (hover): primary.main (primary.main) </Typography>
                                <Typography variant="body3">Border (hover): border.default (border.default) </Typography>
                                <Typography variant="body3">BoxShadow (hover): boxshadow.default (boxshadow.default) </Typography>
                                <Typography variant="body3">Typo (hover): body 3 (body3) </Typography>
                            </Box>
                        </Box>
                        <Box className='test-element'>
                            <Typography variant="h4" className='style-guide-element-title'>Button: Login </Typography>
                            <Box>
                                <Button 
                                    variant="login"
                                >
                                    Login
                                    {/* <Typography variant="body3">Login </Typography> */}
                                </Button>
                                
                            </Box>
                            <Box sx={{display: 'flex', gap: '10px'}}>
                                <Typography variant="body3">Style: custom | Variant: 'login' </Typography>
                                <Typography variant="body3">Bg (hover): primary.main (primary.main) </Typography>
                                <Typography variant="body3">Border (hover): border.default (border.default) </Typography>
                                <Typography variant="body3">BoxShadow (hover): boxshadow.default (boxshadow.default) </Typography>
                                <Typography variant="body3">Typo (hover): body 3 (body3) </Typography>
                            </Box>
                        </Box>
                        <Box className='test-element'>
                            <Typography variant="h4" className='style-guide-element-title'>Button: Register </Typography>
                            <Box>
                                <Button 
                                    variant="register"
                                >
                                    Register
                                    {/* <Typography variant="body3">Register </Typography> */}
                                </Button>
                                
                            </Box>
                            <Box sx={{display: 'flex', gap: '10px'}}>
                                <Typography variant="body3">Style: custom | Variant: 'register' </Typography>
                                <Typography variant="body3">Bg (hover): primary.main (primary.main) </Typography>
                                <Typography variant="body3">Border (hover): border.default (border.default) </Typography>
                                <Typography variant="body3">BoxShadow (hover): boxshadow.default (boxshadow.default) </Typography>
                                <Typography variant="body3">Typo (hover): text (text) </Typography>
                            </Box>
                        </Box>
                        <Box className='test-element'>
                            <Typography variant="h4" className='style-guide-element-title'>Button: New </Typography>
                            <Box>
                                <Button 
                                    variant="new"
                                >
                                    New
                                    {/* <Typography variant="body3">New </Typography> */}
                                </Button>
                                
                            </Box>
                            <Box sx={{display: 'flex', gap: '10px'}}>
                                <Typography variant="body3">Style: custom | Variant: 'register' </Typography>
                                <Typography variant="body3">Bg (hover): primary.main (primary.main) </Typography>
                                <Typography variant="body3">Border (hover): border.default (border.default) </Typography>
                                <Typography variant="body3">BoxShadow (hover): boxshadow.default (boxshadow.default) </Typography>
                                <Typography variant="body3">Typo (hover): text (text) </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className='test-element'>
                <IconButton variant="socials"><LightModeOutlinedIcon/></IconButton>
                <IconButton variant="contained"><DarkModeOutlinedIcon/></IconButton>
                <IconButton variant="outlined"><NotificationsOutlinedIcon/></IconButton>
                <IconButton variant="standard"><ArrowForward/></IconButton>
                <IconButton><SettingsOutlinedIcon/></IconButton>
                <IconButton 
                    type="button"
                    sx={{
                        p: 1,
                        alignItems: "center",
                        mr: "20px",
                        height: "30px",
                        width: "30px",
                        color: colors.greenAccent[800],
                        backgroundColor: colors.blueAccent[200],
                    }}>
                    <SearchIcon />
                </IconButton>
            </Box>
            <Box className='test-element'>
                <TextField label="Standard TextField 1" variant="standard" />
                <TextField label="Custom TextField 2" variant="filled" />
                <TextField label="TextField 3"  variant="outlined"/>
                <TextField label="TextField 4" variant="standard"/>
            </Box>
            <Box className='test-element'>
                <Typography variant="h1">Heading h1</Typography>
                <Typography variant="h2">Heading h2</Typography>
                <Typography variant="h3">Heading h3</Typography>
                <Typography variant="h4">Heading h4</Typography>
                <Typography variant="h5">Heading h5</Typography>
                <Typography variant="h6">Heading h6</Typography>
            </Box>
            <Box className='test-element'>
                <Typography variant="body1">Body 1</Typography>
                <Typography variant="body2">Body 2</Typography>
                <Typography variant="body3">Body 3</Typography>
            </Box>
            <Box className='test-element'>
                <Box>
                    <BoxComponent
                        icon={<SearchIcon/>}
                        title='BoxComponent'
                        subtitle ='Style Guide'
                        sideText ='10'
                        width={4}
                        showButton={true}
                        link='https://www.google.com'
                    >
                        <div><ImageFiller width={260} height={100}/></div>
                    </BoxComponent>
                </Box>
                <Box>
                    <SideBarComponent
                        icon={<SearchIcon/>}
                        title='SideBarComponent'
                        subtitle ='Style Guide'
                        sideText ='10'
                        showButton={true}
                        link='https://www.google.com'
                    >
                        {/* <div><ImageFiller width={260} height={80}/></div> */}
                    </SideBarComponent>
                </Box>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ width: 150, height: 50, backgroundColor: selectedColor }}>
                    <Typography sx={{display: 'flex', alignContent:'center', justifyContent:'center'}} variant="h6">New Color Picked </Typography>
                </Box>
                <Button variant="register" onClick={openColorPicker}>Open Picker</Button>
                <Button variant="new" onClick={selectColor}>Select</Button>
                {isPickerVisible && <SketchPicker color={color} onChangeComplete={handleColorChange} />}
            </Box>
            <Box sx={{width: '1200px', mb: '50px'}}>
                <NewStackInfo/>
                <NewStackSelect/>
                <EditStack/>
                
                <MyStacksMain/>
                <MyProductsFolders/>
                <ViewStackMainOld/>
                
                <NewStackDisplay/>
                
            </Box>
        </Box>
    );
};

export default ThemedComponents;
