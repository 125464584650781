import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import './nonAuthUserPopup.scss';


const NonAuthUserPopup = ({ open, onClose }) => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
        onClose();
    };

    const handleRegister = () => {
        navigate('/register');
        onClose();
    };

    return (
        <Modal className='nonauth-modal' open={open} onClose={onClose}>
            <Box className='nonauth-popup-modal-content'>
                <Typography className='modal-title' variant="h6" component="h2">
                    Welcome!
                </Typography>
                <Typography className='modal-text'>
                    Please login or register to continue.
                </Typography>
                <Box className='nonauth-button-container'>
                    <Button className='modal-login-button' variant="outlined" onClick={handleLogin}>
                        Login
                    </Button>
                    <Button className='modal-register-button' variant="outlined" onClick={handleRegister}>
                        Register
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default NonAuthUserPopup;
