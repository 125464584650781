import React, { useState, useContext } from 'react';
import { Menu, MenuItem, Avatar, Box, Typography } from '@mui/material';
import { ColorModeContext } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import Logout from '../../../global/logout/logout';
import NonAuthUserPopup from '../../popupsglobal/nonauthuserspopup/nonAuthUserPopup';
import './accountWidget.scss';

const AccountWidget = ({ user }) => {
    const theme = useTheme();
    const { colorMode, toggleColorMode } = useContext(ColorModeContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const navigate = useNavigate();

    const handleClick = event => {
        if (user) {
            setAnchorEl(event.currentTarget);
        } else {
            setShowPopup(true);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = path => {
        navigate(path);
        handleClose();
    };

    const handleLogout = () => {
        setIsLoggingOut(true);
        handleClose();
    };

    return (
        <>
            <Avatar
                className="profilePic"
                onClick={handleClick}
                src={
                    user?.user_profile_image ||
                    'https://robohash.org/ducimusodiosunt.png?size=50x50&set=set1'
                }
                alt="Profile"
            />
            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    onClick={() =>
                        handleMenuClick(`/user/profile/${user?.user_id}`)
                    }
                >
                    My Profile
                </MenuItem>
                <MenuItem
                    onClick={() => handleMenuClick(`/user/${user?.user_id}`)}
                >
                    Account
                </MenuItem>
                <MenuItem onClick={() => handleMenuClick('/')}>
                    Customise Feed
                </MenuItem>
                <MenuItem onClick={() => handleMenuClick('/')}>
                    Preferences
                </MenuItem>
                <MenuItem onClick={colorMode.toggleColorMode}>
                    <Box
                        onClick={toggleColorMode}
                        display="flex"
                        gap="10px"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h5">Dark Mode</Typography>
                        {theme.palette.mode === 'dark' ? (
                            <DarkModeOutlinedIcon fontSize="small" />
                        ) : (
                            <LightModeOutlinedIcon fontSize="small" />
                        )}
                    </Box>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <Box
                        display="flex"
                        gap="10px"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h5">Logout</Typography>
                        <LogoutIcon fontSize="small" />
                    </Box>
                </MenuItem>
            </Menu>
            {isLoggingOut && <Logout />}
            <NonAuthUserPopup
                open={showPopup}
                onClose={() => setShowPopup(false)}
            />
        </>
    );
};

export default AccountWidget;
