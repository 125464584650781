import React from 'react';
import { Box } from '@mui/material';
import HeaderStreamed from '../../../components/headerstreamed/HeaderStreamed';
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import UserAccountDelete from '../../../components/users/useraccount/useraccountdelete/userAccountDelete';
import './userAccountSettings.scss';

const UserAccountSettings = (userData, userId, entityType) => {
    return (
        <Box className="user-account-page-container">
            <HeaderStreamed
                title="Account Settings"
                subtitle="high-level settings"
            />
            <Box className="account-container-main">
                <Box className="account-gridPlusSide">
                    <Box className="account-main-grid">
                        <BoxComponent
                            title="Privacy Policy"
                            subtitle="include policies for data collection, etc."
                            width={12}
                        ></BoxComponent>

                        <BoxComponent
                            title="Payment and Billing Info"
                            subtitle="add card, see billing amount and cycle, etc."
                            width={12}
                        ></BoxComponent>

                        <BoxComponent
                            title="OTHER"
                            subtitle="other compliance related info and acknowledgements"
                            width={12}
                        />
                    </Box>
                    <Box className="account-right-panel">
                        <SideBarComponent
                            title="Delete Account !"
                            // subtitle="This is irreversible"
                            sideText="10"
                            showButton={true}
                            link="https://www.google.com"
                        >
                            <UserAccountDelete userId={userId} />
                        </SideBarComponent>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default UserAccountSettings;
