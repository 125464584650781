import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Box, useTheme, Button } from '@mui/material';
import StackPurposeStepper from './stackpurposestepper/stackPurposeStepper';
import StackInfoStepper from './stackinfostepper/stackInfoStepper';
import StackDetailPersonalStepper from './stackdetailpersonalstepper/stackDetailPersonalStepper';
import StackDetailCompanyStepper from './stackdetailcompanystepper/stackDetailCompanyStepper';
import NewStackSelect from '../newstackselect/newStackSelect';
import HeaderStreamed from '../../../../components/headerstreamed/HeaderStreamed';
import apiFetch from '../../../../helpers/apifetch';
import { useSelector, useDispatch } from 'react-redux';

const steps = [
    'Select Purpose',
    'Confirm Role',
    'Complete Details',
    'Set Stack Info',
    'Add Products',
];

const NewStackStepper = () => {
    const token = useSelector(state => state.auth.token);
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();
    const userId = user?.user_id;
    const [activeStep, setActiveStep] = useState(0);
    const [stackData, setStackData] = useState({});
    const [selectedPurpose, setSelectedPurpose] = useState(null);
    const [newStackUserData, setNewStackUserData] = useState(null);
    const [companyList, setCompanyList] = useState([]);
    const theme = useTheme();
    
    useEffect(() => {
        const fetchData = async () => {
            if (!userId) {
                return;
            }
            try {
                const response = await apiFetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/stacks/${userId}`,
                    {
                        credentials: 'include',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                    dispatch
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const userDetailContext = await response.json();
                setNewStackUserData(userDetailContext);
    
                const companyResponse = await apiFetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/company/user_companies/${userId}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                    dispatch
                );
                if (!companyResponse.ok) {
                    throw new Error(`HTTP error! status: ${companyResponse.status}`);
                }
                const companyData = await companyResponse.json();
                setCompanyList(companyData);
            } catch (error) {
                console.error('Failed to fetch new stack user data:', error);
            }
        };
    
        fetchData();
    }, [userId, token, dispatch]);

    const handleNext = data => {
        setStackData(prevData => ({ ...prevData, ...data }));
        setActiveStep(prevStep => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevStep => prevStep - 1);
    };

    const handlePurposeSelected = purpose => {
        setSelectedPurpose(purpose);
    };

    const getStepContent = step => {
        switch (step) {
            case 0:
                return (
                    <StackPurposeStepper
                        onNext={handleNext}
                        onPurposeSelected={handlePurposeSelected}
                    />
                );
            case 1:
                return <StackDetailPersonalStepper token={token} userId={userId} newStackUserData={newStackUserData} onNext={handleNext} />;
            case 2:
                return selectedPurpose === 'Business' ? 
                    <StackDetailCompanyStepper token={token} userId={userId} newStackUserData={newStackUserData} companyList={companyList} onNext={handleNext} /> : 
                    handleNext({});
            case 3:
                return <StackInfoStepper token={token} userId={userId} newStackUserData={newStackUserData} companyList={companyList} selectedPurpose={selectedPurpose} onNext={handleNext} />;
            case 4:
                return <NewStackSelect token={token}  userId={userId} onNext={handleNext} />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Box
            sx={{
                maxWidth: 1200,
                margin: 'auto',
                paddingTop: '50px',
                bgcolor: theme.palette.background.default,
            }}
        >
            <Box sx={{ padding: '20px' }}>
                <HeaderStreamed
                    title="Create a new stack!"
                    subtitle="Quick and simple steps to set up your stack once - then update it as you go!"
                />
            </Box>
            <Stepper
                activeStep={activeStep}
                sx={{
                    margin: '10px 20px',
                    '& .MuiStepIcon-root': {
                        fontSize: '1.8rem', // Increase the size of the icons
                    },
                    '& .MuiStepLabel-label': {
                        fontSize: '1.2rem',
                    },
                    '& .MuiStepIcon-active': {
                        color: theme.palette.neutral.main,
                    },
                    '& .MuiStepLabel-iconContainer .Mui-active': {
                        color: theme.palette.secondary.main,
                    },
                    '& .MuiStepLabel-labelContainer .Mui-active': {
                        color: theme.palette.secondary.main,
                    },
                    '& .MuiStepLabel-iconContainer .Mui-disabled': {
                        color: theme.palette.neutral.light,
                        
                    },
                    '& .MuiStepLabel-labelContainer .Mui-disabled': {
                        color: theme.palette.neutral.light,
                        
                    },
                    '& .MuiStepLabel-iconContainer .Mui-completed': {
                        color: theme.palette.secondary.main,
                    },
                    '& .MuiStepLabel-labelContainer .Mui-completed': {
                        color: theme.palette.secondary.main,
                    },
                    
                }}
            >
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {getStepContent(activeStep)}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                }}
            >
                <Button 
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    style={{
                        color: theme.palette.secondary.main,
                        fontSize: '1.2rem',
                    }}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    // color="primary"
                    fontSize="small"
                    onClick={() => handleNext(stackData)}
                    disabled={activeStep === 0 && !selectedPurpose}
                    style={{ 
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.common.white,
                        fontSize: '1.2rem',
                        alginContent: 'center',
                    }}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default NewStackStepper;
