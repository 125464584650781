//src>charts>stack>stackfit>chartStackProductMatch.jsx

import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import './sharedProductsStackFit.scss';

const SharedProductsStackFit = ({userId, stackId, stackData, showShared = true, numSharedProductsToShow = 5}) => {
    const [numCommonProducts, setNumCommonProducts] = useState(0);
    const [percentageCommon, setPercentageCommon] = useState(0);
    const [commonProducts, setCommonProducts] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const theme = useTheme();

    useEffect(() => {
        if (!stackData || !stackData.visitorUserIdStackPackage) {
            return;
        }

        if (stackData.productPackage && stackData.visitorUserIdStackPackage.products) {
            const stackProductIds = stackData.productPackage.map(product => product.product_id);
            const visitorProductIds = stackData.visitorUserIdStackPackage.products.map(product => product.product_id);
            const commonProducts = stackData.productPackage.filter(product => visitorProductIds.includes(product.product_id));
    
            setCommonProducts(commonProducts);
            setNumCommonProducts(commonProducts.length);
            setPercentageCommon((commonProducts.length / stackProductIds.length) * 100);
            if (stackData && stackData.visitorUserIdStackPackage && stackData.visitorUserIdStackPackage.products) {
                setTotalProducts(stackData.visitorUserIdStackPackage.products.length);
            }
            
        }
    }, [stackData]);


    return (
        <Box className='chart-stack-match-main'>
            {showShared && (
                <Box className="shared-products">
                    <Box className="common-products-match-indicators">
                        <Box className="common-products-sharedBox common-products-sharedBox-left">
                            <Typography variant='h2' className="circle">{percentageCommon.toFixed(0)}%</Typography>
                            <Typography variant='h6'sx={{color: theme.palette.neutral.main}}>Shared</Typography>
                        </Box>
                        <Box className="common-products-sharedBox common-products-sharedBox-middle">
                            <Box className="stack-match-shared-products">
                                {commonProducts.slice(0, numSharedProductsToShow).map((product, index) => (
                                    <Box key={index} >
                                        <img 
                                            style={{
                                                borderColor: theme.palette.primary.main,
                                                borderWidth: 2,
                                                borderStyle: 'solid',
                                                backgroundColor: theme.palette.neutral.light,
                                                boxShadow: `0px 0px 5px 0px ${theme.palette.boxshadow.default}`,
                                                width: '40px',
                                                height: '40px',
                                                objectFit: 'cover',
                                                borderRadius: '50%',
                                            }}
                                            src={product.product_thumb_logo}
                                            alt={product.product_name}
                                        />
                                    </Box>
                                ))}
                                <Link to={`/stacks/${stackId}/composition`} className="link-no-decoration">
                                    <Box className='show-more-circle' sx={{color: theme.palette.common.white}}><span>...</span></Box>
                                </Link>
                            </Box>
                            <Link to={`/stacks/${stackId}/composition`} className="link-no-decoration">
                                <Typography variant='h6' sx={{color: theme.palette.neutral.main}}>See All</Typography>
                            </Link>
                        </Box>
                        <Box className="common-products-sharedBox common-products-sharedBox-right">
                            <Typography variant='h2' className="circle">{numCommonProducts}</Typography>
                            <Typography variant='h6'sx={{color: theme.palette.neutral.main}}>Products</Typography>
                        </Box>
                    </Box>
                    <Typography variant='body3' sx={{display: 'flex', mt:'10px', alignItems:'center', justifyContent:'center'}}>Matched against stack: {stackData?.visitorUserIdStackPackage?.stack?.stack_name} ({totalProducts} products)</Typography>
                </Box>
            )}
        </Box>
    );
}

export default SharedProductsStackFit;