// src>runnercore>createstack>createnewstack>newstackpurpose>newStackPurpose.jsx

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setStackPurpose } from '../../../../features/stacks/stackpurpose/stackPurposeSlice';
import { resetStack } from '../../../../features/stacks/temporaryId/temporaryIdSlice';
import HeaderStreamed from '../../../../components/headerstreamed/HeaderStreamed';
import { Box, Button, Typography } from '@mui/material';
import './newStackPurpose.scss';

const NewStackPurpose = ({ onPurposeSelected }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetStack());
    }, [dispatch]);

    const handlePurposeSelection = purpose => {
        dispatch(setStackPurpose(purpose));
        if (onPurposeSelected) {
            onPurposeSelected(purpose);
        }
    };

    return (
        <div className="stack-purpose-choice">
            <HeaderStreamed
                title="Select Stack Purpose"
                subtitle="What is your stack for"
            />
            <div className="purpose-option-tiles">
                <Box className="purpose-option-selection">
                    <Typography
                        className="purpose-option-tile-title"
                        variant="h4"
                    >
                        Peronal Stack
                    </Typography>
                    <Box className="purpose-option-tile-content">
                        <Typography variant="h6">
                            {' '}
                            create a stack for your personal apps and software
                            you use{' '}
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={() => handlePurposeSelection('Personal')}
                            sx={{
                                padding: '10px 15px',
                                margin: '0 10px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f8f8f8',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                    backgroundColor: '#e0e0e0',
                                },
                            }}
                        >
                            Personal
                        </Button>
                    </Box>
                </Box>
                <Box className="purpose-option-selection">
                    <Typography
                        className="purpose-option-tile-title"
                        variant="h4"
                    >
                        Business Stack
                    </Typography>
                    <Box className="purpose-option-tile-content">
                        <Typography variant="h6">
                            {' '}
                            create a stack for your business apps and software
                            you use{' '}
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={() => handlePurposeSelection('Business')}
                            sx={{
                                padding: '10px 15px',
                                margin: '0 10px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f8f8f8',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                    backgroundColor: '#e0e0e0',
                                },
                            }}
                        >
                            Business
                        </Button>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default NewStackPurpose;
