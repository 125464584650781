//Still used in 'My Stacks' page streamed into 'Stacks' within userPublicProfileMain

import React from "react";
import { Box, Typography } from "@mui/material";



const StackTileSimple = () => {
    return (
        <div>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid white",
                borderRadius: "5px",
                maxwidth: "300px",
                height: "150px",
                margin: "10px",
                }}
            >
                <Typography variant="h6">Stack Tile Simple</Typography>
            </Box>
        </div>
    );
};

export default StackTileSimple;