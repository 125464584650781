//sr-build/client/src/components/product/productlisting/categorylisting/productCategoryListing.jsx

import React from 'react';
import { Grid } from '@mui/material';
import ProductListingTile from './productListingTile';
import './productCategoryListing.scss';

const ProductCategoryListing = ({ products = [] }) => {
    return (
        <Grid container spacing={2} className="products-listing-display">
            {products.map(product => (
                <ProductListingTile key={product.product_id} productId={product.product_id} />
            ))}
        </Grid>
    );
};

export default ProductCategoryListing;
