import React from "react";
import { Box } from "@mui/material";
import HeaderStreamed from "../../../components/headerstreamed/HeaderStreamed";
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/sidepanelcomponent/SideBarComponent';
import ImageFiller from 'react-image-filler';
import './profileActivityUser.scss';

const ProfileActivityUser = () => {


  return(
    <Box className='user-account-page-container'>
        <HeaderStreamed title="Activities" subtitle="platform interactions the user's had"/>
        <Box className="account-container-main" >
            <Box className="account-gridPlusSide" >
                <Box className= "account-main-grid">
                    <BoxComponent
                        title="Edit your profile"
                        subtitle="set general feed preferences | some are premium features (e.g. turn off ads)"
                        width={12}
                    >
                    </BoxComponent>
                    
                    <BoxComponent
                        title="set your preferences"
                        subtitle="search, add or remove interests from your feed"
                        width={12}
                    >
                    </BoxComponent>
                    
                    <BoxComponent 
                        title="Customise your feed" 
                        subtitle="same implementaiton as with 'manage interests' component"
                        width={12}
                    />
                    
                    <BoxComponent title="Manage Teams" subtitle="general toggle-based preference setting" width={12} />
                    <BoxComponent title="Manage claimed entities" subtitle="general toggle-based preference setting" width={12} />
                    <BoxComponent title="Billing" subtitle="toggle for e.g. 'include user posts', 'replies to my comments', etc." width={12} />
                    
                </Box>
                <Box className="account-right-panel" >
                    <SideBarComponent 
                        title="Go to Settings"
                    >
                        
                    </SideBarComponent>
                    <SideBarComponent 
                        title="View your Profile"
                    >
                        <div><ImageFiller width={260} height={100}/></div>
                    </SideBarComponent>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default ProfileActivityUser;