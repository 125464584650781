//src>components>profilepage>profielpageheader>profilepageuserrecognition>profilePageUserRecognition.jsx

import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import './profilePageUserRecognition.scss';

function ProfilePageUserRecognition({userData, userId, entityType, visitorUserId }) {
    const [followedByData, setFollowedByData] = useState([]);
    const [followingData, setFollowingData] = useState([]);
    const theme = useTheme();
    const formatNumber = (num) => {
        return num >= 1000 ? (num / 1000).toFixed(1) + 'k' : num;
    }
    
    // console.log ('ProfilePageUserRecognition userId', userId, 'entityType', entityType, 'visitorUserId', visitorUserId);
    useEffect(() => {
        if (userId) {
            const fetchData = async () => {
                try {
                    const followedByResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/follow/followedby/public/${userId}`);
                    const followingResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/follow/following/public/${userId}`);
                    const awardsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/award/${entityType}/${userId}`);
                    
                    if (!followedByResponse.ok || !followingResponse.ok) {
                        throw new Error('Error fetching follows data');
                    }
                    
                    const followedByData = await followedByResponse.json();
                    const followingData = await followingResponse.json();
                    const awardsData = await awardsResponse.json();

                    // Check if awardsData is an array before calling the sort function
                    if (Array.isArray(awardsData)) {
                        // Sort awardsData by date_awarded in descending order
                        awardsData.sort((a, b) => new Date(b.date_awarded) - new Date(a.date_awarded));
                    } else {
                        console.log("Awards data is not an array:", awardsData); // Log a message when awardsData is not an array
                    }

                    setFollowedByData(followedByData);
                    setFollowingData(followingData);

                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };
    
            fetchData();
        }
        else {
            console.log("No userId provided");
        }
    }, [userId, entityType]);

    if (!userData) {
        return <div>Oops! Sorry, we couldn't find this user.</div>;
    }

    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                gap: '10px',
                height: '100%',
                border: `1px solid ${theme.palette.neutral.main}`,
                borderRadius: '5px',
                backgroundColor: theme.palette.primary.dark, 
                boxShadow: `1px 1px 2px ${theme.palette.neutral.dark}`,
                transition: 'all 0.5s ease',
                '&:hover': {
                boxShadow: `1px 1px 2px ${theme.palette.neutral.light}`,
                cursor: 'pointer', 
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                <Box className='user-header-followers'>
                    <Box className='follow-count'>{formatNumber(followedByData.length)}</Box>
                    <Box className='follow-count-text'>Followers</Box>
                </Box>
                <Box className='user-header-followers'>
                    <Box className='follow-count'>{formatNumber(followingData.length)}</Box>
                    <Box className='follow-count-text'>Following</Box>
                </Box>
                <Box className='user-header-followers'>
                    <Box className='follow-count'>{userData.user.user_points}</Box>
                    <Box className='points-count-text' >Points</Box>
                </Box>
            </Box>
        </Box>
    );
}

export default ProfilePageUserRecognition;