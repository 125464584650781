import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../features/userauth/userAuthSlice';
import './login.scss';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    

    const handleLogin = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/v1/users/login`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ user_email: email, password }),
                    credentials: 'include',
                }
            );

            const data = await response.json();
            if (response.ok) {
                dispatch(
                    setCredentials({ user: data.user, token: data.accessToken })
                );
                const postLoginRedirect =
                    localStorage.getItem('postLoginRedirect') || '/';
                localStorage.removeItem('postLoginRedirect');
                navigate(postLoginRedirect);
            } else {
                setEmail('');
                setPassword('');
                setErrorMessage(
                    data.message || 'Login failed. Please try again.'
                );
            }
        } catch (error) {
            setEmail('');
            setPassword('');
            setErrorMessage('An error occurred while trying to log in.');
        }
    };

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box className="login-page">
            <Typography variant="h4" className="login-title">
                Login
            </Typography>
            {errorMessage && (
                <div className="error-message">
                    {errorMessage}
                    <span>
                        {' '}
                        Try again or{' '}
                        <Link textDecoration="none" to="/register">
                            register
                        </Link>{' '}
                        a new account.
                    </span>
                </div>
            )}
            <TextField
                className="email-input"
                autoFocus
                margin="dense"
                label="Email Address"
                type="email"
                fullWidth
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            <TextField
                className="password-input"
                margin="dense"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                value={password}
                onChange={e => setPassword(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handlePasswordVisibility}
                                edge="end"
                            >
                                {showPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button className="submit-button" onClick={handleLogin}>
                Login
            </Button>
        </Box>
    );
};

export default Login;
