import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Alert, Snackbar, styled, Select, MenuItem, useTheme, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import apiFetch from '../../helpers/apifetch';
import { useDispatch } from 'react-redux';
import { setCompanyInfo } from '../../features/stacks/temporaryStackSlice/temporaryStackSlice';
import CreateNewCompany from '../../userprofile/userCompany/createnewcompany/createNewCompany';
import UserDetailsFormPartial from '../../components/users/useraccount//userdetailsform/userDetailsFormPartial/userDetailsFormPartial';
import HeaderStreamed from '../../components/headerstreamed/HeaderStreamed';

const StackCompanyDetail = ({ userId, companyId, token, onNext, onBack }) => {
    const theme = useTheme();
    const [key, setKey] = useState(Date.now()); 
    const [openCreateNewCompany, setOpenCreateNewCompany] = useState(false);
    const [newCompanyId, setNewCompanyId] = useState(companyId);
    const [companyList, setCompanyList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(companyList[0]?.company_id || null);
    const dispatch = useDispatch();
    
    const NextButton = styled(Button)(({ theme }) => ({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.background.paper,
        '&:disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
        },
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.background.paper,
        },
        '&:disabled:hover': {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
        },
        transition: 'none',
    }));
    
    const BackButton = styled(Button)(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
    }));

    const handleCompanyChange = (event) => {
        setSelectedCompany(event.target.value);
        dispatch(setCompanyInfo({ companyId: event.target.value }));
    };

    const handleOpenCreateNewCompany = () => {
        setOpenCreateNewCompany(true);
    };

    const fetchCompanyList = useCallback(async () => {
        try {
            const companyResponse = await apiFetch(
                `${process.env.REACT_APP_API_URL}/api/v1/company/user_companies/${userId}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
                dispatch
            );
            if (!companyResponse.ok) {
                throw new Error(`HTTP error! status: ${companyResponse.status}`);
            }
            const companyData = await companyResponse.json();
            setCompanyList(companyData);
    
            // Set the first company as the selected company
            if (companyData.length > 0) {
                setSelectedCompany(companyData[0].company_id);
            }
        } catch (error) {
            console.error('Failed to fetch company data:', error);
        }
    }, [userId, token, dispatch]);

    useEffect(() => {
        fetchCompanyList();
    }, [fetchCompanyList]);

    const handleCompanyCreated = async (companyId) => {
        setNewCompanyId(companyId);
        setSelectedCompany(companyId); 

        
        await fetchCompanyList();

        
        setSuccessMessage('Company created successfully!');
        setOpenSuccessSnackbar(true);

        setOpenCreateNewCompany(false);
        setKey(Date.now()); // Key for page reload
    };

    
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccessSnackbar(false);
    };

    const handleNext = () => {
        let companyIdToDispatch = selectedCompany;
        if (newCompanyId) {
            companyIdToDispatch = newCompanyId;
        }
        dispatch(setCompanyInfo({ companyId: companyIdToDispatch }));

        onNext();
    };

    return (
        <Box key={key} sx={{ml: '20px'}}> 
            <Box >
                <Box sx={{mb: '20px'}}>
                    <HeaderStreamed title='What company is the stack for'/>
                </Box>
                <Snackbar 
                    open={openSuccessSnackbar} 
                    autoHideDuration={6000} 
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
                {companyList && companyList.length > 0 ? (
                    <Select
                        value={selectedCompany}
                        onChange={handleCompanyChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        size='small'
                        sx={{margin:'0px 0px 40px 10px', minWidth: '200px'}}
                    >
                        {companyList.map((company) => (
                            <MenuItem key={company.company_id} value={company.company_id}>
                                {company.company_name}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <Box>
                        <Box 
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'row',
                                gap: '30px',
                                mb: '50px'
                            }}
                        >
                            <Typography variant="body1">No company found under your profile. Create one now!</Typography>
                            <Typography color= 'secondary.main' variant="body1">Create one now!</Typography>
                            <IconButton 
                                sx={{
                                    size: 'default',
                                    backgroundColor: 'theme.palette.secondary.main',
                                    borderRadius: '50%',
                                    border: `2px solid ${theme.palette.secondary.main}`,

                                }}
                                size='large' onClick={handleOpenCreateNewCompany}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                        {openCreateNewCompany && <CreateNewCompany userId={userId} onCompanyCreated={handleCompanyCreated} open={openCreateNewCompany}/>}
                    </Box>
                )}

                {selectedCompany && <UserDetailsFormPartial userId={userId} token={token} companyId={selectedCompany} />}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <BackButton onClick={onBack}>Back</BackButton>
                <NextButton type="submit" onClick={handleNext} disabled={!selectedCompany && !newCompanyId}>Next</NextButton>
            </Box>
        </Box>
    );
};

export default StackCompanyDetail;