import React, { useState } from 'react';
import { TextField, Button, Chip, Box, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './feedInterests.scss'

const FeedInterest = () => {
    const [userInterests, setUserInterests] = useState([]);
    const [newInterest, setNewInterest] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    // Dummy data for suggested interests
    const suggestedInterests = ['#Technology', '#Science', '#Art', '#Music'];

    const handleAddInterest = (interest) => {
        if (interest && !userInterests.includes(interest)) {
            setUserInterests([...userInterests, interest]);
        }
    };

    const handleRemoveInterest = (interest) => {
        setUserInterests(userInterests.filter(i => i !== interest));
    };

    const handleAddCustomInterest = () => {
        handleAddInterest('#' + newInterest);
        setNewInterest('');
    };

    return (
        <Box className="feed-interests">
            <TextField
                label="Search Interests"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-field"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />

            <Box className="suggested-interests">
                {suggestedInterests.map(interest => (
                    <Chip
                        key={interest}
                        label={interest}
                        onClick={() => handleAddInterest(interest)}
                        className="interest-chip"
                    />
                ))}
            </Box>

            <Box className="add-custom-interest">
                <TextField
                    label="Add Interest"
                    variant="outlined"
                    value={newInterest}
                    onChange={(e) => setNewInterest(e.target.value)}
                    className="custom-interest-field"
                />
                <Button className="add-button" onClick={handleAddCustomInterest}>Add</Button>
            </Box>

            <Box className="user-interests">
                {userInterests.map(interest => (
                    <Chip
                        key={interest}
                        label={interest}
                        onDelete={() => handleRemoveInterest(interest)}
                        className="user-interest-chip"
                    />
                ))}
                {/* Dummy Chip for demonstration */}
                {userInterests.length === 0 && (
                    <Chip
                        label="#ExampleInterest"
                        onDelete={() => console.log('Remove interest')}
                        className="user-interest-chip"
                    />
                )}
            </Box>

            <Box className="action-buttons">
                <Button variant="contained" color="primary">Discard</Button>
                <Button variant="outlined" color="secondary">Save Changes</Button>
            </Box>
        </Box>
    );
};

export default FeedInterest;
