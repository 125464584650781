import { createSlice } from '@reduxjs/toolkit';

export const addToSlice = createSlice({
  name: 'addTo',
  initialState: {
    drawerOpen: false,
  },
  reducers: {
    toggleDrawer: (state, action) => {
      state.drawerOpen = action.payload;
    },
  },
});

export const { toggleDrawer } = addToSlice.actions;
export default addToSlice.reducer;