import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import apiFetch from '../../../../helpers/apifetch';
import { useNavigate } from 'react-router-dom';
import { fetchProducts } from '../../../../features/products/productsslice/productsSlice';
import { addProductToStack, removeProductFromStack } from '../../../../features/stacks/temporaryStackSlice/temporaryStackSlice';
import ProductsList from '../../../../components/product/productlisting/productlist/productList';
import NewStackPagination from './newstackpagination/newStackPagination';
import HeaderStreamed from '../../../../components/headerstreamed/HeaderStreamed';
import {Grid, Button, Select, Box, MenuItem, FormControl} from '@mui/material';
import ProductCard from '../../../../components/product/productCard/productCard';


import './newStackSelect.scss';

const NewStackSelect = ({token}) => {
    const dispatch = useDispatch();
    const {
        items: products,
        totalItems,
        loading,
        error,
    } = useSelector(state => state.products);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [fetchTrigger, setFetchTrigger] = useState(0);
    const navigate = useNavigate();
    const [domains, setDomains] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState('All');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const selectedProducts = useSelector(state => {return state.newStack.stackProducts});
    const stackDetails = useSelector(state => {return state.newStack.stackDetails});
    const stackInfo = useSelector(state => {return state.newStack.stackInfo});
    const companyInfo = useSelector(state => {return state.newStack.companyInfo});
    const personalInfo = useSelector(state => {return state.newStack.personalInfo});
    const userId = useSelector(state => state.auth.user?.user_id);

    const fetchDomains = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/product/domains`);
        if (response.ok) {
            const data = await response.json();
            setDomains(['All', ...data.map(d => d.domain_name)]);
        }
    };

    const fetchCategories = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/product/categories`);
        if (response.ok) {
            const data = await response.json();
            setCategories(['All', ...data.map(c => c.category_name)]);
        }
    };

    useEffect(() => {
        fetchDomains();
        fetchCategories();
    }, []);

    useEffect(() => {
        dispatch(
            fetchProducts({
                page: currentPage,
                limit: itemsPerPage,
                domain: selectedDomain,
                category: selectedCategory,
            })
        );
    }, [dispatch, currentPage, itemsPerPage, selectedDomain, selectedCategory, fetchTrigger]);

    const handleDomainChange = e => setSelectedDomain(e.target.value);
    const handleCategoryChange = e => setSelectedCategory(e.target.value);

    const handlePageChange = newPage => {
        setCurrentPage(newPage);
        setFetchTrigger(prev => prev + 1); 
    };

    const handleItemsPerPageChange = e => {
        setItemsPerPage(Number(e.target.value));
        setFetchTrigger(prev => prev + 1); 
    };
    
    const handleAddToStack = product => {
        dispatch(addProductToStack(product));
    };

    const handleRemoveFromStack = productId => {
        dispatch(removeProductFromStack(productId));
    };

    const handleSaveStack = async () => {
        const productsData = selectedProducts.map(product => ({
            product_id: product.product_id,
            product_in_stack: true, 
            created_at: new Date().toISOString(), 
            updated_at: new Date().toISOString(), 
        }));
        
        const stackData = {
            userId,
            ...stackDetails,
            ...personalInfo,
            ...companyInfo,
            ...stackInfo,
            products: productsData,
        };

        try {
            const response = await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/stacks/newstack`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(stackData),
                }, dispatch
            );
        
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            navigate(`/stack/profile/${data.stack_id}`);
        } catch (error) {
            console.error('Error saving stack:', error);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="new-stack-select-main">
            <HeaderStreamed
                title="Select products for your stack"
                subtitle="Add products to your stack by clicking on them"
            />
            <Box className="filter-menu-product-select">
                <div>
                    <div>Domain</div>
                    <select
                        value={selectedDomain}
                        label="Domain"
                        id="filter-domains"
                        onChange={handleDomainChange}
                    >
                        {domains.map(domain => (
                            <option key={domain} value={domain}>
                                {domain}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <div>Category</div>
                    <select
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    >
                        {categories.map(category => (
                            <option key={category} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </div>
            </Box>
            <Box className="new-stack-create-titles">
                <div
                    style={{
                        display: 'flex',
                        flex: '2',
                        justifyContent: 'center',
                    }}
                >
                    Choose products you use
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                    }}
                >
                    {' '}
                    Your Software Stack
                </div>
            </Box>
            <Box className="main-display-grids">
                <Grid
                    className="select-products-grid"
                    container
                    spacing={1}
                    item
                    xs={12}
                    sm={8}
                >
                    <ProductsList
                        products={products}
                        onAddToStack={handleAddToStack}
                        context="add-to-stack"
                    />
                </Grid>
                <Grid item xs={12} sm={4} className="user-stack-display-grid">
                    <Grid container spacing={2}>
                        {selectedProducts.map(product => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                key={product.product_id}
                            >
                                <ProductCard
                                    className="user-stack-product-card"
                                    productId={product.product_id}
                                    productData={product}
                                    context="remove-from-stack"
                                    onRemoveFromStack={() =>
                                        handleRemoveFromStack(
                                            product.product_id
                                        )
                                    }
                                    flexDirection="row"
                                    showName={false}
                                    showVendor={false}
                                    showCategory={false}
                                    showSubcategory={false}
                                    showSocialButtons={false}
                                    showBookmarkButton={false}
                                    showQuickView={false}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Box>
            <Box className="create-new-stack-pagingation">
                <NewStackPagination
                    className="pagination-options"
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
                <div className="items-per-page" sx={{ display: 'flex' }}>
                    <FormControl>
                        <Select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            style={{ height: '36px' }}
                        >
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {selectedProducts.length > 0 && (
                    <Button
                        variant='new'
                        onClick={handleSaveStack}
                        className="save-button"
                        style={{ alignSelf: 'flex-end', marginTop: 'auto' }}
                    >
                        Finish and Save
                    </Button>
                )}
            </Box>
        </div>
    );
};

export default NewStackSelect;
