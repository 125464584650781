import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box 
            display='flex'
            flexDirection= 'column'
            justifyContent= 'center'
            alignContent= 'center'
            maxWidth= '1200px'
            margin= '0 auto'
            padding= '10px'
            gap= '5px'
        >
            <Typography 
                variant="h2"
                color = {colors.grey[100]}
                fontWeight="bold"
                sx={{ mb: "5px"}}
            >
                {title}
            </Typography>
            <Typography 
                variant="h5"
                color = {colors.greenAccent[400]}
            >
                {subtitle}
            </Typography>
        </Box>
    )
}

export default Header;