// src/components/users/useraccount/userdetailsform>userPersonalFormPartial>userPersonalFormPartial.jsx

import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import apiFetch from '../../../../../helpers/apifetch';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import HeaderStreamed from '../../../../headerstreamed/HeaderStreamed';
import StaticDropdownOptions from '../../../../../data/StaticFormOptions/staticDropdownOptions.json';
import './userAccountDetailsForm.scss';



const fieldLabels = {
    user_business_relation: 'I work here as',
    user_business_function_id: 'What business function do you work in?',
    user_business_role_id: 'What role describes you best?',
    user_company_name: 'Company Name',
    user_company_url: 'Company URL', 
    user_company_size_fte: 'Company Size (FTE)',
    user_company_size_rev: 'Company Size (Revenue)',
    user_company_country_id: 'Company Country',
    user_company_industry_id: 'Company Industry',
    user_company_sector_id: 'Company Sector',
};


const UserAccountDetailsForm = ({ userId, onFormSubmission, requiredFields }) => {
    const [functions, setFunctions] = useState([]); 
    const [roles, setRoles] = useState([]); 
    const [industries, setIndustries] = useState([]); 
    const [countries, setCountries] = useState([]); 
    const [sectors, setSectors] = useState([]); 
    const [selectedFunction, setSelectedFunction] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const token = useSelector(state => state.auth.token);
    const [, setUpdatedUser] = useState(null);

    const [initialValues, setInitialValues] = useState({
        user_business_relation: '',
        user_business_function_id: '',
        user_business_role_id: '',
        user_company_name: '',
        user_company_url: '',
        user_company_size_fte: '',
        user_company_size_rev: '',
        user_company_country_id: '',
        user_company_industry_id: '',
        user_company_sector_id: '',
    });
      
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiFetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const newInitialValues = requiredFields.reduce((acc, field) => {
                    acc[field] = data[field] || '';
                    return acc;
                }, {});
                setInitialValues(newInitialValues);
                setSelectedFunction(data.user_business_function_id ? data.user_business_function_id.toString() : '');
                setSelectedIndustry(data.user_company_industry_id ? data.user_company_industry_id.toString() : '');
                
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchData();
    }, [userId, requiredFields, token]);
    
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/form/company_country`);
                if (!response.ok) throw new Error('Failed to fetch functions');
                const data = await response.json();
                setCountries(data);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);
    

    useEffect(() => {
        const fetchFunctions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/form/business_function`);
                if (!response.ok) throw new Error('Failed to fetch functions');
                const data = await response.json();
                setFunctions(data);
            } catch (error) {
                console.error('Error fetching functions:', error);
            }
        };
        fetchFunctions();
    }, []);

    useEffect(() => {
        const fetchRoles = async () => {
            if (!selectedFunction) return;

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/form/business_role?functionId=${selectedFunction}`);
                if (!response.ok) throw new Error('Failed to fetch roles');
                const data = await response.json();
                setRoles(data);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        fetchRoles();
    }, [selectedFunction]);


    useEffect(() => {
        const fetchIndustries = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/form/company_industry`);
                if (!response.ok) throw new Error('Failed to fetch industries');
                const data = await response.json();
                setIndustries(data);
            } catch (error) {
                console.error('Error fetching industries:', error);
            }
        };
        fetchIndustries();
    }, []);

    useEffect(() => {
        const fetchSectors = async () => {
            if (!selectedIndustry) return;

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/form/company_sector?industryId=${selectedIndustry}`);
                if (!response.ok) throw new Error('Failed to fetch sectors');
                const data = await response.json();
                setSectors(data);
            } catch (error) {
                console.error('Error fetching sectors:', error);
            }
        };
        fetchSectors();
    }, [selectedIndustry]);


    const validationSchema = Yup.object().shape({
        user_business_relation: Yup.string().required('Required'),
        user_business_function_id: Yup.number().required('Required'),
        user_business_role_id: Yup.number().required('Required'),
        user_company_name: Yup.string().required('Required'),
        user_company_url: Yup.string().url('Invalid URL').required('Required'),
        user_company_size_fte: Yup.string().required('Required'),
        user_company_size_rev: Yup.string().required('Required'),
        user_company_country_id: Yup.number().required('Required'),
        user_company_industry_id: Yup.number().required('Required'),
        user_company_sector_id: Yup.number().required('Required'),
    });

    
    const handleSubmit = async (values, { setSubmitting }) => {
        const payload = {
            ...values, 
            user_business_relation: values.user_business_relation,
            user_business_function_id: parseInt(values.user_business_function_id),
            user_business_role_id: parseInt(values.user_business_role_id),
            user_company_name: values.user_company_name,
            user_company_url: values.user_company_url,
            user_company_size_fte: values.user_company_size_fte,
            user_company_size_rev: values.user_company_size_rev,
            user_company_country_id: parseInt(values.user_company_country_id),
            user_company_industry_id: parseInt(values.user_company_industry_id),
            user_company_sector_id: parseInt(values.user_company_sector_id),
        };

        try {
            const updatedUserData = await apiFetch(`/api/v1/userDetails/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            setUpdatedUser(updatedUserData);
    
        } catch (error) {
            console.error('Update error:', error);
            onFormSubmission(false);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
        <HeaderStreamed title='Complete your details' subtitle='Fill out the form to create a new stack'/>
        <Formik 
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            >
            {({ isSubmitting, setFieldValue, values, touched }) => (
                
                <Form className="user-details-form-partial">
                    <div className="new-stack-form-left-column">
                        
                        <div className="partial-form-row">
                            <label htmlFor="user_company_name">{fieldLabels.user_company_name}</label>
                            <Field 
                                id="user_company_name"
                                name="user_company_name"
                                className={touched.user_company_name ? 'error-field' : ''}
                            />
                        </div>
                        
                        <div className="partial-form-row">
                            <label htmlFor="user_company_url">{fieldLabels.user_company_url}</label>
                            <Field 
                                id="user_company_url"
                                name="user_company_url"
                                className={touched.user_company_url ? 'error-field' : ''}
                            />
                        </div>
                        
                        <div className="partial-form-row">
                            <label htmlFor="user_company_country">HQ Location</label>
                            <Field
                                as="select"
                                id="user_company_country"
                                name="user_company_country_id"
                        
                                value={values.user_company_country_id}
                                className={touched.user_company_country_id ? 'error-field' : ''}
                                onChange={(e) => {setFieldValue('user_company_country_id', e.target.value);}}
                            >    
                                <option value="">Select Country...</option>
                                {countries.map((cont) => (
                                    <option key={cont.country_id} value={cont.country_id}>{cont.country_name}</option>
                                ))}
                            </Field>
                        </div>
                        
                        <div className="partial-form-row">
                            <label htmlFor="user_company_industry">Company Industry</label>
                            <Field 
                                as="select"
                                id="user_company_industry"
                                name="user_company_industry_id"
                                value={values.user_company_industry_id}
                                className={touched.user_company_industry_id ? 'error-field' : ''}
                                onChange={(e) => { setFieldValue('user_company_industry_id', e.target.value);
                                setSelectedIndustry(e.target.value); }}
                            >
                                <option value="">Company industry...</option>
                                {industries.map((ind) => (
                                    <option key={ind.industry_id} value={ind.industry_id}>{ind.industry_name}</option>
                                ))}
                            </Field>
                        </div>
                        
                        <div className="partial-form-row">
                            <label htmlFor="user_company_sector">Company Sector</label>
                            <Field 
                                as="select"
                                id="user_company_sector"
                                name="user_company_sector_id"
                                value={values.user_company_sector_id}
                                className={touched.user_company_sector_id ? 'error-field' : ''}
                                onChange={(e) => setFieldValue('user_company_sector_id', e.target.value)}
                            >
                                <option value="">Select an Industry Sector...</option>
                                {sectors.map((sec) => (
                                    <option key={sec.sector_id} value={sec.sector_id}>{sec.sector_name}</option>
                                ))}
                            </Field>
                        </div>
                    </div>
                    <div className="new-stack-form-right-column">
                        
                        <div className="partial-form-row">
                            <label htmlFor="user_company_size_fte">{fieldLabels.user_company_size_fte}</label>
                            <Field 
                                as="select"
                                id="user_company_size_fte"
                                name="user_company_size_fte"
                                value={values.user_company_size_fte}
                                className={touched.user_company_size_fte ? 'error-field' : ''}
                            >
                                <option value="">Select...</option>
                                {StaticDropdownOptions.companySizeFTE.map(option => (
                                    <option key={option.id} value={option.value}>{option.label}</option>
                                ))}
                            </Field>
                        </div>
                        
                        <div className="partial-form-row">
                            <label htmlFor="user_company_size_rev">{fieldLabels.user_company_size_rev}</label>
                            <Field 
                                as="select"
                                id="user_company_size_rev"
                                name="user_company_size_rev"
                                value={values.user_company_size_rev}
                                className={touched.user_company_size_rev ? 'error-field' : ''}
                            >
                                <option value="">Select...</option>
                                {StaticDropdownOptions.companySizeRevenue.map(option => (
                                    <option key={option.id} value={option.value}>{option.label}</option>
                                ))}
                            </Field>
                        </div>
                        
                        <div className="partial-form-row">
                            <label htmlFor="business_relation">{fieldLabels.user_business_relation}</label>
                            <Field 
                                id="business_relation"
                                as="select"
                                name="user_business_relation"
                                value={values.user_business_relation}
                                className={touched.user_business_relation ? 'error-field' : ''}
                            >
                                <option value="">Select...</option>
                                {StaticDropdownOptions.businessRelations.map(option => (
                                    <option key={option.id} value={option.value}>{option.label}</option>
                                ))}
                            </Field>
                        </div>
                        
                        <div className="partial-form-row">
                            <label htmlFor="business_function">Business Function</label>
                            <Field
                                as="select"
                                id="business_function"
                                name="user_business_function_id"
                                value={values.user_business_function_id}
                                className={touched.user_business_function_id ? 'error-field' : ''}
                                onChange={(e) => {setFieldValue('user_business_function_id', e.target.value);
                                setSelectedFunction(e.target.value);}}
                            >
                                <option value="">Select a function...</option>
                                {functions.map((func) => (
                                    <option key={func.business_function_id} value={func.business_function_id}>{func.business_function_name}</option>
                                ))}
                            </Field>
                        </div>
                        
                        <div className="partial-form-row">
                            <label htmlFor="user_business_role_id">Business Role</label>
                            <Field
                                as="select"
                                id="user_business_role"
                                name="user_business_role"
                                value={values.user_business_role_id}
                                onChange={(e) => setFieldValue('user_business_role_id', e.target.value)}
                                className={touched.user_business_role_id ? 'error-field' : ''}
                            >
                                <option value="">Select a role...</option>
                                {roles.map((role) => (
                                    <option key={role.business_role_id} value={role.business_role_id}>{role.business_role_name}</option>
                                ))}
                            </Field>
                        </div>
                        <button type="submit" disabled={isSubmitting} className="submit-btn">Update</button>
                    </div>
                </Form>
            )}
        </Formik>
        </>
    );
};

export default UserAccountDetailsForm;
