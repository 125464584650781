//src>userprofile>userPublicProfile>userpublicprofilemain>userPublicProfileMain.jsx
import React, { useState, useEffect } from 'react';
import { UserProvider } from '../../../contexts/userContext';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTheme } from "@emotion/react";
import { Box, Tab, Tabs } from '@mui/material';
import HeaderStreamed from '../../../components/headerstreamed/HeaderStreamed';
import UserProfileHeader from '../../../components/users/profilepage/profilepageheader/profilepageheadermain/userProfileHeader';
import MyStacksMain from '../../../runnercore/mystacks/myStacksMain';
import ProfileActivityUser from '../activityUser/profileActivityUser';
import ProfileAboutUser from '../aboutUser/profileAboutUser';
import ProfileStacksUser from '../stacksUser/profileStacksUser';
import ProfileProductsUser from '../productsUser/profileProductsUser';
import ProfilePeopleUser from '../peopleUser/profilePeopleUser';
import apiFetch from '../../../helpers/apifetch';
import  "./userPublicProfileMain.scss";

import ProfileContentUser from '../contentUser/profileContentUser';

const UserPublicProfileMain = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const visitorUserId = useSelector(state => state.auth.user?.user_id); 
  const [entityTypeId, setEntityTypeId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [visitorUserData, setVisitorUserData] = useState(null);
  const token = useSelector(state => state.auth.token);
  
  useEffect(() => {
    if (!userId) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/userDetails/user-profile/${userId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const userData = await response.json();
        setUserData(userData);
        setEntityTypeId(userData.user.entity_id);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (!isLoading && userId) {
      fetchUserData();
    }
  }, [userId, isLoading]);

  useEffect(() => {
    const fetchVisitorUserData = async () => {
      try {
        const response = await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/userDetails/user-profile/${visitorUserId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const visitorUserData = await response.json();
        setVisitorUserData(visitorUserData);
        // console.log('visitorUserData', visitorUserData);
      } catch (error) {
        console.error('Error fetching visitor user data:', error);
      }
    };
  
    if (visitorUserId) {
      fetchVisitorUserData();
    }
  }, [visitorUserId, token]);
  
  
  
  const tabsList = ['About', 'Activity', 'Stacks', 'Products', 'People', 'Companies', 'Content', 'Community'];
  // need to map or components display on wrong tabs
  const planTabs = {
    873: ['About', 'Activity', 'Stacks', 'Products', 'People', 'Companies', 'Content', 'Community'],  
    1: ['About', 'Activity', 'Stacks', 'Products', 'People'],
    3: ['About', 'Activity', 'Stacks']
  };
  
  const visitorUserPlan = visitorUserData?.user?.user_plan;
  const filteredTabsList = tabsList.filter(tab => planTabs[visitorUserPlan]?.includes(tab));


  return (
    <UserProvider userId={userId}>
      <Box 
        className='user-profile-page-container-main' 
        sx={{ 
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box 
          className='user-profile-header-container' 
          sx={{
            position: 'sticky',
            top: '64px',
            zIndex: 20,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <UserProfileHeader userId={userId} userData={userData} entityTypeId={entityTypeId} visitorUserId={visitorUserId}/>
        </Box>
        <Box className="user-profile-tabs-container">
          <Box 
            className='sticky-profile-tabs-container' 
            sx={{
              position: 'sticky',
              top: '241px',
              zIndex: 10,
              backgroundColor: theme.palette.background.default,
              borderBottom: `1px solid ${theme.palette.neutral.dark}`
              }}
            >
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => setSelectedTab(newValue)}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"

              sx={{
                '& .MuiTab-root': {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    color: theme.palette.neutral.main,
                    '&:not(:last-child)': {
                        borderRight: 'none'
                    }
                  },
                '& .MuiTab-textColorPrimary.Mui-selected': {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    borderBottom: `2px solid ${theme.palette.secondary.main}`
                  },
                justifyContent: 'flex-start',
                marginBottom: '5px',
              }}
            >
              {filteredTabsList.map((tab, index) => (
                <Tab key={index} label={tab} aria-label={tab}/>
              ))}
            </Tabs>
          </Box>
            {selectedTab === 0 && (
                <Box className="importPageDisplay">
                    <ProfileAboutUser userId={userId} userData={userData} entityTypeId={entityTypeId} visitorUserId={visitorUserId}/>
                </Box>
            )}
            {selectedTab === 1 && (
                <Box className="importPageDisplay">
                    <ProfileActivityUser userId={userId}/>
                </Box>
            )}
            {selectedTab === 2 && (
                <Box className="importPageDisplay">
                    <ProfileStacksUser userId={userId} userData={userData} entityTypeId={entityTypeId} visitorUserId={visitorUserId}/>
                </Box>
            )}
            {selectedTab === 3 && (
                <Box className="importPageDisplay">
                    <ProfileProductsUser userId={userId} userData={userData} entityTypeId={entityTypeId} visitorUserId={visitorUserId}/>
                </Box>
            )}
            {selectedTab === 4 && (
                <Box className="importPageDisplay">
                    <ProfilePeopleUser userId={userId} userData={userData} entityTypeId={entityTypeId} visitorUserId={visitorUserId}/>
                </Box>
            )}
            {selectedTab === 5 && (
                <Box className="importPageDisplay">
                    <HeaderStreamed title="Companies" subtitle="my org, orgs i'm invited to, following, vendors (product + service)"/>
                    <p>streamed page</p>
                </Box>
            )}
            {selectedTab === 6 && (
                <Box className="importPageDisplay">
                    <HeaderStreamed title="Content" subtitle="posts, articles, etc. where user is author"/>
                    <ProfileContentUser userId={userId}/>
                </Box>
            )}
            {selectedTab === 7 && (
                <Box className="importPageDisplay">
                    <HeaderStreamed title="Community" subtitle="page for user chatter, comments, reviews"/>
                    <p>still to be defined better vis-a-vis 'Interests' and 'Activity'</p>
                    <MyStacksMain/>
                </Box>
            )}
            
        </Box>
      </Box>
    </UserProvider>
  );
};

export default UserPublicProfileMain;