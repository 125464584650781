import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import ProductQuickView from '../productQuickView/productQuickView';
import LikeButton from '../../socials/likes/likeButton';
import FollowButton from '../../socials/follow/followButton';
import SaveButton from '../../socials/saves/saveButton';
import TrialButton from '../../socials/trialling/trialButton';
import NopeButton from '../../socials/nope/nopeButton';
import ShareButton from '../../socials/shares/shareButton';
import EvaluateButton from '../../socials/evaluate/evaluateButton';
import UsingButton from '../../socials/using/usingButton';
import './productCard.scss';

const ProductCard = ({
    userData,
    productId,
    productData, // passed as prop for 'remove-from-stack' context
    pathDetails,
    showName = false,
    showVendor = false,
    showCategory = false,
    showSubcategory = false,
    showSocialButtonsOne = false, 
    showSocialButtonsTwo = false, 
    showQuickView = true, 
    flexDirection = 'row',
    productNameStyle,
    onAddToStack,
    onRemoveFromStack,
    onViewDetail,
    context
}) => {
    const userId = useSelector(state => state.auth.user ? state.auth.user.user_id : null);
    const [quickViewOpen, setQuickViewOpen] = useState(false);
    const [localProductData, setLocalProductData] = useState(productData);
    const [localPathDetails, setLocalPathDetails] = useState(pathDetails);
    const entityType = 'product';
    
    
    useEffect(() => {
        if (!productData && context !== 'remove-from-stack' && productId) {
            const fetchProduct = async () => {
                try {
                    const productResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/product/${productId}`);
                    if (!productResponse.ok) {
                        throw new Error(`HTTP error! status: ${productResponse.status}`);
                    }
                    const data = await productResponse.json();
                    setLocalProductData(data);
                } catch (error) {
                    console.error('Error fetching product:', error);
                }
            };

            if (productId) {
                fetchProduct();
            }
        }
    }, [productId, context, productData]);

    useEffect(() => {
        const fetchPathDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/product/details/${productId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const pathDetails = await response.json();
                setLocalPathDetails(pathDetails);
                // console.log('Product Path Details:', pathDetails);
            } catch (error) {
                console.error('A problem occurred while fetching the product details:', error);
            }
        };
    
        if (!pathDetails && productId) {
            fetchPathDetails();
        }
    }, [pathDetails, productId]);


    const handleOpenQuickView = (event) => {
        event.stopPropagation(); 
        setQuickViewOpen(true);
        console.log('Quick view opened');
    };

    const handleCloseQuickView = () => {
        setQuickViewOpen(false);
        console.log('QuickView closed');
    };
    
    const handleCardClick = () => {
        if (context === 'add-to-stack' && onAddToStack) {
            onAddToStack(localProductData);
        } else if (context === 'remove-from-stack' && onRemoveFromStack) {
            onRemoveFromStack(productId); 
        } else if (onViewDetail) {
            onViewDetail(productId);
        }
    };

    if (!localProductData) {
        return <div>Loading...</div>;
    }

    return (
        <Box className={`product-card-main ${flexDirection === 'column' ? 'column-direction' : ''}`} onClick={handleCardClick}>
            <Box className="product-card-image-context">
                <div style={{ position: 'relative' }}>
                    <Box className="product-card-logo" style={{ width: '80px', height: '80px' }}>
                        <img src={localProductData.product_thumb_logo} alt={localProductData.product_name} style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '50%' }} />
                    </Box>
                    {showQuickView && (
                        <IconButton 
                            className='product-card-show-quickview'
                            style={{ position: 'absolute', top: -14, left: -14 }}
                            onClick={handleOpenQuickView}
                        >
                            <VisibilityIcon style={{color:'black', borderRadius: '50%', padding: '1px', backgroundColor: '#ffebe9', fontSize:'22'}}/>
                        </IconButton>
                    )}
                    {context === 'remove-from-stack' && (
                        <IconButton 
                            style={{ position: 'absolute', top: -12, right: -12 }}
                            onClick={(e) => { e.stopPropagation(); onRemoveFromStack(productId); }}
                        >
                            <CloseIcon style={{fontSize:'14'}}/>
                        </IconButton>
                    )}
                </div>
            </Box>
            <Box className="product-card-details">
                {showName && <Typography className="product-name" style={productNameStyle}>{localProductData.product_name}</Typography>}
                {showVendor && <Typography className="vendor-name">by {localProductData.product_vendor_name}</Typography>}
                {showCategory && <Typography className="product-category">Category: {localProductData.primary_category_name}</Typography>}
                {showSubcategory && <Typography className="product-subcategory"> Sub-Category: {localProductData.primary_sub_category_name}</Typography>}
            </Box>
            <Box className='product-card-action-menu'>
                {showSocialButtonsOne && (
                    <Box className="product-card-actions-one">
                        <LikeButton showCount={false} entityId={productId} userId={userId} entityType={entityType}/>
                        <FollowButton showCount={false} entityId={productId} userId={userId} entityType={entityType}/>
                        <SaveButton showCount={false} entityId={productId} userId={userId} entityType={entityType}/>
                        <ShareButton showCount={false} entityId={productId} userId={userId} entityType={entityType}/>
                    </Box>
                )}
                {showSocialButtonsTwo && (
                    <Box className="product-card-actions-two">
                        <TrialButton showCount={false} entityId={productId} userId={userId} entityType={entityType}/>
                        <NopeButton showCount={false} entityId={productId} userId={userId} entityType={entityType}/>
                        <EvaluateButton showCount={false} entityId={productId} userId={userId} entityType={entityType}/>
                        <UsingButton showCount={false} showText={false} entityId={productId} userId={userId} entityType={entityType}/>
                    </Box>
                )}
            </Box>
            {quickViewOpen && <ProductQuickView userId={userId} productId={productId} product={localProductData} entityType={entityType} pathDetails={localPathDetails} open={quickViewOpen} onClose={handleCloseQuickView} />}
        </Box>
    );
};

export default ProductCard;
