import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, FormControlLabel, Checkbox, Button, Box, useTheme, styled, Grid, IconButton, Popover, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { setStackDetails } from '../../features/stacks/temporaryStackSlice/temporaryStackSlice';
import HeaderStreamed from '../../components/headerstreamed/HeaderStreamed';

const creationModes = {
    "Create from Scratch": 1,
};

const StyledButton = styled(Button)(({ theme, selected }) => ({
    backgroundColor: selected ? theme.palette.secondary.main : theme.palette.neutral.main,
    color: selected ? theme.palette.primary.main : theme.palette.text.primary,
    
    '&:hover': {
        backgroundColor: selected ? theme.palette.secondary.main : theme.palette.neutral.main,
    },
    transition: 'none',
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.secondary.main,
    '&.Mui-checked': {
      color: theme.palette.secondary.main,
    },
    '&.MuiCheckbox-root': {
      color: theme.palette.neutral.light,
    },
}));

const NextButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.paper,
    '&:disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
    },
    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.background.paper,
    },
    '&:disabled:hover': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
    },
    transition: 'none',
}));

const BackButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
}));

const NewStackInfo = ({ onNext, onBack }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [formValues, setFormValues] = useState({
        stack_name: '',
        stack_description: '',
        stack_is_official: false,
        stack_create_mode_id: creationModes["Create from Scratch"],
        stack_purpose: '', 
        stack_type: '',
    });
    const isNextDisabled = !formValues.stack_name || !formValues.stack_purpose || !formValues.stack_type;

    const [anchorElPurpose, setAnchorElPurpose] = useState(null);
    const [anchorElType, setAnchorElType] = useState(null);
    const [anchorElName, setAnchorElName] = useState(null);
    const [anchorElDescription, setAnchorElDescription] = useState(null);
    const [anchorElOfficial, setAnchorElOfficial] = useState(null);

    
    
    const handlePopoverOpenPurpose = (event) => {
        setAnchorElPurpose(event.currentTarget);
    };
    
    const handlePopoverOpenType = (event) => {
        setAnchorElType(event.currentTarget);
    };
    
    const handlePopoverOpenName = (event) => {
        setAnchorElName(event.currentTarget);
    };
    
    const handlePopoverOpenDescription = (event) => {
        setAnchorElDescription(event.currentTarget);
    };

    const handlePopoverOpenOfficial = (event) => {
        setAnchorElOfficial(event.currentTarget);
    };

    const handlePopoverClosePurpose = () => {
        setAnchorElPurpose(null);
    };
    
    const handlePopoverCloseType = () => {
        setAnchorElType(null);
    };

    const handlePopoverCloseName = () => {
        setAnchorElName(null);
    };
    
    const handlePopoverCloseDescription = () => {
        setAnchorElDescription(null);
    };

    const handlePopoverCloseOfficial = () => {
        setAnchorElOfficial(null);
    };

    const openPurpose = Boolean(anchorElPurpose);
    const openType = Boolean(anchorElType);
    const openName = Boolean(anchorElName);
    const openDescription = Boolean(anchorElDescription);
    const openOfficial = Boolean(anchorElOfficial);

    const handleChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleCheckboxChange = (event) => {
        setFormValues({
            ...formValues,
            stack_is_official: event.target.checked,
        });
    };

    const handleTypeChange = (type) => {
        setFormValues({
            ...formValues,
            stack_type: type,
        });
    };

    const handlePurposeChange = (purpose) => {
        setFormValues({
            ...formValues,
            stack_purpose: purpose,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(setStackDetails(formValues)); 
        onNext(formValues.stack_purpose); 
    };


    return (
        <Box
            sx={{ border: `1px solid ${theme.palette.common.white}`, padding: '10px', borderRadius: '5px', display: 'flex', flexDirection: 'column', gap: 2 }}
        >
            <Box sx={{margin: '0px'}}>
                <HeaderStreamed title='Enter Stack Details' subtitle=' Choose stack purpose, type and other details'/>
            </Box>
            <form onSubmit={handleSubmit}>
                <Box sx={{ minWidth: '500px', display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Typography>Stack Purpose</Typography>
                                <IconButton
                                    aria-owns={openPurpose ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpenPurpose}
                                    onMouseLeave={handlePopoverClosePurpose}
                                    sx={{ position: 'absolute', right: 15, top: -15, padding: '5px' }}
                                >
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={openPurpose}
                                    anchorEl={anchorElPurpose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClosePurpose}
                                    disableRestoreFocus
                                >
                                    <Typography variant='h6' sx={{ p: 1 }}>Choose whether the stack is for personal or business use</Typography>
                                </Popover>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                                <StyledButton selected={formValues.stack_purpose === 'Personal'} onClick={() => handlePurposeChange('Personal')}>Personal</StyledButton>
                                <StyledButton selected={formValues.stack_purpose === 'Business'} onClick={() => handlePurposeChange('Business')}>Business</StyledButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Typography>Stack Type</Typography>
                                <IconButton
                                    aria-owns={openType ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpenType}
                                    onMouseLeave={handlePopoverCloseType}
                                    sx={{ position: 'absolute', right: 15, top: -15, padding: '5px' }}
                                >
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={openType}
                                    anchorEl={anchorElType}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverCloseType}
                                    disableRestoreFocus
                                >
                                    <Typography variant='h6' sx={{ p: 1 }}>Choose whether the stack is for business-wide use or for selected functions</Typography>
                                </Popover>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                                <StyledButton selected={formValues.stack_type === 'Business-wide'} onClick={() => handleTypeChange('Business-wide')}>Business-wide</StyledButton>
                                <StyledButton selected={formValues.stack_type === 'Selected Functions'} onClick={() => handleTypeChange('Selected Functions')}>Selected Functions</StyledButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Typography>Stack Name</Typography>
                                <IconButton
                                    aria-owns={openName ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpenName}
                                    onMouseLeave={handlePopoverCloseName}
                                    sx={{ position: 'absolute', right: 15, top: -15, padding: '5px' }}
                                >
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={openName}
                                    anchorEl={anchorElName}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverCloseName}
                                    disableRestoreFocus
                                >
                                    <Typography variant='h6' sx={{ p: 1 }}>Enter the name of the stack</Typography>
                                </Popover>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField
                                label="Name"
                                name="stack_name"
                                value={formValues.stack_name}
                                onChange={handleChange}
                                required
                                // helperText="Enter the name of the stack"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Typography>Description</Typography>
                                <IconButton
                                    aria-owns={openDescription ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpenDescription}
                                    onMouseLeave={handlePopoverCloseDescription}
                                    sx={{ position: 'absolute', right: 15, top: -15, padding: '5px' }}
                                >
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={openDescription}
                                    anchorEl={anchorElDescription}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverCloseDescription}
                                    disableRestoreFocus
                                >
                                    <Typography variant='h6' sx={{ p: 1 }}>Enter a description for the stack</Typography>
                                </Popover>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField
                                label="Description"
                                name="stack_description"
                                value={formValues.stack_description}
                                onChange={handleChange}
                                fullWidth
                                multiline
                                rows={2}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Typography>Is Official</Typography>
                                <IconButton
                                    aria-owns={openOfficial ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpenOfficial}
                                    onMouseLeave={handlePopoverCloseOfficial}
                                    sx={{ position: 'absolute', right: 15, top: -15, padding: '5px' }}
                                >
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={openOfficial}
                                    anchorEl={anchorElOfficial}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverCloseOfficial}
                                    disableRestoreFocus
                                >
                                    <Typography variant='h6' sx={{ p: 1 }}>
                                        When checked, this stack becomes your primary personal or busioness stack used to create contextual insights and comparisons 
                                    </Typography>
                                </Popover>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <FormControlLabel
                                control={
                                    <StyledCheckbox
                                        name="stack_is_official"
                                        checked={formValues.stack_is_official}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label="Is Official"
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                        <BackButton onClick={onBack}>Back</BackButton>
                        <NextButton type="submit" disabled={isNextDisabled}>Next</NextButton>
                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default NewStackInfo;