import React from 'react';
import { TextField } from '@mui/material';
import './textfieldFilterComponent.scss';

const TextFieldFilter = ({ label, value, onChange }) => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      variant="outlined"
      size="small"
      fullWidth
    />
  );
}

export default TextFieldFilter;