// src/features/stacks/temporaryId/temporaryIdSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tempStack: {
    details: {
      purpose: '',
      companyId: '',
    },
    info: {
      stack_name: '',
      stack_description: '',
      stack_type: '',
      stack_is_official: false,
      stack_create_mode_id: null,
      stack_author_user_id: '',
      stack_owner_company_id: '',
      stack_owner_user_admin_id: '',
    },
    products: []
  }
};

export const temporaryIdSlice = createSlice({
  name: 'tempstackid',
  initialState,
  reducers: {
    setStackInfo: (state, action) => {
      state.tempStack.info = { ...state.tempStack.info, ...action.payload };
    },
    setStackDetails: (state, action) => {
      state.tempStack.details = { ...state.tempStack.details, ...action.payload };
    },
    addProductToStack: (state, action) => {
      state.tempStack.products.push(action.payload);
    },
    removeProductFromStack: (state, action) => {
      state.tempStack.products = state.tempStack.products.filter(
        product => product.product_id !== action.payload
      );
    },
    resetStack: state => {
      state.tempStack = initialState.tempStack;
    }
  }
});

export const { setStackInfo, setStackDetails, addProductToStack, removeProductFromStack, resetStack } = temporaryIdSlice.actions;

export default temporaryIdSlice.reducer;
