import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Switch } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import apiFetch from '../../../../helpers/apifetch';

const ToggleControlsAdmin = () => {
    const token = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const [plans, setPlans] = useState([]);
    const [featureGroups, setFeatureGroups] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch both feature master data and plan-feature data
    useEffect(() => {
        const combineDataForRows = (masterFeatures, planFeatures) => {
            return masterFeatures.map((masterFeature) => {
                const planData = plans.reduce((acc, plan) => {
                    const planFeature = planFeatures.find(pf => pf.platform_feature_id === masterFeature.platform_feature_id && pf.plan_id === plan.plan_id);
                    acc[`plan_${plan.plan_id}`] = {
                        is_enabled: planFeature?.is_enabled || false,
                        plan_feature_id: planFeature?.plan_feature_id
                    };
                    return acc;
                }, {});
                return {
                    id: masterFeature.platform_feature_id,
                    name: masterFeature.platform_feature_name,
                    group: featureGroups.find(fg => fg.feature_group_id === masterFeature.feature_group_id)?.feature_group_name || '',
                    is_active: masterFeature.is_enabled,
                    created_at: masterFeature.created_at, 
                    updated_at: masterFeature.updated_at,
                    ...planData
                };
            });
        };

        const fetchData = async () => {
            setLoading(true);
            try {
                const masterResponse = await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/features/platform-features`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const planResponse = await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/features/plan-features`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                const masterData = await masterResponse.json();
                const planData = await planResponse.json();

                const combinedRows = combineDataForRows(masterData.features, planData.features);
                setRows(combinedRows);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [token, dispatch, plans, featureGroups]);

    // Fetch plans and feature groups
    useEffect(() => {
        const fetchPlansAndGroups = async () => {
            try {
                const plansResponse = await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/features/plans`, { headers: { Authorization: `Bearer ${token}` }});
                const featureGroupsResponse = await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/features/feature-groups`, { headers: { Authorization: `Bearer ${token}` }});

                const plansData = await plansResponse.json();
                const featureGroupsData = await featureGroupsResponse.json();

                setPlans(plansData);
                setFeatureGroups(featureGroupsData);
            } catch (error) {
                console.error('Error fetching plans and feature groups:', error);
            }
        };
        fetchPlansAndGroups();
    }, [token, dispatch]);

    // Handle master toggle event
    const handleMasterToggle = async (platformFeatureId, newState) => {
        try {
            await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/features/update-master/${platformFeatureId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
                body: JSON.stringify({ isActive: newState }),
            });
            // Update the rows state to reflect the change
            setRows(prevRows => prevRows.map(row => row.id === platformFeatureId ? {...row, is_active: newState} : row));
        } catch (error) {
            console.error('Error toggling master feature:', error);
        }
    };


    // Handle plan-feature toggle event
    const handleToggle = async (planFeatureId, newState) => {
        try {
            await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/features/update/${planFeatureId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
                body: JSON.stringify({ is_enabled: newState }),
            });
            // Update the rows state to reflect the change
            setRows(prevRows => prevRows.map(row => {
                const planFeatureKey = Object.keys(row).find(key => row[key]?.plan_feature_id === planFeatureId);
                if (planFeatureKey) {
                    return {...row, [planFeatureKey]: {...row[planFeatureKey], is_enabled: newState}};
                }
                return row;
            }));
        } catch (error) {
            console.error('Error toggling feature:', error);
        }
    };

    // Generate columns dynamically based on plans
    const columns = [
        { field: 'id', headerName: 'ID', width: 50, headerAlign: 'center' },
        { field: 'name', headerName: 'Feature Name', width: 200, headerAlign: 'center' },
        { field: 'group', headerName: 'Feature Group', width: 200, headerAlign: 'center' },
        { field: 'created_at', headerName: 'Created At', width: 120, headerAlign: 'center' },
        { field: 'updated_at', headerName: 'Updated At', width: 120, headerAlign: 'center' },
        { field: 'is_active', headerName: 'Is Active', width: 100, headerAlign: 'center', renderCell: params => (
            <Switch
                checked={params.row.is_active}
                onChange={(event, newState) => {
                    event.stopPropagation(); // Prevent triggering onCellClick
                    handleMasterToggle(params.row.id, newState);
                }}
                color={params.row.is_active ? 'secondary' : 'primary'}
            />
        )},
        ...plans.map(plan => ({
            field: `plan_${plan.plan_id}`,
            headerName: plan.plan_name,
            width: 100,
            headerAlign: 'center',
            
            renderCell: params => {
                const planFeature = params.row[`plan_${plan.plan_id}`];
                return (
                    <Switch
                        checked={planFeature?.is_enabled || false}
                        onChange={(event, newState) => {
                            event.stopPropagation(); // Prevent triggering onCellClick
                            handleToggle(planFeature?.plan_feature_id, newState); // Correctly pass the plan_feature_id
                        }}
                        color={planFeature?.is_enabled ? 'secondary' : 'primary'}
                    />
                );
            },
        })),
    ];

    

    return (
        <div style={{ height: '650px', width: '100%' }}>
            {loading ? (
                <p>Loading...</p> 
            ) : (
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={25}
                    rowsPerPageOptions={[5, 25, 50, 100]}
                    onCellClick={(params, event) => {
                        if (params.field === 'is_active') {
                            handleMasterToggle(params.row.id, !params.row.is_active);
                        } else if (params.field.startsWith('plan_')) {
                            const planFeature = params.row[params.field];
                            if (planFeature && planFeature.plan_feature_id) {
                                handleToggle(planFeature.plan_feature_id, !planFeature.is_enabled);
                            }
                        }
                    }}
                />
            )}
        </div>
    );
};

export default ToggleControlsAdmin;
