// src>helpers>apifetch.js
import { resetAccessToken } from '../features/userauth/userAuthSlice';
import Cookies from 'js-cookie';

let refreshTimeout;

async function apiFetch(url, options, dispatch) {
    const token = Cookies.get('token');
    // console.log('apiFetch - Initial Token:', token);
    options.headers.Authorization = `Bearer ${token}`;

    let response = await fetch(url, options);

    if (response.status === 401) {
        // console.log('apiFetch - Token expired, refreshing...');
        const data = await refreshAccessToken(dispatch);
        if (!data.accessToken) {
            throw new Error('Failed to refresh access token');
        }

        // Update the token in the request headers
        options.headers.Authorization = `Bearer ${data.accessToken}`;
        // console.log('apiFetch - Token refreshed, retrying request...');
        response = await fetch(url, options);

        // If the response is still unauthorized, throw an error
        if (response.status === 401) {
            throw new Error('Unauthorized after token refresh');
        }
    }

    return response;
}

let isRefreshing = false;
let refreshPromise;

async function refreshAccessToken(dispatch) {
    if (isRefreshing) {
        // If a token refresh is already in progress, wait for it to finish
        await refreshPromise;
    } else {
        isRefreshing = true;
        // console.log('refreshAccessToken - Requesting new token...');
        refreshPromise = fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/refresh_token`,
            { method: 'POST', credentials: 'include' }
        )
        .then(response => response.json())
        .then(data => {
            isRefreshing = false;
            if (data.accessToken) {
                // console.log('refreshAccessToken - New token received:', data.accessToken);
                Cookies.set('token', data.accessToken);
                dispatch(resetAccessToken(data.accessToken));
                // console.log('refreshAccessToken - New token stored in cookie and store:', data.accessToken);
                clearTimeout(refreshTimeout);
                refreshTimeout = setTimeout(
                    () => {
                        // console.log('refreshAccessToken - Token about to expire, refreshing...');
                        return refreshAccessToken(dispatch);
                    },
                    (data.expiresIn - 15) * 1000
                );
            } else {
                console.error('refreshAccessToken - Failed to refresh access token');
            }
            return data;
        })
        .catch(error => {
            console.error('Error in refreshAccessToken:', error);
        });
        await refreshPromise;
    }
}

export default apiFetch;
