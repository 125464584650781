import React, { useState } from 'react';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from "@emotion/react";
import { Link } from 'react-router-dom';
import OverviewViewStack from './../../stackpages/stackpageoverview/overviewViewStack';
import CompositionViewStack from './../../stackpages/stackpagecomposition/compositionViewStack';
import ComparisonViewStack from './../../stackpages/stackpagecomparison/comparisonViewStack';
import AutomationViewStack from './../../stackpages/stackpageautomation/automationViewStack';
import RecommendationViewStack from './../../stackpages/stackpagerecommendation/recommendationViewStack';
import CommunityViewStack from './../../stackpages/stackpagecommunity/communityViewStack';
import PersonalaiViewStack from './../../stackpages/stackpagepersonalai/personalaiViewStack';
import './viewStackMainOld.scss';

const ViewStackMainOld = () => {
    const [following, setFollowing] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const theme = useTheme();
    
    const handleFollowingClick = () => {
    // add the object to the user's "followed" list
    setFollowing(true);
    };
    
    const handleUnFollowingClick = () => {
        // add the object to the user's "followed" list
        setFollowing(false);
    };
        
    //Dummy Data for building the Stack Overview Dashboard
    const stackData = {
        name: 'Your Stack Name',
        userName: 'Sarah',
        userProfile: 'https://www.example.com/profile',
        purpose: 'Business',
        type: 'Company-wide',
        description: "It's a dummy stack we use at Widget Ltd",
        ratings: 4.5, 
        likes: 1200, 
        shares: 230,
        clones: 34,
        following: 12569,
        chatter: 324,
        company: 'Widget Ltd',
        companyUrl: 'https://www.example.com',
        companyProfile: './dashboard',
        award: 'Best Stack 2023',
    };

    const tabsList = [
        'Overview',
        'Composition',
        'Comparison',
        'Automation',
        'Recommendations',
        'Community',
        'Personal'
    ];

    return (
        <Box
            className="stackview-container"
            sx={{
                display: "flex",
                flexDirection:"column",
                gap: "10px",
                border: "1px solid white",
            }}
        >
            {/* <Box className="backgroundImage" sx={{height: "80px", backgroundColor:"lightgreen"}}/> */}
            <Box className="Header" sx={{display: "flex", justifyContent: "space-between"}}>
                <Box className="stackInfo" sx={{backgroundColor: "red"}}>
                    <Box className="stackImage">
                        <Box
                        sx={{
                            position: "relative",
                            width: "100px",
                            height: "100px",
                            backgroundColor: "skyblue",
                        }}
                        >
                        
                        </Box>
                    </Box>
                    <Box className="stackDetail">
                        <Typography variant="h5">{stackData.name}</Typography>
                        <Typography variant="body1">{stackData.description}</Typography>
                        <Typography variant="body2">Type: {stackData.type}</Typography>
                        <Typography variant="body2">Purpose: {stackData.purpose}</Typography>
                    </Box>
                </Box>
                <Box className="stackOwner" sx={{backgroundColor: 'gray'}}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        
                        <Typography variant="body1">{stackData.userName}</Typography>
                        {following ? (
                        <Box className="follow-button" onClick={handleUnFollowingClick}>following</Box>
                        ) : (
                        <Box className="follow-button" onClick={handleFollowingClick}>follow</Box>
                        )}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        
                        <Link to={stackData.companyProfile} sx={{ color: "primary" }}>{stackData.company}</Link>
                        <Box className="follow-button">follow</Box>
                    </Box>
                </Box>
                <Box className="actionButtons" sx={{backgroundColor: "lightblue"}} >
                    <Box className="stackviewButton">
                        
                        <Typography>AI Chat</Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                        <Button className="stackviewButton">AI</Button>
                        <Button className="stackviewButton">Fav</Button>
                        <Button className="stackviewButton">Delete</Button>
                    </Box>
                </Box>
            </Box>
            <Box className="Socials" sx={{ display: "flex", flexDirection:"row", gap: "5px" }}>
                <Box className="stats">
                    <Box className="engagement" sx={{ border: "1px solid white", display: "flex", gap: "5px" }}>
                        
                        <Typography className="statText">{stackData.likes} Likes</Typography>
                    </Box>
                    <Box className="engagement" sx={{ border: "1px solid white", display: "flex", gap: "5px" }}>
                        
                        <Typography className="statText">{stackData.shares} Shares</Typography>
                    </Box>
                    <Box className="engagement" sx={{ border: "1px solid white", display: "flex", gap: "5px" }}>
                        
                        <Typography className="statText">{stackData.clones} Clones</Typography>
                    </Box>
                    <Box className="engagement" sx={{ border: "1px solid white", display: "flex", gap: "5px" }}>
                        
                        <Typography className="statText">{stackData.following} Following</Typography>
                    </Box>
                    <Box className="engagement" sx={{ border: "1px solid white", display: "flex", gap: "5px" }}>
                        
                        <Typography className="statText">{stackData.chatter} Chatter</Typography>
                    </Box>
                </Box>
                <Box className="awards">
                    <Box className="awards-feature" sx={{ border: "1px solid white", display: "flex", gap: "5px" }}>
                        
                        <Typography className="statText">{stackData.award} </Typography>
                        <Link to={stackData.companyProfile} sx={{ color: "primary" }}>see all awards</Link>
                    </Box>
                    <Box className="awards-feature" sx={{ border: "1px solid white", display: "flex", gap: "5px" }}>
                        
                        <Typography className="statText">{stackData.award} </Typography>
                        <Link to={stackData.companyProfile} sx={{ color: "primary" }}>see all awards</Link>
                    </Box>
                </Box>
                <Box className="stackActions">
                    <Box className="action">
                        
                        <Typography className="actionText">add to</Typography>
                    </Box>
                    <Box className="action">
                        
                        <Typography className="actionText">add to</Typography>
                    </Box>
                </Box>
            </Box>
            <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                
                sx={{
                    '& .MuiTab-root': {
                        border: `1px solid ${theme.palette.grey[300]}`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        margin: '0 auto',
                        '&:not(:last-child)': {
                            borderRight: 'none'
                        }
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'transparent' //theme.palette.primary.main
                    },
                    '& .MuiTab-textColorPrimary.Mui-selected': {
                        color: theme.palette.secondary.main,
                        fontWeight: 'bold',
                        borderBottom: `2px solid ${theme.palette.secondary.main}`
                    }
                }}
            >
                {tabsList.map((tab, index) => (
                    <Tab key={index} label={tab} />
                ))}
            </Tabs>

            {/* Conditional Rendering based on Selected Tab */}
            {selectedTab === 0 && (
                <Box className="importPageDisplay">
                    <OverviewViewStack/>
                </Box>
            )}
            {selectedTab === 1 && (
                <Box className="importPageDisplay">
                    <CompositionViewStack/>
                </Box>
            )}
            {selectedTab === 2 && (
                <Box className="importPageDisplay">
                    <ComparisonViewStack/>
                </Box>
            )}
            {selectedTab === 3 && (
                <Box className="importPageDisplay">
                    <AutomationViewStack/>
                </Box>
            )}
            {selectedTab === 4 && (
                <Box className="importPageDisplay">
                    <RecommendationViewStack/>
                </Box>
            )}
            {selectedTab === 5 && (
                <Box className="importPageDisplay">
                    <CommunityViewStack/>
                </Box>
            )}
            {selectedTab === 6 && (
                <Box className="importPageDisplay">
                    <PersonalaiViewStack/>
                </Box>
            )}
            <Box className="ref-image">
                {/* <img className="reference" src={Designref1} alt="full ref" /> */}
                {/* <img className="reference" src={Viewstacknewref} alt="new ref" /> */}
            </Box>
        </Box>
        
    );
};

export default ViewStackMainOld;





