import React from 'react';
import { tokens } from "../../../theme";
import { Box, useTheme } from '@mui/material';
import { Slider, Typography } from '@mui/material';


const RangeSliderFilter = ({ label, value, onChange, min, max, step, marks, showMarks=true }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const marksArray = marks ? Array.from({length: (max - min) / step + 1}, (_, i) => ({ 
    value: min + (i * step), label: min + (i * step) 
  })) : false;

  const thumbStyle = {
    bgcolor: theme.palette.secondary.main,
    border: `1px solid ${colors.accentGreen}`,
  };

  const railStyle = {
    bgcolor: theme.palette.neutral.main,
  };

  const trackStyle = {
    bgcolor: theme.palette.secondary.main,
  };
  const markStyle = {
    color: theme.palette.neutral.light,
  };
  const markLabelStyle = {
    color: theme.palette.neutral.light,
    size: '8px'
  };

  return (
    <Box
      sx={{margin: '0px 10px'}}
    >
      <Typography id={`${label}-slider`} gutterBottom>
        {label}
      </Typography>
      <Slider
        value={value}
        onChange={onChange}
        valueLabelDisplay={showMarks ? "auto" : "off"}
        aria-labelledby={`${label}-slider`}
        min={min}
        max={max}
        step={step}
        marks={marksArray}
        sx={{
          '& .MuiSlider-thumb': thumbStyle,
          '& .MuiSlider-rail': railStyle,
          '& .MuiSlider-track': trackStyle,
          '& .MuiSlider-mark': markStyle,
          '& .MuiSlider-markLabel': markLabelStyle,
        }}
      />
    </Box>
  );
}

export default RangeSliderFilter;