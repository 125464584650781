import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '../../../helpers/apifetch';
import Cookies from 'js-cookie';

// Async thunk to fetch the use count
export const fetchUseCount = createAsyncThunk(
  'uses/fetchCount',
  async ({ entityType, entityId }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/using/count/${entityType}/${entityId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch use count');
    }
    const data = await response.json();
    return { entityId, entityType, count: Number(data.total_uses) };
  }
);

// Async thunk to fetch the user's use state
export const fetchUseState = createAsyncThunk(
  'uses/fetchStatus',
  async ({ entityType, entityId, userId }, { getState, dispatch }) => {
    const token = getState().auth.token;
    try {
      const response = await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/using/status/${userId}/${entityType}/${entityId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }, dispatch);

      if (!response.ok) {
        throw new Error('Failed to fetch use status');
      }

      const data = await response.json();
      return { entityId, entityType, userId, is_used: data.is_used };
    } catch (error) {
      throw new Error('Failed to fetch use status');
    }
  }
);

// Async thunk to toggle use
export const toggleUse = createAsyncThunk(
  'uses/toggle',
  async ({ entityType, entityId, userId }, { getState, dispatch }) => {
    const token = Cookies.get('token'); // original: const token = getState().auth.token;
    
    try {
      const response = await apiFetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/using/toggle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ entityId, entityType, userId })
      }, dispatch);

      if (!response.ok) throw new Error('Failed to toggle use');

      const data = await response.json();

      dispatch({ type: 'auth/logToken', payload: token });
      // Dispatch fetchUseCount action to update the use count in the state
      dispatch(fetchUseCount({ entityType, entityId }));

      return data; 
    } catch (error) {
      throw new Error('Failed to toggle use');
    }
    
  }
);


export const usingSlice = createSlice({
  name: 'using',
  initialState: {
    counts: {}, 
    byId: {},
    allIds: [],
    loading: false,
    error: null
  },
  reducers: {
    updateUseCount: (state, action) => {
      const { useCountId, count } = action.payload;
      state.counts[useCountId] = count;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUseState.fulfilled, (state, action) => {
        const { entityType, entityId, userId, is_used } = action.payload;
        const useId = `${entityType}_${entityId}_${userId}`;
      
        if (!state.byId[useId]) {
          state.byId[useId] = { entityId, userId, used: is_used };
          if (!state.allIds) {
            state.allIds = [];
          }
          state.allIds.push(useId);
        } else {
          state.byId[useId].used = is_used;
        }
        // console.log(`User ${userId} use STATE for ${entityType} ${entityId}:`, state.byId[useId].used);
        state.loading = false;
      })
      .addCase(fetchUseCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUseCount.fulfilled, (state, action) => {
        const { entityType, entityId, count } = action.payload;
        const useCountId = `${entityType}_${entityId}`;
    
        if (!state.counts) state.counts = {}; 
    
        if (entityType && entityId) {
          state.counts[useCountId] = count;
        } else {
          console.error(`Invalid useCountId: ${useCountId}`);
        }
        state.loading = false;
      })
      .addCase(fetchUseCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(toggleUse.fulfilled, (state, action) => {
        const { entityType, entityId, userId } = action.meta.arg;
        const useId = `${entityType}_${entityId}_${userId}`;
        const useCountId = `${entityType}_${entityId}`;
        const updatedUseCount = action.payload.total_uses;

        if (state.byId[useId]) {
          state.byId[useId].used = !state.byId[useId].used;
        } else {
          state.byId[useId] = { entityId, userId, used: true };
          if (!state.allIds) {
            state.allIds = [];
          }
          state.allIds.push(useId);
        }
        // console.log(`User ${userId} used STATUS for ${entityType} ${entityId}:`, state.byId[useId].used);
        state.counts[useCountId] = updatedUseCount;
      })
      .addCase(toggleUse.rejected, (state, action) => {
        state.error = action.error.message;
      });
  }
});

export const { updateUseCount } = usingSlice.actions;
export default usingSlice.reducer;
