import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    useTheme,
    Box,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Collapse,
} from '@mui/material';
import { tokens } from '../../theme';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import ArchiveIcon from '@mui/icons-material/Archive';
import ManageIcon from '@mui/icons-material/ManageSearch';
import ToolsIcon from '@mui/icons-material/Build';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import ReportIcon from '@mui/icons-material/Report';
import CreateIcon from '@mui/icons-material/Create';
import FolderIcon from '@mui/icons-material/Folder';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import SchoolIcon from '@mui/icons-material/School';
import SaveIcon from '@mui/icons-material/Save';
import HistoryIcon from '@mui/icons-material/History';
import GroupIcon from '@mui/icons-material/Group';
import PaymentIcon from '@mui/icons-material/Payment';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import BarChartIcon from '@mui/icons-material/BarChart';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import StackIcon from '@mui/icons-material/StackedLineChart';

const SideMenuNew = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const user = useSelector(state => state.auth.user);
    const isLoggedIn = Boolean(user);
    const [isOpen, setIsOpen] = useState(false);
    const [isCollapsed] = useState(false);

    const handleSideMenuClick = () => {
        setIsOpen(!isOpen);
    };

    const menuItems = [
        {
            icon: <HomeIcon />,
            text: 'Home',
            link: '/',
            subMenuItems: [
                { icon: <HomeWorkIcon />, text: 'Overview', link: '/' },
                { icon: <ReportIcon />, text: 'other' },
                { icon: <BarChartIcon />, text: 'Analytics' },
            ],
        },
        {
            icon: <ToolsIcon />,
            text: 'Stacks',
            link: '/mystacksMain',
            subMenuItems: [
                {
                    icon: <StackIcon />,
                    text: 'My Stacks',
                    link: '/mystacksMain',
                },
                { icon: <InboxIcon />, text: 'Browse' },
                {
                    icon: <CompareArrowsIcon />,
                    text: 'Analysis',
                    link: '/viewStackMain',
                },
                { icon: <MapIcon />, text: 'Roadmap', link: '/editStack' },
                {
                    icon: <CreateIcon />,
                    text: 'Create New',
                    link: '/create/newstack/mode',
                },
            ],
        },
        {
            icon: <SettingsIcon />,
            text: 'Products',
            link: '/products',
            subMenuItems: [
                {
                    icon: <FolderIcon />,
                    text: 'My Folders',
                    link: '/myProductsFolders',
                },
                {
                    icon: <InboxIcon />,
                    text: 'Browse',
                    link: '/productPageMain',
                },
                { icon: <CompareArrowsIcon />, text: 'Compare' },
                { icon: <SchoolIcon />, text: 'Academy' },
            ],
        },
        {
            icon: <ArchiveIcon />,
            text: 'Services',
            subMenuItems: [
                { icon: <SaveIcon />, text: 'Saved Services' },
                { icon: <InboxIcon />, text: 'Browse' },
                { icon: <ToolsIcon />, text: 'Tools' },
                { icon: <HistoryIcon />, text: 'History' },
            ],
        },
        {
            icon: <ManageIcon />,
            text: 'Manage',
            link: '/manage',
            subMenuItems: [
                { icon: <DashboardIcon />, text: 'Dashboard' },
                { icon: <DraftsIcon />, text: 'Manage Software' },
                { icon: <GroupIcon />, text: 'Team & People' },
                { icon: <PaymentIcon />, text: 'Transactions' },
            ],
        },
        {
            icon: <ToolsIcon />,
            text: 'Tools',
            subMenuItems: [
                { icon: <MapIcon />, text: 'MAPS', link: '/maptoolDash' },
                { icon: <ListIcon />, text: 'LISTS & GROUPS' },
                { icon: <BarChartIcon />, text: 'REPORTS' },
            ],
        },
    ];

    const [openSubMenuIndex, setOpenSubMenuIndex] = useState(-1);

    const handleSubMenuClick = index => {
        if (openSubMenuIndex === index) {
            setOpenSubMenuIndex(-1);
        } else {
            setOpenSubMenuIndex(index);
        }
    };

    if (!isLoggedIn) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                backgroundColor: 'transparent',
                zIndex: 1300,
                position: 'fixed',
                top: 0,
            }}
        >
            <IconButton
                onClick={handleSideMenuClick}
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mt: '0.5rem',
                    ml: '0.5rem',
                    borderRadius: '0px',
                    color: theme.palette.text.primary,
                    '&:hover': {
                        backgroundColor: 'inherit',
                    },
                }}
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                sx={{
                    width: '350px',
                    height: '100vh',
                    zIndex: theme.zIndex.drawer + 1,

                    '.MuiPaper-root': {
                        backgroundColor: colors.grey[800],
                        height: '100vh',
                    },
                }}
                anchor="left"
                open={isOpen}
                onClose={handleSideMenuClick}
                variant={isCollapsed ? 'permanent' : 'temporary'}
            >
                <List
                    sx={{
                        marginTop: '40px',
                        backgroundColor: 'transparent',
                    }}
                >
                    {menuItems.map((item, index) => (
                        <div key={index}>
                            <Link
                                to={item.link || '#'}
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                }}
                            >
                                <ListItem
                                    button
                                    onClick={() => handleSubMenuClick(index)}
                                    sx={{
                                        color: theme.palette.text.primary,
                                        backgroundColor: 'inherit',
                                    }}
                                >
                                    <ListItemText>
                                        <Box display="flex" alignItems="center">
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                    mr: '8px',
                                                    size: 'medium',
                                                }}
                                            >
                                                {item.icon}
                                            </Box>
                                            {item.text}
                                        </Box>
                                    </ListItemText>
                                </ListItem>
                            </Link>
                            {item.subMenuItems && (
                                <Collapse
                                    in={openSubMenuIndex === index}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        {item.subMenuItems.map(
                                            (subItem, subIndex) => (
                                                <Link
                                                    to={subItem.link || '#'}
                                                    key={subIndex}
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: 'inherit',
                                                    }}
                                                >
                                                    <ListItem
                                                        button
                                                        sx={{
                                                            color: theme.palette
                                                                .text.secondary,
                                                            backgroundColor:
                                                                'inherit',
                                                        }}
                                                    >
                                                        <ListItemText inset>
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignContent:
                                                                            'center',
                                                                        mr: '4px',
                                                                        size: 'x-small',
                                                                    }}
                                                                >
                                                                    {
                                                                        subItem.icon
                                                                    }
                                                                </Box>
                                                                {subItem.text}
                                                            </Box>
                                                        </ListItemText>
                                                    </ListItem>
                                                </Link>
                                            )
                                        )}
                                    </List>
                                </Collapse>
                            )}
                        </div>
                    ))}
                </List>
            </Drawer>
        </Box>
    );
};

export default SideMenuNew;
