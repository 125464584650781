// src/features/products/basicdisplay/productBasicsSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const productLogoSlice = createSlice({
  name: 'productLogo',
  initialState: [],
  reducers: {
    addProduct: (state, action) => {
      state.push(action.payload);
    },
    removeProduct: (state, action) => {
      return state.filter(product => product.id !== action.payload.id);
    },
    // ... more reducers can be added as needed
  }
});

export const { addProduct, removeProduct } = productLogoSlice.actions;
export default productLogoSlice.reducer;
