//src>components>profilepage>profielpageheader>profilepageuserrecognition>profilePageUserRecognition.jsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import './profilePageUserStatus.scss';

function ProfilePageUserStatus({userData, userId, entityType, visitorUserId }) {
    const [awardsData, setAwardsData] = useState([]);
    const theme = useTheme();
    const navigate = useNavigate(); 
    
    // console.log ('ProfilePageUserRecognition userId', userId, 'entityType', entityType, 'visitorUserId', visitorUserId);
    useEffect(() => {
        if (userId) {
            const fetchData = async () => {
                try {
                    const followedByResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/follow/followedby/public/${userId}`);
                    const followingResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/follow/following/public/${userId}`);
                    const awardsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/award/${entityType}/${userId}`);
                    
                    if (!followedByResponse.ok || !followingResponse.ok) {
                        throw new Error('Error fetching follows data');
                    }
                    
                    const awardsData = await awardsResponse.json();

                    // Check if awardsData is an array before calling the sort function
                    if (Array.isArray(awardsData)) {
                        // Sort awardsData by date_awarded in descending order
                        awardsData.sort((a, b) => new Date(b.date_awarded) - new Date(a.date_awarded));
                    } else {
                        console.log("Awards data is not an array:", awardsData); // Log a message when awardsData is not an array
                    }

                    setAwardsData(awardsData);

                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };
    
            fetchData();
        }
        else {
            console.log("No userId provided");
        }
    }, [userId, entityType]);

    if (!userData) {
        return <div>Oops! Sorry, we couldn't find this user.</div>;
    }

    return (
        <Box 
            className='profile-header-recognition-main'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '10px',
                gap: '10px',
                height: '100%',
                border: `1px solid ${theme.palette.neutral.main}`,
                borderRadius: '5px',
                backgroundColor: theme.palette.primary.dark, 
                boxShadow: `1px 1px 2px ${theme.palette.neutral.dark}`,
                transition: 'all 0.5s ease',
                '&:hover': {
                boxShadow: `1px 1px 2px ${theme.palette.neutral.light}`,
                cursor: 'pointer', 
                },
            }}
        >
            <div className='user-platform-status'>
                <div className='user-status-activity'>
                    <div>Status: </div>
                    <div>{userData.user.user_status_platform}</div>
                </div>
            </div>
            <div className='user-status-badges'>
                {Array.isArray(awardsData) && awardsData.slice(0, 2).map((award, index) => (
                    <img key={index} className={`user-badge-${index}-badge`} src={award.award_badge} alt="badge"/>
                ))}
                {awardsData.length > 0 && <div className='user-badge-text' onClick={() => navigate(`/user/${userId}/about`)}>see all badges</div>}
            </div>
        </Box>
    );
}

export default ProfilePageUserStatus;