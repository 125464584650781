//src>scenes>listings>stacklistingpage>stackListingPage.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserForPage } from '../../../features/users/userpage/userPageSlice';
import {
    Box,
    Divider,
    Button,
    Grid,
    Pagination,
    MenuItem,
    FormControl,
    Select,
} from '@mui/material';


// existing components
import StackCard from '../../../components/stack/stackCard/stackCard';
import SideBarComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import TextFieldFilter from '../../../global/filters/textfilter/textfieldFilterComponent';
import SelectFilter from '../../../global/filters/selectfilter/selectFilterComponent';
import AutocompleteFilterComponent from '../../../global/filters/autocomplete/autocompletefilterComponent';
import CheckboxFilter from '../../../global/filters/checkboxfilter/checkboxFilterComponent';
import SliderFilter from '../../../global/filters/sliderfilter/sliderFilterComponent';
import RangeSliderFilter from '../../../global/filters/rangefilter/rangeSliderFilter';
import ToggleFilter from '../../../global/filters/togglefilter/toggleFilterComponent';
import dropdownOptions from '../../../data/StaticFormOptions/staticDropdownOptions.json'; // Import the JSON file
import './browseStackListings.scss';

// TO DO:
// 1) implement client-side pagination controls to avoid all stacks being loaded on page load or when no filter values are selection
// 2) move fetch for stackData to server-side

const BrowseFilteredStacks = () => {
    const user = useSelector(state => state.auth.user);
    const visitorUserId = user?.user_id;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [stackData, setStackData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [companySizeFTEValue, setCompanySizeFTEValue] = useState('');
    const [companySizeRevenueValue, setCompanySizeRevenueValue] = useState('');
    const [stackTypeValue, setStackTypeValue] = useState('');
    const [stackPurposeValue, setStackPurposeValue] = useState('');
    const [companyCountryValue, setCompanyCountryValue] = useState('');
    const [countryOptions, setCountryOptions] = useState([]);
    const [stackIds, setStackIds] = useState([]);
    const [, setTotalCount] = useState(0);
    const [productsInStackValue, setProductsInStackValue] = useState(1);
    const [, setMinProductsInStack] = useState(0);
    const [maxProductsInStack, setMaxProductsInStack] = useState(50);
    const [isTemplateOnly, setIsTemplateOnly] = useState(false);
    const [productsInStackRange, setProductsInStackRange] = useState([1, 50]);

    const dispatch = useDispatch();
    const clearAllFilters = () => {
        setCompanySizeFTEValue('');
        setCompanySizeRevenueValue('');
        setStackTypeValue('');
        setStackPurposeValue('');
        setCompanyCountryValue('');
    };

    const fetchStackData = useCallback(async (stackIds) => {
        setLoading(true);
        setError(null);
        try {
            const data = await Promise.all(
                stackIds.map(async stack => {
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/v1/stacks/${stack.stackId}/${visitorUserId}`
                    );
                    const stackData = await response.json();
                    return stackData;
                })
            );
    
            setStackData(data);
        } catch (error) {
            console.error('Error fetching stack data:', error);
        } finally {
            setLoading(false);
        }
    }, [visitorUserId]);

    useEffect(() => {
        const fetchCountryNames = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/filter/getcountries`
                );
                const data = await response.json();
                const countryNames = data.map(country => country.country_name); // Extract only country names
                setCountryOptions(countryNames);
                // console.log('Country Options:', countryNames);
            } catch (error) {
                console.error('Error fetching country names:', error);
            }
        };

        fetchCountryNames();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    useEffect(() => {
        const fetchFilteredStackIds = async () => {
            setLoading(true);
            setError(null);
            // console.log(
            //     `Fetching data for filters: Company Size (FTE), Company Size (Revenue), Stack Purpose, Stack Type, Product Count, values: ${companySizeFTEValue}, ${companySizeRevenueValue}, ${stackPurposeValue}, ${stackTypeValue}, ${productsInStackValue}, page: ${currentPage}, itemsPerPage: ${itemsPerPage}`
            // );
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/filter` +
                        `?companySizeFTE=${companySizeFTEValue}` +
                        `&companySizeRevenue=${companySizeRevenueValue}` +
                        `&stackPurpose=${stackPurposeValue}` +
                        `&stackType=${stackTypeValue}` +
                        `&country=${companyCountryValue}` +
                        `&minProductsInStack=${productsInStackRange[0]}` +
                        `&maxProductsInStack=${productsInStackRange[1]}` +
                        `&page=${currentPage}` +
                        `&itemsPerPage=${itemsPerPage}` +
                        `&isTemplateOnly=${isTemplateOnly}`
                );
                const data = await response.json();
                console.log('Response:', data);
                const stackIds = data.ids.map(id => ({ stackId: id.stack_id }));
                setStackIds(stackIds);
                const productCounts = data.ids.map(id => id.product_count);

                setMinProductsInStack(Math.max(1, Math.min(...productCounts)));
                setMaxProductsInStack(Math.max(...productCounts));

                setTotalPages(data.totalPages);
                setTotalCount(data.count);

                if (stackIds.length > 0) {
                    fetchStackData(stackIds);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchFilteredStackIds();
    }, [
        companySizeFTEValue,
        companySizeRevenueValue,
        stackPurposeValue,
        stackTypeValue,
        companyCountryValue,
        productsInStackValue,
        productsInStackRange,
        currentPage,
        itemsPerPage,
        isTemplateOnly,
        fetchStackData,
    ]);

    useEffect(() => {
        if (visitorUserId !== undefined) {
            dispatch(fetchUserForPage(visitorUserId));
        }
    }, [dispatch, visitorUserId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!stackData) return <div>No product data available</div>;

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleItemsPerPageChange = event => {
        setItemsPerPage(event.target.value);
    };

    return (
        <Box className="stack-filter-page-main-container">
            <Box className="display-area">
                <Box className="leftSideBar">
                    <Button variant="standard" onClick={clearAllFilters}>
                        Clear All
                    </Button>
                    <Divider sx={{ margin: '15px 0px', color: 'grey' }} />
                    <p>Company Filters</p>
                    <SelectFilter
                        label="Company Size (FTE)"
                        value={companySizeFTEValue}
                        onChange={setCompanySizeFTEValue}
                        options={dropdownOptions.companySizeFTE}
                    />
                    <SelectFilter
                        label="Company Size (Revenue)"
                        value={companySizeRevenueValue}
                        onChange={setCompanySizeRevenueValue}
                        options={dropdownOptions.companySizeRevenue}
                    />
                    <AutocompleteFilterComponent
                        label="Location"
                        // Option: insert logged-in user's country as default value
                        value={companyCountryValue || 'All'}
                        options={countryOptions}
                        onChange={setCompanyCountryValue}
                    />
                    <Divider sx={{ margin: '15px 0px', color: 'grey' }} />
                    <p>Stack Filters</p>
                    <SelectFilter
                        label="Stack Purpose"
                        value={stackPurposeValue}
                        onChange={setStackPurposeValue}
                        options={dropdownOptions.stackPurpose}
                    />
                    <SelectFilter
                        label="Stack Type"
                        value={stackTypeValue}
                        onChange={setStackTypeValue}
                        options={dropdownOptions.stackType}
                    />
                    <SliderFilter
                        label="Min: Products in Stack"
                        value={productsInStackValue}
                        min={1}
                        max={maxProductsInStack || 50}
                        step={Math.round((maxProductsInStack - 1) / 6)}
                        marks={true}
                        showMarks={true}
                        onChange={(event, newValue) => {
                            setProductsInStackValue(newValue);
                        }}
                    />
                    <RangeSliderFilter
                        label="Range: Products in Stack"
                        value={productsInStackRange}
                        min={1}
                        max={maxProductsInStack || 50}
                        step={Math.round((maxProductsInStack - 1) / 6)}
                        marks={true}
                        showMarks={true}
                        onChange={(event, newValue) => {
                            setProductsInStackRange(newValue);
                        }}
                    />
                    <CheckboxFilter
                        label="Templates Only"
                        checked={isTemplateOnly}
                        onChange={event =>
                            setIsTemplateOnly(event.target.checked)
                        }
                    />
                    <Divider sx={{ margin: '15px 0px', color: 'grey' }} />
                    <p>Filters to be finalised</p>
                    <ToggleFilter label="User Look-alikes" checked={false} />
                    <TextFieldFilter label="User Look-alikes" />
                </Box>

                <Box className="center-display">
                    <Grid container spacing={2}>
                        {stackData &&
                            stackData.map(
                                (stack, index) =>
                                    stackIds &&
                                    stackIds[index] && (
                                        <Grid item xs={3} key={index}>
                                            <StackCard
                                                stackId={
                                                    stackIds[index].stackId
                                                }
                                                stackData={stackData[index]}
                                                userId={visitorUserId || ''}
                                                showName={true}
                                                showQuickView={true}
                                                flexDirection="column"
                                            />
                                        </Grid>
                                    )
                            )}
                    </Grid>
                    <Box className="pagination-container">
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            showFirstButton
                            showLastButton
                        />
                        <FormControl>
                            <Select
                                value={itemsPerPage}
                                size="small"
                                onChange={handleItemsPerPageChange}
                            >
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box className="rightSideBar">
                    <SideBarComponent title="Similar Stacks"></SideBarComponent>
                    <SideBarComponent title="Popular Stacks"></SideBarComponent>
                    <SideBarComponent title="New Stacks"></SideBarComponent>
                </Box>
            </Box>
        </Box>
    );
};

export default BrowseFilteredStacks;
