//src>userprofile>userAccount>useraccountmain>userAccountMain.jsx

import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useSelector } from 'react-redux';
import apiFetch from '../../../helpers/apifetch';
import { useTheme } from '@emotion/react';
import Header from '../../../components/Header';
import UserAccountOverview from '../useraccountoverview/userAccountOverview';
import UserDetails from '../userdetails/userDetails';
import UserAccountFeed from '../useraccountfeed/userAccountFeed';
import UserAccountTeams from '../useraccountteams/userAccountTeams';
import UserAccountPreferences from '../useraccountpreferences/userAccountPreferences';
import UserAccountEntities from '../useraccountentities/userAccountEntities';
import UserAccountBilling from '../useraccountbilling/userAccountBilling';
import UserAccountSettings from '../useraccountsettings/userAccountSettings';
import ToggleControlsAdmin from '../../../global/dashboards/admin/togglecontrols/toggleControlsAdmin';
import './userAccountMain.scss';

const UserAccountMain = ({ user }) => {
    const theme = useTheme();
    const userId = user.user_id;
    const token = useSelector(state => state.auth.token);
    const [userData, setUserData] = useState(null);
    const [entityType, setEntityType] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);

    const userPlanToTabs = {
        1: [
            'Overview',
            'My Details',
            'Preferences',
            'My Feed',
            'Teams',
            'My Entities',
            'Billing & Payments',
            'Privacy Settings',
        ],
        2: [
            'Overview',
            'My Details',
            'Preferences',
            'My Feed',
            'Teams',
            'My Entities',
        ],
        873: [
            'Overview',
            'My Details',
            'Preferences',
            'My Feed',
            'Teams',
            'My Entities',
            'Billing & Payments',
            'Privacy Settings',
            'Admin',
        ],
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (userId) {
                    const response = await apiFetch(
                        `${process.env.REACT_APP_API_URL}/api/v1/userDetails/user-profile/${userId}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        }
                    );
                    if (!response.ok) {
                        throw new Error(
                            `HTTP error! status: ${response.status}`
                        );
                    }
                    const userData = await response.json();
                    setUserData(userData);
                    setEntityType(userData.user.entity_type);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [userId, token]);

    if (!user) {
        return <div>Oops! We couldn't find a user with these credentials</div>;
    }

    const tabsList = userPlanToTabs[Number(user.user_plan)] || [];

    return (
        <Box
            className="user-account-profile-container"
            sx={{ backgroundColor: theme.palette.primary.main }}
        >
            <Box
                sx={{
                    position: 'sticky',
                    top: '64px',
                    zIndex: 10,
                    backgroundColor: theme.palette.primary.main,
                    margin: '0px 0px 20px 0px',
                    border: `1px solid ${theme.palette.border.default}`,
                    padding: '0px 5px 5px 5px',
                    borderRadius: '5px',
                    boxShadow: `0.1rem 0.1rem 0.1rem 0.05rem ${theme.palette.neutral.light}`,
                }}
            >
                <Box
                    sx={{
                        position: 'sticky',
                        top: '64px',
                        zIndex: 10,
                        backgroundColor: theme.palette.primary.main,
                        mb: '20px',
                    }}
                >
                    <Header
                        title={`${
                            user.user_first_name
                                ? `${user.user_first_name}'s Account`
                                : `#${user.user_name}`
                        }`}
                        subtitle="Manage your account"
                    />
                </Box>
                <Box
                    className="sticky-tabs-container"
                    sx={{
                        position: 'sticky',
                        top: '224px',
                        zIndex: 10,
                        backgroundColor: theme.palette.primary.main,
                    }}
                >
                    <Tabs
                        className="userProfileTabs"
                        value={selectedTab}
                        onChange={(event, newValue) => setSelectedTab(newValue)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{
                            '& .MuiTab-root': {
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                margin: '0 auto',
                                '&:not(:last-child)': {
                                    borderRight: 'none',
                                },
                            },
                            '& .MuiTabs-indicator': {
                                backgroundColor: 'transparent',
                            },
                            '& .MuiTab-textColorPrimary.Mui-selected': {
                                color: theme.palette.secondary.main,
                                fontWeight: 'bold',
                                borderBottom: `2px solid ${theme.palette.secondary.main}`,
                            },
                        }}
                    >
                        {tabsList.map((tab, index) => (
                            <Tab key={index} label={tab} />
                        ))}
                    </Tabs>
                </Box>
            </Box>
            {selectedTab === 0 && (
                <Box className="importPageDisplay">
                    <UserAccountOverview
                        userId={userId}
                        userData={userData}
                        entityType={entityType}
                    />
                </Box>
            )}
            {selectedTab === 1 && (
                <Box className="importPageDisplay">
                    <UserDetails
                        userId={userId}
                        userData={userData}
                        entityType={entityType}
                    />
                </Box>
            )}
            {selectedTab === 2 && (
                <Box className="importPageDisplay">
                    <UserAccountPreferences
                        userId={userId}
                        userData={userData}
                        entityType={entityType}
                    />
                </Box>
            )}
            {selectedTab === 3 && (
                <Box className="importPageDisplay">
                    <UserAccountFeed
                        userId={userId}
                        userData={userData}
                        entityType={entityType}
                    />
                </Box>
            )}
            {selectedTab === 4 && (
                <Box className="importPageDisplay">
                    <UserAccountTeams
                        userId={userId}
                        userData={userData}
                        entityType={entityType}
                    />
                </Box>
            )}
            {selectedTab === 5 && (
                <Box className="importPageDisplay">
                    <UserAccountEntities
                        userId={userId}
                        userData={userData}
                        entityType={entityType}
                    />
                </Box>
            )}
            {selectedTab === 6 && (
                <Box className="importPageDisplay">
                    <UserAccountBilling
                        userId={userId}
                        userData={userData}
                        entityType={entityType}
                    />
                </Box>
            )}
            {selectedTab === 7 && (
                <Box className="importPageDisplay">
                    <UserAccountSettings
                        userId={userId}
                        userData={userData}
                        entityType={entityType}
                    />
                </Box>
            )}
            {selectedTab === 8 && (
                <Box className="importPageDisplay">
                    <ToggleControlsAdmin
                        userId={userId}
                        userData={userData}
                        entityType={entityType}
                    />
                </Box>
            )}
        </Box>
    );
};

export default UserAccountMain;
