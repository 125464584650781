//src>feed>feedpage>feedPageMain.jsx
import React from "react";
import { Box } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import FeedComponent from '../../feed/feedcomponent/FeedComponent';
import SideBarComponent from '../../global/dashboards/boxcomponent/BoxComponent';
import './feedPageMain.scss';


const FeedPageMain = () => {

    return(
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '10px', margin: '0 auto', maxWidth: 1200 }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, width: '100%' }}>
                <Box sx={{ width: '225px', display: 'flex', flexDirection: 'column', gap: 2, '@media (max-width: 768px)': { display: 'none' } }}>
                    <SideBarComponent/>
                    <SideBarComponent title="Box B" subtitle="This is box B" sideText="160" />
                    <SideBarComponent title="Box C" subtitle="This is box C" sideText="160" />
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', gridAutoRows: 'auto', gap: 2, width: '555px' }}>
                    <FeedComponent 
                    title="Post 1"
                    subtitle="This is box 1"
                    sideText="10000"
                    icon={<DownloadOutlinedIcon/>}
                    width={12}
                    showButton={true}
                    link="https://www.google.com"  
                    />
                    <FeedComponent title="Post 2" subtitle="This is Post 1" sideText="10" width={12} />
                    <FeedComponent title="Post 3" width={12}  />
                    <FeedComponent title="Post 4" width={12} />
                    <FeedComponent title="Post 5" width={12} />
                    <FeedComponent title="Post 6" width={12} />
                </Box>
                <Box sx={{ minWidth: 300, maxWidth: 300, display: 'flex', flexDirection: 'column', gap: 2, '@media (max-width: 768px)': { display: 'none' } }}>
                    <SideBarComponent />
                    <SideBarComponent title="Box B" subtitle="This is box B" sideText="160" />
                    <SideBarComponent title="Box C" subtitle="This is box C" sideText="160" />
                </Box>
            </Box>
        </Box>
      )
}


export default FeedPageMain;
