import React from 'react';
import { useTheme } from '@mui/material/styles';

const NumberIcon = ({ number }) => {
    const theme = useTheme();

    return (
        <div style={{
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            fontWeight: 700,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '18px'
        }}>
            {number}
        </div>
    );
};

export default NumberIcon;