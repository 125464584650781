import React from 'react';
import ProductCard from '../../productCard/productCard';
import { Grid } from '@mui/material';
import './productList.scss'; 

const ProductsList = ({ products = [], onAddToStack }) => {
    
    return (
        <Grid container spacing={2} className="products-card-display">
            {products.map(product => (
                <Grid item key={product.product_id} >
                    <ProductCard 
                        productId={product.product_id}
                        context="add-to-stack"
                        onAddToStack={() => onAddToStack(product)}
                        showName={false}
                        showVendor={false}
                        showCategory={false}
                        showSubcategory={false}
                        showSocialButtons={false}
                        showBookmarkButton={false}
                        showQuickView = {true}
                        flexDirection='row'
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default ProductsList;

