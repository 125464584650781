import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import PersonalDetails from './personalDetails';
import NewStackInfo from './newStackInfo';
import NewStackSelect from '../createstack/createnewstack/newstackselect/newStackSelect';
import StackCompanyDetail from './stackCompanyDetail';
import apiFetch from '../../helpers/apifetch';
import { resetNewStack } from '../../features/stacks/temporaryStackSlice/temporaryStackSlice';



const NewStackForm = () => {
    const [step, setStep] = useState(0);
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const user = useSelector(state => state.auth.user);
    const userId = user?.user_id;
    const [newStackUserData, setNewStackUserData] = useState(null);
    

    //Reset the form
    useEffect(() => {
        dispatch(resetNewStack());
    }, [dispatch]);

    // Get user Data
    useEffect(() => {
        const fetchData = async () => {
            if (!userId) {
                return;
            }
            try {
                const response = await apiFetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/stacks/${userId}`,
                    {
                        credentials: 'include',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                    dispatch
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const userDetailContext = await response.json();
                setNewStackUserData(userDetailContext);
            } catch (error) {
                console.error('Failed to fetch new stack user data:', error);
            }
        };
    
        fetchData();
    }, [userId, token, dispatch]);

    // Get the data from the new slice
    const { stackDetails, companyInfo } = useSelector(state => state.newStack);

    
    
    const nextStep = (data) => {
        setStep(prevStep => prevStep + 1);
    };

    const backStep = () => {
        setStep(prevStep => prevStep - 1);
    };

    
    
    

    return (
        <Box
            sx={{
                margin: '0 auto',
                maxWidth: '1200px',
            }}
        >
            <div>
                <h1>Step {step + 1}</h1>
                {step === 0 && <NewStackInfo onNext={nextStep} />}
                {step === 1 && <PersonalDetails token={token} userId={userId} newStackUserData={newStackUserData} onNext={nextStep} onBack={backStep} />}
                {step === 2 && stackDetails.stack_purpose === 'Business' && <StackCompanyDetail token={token} userId={userId} newStackUserData={newStackUserData} companyId={companyInfo.companyId} onNext={nextStep} onBack={backStep}/>}
                {step === 3 && <NewStackSelect userId={userId} token={token} onBack={backStep}/>}
            </div>
        </Box>
    );
};

export default NewStackForm;
    
// token={token} userId={userId} newStackUserData={newStackUserData} companyList={companyList}