//src>features>charts>stack>stackpage>stackcount>stackProductByDomainSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchProductsByDomain = createAsyncThunk(
  'productsByDomain/fetchByStack',
  async ({ stackId }, { rejectWithValue }) => {
    try {
      const response = await fetch(`/api/v1/charts/stack-products-by-domain/${stackId}`);
      if (!response.ok) throw new Error('Network response was not ok');
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const productsByDomainSlice = createSlice({
  name: 'productsByDomain',
  initialState: {
    data: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsByDomain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductsByDomain.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchProductsByDomain.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export default productsByDomainSlice.reducer;
