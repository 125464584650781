import React, { useState } from 'react';
import { Box, Button, Typography, Grid, Paper, Checkbox, ToggleButton, ToggleButtonGroup } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MonthIcon from '@mui/icons-material/CalendarToday';
import YearIcon from '@mui/icons-material/DateRange';
import FeatureComparisonTable from './plansfeaturecomparison';

const plans = [
    {
        name: 'Basic',
        monthlyPrice: '$10/month',
        annualPrice: '$100/year',
        features: ['Feature 1', 'Feature 2'],
    },
    {
        name: 'Pro',
        monthlyPrice: '$20/month',
        annualPrice: '$200/year',
        features: ['Feature 1', 'Feature 2', 'Feature 3'],
    },
    {
        name: 'Enterprise',
        monthlyPrice: 'Contact us',
        annualPrice: 'Contact us',
        features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
    },
];

const PlansPage = () => {
    const [pricingPeriod, setPricingPeriod] = useState('monthly');
    const [showComparison, setShowComparison] = useState(false);

    const handlePricingPeriodChange = (event, newPricingPeriod) => {
        setPricingPeriod(newPricingPeriod);
    };

    const handleCompareClick = () => {
        setShowComparison(!showComparison);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '1200px',
                margin: '0 auto',
                padding: 2,
            }}
        >
            <Typography variant="h4" gutterBottom>
                Our Plans
            </Typography>
            <ToggleButtonGroup
                value={pricingPeriod}
                exclusive
                onChange={handlePricingPeriodChange}
                aria-label="pricing period"
            >
                <ToggleButton value="monthly" aria-label="monthly">
                    <MonthIcon />
                    Monthly
                </ToggleButton>
                <ToggleButton value="annual" aria-label="annual">
                    <YearIcon />
                    Annual
                </ToggleButton>
            </ToggleButtonGroup>
            <Grid container spacing={2}>
                {plans.map((plan, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper elevation={3} p={2} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" gutterBottom>
                                {plan.name}
                            </Typography>
                            <Typography variant="h5">
                                {pricingPeriod === 'monthly' ? plan.monthlyPrice : plan.annualPrice}
                            </Typography>
                            <ul>
                                {plan.features.map((feature, index) => (
                                    <li key={index}>
                                        <Checkbox
                                            checked
                                            icon={<CheckCircleOutlineIcon />}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            <Box mt="auto">
                                <Button variant="register" color="primary" fullWidth>
                                    Select Plan
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Box mt={2}>
                <Button variant="new" onClick={handleCompareClick}>
                    Compare Details
                </Button>
            </Box>
            {showComparison && <FeatureComparisonTable />}
        </Box>
    );
};

export default PlansPage;