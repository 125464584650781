// src>runnercore>createstack>newStackSelect.jsx

import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import BasicDisplay from '../../components/product/basicDisplay/basicsDisplay.js';
import { setAvailableProducts, addProductToStack, removeProductFromStack } from '../../features/products/basicsdisplay/productBasicsSlice.js';
import productsData from '../../data/products_.json';
import stacksData from '../../data/stacks_.json';
import { useDrop } from 'react-dnd';

const NewStackDisplay = () => {
    const dispatch = useDispatch();

    // Initialize available products (this should ideally come from an API or similar)
    useEffect(() => {
        const initialStack = stacksData.find(stack => stack.stack_id === 1);
        const initialAvailableProducts = productsData.filter(product => 
          !initialStack.products_inStack.includes(product.product_id)
        );
        dispatch(setAvailableProducts(initialAvailableProducts));
    }, [dispatch]);

    // Selectors for getting the current state
    const availableProducts = useSelector((state) => state.productBasics.availableProducts || []);
    const stackProductIds = useSelector((state) => state.productBasics.stack.products_inStack || []);
    const stackProducts = productsData.filter(product => stackProductIds.includes(product.product_id));

    // Drag and Drop Hooks
    const [{ isOver }, dropRef] = useDrop({
        accept: 'PRODUCT',
        drop: (item) => {
          dispatch(addProductToStack({ productId: item.id, stackId: '1' }));
        },
        collect: monitor => ({
          isOver: !!monitor.isOver(),
        }),
    });

    // Handler to remove product from the stack
    const handleRemoveProduct = (productId) => {
        dispatch(removeProductFromStack({ productId }));
    };

    
    return (
        <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', margin: "20px", border: "0px solid blue" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: "20px" }}>NewStackDisplay</Box>
            <Grid container spacing={1} sx={{ width: '100%', justifyContent: 'space-between', border: '0px solid green' }}>
                {/* Left Grid: Products to Add */}
                <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '5px' }}>
                    <Box sx={{ mb: 2, display: 'flex', flexDirection: 'row', border: '0px solid yellow', gap: 5, alignContent: 'flex-start', justifyContent: 'flex-start'}}>
                        <Typography variant="h5" fontWeight="bold">Available Products</Typography>
                        <Typography variant="subtitle1">Select products to add to your stack</Typography>
                    </Box>
                    <Grid 
                        container spacing={1}
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignContent: 'center',
                            padding: "10px",
                            gap: "10px",
                            background: "transparent"
                        }}
                    >
                        {availableProducts.map(product => (
                            <BasicDisplay 
                                key={product.product_id}
                                product={product}
                                showTooltip={true}
                            />
                        ))}
                    </Grid>
                </Grid>
                
                {/* Right Grid: Added Products */}
                <Grid item xs={12} md={4}  sx={{ display: 'flex', flexDirection: 'column', borderRadius: '5px' }}>
                    <Box sx={{ mb: 2, display: 'flex', flexDirection: 'row', gap: '50px', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant="h5" fontWeight="bold">Your Stack</Typography>
                        <Typography variant="subtitle1">Products in your stack</Typography>
                    </Box>
                    <Grid 
                        container spacing={1}
                        ref={dropRef}
                        sx={{ 
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignContent:'flex-start',
                            padding: '10px',
                            height: '100%',
                            gap: '10px',
                            transition: 'all 0.2s ease-in-out',
                            ...(isOver && { 
                                borderRadius: '5px', 
                                
                             })
                        }}
                    >
                        {stackProducts.map(product => (
                            <Box sx={{ position: 'relative' }} key={product.product_id}>
                                <BasicDisplay 
                                    product={product}
                                    showTooltip={false} 
                                />
                                <Box 
                                    sx={{
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '12px',
                                        top: 4,
                                        right: 4,
                                        width: '18px',
                                        height: '18px',
                                        '&:hover': {
                                            width: '24px',
                                            height: '24px',
                                            backgroundColor: 'grey',
                                            borderRadius: '50%',
                                            top: 4,
                                            right: 4,
                                            cursor: 'pointer',
                                        }
                                    }}
                                    onClick={() => handleRemoveProduct(product.product_id)}
                                >
                                    x
                                </Box>
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NewStackDisplay;
