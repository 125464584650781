// src/runnercore/createstack/createnewstack/newstackinfo/newStackInfo.jsx

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import apiFetch from '../../../../helpers/apifetch';
import UserDataValidation from '../../../../components/users/useraccount/userdetailsvalidation/userDetailsValidation';
import StackDetailsForm from '../../../../components/stack/stackNew/stackdetailsform/stackDetailsForm';
import { setStackDetails as setStackDetailsRedux } from '../../../../features/stacks/temporaryId/temporaryIdSlice';
import NewStackPurpose from '../newstackpurpose/newStackPurpose';

const NewStackInfo = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    // console.log('NewStackInfo token', token);
    const user = useSelector(state => state.auth.user);
    const userId = user?.user_id;
    const [newStackUserData, setNewStackUserData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!userId) {
                return;
            }
            try {
                const response = await apiFetch(
                    `${process.env.REACT_APP_API_URL}/api/v1/stacks/${userId}`,
                    {
                        credentials: 'include',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const userDetailContext = await response.json();
                setNewStackUserData(userDetailContext);
            } catch (error) {
                console.error('Failed to fetch new stack user data:', error);
            }
        };

        fetchData();
    }, [userId, token, dispatch]);

    const [stackPurpose, setStackPurpose] = useState(null);

    const [isValidationComplete, setValidationComplete] = useState(false);
    const [stackDetails, setStackDetails] = useState({
        stack_name: '',
        stack_description: '',
        stack_type: '',
        stack_purpose: '',
        stack_is_official: false,
        stack_create_mode_id: null,
        stack_author_user_id: userId,
        stack_owner_company_id: '',
        stack_owner_user_admin_id: [userId],
    });

    const handlePurposeSelection = purpose => {
        setStackDetails(prevDetails => ({
            ...prevDetails,
            stack_purpose: purpose,
        }));
    };

    useEffect(() => {
        if (stackDetails.stack_purpose) {
            setStackPurpose(stackDetails.stack_purpose);
        }
    }, [stackDetails.stack_purpose]);

    const handleDetailsSubmit = details => {
        dispatch(setStackDetailsRedux(details));
    };

    const handleCompanyChange = selectedCompany => {
        setStackDetails(prevDetails => ({
            ...prevDetails,
            stack_owner_company_id: selectedCompany,
        }));
    };

    const handleValidationComplete = isValid => {
        if (isValid) {
            setValidationComplete(true);
        }
    };

    return (
        <Box
            className="new-stack-info"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                border: '1px solid #ee4040',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                marginBottom: '20px',
                maxWidth: '1200px',
                margin: '0 auto',
            }}
        >
            <NewStackPurpose onPurposeSelected={handlePurposeSelection} />
            {stackPurpose === 'Business' && (
                <div className="new-stack-forms">
                    <Box className="new-stack-form-fields">
                        <StackDetailsForm
                            stackDetails={stackDetails}
                            onDetailsSubmit={handleDetailsSubmit}
                            selectedPurpose={stackPurpose}
                            userId={userId}
                            onCompanyChange={handleCompanyChange}
                            isValidationComplete={isValidationComplete}
                            userDetailContext={newStackUserData}
                        />
                        <UserDataValidation
                            onValidationComplete={handleValidationComplete}
                            selectedPurpose={stackPurpose}
                            userId={userId}
                            companyId={stackDetails.stack_owner_company_id}
                            userDetailContext={newStackUserData}
                        />
                    </Box>
                </div>
            )}

            {stackPurpose === 'Personal' && (
                <div>Render of Personal to be done</div>
            )}
        </Box>
    );
};

export default NewStackInfo;
