import { css, Global } from '@emotion/react';


const GlobalStyles = ({ theme }) => (
  <Global
    styles={css`
      html,
      body {
        height: 100%;
        width: 100%;
        font-family: Source Sans 3, sans-serif;
        // overflow-y: scroll; // always show the scrollbar
        scrollbar-width: thin;
        box-sizing: border-box;
      }
      #root,
      .app,
      .content {
        height: 100%;
        width: 100%;
        font-family: ${theme.typography.fontFamily};
      }

      .content {
        margin-top: 64px;
      }

      .app {
        display: flex;
        position: relative;
      }

      ::-webkit-scrollbar {
        width: 0.5rem;
        width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #e0e0e0;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #e1e0e0;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `}
  />
);

export default GlobalStyles;