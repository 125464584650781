import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const HeaderStreamed = ({ title, subtitle, flexDirection = 'row' }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box 
            display='flex'
            alignItems='flex-end'
            flexDirection={flexDirection}
            maxWidth='1200px'
            padding='10px'
            gap='5px'
        >
            <Typography 
                variant="h4"
                color={colors.grey[100]}
                fontWeight="bold"
                mr='10px'
            >
                {title}
            </Typography>
            <Typography 
                variant="h6"
                color={colors.greenAccent[400]}
                mr='10px'
                display='flex'
                alignContent='center'
            >
                {subtitle}
            </Typography>
        </Box>
    )
}

export default HeaderStreamed;