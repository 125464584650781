import React from 'react';
import { Box, useTheme, Tooltip } from '@mui/material';
import LikeButton from '../../../../socials/likes/likeButton';
import FollowButton from '../../../../socials/follow/followButton';
import ShareButton from '../../../../socials/shares/shareButton';
import SaveButton from '../../../../socials/saves/saveButton';
import './profilePageHeaderActions.scss';

function ProfilePageHeaderActions({userData, userId, entityType, visitorUserId}) {
    const theme = useTheme();

    const buttonClass = visitorUserId ? 'testing-new logged-in' : 'testing-new no-pointer-events';

    if (!entityType || !userId) {
        return <div>Can't load actions bar...</div>;
    }

    const actionsBar = (
        <Box sx={{display: 'flex', flexDirection:'row', height: '100%'}}>
            <Box className="user-actions"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px',
                    gap: '10px',
                    height: '100%',
                    border: `1px solid ${theme.palette.neutral.main}`,
                    borderRadius: '5px',
                    backgroundColor: theme.palette.primary.dark, 
                    boxShadow: `1px 1px 2px ${theme.palette.neutral.dark}`,
                    transition: 'all 0.5s ease',
                    '&:hover': {
                        boxShadow: `1px 1px 2px ${theme.palette.neutral.light}`,
                        cursor: 'pointer', 
                    },
                }}
            >
                <Box className={buttonClass}>
                    <Tooltip title="Like">
                        <LikeButton
                            entityType={entityType}
                            entityId={userId}
                            userId={visitorUserId}
                        />
                    </Tooltip>
                </Box>
                <Box className={buttonClass}>
                    <Tooltip title="Follow">
                        <FollowButton
                            entityType={entityType}
                            entityId={userId}
                            userId={visitorUserId}
                        />
                    </Tooltip>
                </Box>
                <Box className={buttonClass}>
                    <Tooltip title="Save">
                        <SaveButton
                            entityType={entityType}
                            entityId={userId}
                            userId={visitorUserId}
                        />
                    </Tooltip>
                </Box>
                <Box className={buttonClass}>
                    <Tooltip title="Share">
                        <ShareButton
                            entityType={entityType}
                            entityId={userId}
                            userId={visitorUserId}
                        />
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box className='profilePageHeaderActions-main'>
            {visitorUserId ? actionsBar : <Tooltip title="Login to take actions on the profile" placement="top">{actionsBar}</Tooltip>}
        </Box>
    );
}

export default ProfilePageHeaderActions;