import React, { useState } from 'react';
import { Grid } from "@mui/material";
import './userPublicBadges.scss';

const UserPublicBadges = () => {
    const [showAllBadges, setShowAllBadges] = useState(false);
    const [showAllAchievements, setShowAllAchievements] = useState(false);

    const badges = Array(8).fill().map((_, i) => ({ id: i, color: 'blue' }));
    const achievements = Array(8).fill().map((_, i) => ({ id: i, color: 'green'}));

    return (
        <Grid container spacing={2} className='badges-main'>
            <Grid item xs={12} sm={6}>
                <div className="badges-section">
                    <h5>Badges</h5>
                    <div className="badges-display">
                        {(showAllBadges ? badges : badges.slice(0, 5)).map(badge => (
                            <div 
                                key={badge.id}
                                className="badge"
                                style={{
                                    border: '1px solid white',
                                    width: '40px',
                                    height: '40px',
                                    backgroundColor: badge.color
                                }}
                            />
                        ))}
                    </div>
                    {badges.length > 5 && <button onClick={() => setShowAllBadges(!showAllBadges)}>{showAllBadges ? 'Show less' : 'Show all'}</button>}
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div className="achievements-section">
                    <h5>Achievements</h5>
                    <div className="achievements-display" sx={{display: 'flex', flexDirection: 'column'}}>
                        {(showAllAchievements ? achievements : achievements.slice(0, 5)).map(achievement => (
                            <div 
                                key={achievement.id}
                                className='achievement'
                                style={{
                                    border: '1px solid white',
                                    width: '40px',
                                    height: '40px',
                                    backgroundColor: achievement.color
                                 }}
                            />
                        ))}
                    </div>
                    {achievements.length > 5 && <button onClick={() => setShowAllAchievements(!showAllAchievements)}>{showAllAchievements ? 'Show less' : 'Show all'}</button>}
                </div>
            </Grid>
        </Grid>
    );
};

export default UserPublicBadges;
