import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPersonalInfo } from '../../features/stacks/temporaryStackSlice/temporaryStackSlice';
import { Box, Button, useTheme, styled } from '@mui/material';
import UserPersonalsForm from '../../components/users/useraccount/userdetailsform/userPersonalFormPartial/userPersonalsForm';  
import HeaderStreamed from '../../components/headerstreamed/HeaderStreamed';


const NextButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.paper,
    '&:disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
    },
    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.background.paper,
    },
    '&:disabled:hover': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
    },
    transition: 'none',
}));

const BackButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
}));

const StackDetailPersonalStepper = ({token, userId, newStackUserData, onNext, onBack}) => {
    const dispatch = useDispatch();
    const [isValid, setIsValid] = useState(false);
    const isNextDisabled = !isValid;
    
    const handleNext = () => {
        if (isValid) {
            const personalInfo = {
                stack_author_user_id: userId,
                stack_owner_user_admin_id: userId,
                userId: userId,
            };
            dispatch(setPersonalInfo(personalInfo));
            onNext();
        }
    };

    return (
        <Box sx={{margin: '20px 10px'}}>
            <Box sx={{margin: '0px'}}>
                <HeaderStreamed title='Personal Details' subtitle=' confirm or enter missing details'/>
            </Box>
            <Box sx={{padding: '10px', border: '1px solid white', borderRadius: '5px'}}>
                <UserPersonalsForm
                    token={token}
                    userId={userId}
                    newStackUserData={newStackUserData}
                    isValid={isValid} 
                    setIsValid={setIsValid}
                />
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    <BackButton onClick={onBack}>Back</BackButton>
                    <NextButton onClick={handleNext} disabled={isNextDisabled}>Next</NextButton>
                </Box>
            </Box>
        </Box>
    );
};


export default StackDetailPersonalStepper;
