// src>global>dashboards>popularproducts>popularProductsWidget.jsx

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@mui/material';
import BoxComponent from '../boxcomponent/BoxComponent';
import ProductCard from '../../../components/product/productCard/productCard';
import './popularProductsWidget.scss';

const PopularProductsWidget = () => {
    const userId = useSelector(state => state.auth.user?.user_id);
    const entityType = 'product';
    const [userData, setUserData] = useState(null);
    const [products, setProducts] = useState([]);
    

    useEffect(() => {
        const fetchUserData = async () => {
            if (!userId) {
                return;
            }
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/userDetails/user-profile/${userId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const userData = await response.json();
                setUserData(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        
        const fetchTopLikedProducts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/interactions/like/top-liked-products`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                //response header addition
                if (!response.headers.get('content-type').includes('application/json')) {
                    console.error('Received non-JSON response');
                    return;
                }
                const products = await response.json();
                const updatedProducts = await Promise.all(products.map(async product => {
                    if (!product.entity_id) {
                        console.error('Product id is undefined for product:', product);
                        return product;
                    }
                    const detailsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/product/details/${product.entity_id}`);
                    if (!detailsResponse.ok) {
                        throw new Error(`HTTP error! status: ${detailsResponse.status}`);
                    }
                    //response header addition
                    if (!detailsResponse.headers.get('content-type').includes('application/json')) {
                        console.error('Received non-JSON response');
                        return;
                    }
                    const details = await detailsResponse.json();
                    const url = `/products/${(details.domain_name || 'undefined').replace(/ /g, '-')}/${(details.primary_category_name || 'undefined').replace(/ /g, '-')}/${(details.primary_sub_category_name || 'undefined').replace(/ /g, '-')}/${product.entity_id}/${(details.product_name || 'undefined').replace(/ /g, '-')}`;
                    return { ...product, pathDetails: { ...details, url } };
                }));
                setProducts(updatedProducts);
            } catch (error) {
                console.error('Error fetching top liked products:', error);
            }
        };
    
        fetchUserData();
        fetchTopLikedProducts();
    }, [userId]);

    
    return (
        <Box className='popular-products-main-container' >
            <Grid container direction="row" wrap="nowrap"  spacing={2}>
                {products.map(product => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <BoxComponent 
                            className='popular-card-wrapper'
                            width={3}
                            showButton={true}
                            link={product.pathDetails.url}
                            platform='internal'
                        >
                            <ProductCard
                                className="product-card-style"
                                productId={product.entity_id}
                                userId={userId || null}
                                entityType={entityType}
                                pathDetails={product.pathDetails}
                                userData = {userData}
                                showName={true}
                                showVendor={false}
                                showCategory={false}
                                showSubcategory={false}
                                showSocialButtonsOne={false}
                                showSocialButtonsTwo={false}
                                showBookmarkButton={false}
                                showQuickView={true}
                                flexDirection={'column'}
                            />
                        </BoxComponent>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default PopularProductsWidget;