import React from "react";
import { Box } from "@mui/material";
import HeaderStreamed from "../../../components/headerstreamed/HeaderStreamed";
import BoxComponent from '../../../global/dashboards/boxcomponent/BoxComponent';
import SideBarComponent from '../../../global/dashboards/sidepanelcomponent/SideBarComponent';
import PeopleCard from "../../../components/users/peoplecard/peopleCard";
import PopularProductsWidget from '../../../global/dashboards/popularproducts/popularProductsWidget';
import UserAboutPublic from "../../../components/users/userprofile/userabout/userAboutPublic";
import UserInterestsPublic from "../../../components/users/userprofile/userabout/userinterests/userPublicInterests";
import UserPublicBadges from "../../../components/users/userprofile/userabout/userbadges/userPublicBadges";
import './profileAboutUser.scss';

const ProfileAboutUser = ({userId, userData, entityTypeId, visitorUserId}) => {
    
    const entityType= 'user';
  return(
    <Box className='user-profile-page-container'>
        <HeaderStreamed title="About" subtitle={`This is the about page of user with ID: ${userId}`} />
        <Box className="profile-container-main" >
            <Box className="profile-gridPlusSide" >
                <Box className= "profile-main-grid">
                                    
                    <BoxComponent
                        title="Intro and Company Info"
                        width={12}
                    >
                        <UserAboutPublic userId={userId} userData={userData} entityType={entityType} visitorUserId={visitorUserId} showCompanyLink = {false}/>
                    </BoxComponent>
                    <BoxComponent
                        title="Interests"
                        // subtitle="get from Account > Feed > Topics/Categories/Interests"
                        width={12}
                    >
                        <UserInterestsPublic/>
                    </BoxComponent>
                    
                    <BoxComponent 
                    title="Badges and Achievements" 
                    width={12}
                    >
                        <UserPublicBadges/>
                    </BoxComponent>
                </Box>
                <Box className="profile-right-panel" >
                    <SideBarComponent title="Recent Activity" link="https://www.google.com"/>
                    <SideBarComponent title="Similar Users" showButton={false}>
                        <Box padding='5px'>
                            <PeopleCard 
                                userId={userId}
                                userData={userData}
                                entityType={entityType}
                                visitorUserId={visitorUserId}
                                showFirstName = {true}
                                showUserCompany = {true}
                                showBusinessRole = {true}
                                showStacks = {false}
                                showActions= {false}
                                showQuickView = {true}
                                imgStyle={{ width: '40px', height: '40px' }}
                                flexDirection = 'row'
                            />
                        </Box>
                    </SideBarComponent>
                    <SideBarComponent 
                        title="Stats on User Industry"
                        subtitle="UserProfileWidgetList removed"
                        sideText="10"
                        showButton={true}
                        link="https://www.google.com"
                    >
                    </SideBarComponent>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}


export default ProfileAboutUser;